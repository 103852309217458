<template>
  <router-link
    v-cloak
    class="image-card"
    :class="cardLayout"
    :title="unit.name"
    :to="{ name: 'unit-view', params: { unit_id: unit.unit_id }
    }"
  >

    <div class="ic__mask ic__dimmer"></div>

    <div :class="[isGrid ? 'ic__gradient--grid' : 'ic__gradient--full', 'ic__mask' ]">
    </div>

    <div
      class="ic__mask ic__bg"
      :style="{ 'background-image': 'url(' + unit.cover_image + ')' }"
    >
    </div>

    <div class="action-2 text-white" style="z-index: 10;">
      {{ unit.title }}
    </div>

    <div
      v-if="unit.unit_type == 'Video'"
      :class="isGrid ? 'ic__cta' : 'ic__cta--full'"
    >
      <button class="body-2 cta-btn" @click="goToUnit">
        WATCH
      </button>
    </div>


  </router-link>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import { MdiPhoto, MdiVideoCam } from '@/components/graphics';
import PartnerLogo from '@/components/graphics/PartnerLogo.vue';

export default {
  name: 'image-card',
  props: {
    isGrid: {
      type: Boolean,
      default: false,
    },
    unit: Object,
  },
  components: {
    AvatarIcon,
    MdiPhoto,
    MdiVideoCam,
    PartnerLogo,
  },
  computed: {
    cardLayout() {
      return this.isGrid ? 'l-grid' : 'l-full';
    },
    imgClass() {
      if (this.unit.unit_type == 'Playlist') {
        return 'mc__cover-img-stack';
      }
      return 'mc__cover-img';
    },
    unitCardIcon() {
      const iconMap = {
        Image: 'mdi-photo',
        Video: 'mdi-video-cam',
      };

      return iconMap[this.unit.unit_type];
    },
  },
  methods: {
    goToUnit() {
      // placeholder - ask CJ: where does this route to if not to the unitview itself?
      // eslint-disable-next-line
      console.log("goes to unit");
    },
  },
};
</script>

<style lang="scss" scoped>

// General layout & background

[v-cloak] > * {
  display: none;
}

.image-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}

.l-full {
  width: 100%;
  min-height: 122px;
}

.l-grid {
  width: 140px;
  height: 140px;
}

.ic__mask {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}

.ic__bg {
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ic__dimmer {
  z-index: 2;
  background-color: var(--disabled);
  background-blend-mode: overlay;
}

.ic__gradient--grid {
  z-index: 3;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.ic__gradient--full {
  z-index: 3;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

// Text section
.ic__cta {
  position: absolute;
  left: calc(50% - 81px/2 - 0.5px);
  bottom: 10px;
  z-index: 20;
}

.ic__cta--full {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 20;
}

.cta-btn {
  cursor: pointer;
  width: 84px;
  height: 32px;
  background-color: var(--disabled);
  border: 1px solid white;
  color: white;
  z-index: 20;
}
</style>
