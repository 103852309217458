<template>
  <div>
    <keep-alive>
      <QaModule
        v-if="qaMode"
        :context-state="contextState"
      />
    </keep-alive>
    <ShareTool
      v-if="contextState == 'share'"
      :in-profile="inProfile"
    />
    <PurchaseTool v-if="contextState == 'purchase'" />
  </div>
</template>

<script>
import PurchaseTool from '@/components/tools/PurchaseTool.vue';
import QaModule from "@/components/qa/QaModule.vue";
import ShareTool from '@/components/tools/ShareTool.vue';

export default {
  name: 'ContextTray',
  components: {
    PurchaseTool,
    QaModule,
    ShareTool,
  },
  props: {
    contextState: {
      type: String,
      required: true,
    },
    inProfile: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    qaMode() {
      return this.contextState == 'preview' || this.contextState == 'summary' || this.contextState == 'active';
    },
  },
};
</script>
