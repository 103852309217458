import store from './store';

const STORAGE_LABEL = 'idealog_token';

export const loadToken = () => {
  const serializedToken = localStorage.getItem(STORAGE_LABEL);
  console.log({ serializedToken });
  if (serializedToken === null) {
    console.log('auth token not found');
    return undefined;
  }
  console.log('Found auth token', serializedToken);
  return JSON.parse(serializedToken);
};

export const saveToken = (token) => {
  try {
    const serializedToken = JSON.stringify(token);
    localStorage.setItem(STORAGE_LABEL, serializedToken);
    console.log('Token saved', serializedToken);
  } catch (err) {
    console.log('Error saving token', err);
  }
};

export const removeToken = () => {
  try {
    localStorage.removeItem(STORAGE_LABEL);
    console.log('Token removed');
  } catch (err) {
    console.log('Error removing token', err);
  }
};

export const checkAuth = () => {
  const serializedToken = Boolean(localStorage.getItem(STORAGE_LABEL));
  return serializedToken;
}

export const logOut = async () => {
  await removeToken();
  store.dispatch('LOGOUT');
}
