<template>
  <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M8,5.14V19.14L19,12.14L8,5.14Z" />
  </svg>
</template>

<script>
export default {
  name: 'mdi-play',
};
</script>

<style scoped>
</style>
