<template>
  <div
    ref="reg"
    class="registration-wrapper"
  >
    <CloseButton />

    <div
      class="rf-tray"
      @scroll="handleScroll"
    >
      <div class="rf-header">
        <TitleText
          :title="activeTitle"
          :description="activeSubtitle"
        />
      </div>

      <div v-if="currentStep === 0">
        <div class="rf-item">
          <TextInput
            autofocus="autofocus"
            field="firstName"
            placeholder="First name"
            required
            tabindex="0"
            :value="userInput.firstName"
            @input="updateUserInput"
          />
          <TextInput
            field="lastName"
            placeholder="Last name"
            required
            tabindex="0"
            :value="userInput.lastName"
            @input="updateUserInput"
            @keyup.native.enter="validateName"
          />
        </div>

        <div class="rf-item">
          <div class="roles-header">
            <div class="headline text-secondary">
              What do you do?
            </div>
            <div class="body-2 text-disabled">
              Select any
            </div>
          </div>

          <template v-for="role in roles">
            <input
              type="checkbox"
              :key="'input-' + role.title"
              :id="role.title"
              :name="role.title"
            >
            <label
              :key="'label-' + role.title"
              :for="role.title"
            >
              {{ role.label }}
            </label>
          </template>
        </div>
      </div>

      <div v-if="currentStep === 1">
        <div class="rf-item">
          <div class="input__email">
            <TextInput
              field="email"
              placeholder="Email address"
              required
              :value="userInput.email"
              @input="updateUserInput"
            />
          </div>

          <TextInput
            field="password"
            password
            placeholder="Password"
            required
            :value="userInput.password"
            @input="updateUserInput"
          />

          <h3 class="tag text-secondary">
            PRACTICE SAFE SIGN-IN:
          </h3>

          <ul
            class="body-2 text-secondary"
            style="list-style-type: disc; margin-left: 20px; margin-bottom: 20px;"
          >
            <li>
              Use a long, memorable pass<em>phrase</em>
            </li>
            <li>Use a different password everywhere</li>
            <li>Use a password manager</li>
          </ul>

          <transition-group name="fade">
            <div
              v-if="showErrors"
              key="credential-errors"
              class="tag text-negative"
            >
              <div v-if="$v.userInput.email.$invalid">
                INVALID EMAIL.
              </div>
              <div v-if="$v.userInput.password.$invalid">
                PASSWORD MUST BE AT LEAST 6 CHARACTERS LONG.
              </div>
              <div v-if="createUserError">
                EMAIL IS ALREADY TAKEN BY ANOTHER USER.
              </div>
            </div>
          </transition-group>
        </div>
        <!--

        <div class="rf-item">
          <p class="secondary">Want your data leaked?</p>
          <a class="box-button facebook-button"><MdiFacebookBox /> Continue with Facebook</a>
        </div>
        -->
      </div>


      <div v-if="currentStep === 2">
        <div class="rf-terms-list">
          <template v-for="term in termsList">
            <div
              :key="'term-' + term.theme"
              class="body-2"
            >
              <MdiAlien :class="term.theme" />
              {{ term.text }}
            </div>
          </template>
        </div>

        <div class="tabs">
          <div class="tab-button-wrap">
            <div
              v-for="tab in termsTabs"
              :key="'tab-' + tab.id"
              class="tab-button action-2"
              :class="{ 'active': termIsActive(tab.id) }"
              :id="tab.id"
              @click="toggleTerms"
            >
              <span>{{ tab.text }}</span>
            </div>
          </div>
        </div>

        <div class="tab-content-wrap">
          <TermsText :terms-active="termsActive" />
        </div>
      </div>


      <div
        v-if="currentStep === 3"
        class="user-info-module"
      >
        <UserProfileEditor
          v-if="!loading"
          :user="user"
        />
      </div>

      <div
        v-if="currentStep === 4"
        class="rf__follow"
      >
        <template v-for="(user,i) in units[1].associated_creators">
          <div
            :key="'to-follow-' +user.user_id"
            class="follow-user-wrap"
          >
            <AvatarIcon
              :image="user.image"
              :title="user.name"
            />
            <h2 class="title-2 inline-block">
              {{ user.name }}
            </h2>
            <IconButton
              v-if="following[i].status"
              icon="mdi-account-check"
              @click="toggleFollow(i)"
            />
            <IconButton
              v-else
              class="icon-btn--tint"
              icon="mdi-account-plus"
              @click="toggleFollow(i)"
            />
          </div>
          <div
            :key="'follow-description-' + user.user_id"
            class="follow-user-description"
          >
            {{ following[i].description }}
          </div>
        </template>
      </div>

      <div v-if="currentStep === 5">
        <UnitPreviewList v-if="!loading" />
      </div>
    </div>

    <div class="lower-section">
      <RegistrationToolbelt
        :current-step="currentStep"
        :scrolled-to-bottom="scrolledToBottom"
        :validations="$v.userInput"
        @increment="incrementStep"
        @errors="handleErrors"
      />
      <RegistrationFunnel
        :current-step="currentStep"
        :unlocked="unlocked"
        @increment="incrementStep"
      />
    </div>
  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import CloseButton from '@/components/buttons/CloseButton.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import MdiAlien from '@/components/graphics/MdiAlien.vue';
// import MdiFacebookBox from "@/components/graphics/MdiFacebookBox.vue";
import RegistrationFunnel from '@/components/account/RegistrationFunnel.vue';
import RegistrationToolbelt from '@/components/account/RegistrationToolbelt.vue';
import TermsText from '@/components/account/TermsText.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TitleText from '@/components/shared/TitleText.vue';
import UnitPreviewList from '@/components/unit/UnitPreviewList.vue';
import UserProfileEditor from '@/components/account/UserProfileEditor.vue';

import { mapActions, mapState } from 'vuex';
import { required, email, minLength } from 'vuelidate/lib/validators';


export default {
  name: 'RegistrationContainer',

  validations: {
    userInput: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
    },
  },

  components: {
    AvatarIcon,
    CloseButton,
    IconButton,
    MdiAlien,
    // MdiFacebookBox,
    RegistrationFunnel,
    RegistrationToolbelt,
    TermsText,
    TextInput,
    TitleText,
    UnitPreviewList,
    UserProfileEditor,
  },

  data() {
    return {
      userInput: {
        bio: null,
        email: null,
        firstName: null,
        lastName: null,
        password: null,
      },

      // Logic for stepping through flow
      currentStep: 0,
      unlocked: false,
      termsActive: true,
      scrolledToBottom: false,
      createUserError: false,
      showErrors: false,

      // Data for UI text
      titles: ['Hey there', 'Hi ', "It's terms time", "You're all set", "Who's who", "What's what"],
      subtitles: ["What's your name?", "Let's make your account", "Here's a summary", 'Wanna snazz up your profile?', 'Some people to follow', 'Begin an inquiry'],
      roles: [
        { title: 'creator', label: 'I create culture' },
        { title: 'curator', label: 'I curate cultural works' },
        { title: 'advocate', label: 'I advocate for culture' },
      ],
      termsList: [
        { theme: 'danger', text: 'We can monetize anything you say or do on Idealog.' },
        { theme: 'neutral', text: 'So can you.' },
        { theme: 'tip', text: 'We are free to use tracks, videos, and images you upload.' },
        { theme: 'warning', text: 'You must have legal rights to anything you upload.' },
      ],
      termsTabs: [
        { id: 'terms', text: 'Terms of service' },
        { id: 'privacy', text: 'Privacy policy' },
      ],
      following: [
        { status: false, description: 'I love music.' },
        { status: false, description: "Oh hey, it's me. Thanks for looking at my profile." },
        { status: false, description: 'Check out my new album!' },
      ],
    };
  },

  computed: {
    activeTitle() {
      if (this.currentStep == 1) {
        return `${this.titles[1]}  ${this.userInput.firstName}!`;
      }
      return this.titles[this.currentStep];
    },
    activeSubtitle() {
      return this.subtitles[this.currentStep];
    },
    ...mapState(['loading', 'user', 'units']),
  },

  mounted() {
    this.calculateVH;
    window.addEventListener('resize', this.calculateVH);
  },

  destroyed() {
    window.removeEventListener('resize', this.calculateVH);
  },

  methods: {

    ...mapActions(['LOAD_USER', 'LOAD_UNITS_DUMMY']),

    calculateVH() {
      this.$_.debounce(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 500);
    },

    handleErrors(errorType) {
      if (errorType == 'create-user') {
        this.showErrors = true;
        this.createUserError = true;
        setTimeout(() => {
          this.showErrors = false;
          this.createUserError = false;
        }, 3000);
      } else {
        this.showErrors = this.$v.$invalid;
      }
    },

    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.scrolledToBottom = true;
      }
    },

    incrementStep(step) {
      if (typeof step === 'number') {
        this.currentStep = step;
      } else {
        this.currentStep++;
      }
    },

    termIsActive(tab) {
      return tab == 'terms' ? this.termsActive : !this.termsActive;
    },

    toggleTerms() {
      this.termsActive = !this.termsActive;
    },

    toggleFollow(index) {
      this.following[index].status = !this.following[index].status;
    },

    updateUserInput(data) {
      const { field } = data;
      this.userInput[field] = data.value;
    },

    validateName() {
      const vals = this.$v.userInput;
      if (vals.firstName.required && vals.lastName.required) {
        this.incrementStep();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
a.close-button {
  top: 0px;
  left: 0px;
}
.follow-user-wrap {
  display: flex;
  padding-bottom: calc(var(--global-padding) / 3);
}
.registration-wrapper {
  /*    max-width:640px;*/
  width: 100%;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  align-content: space-between;
  flex-direction: column;

  .rf-tray {
    padding: 32px var(--global-padding);
    height: calc((var(--vh, 1vh) * 100) - 100px);
  }
}

.rf-tray {
  flex: 1;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  max-height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.rf-header {
  margin-bottom: 20px;
}

.rf__follow {
  display: flex;
  flex-direction: column;
}

.follow-user-wrap {
  display: flex;
  padding-bottom: calc(var(--global-padding) / 3);
}
.follow-user-description {
  margin-bottom: var(--global-padding);
}
.follow-user-wrap > * {
  flex: 1;
  vertical-align: middle;
  line-height: 40px;
  &.title-2 {
    margin: 0 calc(var(--global-padding) / 2);
  }
}
.size-40 {
  height: 40px;
  width: 40px;
  &.idealog-icon {
    &:not(.active) {
      background: rgba(0, 0, 0, 0.05);
    }
    svg {
      fill: rgba(0, 0, 0, 0.5) !important;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
.user-info-module {
  overflow-x: hidden;
}

.registration-toolbelt {
  display: flex;
  flex-direction: row;
}
.tab-content-wrap {
  position: relative;
  background: #ffffff;
  padding: var(--global-padding);
  margin: calc(-1 * var(--global-padding)) calc(-1 * var(--global-padding))
    calc(-1 * var(--global-padding)) calc(-1 * var(--global-padding));
}
.tabs {
  margin: calc(2 * var(--global-padding)) calc(-1 * var(--global-padding)) 0
    calc(-1 * var(--global-padding));
  overflow-x: hidden;
}
.tab-button {
  text-align: center;
  display: inline-block;
  z-index: 0;
  flex: 1;
  max-width: calc(45% + 10px);
  width: calc(45% + 10px);
  color: rgba(127, 127, 127, 1);
  font-weight: bold;
  padding: 0 10px;
  border-right: 20px solid transparent;
  border-bottom: 40px solid rgba(0, 0, 0, 0.05);
  border-left: 20px solid transparent;
  position: relative;
  margin-left: -20px;
  top: -20px;
  z-index: 1;
  &:first-child {
    margin-left: 0;
  }
  &.active {
    z-index: 2;
    pointer-events: none;
    border-bottom-color: #ffffff;
  }
  span {
    position: relative;
    top: 30px;
  }
}

body {
  background: #fafafa;
}
.rf-terms-list {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    margin-bottom: 10px;
    svg {
      margin-right: 10px;
    }
  }
}

svg.danger {
  fill: var(--negative);
}
svg.warning {
  fill: var(--warning);
}
svg.neutral {
  fill: var(--neutral);
}
svg.tip {
  fill: var(--tip);
}

label {
  display: block;
  margin-bottom: var(--global-padding);
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 0;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  height: 40px;
  width: 40px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  line-height: 0;
  vertical-align: middle;
  margin: 0 10px 3px 0;
  z-index: 1;
}
input[type="checkbox"]:checked + label {
  color: #000;
  &:after {
    content: "";
    position: absolute;
    display: block;
    z-index: 2;
    top: 13px;
    left: 12px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-top: none;
    border-right: none;
    transform: rotate(-50deg);
    height: 8px;
    width: 18px;
  }
}

.input__email {
  padding-top: 15px;
}

.rf-item {
  margin-bottom: 32px;
}

.roles-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.lower-section {
  display: flex;
  display: block;
  width: 100%;
  height: 100px;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
