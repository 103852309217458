<template>
  <div class="dot-container">
    <div class="dot-icon" />
    <div class="dot-icon is-second" />
    <div class="dot-icon is-third" />
  </div>
</template>

<script>
export default {
  name: 'loading-indicator',
};
</script>

<style lang="scss">
  .dot-container {
    display: inline-block;
  }

  .dot-icon {
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background-color: #6BF178;
    margin: 1px;
    display:inline-block;
    vertical-align:middle;
    animation-name: opacityLoop;
    animation-duration: .7s;
    animation-iteration-count:infinite;
    animation-direction:alternate;
    animation-play-state:playing;
    animation-delay: -.5s;
    opacity: 1;
  }

  .dot-icon.is-second {
    animation-delay: -0.35s;
  }

  .dot-icon.is-third {
    animation-delay: -.15s;
  }

  @keyframes opacityLoop {
    0% {opacity: 1;}
    100% {opacity: 0;}
  }
</style>
