<template>
  <div class="media-item">
    <div
      v-if="image"
      class="mi__image-wrap"
    >
      <img :src="image">
    </div>

    <div class="mi__track-info">
      <div class="track-data body-2 text-sec-white">
        <div>
          {{ item.name }}
        </div>
        <div class="track-duration">
          {{ item.duration_str }}
        </div>
      </div>
      <img
        class="track-genome"
        :src="require('@/assets/png/track-genome.png')"
      >
    </div>
    <div class="mi__columns">
      <div class="mi__column body-2 text-sec-white">
        {{ item.ensemble }}
      </div>
      <div class="mi__column body-2 text-sec-white">
        {{ item.series }}
      </div>
      <div class="mi__column body-2 text-sec-white">
        {{ item.releaseDate }}
      </div>
      <div class="mi__column body-2 text-sec-white">
        {{ item.duration_str }}
      </div>
    </div>
  </div>
</template>

<script>
const Chance = require('chance');
const chance = new Chance();

export default {
  name: "MediaItem",
  props: {
    image: {
      default: '',
      type: String,
    },
    item: {
      required: true,
      type: Object,
    }
  },
  // data() {
  //   return {
  //     company: '',
  //   }
  // },
  // created() {
  //   this.company = this.randomCompany();
  // },
  // methods: {
  //   randomCompany() {
  //     let company = chance.company();
  //     company = company.split(' ').slice(0,2).join(' ');
  //     return company;
  //   },
  // }
}
</script>

<style lang="scss" scoped>
.media-item {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 14px;
}

.mi__image-wrap {
  position: relative;
  flex: 0 0 36px;
  height: 36px;
  display: inline-block;
  margin-right: 12px;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.mi__track-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 36px;
  transition: width 300ms ease, margin-right 300ms ease;
  @include for-size(medium-screen-up) {
    width: 300px;
    margin-right: 32px;
    transition: width 300ms ease, margin-right 300ms ease;
  }
  @include for-size(tablet-landscape-up) {
    margin-right: 64px;
    transition: margin-right 300ms ease;
  }
  .track-data {
    display: flex;
    justify-content: space-between;
    line-height: 14px;
    padding-bottom: 2px;
  }

  .track-duration {
    opacity: 1;
    transition: opacity 300ms ease;
    @include for-size(medium-screen-up) {
      opacity: 0;
    }
  }

  .track-genome {
    width: 100%;
    height: 14px;
    object-fit: cover;
    object-position: center center;
  }
}

.mi__columns {
  display: none;
  @include for-size(medium-screen-up) {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  .mi__column:first-of-type {
    width: 120px;
  }

  .mi__column:nth-of-type(2) {
    width: 120px;
  }

  .mi__column:nth-of-type(3) {
    width: 80px;
  }

  .mi__column:nth-of-type(4) {
    width: 42px;
  }
}

</style>
