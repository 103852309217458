<template>
  <div class="sales-bottom-tray">
    <div class="sbt__bundle-price">
      <div class="tag text-hint-white">
        BUNDLE PRICE:
      </div>
      <div class="title-2 text-white">
        {{ formattedPrice }}
      </div>
    </div>

    <div class="hero-2 text-sec-white">
      &asymp;
    </div>

    <div class="sbt__value-comparison">
      <div v-if="currentStep == 0" class="body-3 text-white">
        Payout generated by <span class="text-positive">560,000 streams</span> on avg streaming service
      </div>

      <div v-if="currentStep == 1" class="body-1 text-white">
        <span class="text-positive">46 lbs</span> of &#x1F34C;
      </div>

      <div
        v-if="currentStep == 2 && disabledTextIsDefault && !paymentInfoIsValid"
        class="body-3 text-white"
      >
        <span class="text-positive">4 minutes</span> of production time
      </div>

      <div
        v-if="currentStep == 2 && !disabledTextIsDefault && !paymentInfoIsValid"
        class="body-1 text-white"
      >
        <span class="text-positive">9.5 gal</span> of &#x26FD;
      </div>

      <div
        v-if="currentStep == 2 && paymentInfoIsValid"
        class="body-3 text-white"
      >
        <span class="text-positive">12 sales</span> if distributed through a label
      </div>

      <div v-if="currentStep == 3" class="body-3 text-white">
        <span class="text-positive">2.7 hours</span> of work at the US minimum wage
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sales-bottom-tray',
  props: {
    currentStep: Number,
    price: String,
  },
  data() {
    return {
      disabledText: 'Add payment info to check out',
      paymentInfoIsValid: false,
    };
  },
  computed: {
    disabledTextIsDefault() {
      return this.disabledText == 'Add payment info to check out';
    },
    formattedPrice() {
      return `$${this.price}.00`;
    },
  },
  created() {
    this.$eventBus.$on('update-disabled-text', () => {
      this.disabledText = 'Fix your card details to continue';
    });
    this.$eventBus.$on('validate-payment-info', (boolean) => {
      this.paymentInfoIsValid = boolean;
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('update-disabled-text');
    this.$eventBus.$off('validate-payment-info');
  },

};
</script>

<style lang="scss" scoped>
.sales-bottom-tray {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
}

.sbt__bundle-price {
  display: flex;
  flex-direction: column;
  // position: absolute;
  width: 92px;
  height: 44px;
  // left: 12px;
  // top: 8px;
  padding: 2px 0px 4px 0px;
}

.sbt__value-comparison {
  // position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 147px;
  height: 52px;
  // left: 163px;
  // top: 4px;
  padding: 2px 8px;
  border-radius: 5px;
  background: var(--stroke-white);

  > div {
    text-align: center;
  }
}
</style>
