<template>
  <router-link
    v-cloak
    class="text-card"
    :class="cardWidth"
    :title="unit.name"
    :to="{ name: 'unit-view', params: { unit_id: unit.unit_id }
    }"
  >

    <div class="tc__title action-2 text-primary">
      {{ unit.title }}
    </div>

    <div
      class="tc__main"
      :class="isGrid ? 'l-main-ctr' : 'l-main-sb'"
    >
      <div v-if="!isGrid" class="main__desc tag text-secondary">
        {{ unit.description }}
      </div>

      <span class="main__img" :class="isGrid ? 'l-img--grid': 'l-img--full'">
        <img :src="this.unit.cover_image" />
      </span>

    </div>

  </router-link>
</template>

<script>

export default {
  name: 'TextCard',
  props: {
    isGrid: {
      type: Boolean,
      default: false,
    },
    unit: Object,
  },
  computed: {
    cardWidth() {
      return this.isGrid ? 'l-grid' : 'l-full';
    },
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] > * {
  display: none;
}

.text-card {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
  padding: 10px;
}

.l-full {
  height: 138px;
  width: 100%;
  justify-content: space-between;
}

.l-grid {
  min-height: 128px;
  max-height: 200px;
  width: 140px;
  justify-content: flex-start;
}

.tc__title {
  position: relative;
  margin-bottom: 10px;
  z-index: 10;
}

.tc__main {
  position: relative;
  display: flex;
  width: 100%;

  &.l-main-ctr {
    justify-content: center;
  }

  &.l-main-sb {
    justify-content: space-between;
  }

  .main__desc {
    overflow: hidden;
    max-width: 205px;
    max-height: 67px;
    font-weight: 400;
  }

  .main__img {
    background-color:rgba(0, 0, 0, 0.25);
    overflow:hidden;
    img {
      height:100%;
      width:100%;
      object-fit:cover;
    }
  }

  .l-img--grid {
    width: 78px;
    height: 55px;
  }

  .l-img--full {
    width: 95px;
    height: 67px;
  }
}

</style>
