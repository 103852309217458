<template>
  <div class="media-item-list">
    <div
      v-if="creatorView || artists.length"
      :class="['mil__header--mobile', { 'with-creator-view': creatorView }]"
    >
      <AvatarIcon
        class="avatar--xs"
        :image="avatars[0]"
      />
      <div class="headline text-white">
        {{ artists[0] }}
      </div>
    </div>

    <div v-if="!creatorView" class="mil__header">
      <div class="mil__header-buffer" />
      <div class="mil__header-columns">
        <div class="mi__column body-2 text-sec-white">
          <b>Artist</b>
        </div>
        <div class="mi__column body-2 text-sec-white">
          <b>Series</b>
        </div>
        <div class="mi__column body-2 text-sec-white">
          <b>Released</b>
        </div>
        <div class="mi__column body-2 text-sec-white">
          <b>Time</b>
        </div>
      </div>
    </div>
    <!-- <transition-group name="list" mode="out-in"> -->
    <MediaItem
      v-for="(item,i) in activeList"
      class="mil-item"
      :image="avatars[i % avatars.length]"
      :item="item"
      :key="'media-item-' + i + '-' + Math.random()"
    />
    <!-- </transition-group> -->
  </div>
</template>

<script>
import AvatarIcon from "@/components/entities/AvatarIcon.vue";
import MediaItem from "@/components/entities/MediaItem.vue";

export default {
  name: "MediaItemList",
  props: {
    activeFilters: {
      required: true,
      type: Array,
    },
    activeSort: {
      required: true,
      type: String,
    },
    artists: {
      default: () => {
        return [];
      },
      type: Array,
    },
    avatars: {
      default: () => {
        return [];
      },
      type: Array,
    },
    creatorView: Boolean,
    list: {
      required: true,
      type: Array,
    }
  },
  components: {
    AvatarIcon,
    MediaItem
  },
  computed: {
    activeList() {  
      // Pending unit types filter
      let assets = this.list.map(asset => { 
        return {...asset, mediaType: 'music'};
      });
      assets = assets.filter(asset => {
        return this.activeFilters.includes(asset.mediaType)
      });
        
      return (this.activeSort == 'date') 
        ? assets.sort((a,b) => a.purchaseDate > b.purchaseDate)
        : assets.sort((a,b) => a.name > b.name);
    }
  },
}
</script>

<style lang="scss" scoped>
.media-item-list {
  position: relative;
  display: flex;
  flex-direction: column;
}

.mil__header--mobile {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  > div:first-child {
    margin-right: 6px;
  }

  @include for-size(medium-screen-up) {
    display: none;
  }

  &.with-creator-view {
    @include for-size(medium-screen-up) {
      display: flex;
    }
  }
}

.mil__header {
  display: none;
  @include for-size(medium-screen-up) {
    display: flex;
    box-sizing: content-box;
    min-height: 20px;
    padding-top: 10px;
    margin-bottom: 20px;
    .mil__header-buffer {
      @include for-size(medium-screen-up) {
        width: 348px;
        margin-right: 32px;
        transition: margin-right 300ms ease;
      }
      @include for-size(tablet-landscape-up) {
        margin-right: 64px;
        transition: margin-right 300ms ease;
      }
    }
    .mil__header-columns {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .mi__column:first-of-type {
        width: 120px;
        transition: padding-left 300ms ease;
      }
      .mi__column:nth-of-type(2) {
        width: 120px;
      }
      .mi__column:nth-of-type(3) {
        width: 80px;
      }
      .mi__column:nth-of-type(4) {
        width: 42px;
      }
    }
  }

}

.mil-item:not(:last-of-type) {

  border-bottom: 1px solid #2d2d2d;
}

.mil-item:last-of-type {
  padding-bottom: 0px;
}

.list-move {
  transition: transform 500ms;
}
</style>
