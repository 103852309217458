<template>
  <div class="filter-menu">
    <div
      v-for="(option,i) in options"
      :key="'filter-menu-block-' + i"
      class="fm-block"
    >
      <div class="block-text">
        <div class="body-2 text-white">
          {{ option.title }}
        </div>
        <div
          class="tag text-sec-white"
          style="font-weight: 400;"
        >
          {{ option.subtitle }}
        </div>
      </div>
      <div class="block-buttons">
        <component :is="option.tool" />
      </div>
    </div>
  </div>
</template>

<script>
import SortTool from '@/components/menus/SortTool.vue';
import TypeFilters from '@/components/menus/TypeFilters.vue';
import ViewTool from '@/components/menus/ViewTool.vue';

export default {
  name: 'FilterMenu',
  components: {
    SortTool,
    TypeFilters,
    ViewTool,
  },
  data() {
    return {
      options: [
        {
          title: 'Type',
          subtitle: 'Select media type',
          tool: 'type-filters',
        },
        {
          title: 'Sort',
          subtitle: 'Sort order',
          tool: 'sort-tool',
        },
        {
          title: 'View',
          subtitle: 'Select one',
          tool: 'view-tool',
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.filter-menu {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 32px 24px 32px;
  background-color: black;
  flex-direction: column;
  height: 215px;
  @include for-size(small-desktop-up) {
    flex-direction: row;
    height: 70px;
  }
}

.fm-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include for-size(small-desktop-up) {
    width: auto;
  }
}

.block-text {
  display: flex;
  flex-direction: column;
}

.block-buttons > div, .block-buttons > button {
  margin-left: 8px;
}
</style>

