<template>
  <div
    v-if="unit"
    class="asset-module"
  >
    <div class="am__summary body-3 text-gray">
      <div>
        {{ assetModuleTitle }}
      </div>
      <div
        v-if="hasPlayer"
        v-html="assetDuration"
        class="asset-duration"
      />
    </div>

    <div class="am__title">
      <div
        v-if="isMusic"
        class="title-image-wrap"
      >
        <img :src="unit.image_url || fakerImage">
      </div>
      <div>
        <span class="headline text-white">
          {{ unit.title }}
        </span>
        <br>
        <span class="body-2 text-sec-white">
          By {{ unit.project.ensemble.name }}
        </span>
      </div>
      <button
        v-if="hasPlayer"
        class="cta-btn"
      >
        {{ isMusic ? "LISTEN" : "WATCH" }}
      </button>
    </div>
    <div
      v-if="!hasPlayer"
      class="asset-image-wrap"
    >
      <img :src="unit.image_url || fakerImage">
    </div>
    <div
      v-if="unit.unit_type !== 'Image'"
      class="body-3 text-white"
    >
      {{ unit.description }}
    </div>

    <AssetPlayer
      v-if="hasPlayer"
      :unit-id="unit.unit_id"
    />
  </div>
</template>

<script>
import AssetPlayer from '@/components/unit/AssetPlayer';
import faker from "faker";

export default {
  name: 'AssetModule',
  components: {
    AssetPlayer
  },
  computed: {
    assetDuration() {
      if (!this.hasPlayer) {
        return;
      }
      const type = this.unit.unit_type
      const duration = this.unit[type].duration_str;
      return type === 'playlist'
        ? `${this.unit.playlist.tracks.length} Tracks <br>${duration}`
        : duration;
    },
    assetModuleTitle() {
      const titles = {
        audio: 'LISTEN TO WHAT THEY MADE',
        playlist: 'LISTEN TO WHAT THEY MADE',
        post: 'READ WHAT THEY WROTE',
        video: 'WATCH WHAT THEY MADE',
        image: 'SEE THE IMAGES',
      }
      return titles[this.unit.unit_type];
    },
    fakerImage() {
      faker.seed(123);
      return faker.image.nightlife();
    },
    isNativeAudio() {
      return this.unit.unit_type === 'audio' && this.unit.audio.file.mp3;
    },
    hasPlayer() {
      const types = ['audio', 'playlist', 'video'];
      return types.includes(this.unit.unit_type) && this.isNativeAudio;
    },
    isMusic() {
      return ['audio', 'playlist'].includes(this.unit.unit_type);
    },
    unit() {
      return this.$store.state.unit;
    }
  },
}
</script>

<style lang="scss" scoped>
.asset-module {
  display: flex;
  flex-direction: column;
  width: inherit;
}

.am__summary {
  display: flex;
  justify-content: space-between; 
  flex: 0 0 auto;
  margin-bottom: 16px;
  .asset-duration {
    text-align: right;
  }
}

.am__title {
  display: flex; 
  align-items: center;
  margin-bottom: 18px;
}

.cta-btn {
  margin-left: auto;
}

.title-image-wrap {
  width: 45px;
  height: 45px;
  background-color: lightgray;
  margin-right: 8px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.asset-image-wrap {
  width: calc(100% + 16px);
  height: 200px;
  background-color: lightpink;
  margin-left: -8px;
  margin-bottom: 12px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.title-image-wrap > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.25);
}
</style>

