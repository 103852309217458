<template>
  <div class="friends-list">
    <head class="friends-header">
      <CloseButton />
    </head>
    <main>
      <p class="title-1"> Followers</p>
      <div v-for="(follower, index) in user.followers" :key="index">
        <div class="friend-head">
          <span class="avatar-wrap" :title="follower.name">
            <img draggable="false" :src= follower.image />
          </span>
          <p class="title-2 name">{{ follower.name }}</p>
          <IconButton icon="mdi-account-check" />
        </div>
        <div class="body-1">
          {{ follower.bio }}
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CloseButton from '../buttons/CloseButton.vue';
import IconButton from '../buttons/IconButton.vue';

export default {
  name: 'FollowersList',
  computed: {
    ...mapState(['loading', 'user']),
  },
  components: {
    CloseButton,
    IconButton,
  },
};
</script>

<style lang="scss" scoped>
.friends-list {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: white;
  // background: #fafafa;
}

.friends-header {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  margin: 0px 0px 32px;
}

main {
  width: 100%;
}

.friend-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 10px;
  & > :first-child {
    height: 40px;
    width: 40px;
    margin-right: 12px;
  }
  .name {
    margin-bottom: 0px;
  }
  & > :nth-child(3) {
    margin-left: auto;
  }
}
</style>
