<template>
  <div class="pl__wrap">
    <img
      v-if="this.name == 'npr'"
      :src="require('@/assets/png/npr-logo.png')"
      style="height: 13px; width: 39px;"
    />
    <img
      v-if="this.name == 'vice'"
      :src="require('@/assets/png/vice-logo.png')"
      style="height: 23px; width: 69px;"
    />
  </div>
</template>

<script>
export default {
  name: 'PartnerLogo',
  props: {
    name: String,
  },
};
</script>

<style lang="scss" scoped>
.pl__wrap {
  width:70px;
  // height:140px;
  display:inline-flex;
  justify-content: center;
  background-color: transparent;
  overflow:hidden;
  img{
    object-fit: center center;
    object-position: center;
  }
}
</style>
