<template>
  <div class="sb__container" @click="$emit('click')">
    <IconButton :class="platform" :icon="icon" />
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'SocialButton',
  components: {
    IconButton,
  },
  props: {
    icon: String,
    success: Boolean,
  },
  data() {
    return {

    };
  },
  computed: {
    platform() {
      if (this.icon == 'mdi-twitter') {
        return 'icon-btn--twitter';
      } if (this.icon == 'mdi-facebook-box') {
        return 'icon-btn--fb';
      }
      if (this.success) {
        return 'icon-btn--web--success';
      }
      return 'icon-btn--web';
    },
  },
};
</script>

<style lang="scss" scoped>
.sb__container {
  display: inline;
  z-index: 5;
}
</style>
