<template>
  <div class="audio-wave-form">
    <div
      v-for="i in 200"
      :key="'awb-bar-' + i"
      class="awb__bar"
      :style="{ backgroundColor: JSON.parse(waves)[i] }"
    />
  </div>
</template>

<script>
export default {
  name: 'AudioWaveForm',
  props: {
    waves: {
      type: String,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.audio-wave-form {
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  z-index: 2;
  content: "";
  width: 100%;
  height: 14px;
}

.awb__bar {
  content: "";
  height: 100%;
  width: 1%;
  flex: 1;
}
</style>

