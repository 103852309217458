<template>
  <div class="impact-module">
    <div class="im__tabs btn-group">
      <text-button
        v-for="(tab,i) in tabs"
        :key="`im-tab-${i}`"
        :class="[
          mode == tab ? 'btn--black-white' : 'btn--tint-white',
          'btn--pill'
        ]"
        size="xs"
        @click="handleTabClick(tab)"
      >
        {{ tab.toUpperCase() }}
      </text-button>
    </div>
    <div class="im__cta">
      <button
        v-if="bundle && bundle.unit_id"
        class="im__cta-btn cta-support-bg tag text-positive"
        :class="{ hide: mode !== 'project' }"
        @click="goToSales"
      >
        SUPPORT
      </button>
      <button
        class="im__cta-btn cta-react-bg tag text-warning"
        :class="{ hide: mode !== 'all' }"
        @click="handleTabClick('reactions')"
      >
        REACT
      </button>
    </div>
    <div class="impact-gradient" />
    <HexadGrid class="grids" />
    <Rubberband
      class="grids"
      :heights="heights"
      :mode="mode"
      :reactions="reactions"
      @update-reaction="handleUpdateReaction"
    />
    <div class="pole-grid grids">
      <ImpactPole
        v-for="(position,i) in positions"
        :key="`pole-${position}`"
        :ref="`${position}`"
        class="ip"
        :reacting="(i%2 == 0) && reacting"
        :scale="poleY[i]"
        :position="position"
        @record-pole-height="handleRecordPoleHeight"
      />
    </div>
  </div>
</template>

<script>
import HexadGrid from '@/components/impact/HexadGrid.vue';
import ImpactPole from '@/components/impact/ImpactPole.vue';
import Rubberband from '@/components/impact/Rubberband.vue';
import TextButton from '@/components/buttons/TextButton.vue';
import { mapState } from 'vuex';

export default {
  name: "ImpactModule",
  components: {
    HexadGrid,
    ImpactPole,
    Rubberband,
    TextButton
  },
  data() {
    return {
      tabs: ['project', 'all', 'reactions'],
      mode: 'all',
      axes: [
        { position: 'a', label: 'Sales', measure: 212 },
        { position: 'b', label: 'Engagement', measure: '30.4x avg' },
        { position: 'c', label: 'Investment', measure: '$55k, 404hr' },
      ],
      indices: {
        sales: 1.2,
        rating: 2.0,
        engagement: 20.0,
        missionworthiness: 0.2,
        investment: 0.1,
        effort: 0.0,
      },
      reactions: {
        ra: -0.5,
        rb: -0.7,
        rc: 0.8,
      },
      ixs: [1.5, 1.1, 1.3, 1.4, 1.2, 1.6],
      labels: [],
      positions: ['ra', 'ic', 'rb', 'ia', 'rc', 'ib'],
      reacting: true,
      heights: {
        ra: 0,
        ic: 0,
        rb: 0,
        ia: 0,
        rc: 0,
        ib: 0,
      }
    }
  },
  computed: {
    bundle() {
      const unit = this.$store.state.unit || null;
      if (!unit || !unit.bundles.length) return;
      return unit.bundles[0];
    },
    impact() {
      return this.$store.state.unit.impact || null;
    },
    poleX() {
      return this.ixs.map(ix => parseInt((ix - 1) * 10));
    },
    poleY() {
      const sigmoid = (x) => (1 / (1 + Math.pow(Math.E, -x)));
      const scale = this.poleX.map(x => +sigmoid(x).toFixed(3));
      return scale;
    },
  },
  beforeDestroy() {
    this.postUpdatedReactions();
  },
  methods: {
    goToSales() {
      this.$router.push({
        name: 'unit-checkout',
          params: { unit_id: this.bundle.unit_id }
      });
    },
    handleRecordPoleHeight({ position, height}) {
      this.heights[position] = height;
    },
    handleTabClick(tab) {
      this.mode = tab;
    },
    handleUpdateReaction({ value, position }) {
      this.reactions[position] = value;
    },
    postUpdatedReactions() {
      console.log('post updated reactions');
    }
  }
};
</script>

<style lang="scss" scoped>
.impact-module {
  position: relative;
  width: inherit;
  height: 368px;
  overflow-y: scroll;
  padding: 0px 20px;
}

.grids {
  position: absolute;
  top: calc(50% - 224px/2);
  left: calc(50% - 224px/2);
}

.pole-grid {
  width: 224px;
  height: 228px;
  z-index: 1;

  .ip {
    position: absolute;
    top: 50%;
    left: calc(50% - 224px/2);
    left: calc(50% - 33px/2);
  }
}

.im__tabs {
  display: flex;
  justify-content: center;
}

.im__cta {
  display: flex;
  // justify-content: space-between;
}

.im__cta-btn {
  height: 40px;
  padding: 0 12px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}

.cta-support-bg {
  background-color: rgba(107, 241, 120, 0.1);
}

.cta-react-bg {
  background-color: rgba(255, 231, 76, 0.1);
  margin-left: auto;
}

.hide {
  display: none;
}

.impact-gradient {
  position: absolute;
  width: 226px;
  height: 226px;
  top: calc(50% - 226px/2);
  left: calc(50% - 226px/2);
  background: linear-gradient(235.7deg, rgba(45, 156, 219, 0.5) 19.89%, rgba(255, 255, 255, 0) 42.07%, rgba(45, 156, 219, 0) 42.07%), linear-gradient(302.97deg, rgba(39, 174, 96, 0.5) -23.51%, rgba(255, 255, 255, 0) 32.59%, rgba(39, 174, 96, 0) 32.59%), linear-gradient(1.23deg, rgba(242, 201, 76, 0.5) -3.05%, rgba(242, 201, 76, 0) 38.75%), linear-gradient(57.65deg, rgba(242, 153, 74, 0.5) 1.74%, rgba(242, 153, 74, 0) 58.2%), linear-gradient(123.33deg, rgba(206, 76, 76, 0.5) 10.51%, rgba(255, 0, 0, 0) 67.17%), linear-gradient(179.33deg, rgba(155, 81, 224, 0.5) 5.61%, rgba(132, 0, 255, 0) 103.19%);
  z-index: 10;
  mix-blend-mode: hue;
}

</style>
