import axios from 'axios';
import store from '@/store/store.js';
import NProgress from 'nprogress';
import '@/assets/css/nprogress.css';
import { loadToken } from './localStorage';

const instance = axios.create({
  baseURL: 'https://idealog.liberation.fm/',
  viewURL: 'https://unit.idealog.liberation.fm',
  headers: {
    'Content-Type': 'application/json',
  },
  params: { auth_token: loadToken() },
});

// NProgress.configure({ showSpinner: false });

instance.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

instance.interceptors.response.use((response) => {
  NProgress.done();
  return response;
}, (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    store.dispatch('LOGOUT');
    window.location = '/signin';
    console.log('error 401!')
    NProgress.done();
  }
  return Promise.reject(error);
});

export default instance;
