<template>
  <div
    :class="['profile-grid', profileGridBottomPosition]"
    @click="handleContainerClick"
  >
    <MasonryLayout
      v-for="size in sizes"
      :key="'masonry-layout-' + size"
      :class="size"
      :columns="columns[size]"
    />
  </div>
</template>

<script>
import MasonryLayout from '@/components/shared/MasonryLayout.vue';

import { mapState } from 'vuex';

export default {
  name: 'ProfileGrid',
  components: {
    MasonryLayout
  },
  props: {
    lowerState: {
      default: 'preview',
      type: String,
    },
  },
  data() {
    return {
      sizes: ['small', 'medium', 'large'],
    }
  },
  computed: {
    ...mapState(['entity', 'qa']),
    smallColumns() {
      const col = [
        { component: 'entity-info', props: {} },
        {
          component: 'unit-info-answer', 
          props: { 'qa-featured': this.qa.featured[0] }
        },
        { component: 'entity-relationships', props: {} },
        { component: 'entity-members', props: {} },
        {
          component: 'timeline-module', 
          props: {
            context: 'profile',
            entityName: this.entity.name,
            timelineItems: this.entity.timeline
          }
        },
        {
          component: 'unit-info-answer', 
          props: { 'qa-featured': this.qa.featured[1] }
        },
        { component: 'channels-module', props: {} },
      ];

      return [col];
    },
    mediumColumns() {
      const one = [
        { component: 'entity-info', props: {} },
        {
          component: 'unit-info-answer', 
          props: { 'qa-featured': this.qa.featured[0] }
        },
        {
          component: 'channels-module',
          props: {
            // style: { paddingTop: '180px' }
          }
        },
      ];

      const two = [
        { component: 'entity-relationships', props: {} },
        { component: 'entity-members', props: {} },
        {
          component: 'unit-info-answer', 
          props: { 'qa-featured': this.qa.featured[1] }
        },
        {
          component: 'timeline-module', 
          props: {
            context: 'profile',
            entityName: this.entity.name,
            timelineItems: this.entity.timeline
          }
        },
      ];

      return [one, two];
    },
    largeColumns() {
      const one = [
        { component: 'entity-info', props: {} },
        { component: 'entity-relationships', props: {} },
        { component: 'entity-members', props: {} },
      ];

      const two = [
        {
          component: 'unit-info-answer', 
          props: { 'qa-featured': this.qa.featured[0] }
        },
        {
          component: 'channels-module',
          props: {
            // style: { paddingTop: '180px' }
          }
        },
      ];  

      const three = [
        { component: 'impact-module', props: {} },
        {
          component: 'unit-info-answer', 
          props: { 'qa-featured': this.qa.featured[1] }
        },
        {
          component: 'timeline-module', 
          props: {
            context: 'profile',
            entityName: this.entity.name,
            timelineItems: this.entity.timeline
          }
        },
      ];

      return [one, two, three];
    },
    profileGridBottomPosition() {
      return `lower-is-${this.lowerState}`;
    },
    columns() {
      return {
        small: this.smallColumns,
        medium: this.mediumColumns,
        large: this.largeColumns,
      }
    }
  },
  methods: {
    handleContainerClick() {
      if (this.lowerState === 'active') {
        this.$eventBus.$emit('toggle-context-state', 'preview');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-grid {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: auto;
  padding: 0px 20px 20px;
  background-color: black;
  overflow-y: scroll;
  transition: bottom 300ms ease;
  > div {
    flex-shrink: 0;
  }
  &.lower-is-preview {
    bottom: 120px;
  }
  &.lower-is-summary {
    bottom: 315px;
  }
  &.lower-is-active {
    opacity: 0.5;
    bottom: calc(100% - 60px);
  }
  &.lower-is-share {
    bottom: 228px;
  }
}

.small {
  padding-top: 168px;
  @include for-size(medium-screen-up) {
    display: none;
  }
}

.medium {
  padding-top: 62px;
  @include for-size(small-screen-only) {
    display: none;
  }
  @include for-size(small-desktop-up) {
    display: none;
  }
}

.large {
  display: none;
  @include for-size(small-desktop-up) {
    padding-top: 62px;
    display: flex;
  }
}
</style>

