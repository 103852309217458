<template>
  <!-- <transition appear name="chart-fade" mode="out-in"> -->
  <svg :height="this.small ? 34 : 215" :width="this.small ? 34 : 355">
    <g :style="translate" :ref="this.category">
      <template v-for="(stat,i) in this.formula[this.category]">
        <g :key="category + '-' + stat" @click="emitStatName(stat)">
          <path :key="stat + '-1'" :ref="stat + '-bl'"></path>

          <path :key="stat + '-2'" :ref="stat + '-mid'"></path>

          <path :key="stat + '-3'" :ref="stat + '-top'"></path>

          <StatsAnnotation
            v-if="!small"
            :key="stat + '-t-' + i"
            :attrs="annotationAttrs[i]"
            :category="category"
            :stats="stats"
            :statName="stat"
          ></StatsAnnotation>
        </g>
      </template>
    </g>
  </svg>
  <!-- </transition> -->
</template>

<script>
import StatsAnnotation from '@/components/stats/StatsAnnotation.vue';
import * as d3 from 'd3';

export default {
  name: 'RadialBarChart',

  components: { StatsAnnotation },

  props: {
    category: String,
    small: Boolean,
    stats: Object,
  },

  data() {
    return {
      avgAngle: 0.47,

      stats2: {
        sums: {
          timeOnPage: 0,
          views: 582,
          shares: 27,
          purchases: 19,
          questions: 5,
          answers: 3,
          answersPerQuestion: 1.67,
          participants: 9,
          investments: '$6545',
          productionLength: '1445 days',
          newsworthiness: 1.2,
        },

        avgs: {
          timeOnPage: '5 hours 9 minutes',
          answersPerQuestion: 10,
        },

        indices: {
          timeOnPage: 0.0005,
          views: 1.72,
          shares: 1.44,
          purchases: 1.4,
          questions: 0.7,
          answers: 0.5,
          answersPerQuestion: 0.8,
          participants: 0.7,
          investments: 1.5,
          productionLength: 1.2,
          heat: 0,
          newsworthiness: 0,
          effort: 0,
        },
      },

      formula: {
        superindices: ['heat', 'newsworthiness', 'effort', 'timeOnPage'],

        heat: ['newsworthiness', 'views', 'shares', 'purchases', 'timeOnPage'],

        newsworthiness: [
          'shares',
          'questions',
          'answers',
          'answersPerQuestion',
          'timeOnPage',
        ],

        effort: [
          'participants',
          'investments',
          'productionLength',
          'timeOnPage',
        ],
      },

      fills: {
        avg: [
          ['#fff', '#fff', 0, 0.7, '14%', '98%'],
          ['#fff', '#fff', 0, 0.7, '25%', '100%'],
        ],

        // are the 0% & 100% backward vs. the spec?
        superindices: [
          ['#ff0000', '#ff4d4d'],
          ['#0090ff', '#4db1ff'],
          ['#00ff19', '#4dff5f'],
          ['#7b00ff', '#a34dff'],
        ],

        heat: [
          ['#ff0000', '#ff4d4d'],
          ['#ff5500', '#ff884d'],
          ['#d4ff00', '#e1ff4d'],
          ['#ff00f5', '#ff4Df9'],
          ['#7b00ff', '#a34dff'],
        ],

        newsworthiness: [
          ['#CCFF00', '#DBFF4D'],
          ['#FFE500', '#FFED4D'],
          ['#FF0099', '#FF4DB8'],
          ['#00FFF0', '#4DFFF3'],
          ['#7b00ff', '#a34dff'],
        ],

        effort: [
          ['#0500ff', '#4f4dff'],
          ['#0090ff', '#4db1ff'],
          ['#00ff19', '#4dff5f'],
          ['#7b00ff', '#a34dff'],
        ],
      },
    };
  },

  computed: {
    annotationAttrs() {
      const arr = [];

      const className = this.category == 'superindices' ? 'title-2' : 'action-1';
      const style = 'fill: white;';
      const x = '6px';

      for (let i = 0; i < this.numBars; i++) {
        const y = this.numBars == 5
          ? -(this.innerRadiuses[i] + 1)
          : -(this.innerRadiuses[i] + 6);

        const obj = {
          class: className,
          style,
          x,
          y: `${y}px`,
        };

        arr.push(obj);
      }

      return arr;
    },
    /*
    * Related to the dimensions and number of bars in the chart
    */

    barGap() {
      return this.small ? 1 : 5;
    },

    barWidth() {
      if (this.small) {
        return 3;
      }
      return this.numBars == 5 ? 14 : 19;
    },

    numBars() {
      return this.formula[this.category].length;
    },

    /*
    * Radius calculations for the overall chart + outer/inner Rs of the bars
    */

    chartRadius() {
      return this.small ? 17 : 107;
    },

    innerRadiuses() {
      const arr = [];

      // audit this for potential side effects
      // eslint-disable-next-line
      this.outerRadiuses.forEach(value => {
        arr.push(value - this.barWidth);
      });

      return arr;
    },

    outerRadiuses() {
      const arr = [];

      for (let i = 0; i < this.numBars; i++) {
        const outer = this.chartRadius - (this.barWidth + this.barGap) * i;
        arr.push(outer);
      }

      return arr;
    },

    // Computes the center for each arc, applied to the <g> node
    translate() {
      return this.small
        ? { transform: 'translate(17px, 17px)' }
        : { transform: 'translate(110px, 107px)' };
    },
  },

  /*
  * THIS WATCHER IS NOT NECESSARY IN UPDATED IMPLEMENTATION
  */
  // watch: {
  //   "stats.indices": {
  //     deep: true,

  //     immediate: true,

  //     handler: function updateSuperindices() {
  //       ["heat", "newsworthiness", "effort"].forEach(el => {
  //         this.stats.indices[el] = this.calcSuperindex(el);
  //       });
  //     },
  //   },
  // },

  mounted() {
    this.drawBars(this.category);
  },

  updated() {
    this.drawBars(this.category);
  },

  methods: {
    calcStatAngle(stat) {
      const timeIndex = this.stats.sums.timeOnPage / this.stats.avgTimeOnPage;

      const value = stat == 'timeOnPage' ? timeIndex : this.stats.indices[stat];

      const angle = value ? value * this.avgAngle : 0;

      return angle;
    },

    calcSuperindex(name) {
      const arr = this.getStatValues(name);

      return arr.reduce((sum, x) => sum + x) / arr.length;
    },

    drawArcs(stat, bar, max = false) {
      // This method draws the set of arcs (background, average, and stat) for an individual bar of the chart. Each bar represents a stat, and each arc within a bar is represented by an SVG path.

      // The number of radians in a full circle
      const tau = 2 * Math.PI;

      // Does this stat outperform its average?
      const outperforms = this.calcStatAngle(stat) > this.avgAngle;

      // If the stat outperforms, the arc representing the average should be the topmost path, so that the average gradient shows
      let avgPath; let
        statPath;
      if (outperforms) {
        avgPath = `${stat}-top`;
        statPath = `${stat}-mid`;
      } else {
        avgPath = `${stat}-mid`;
        statPath = `${stat}-top`;
      }

      // Select each the path node of each arc (background, average, stat)
      const bp = d3.select(this.$refs[`${stat}-bl`][0]);
      const ap = d3.select(this.$refs[avgPath][0]);
      let sp = d3.select(this.$refs[statPath][0]);

      // Create d3 arc object
      const arc = d3
        .arc()
        .innerRadius(this.innerRadiuses[bar])
        .outerRadius(this.outerRadiuses[bar])
        .cornerRadius(20)
        .endAngle(tau);

      // Draw and style the path of the background arc
      bp
        .datum({ startAngle: 0.25 * tau })
        .style('fill', '#2e2e2e')
        .attr('d', arc);

      // Draw and style the path of the avg arc. Note that the fill of the gradient of the avg arc depends on whether the stat outperforms its average.
      ap
        .datum({ startAngle: (1 - this.avgAngle) * tau })
        .style('fill', `url(#a${stat}${bar})`)
        .attr('d', arc);

      this.drawGradients(`a${stat}${bar}`, ...this.fills.avg[+outperforms]);

      // Calculate the scale of the stat arc
      let statAngle = this.calcStatAngle(stat);

      // Update the stat arc's scale if it's the max (i.e., if it has the largest value of all the stats in the chart) and/or if it outperforms its average.
      if (max && stat != 'timeOnPage') {
        statAngle = outperforms ? 0.75 : this.avgAngle;
      }

      // Draw and style the stat arc's path
      // The Math.max was added to set a minimum arc length, no matter the metric value. Potentially set up a more permanent fix later.
      if (statAngle) {
        sp = sp
          .datum({ startAngle: (1 - Math.max(0.045, Math.min(statAngle, 0.75))) * tau })
          .style('fill', `url(#m${stat}${bar})`)
          .attr('d', arc);
      }

      this.drawGradients(`m${stat}${bar}`, ...this.fills[this.category][bar]);

      // If this represents timeOnPage: set the interval that updates and re-draws the length of the stat arc as timeOnPage ticks upward
      if (stat == 'timeOnPage') {
        this.interpolateTimeOnPage(sp, arc);
      }
    },

    drawBars(category) {
      const max = Math.max(...this.getStatValues(category));

      // Changed to arrow function so this refers to component
      this.formula[category].forEach((m, i) => {
        const isMax = this.stats.indices[m] == max;

        this.drawArcs(m, i, isMax);
      });
    },

    drawGradients(name, c1, c2, a1 = 1, a2 = 1, o1 = '0%', o2 = '100%') {
      const defs = d3.select(this.$refs[this.category]).append('defs');
      const gradient = defs
        .append('linearGradient')
        .attr('id', name)
        .attr('x1', '0%') // potentially update these angles
        .attr('x2', '100%')
        .attr('y1', '0%')
        .attr('y2', '100%');

      gradient
        .append('stop')
        .attr('class', 'start')
        .attr('stop-color', c1)
        .attr('stop-opacity', a1)
        .attr('offset', o1);

      gradient
        .append('stop')
        .attr('class', 'end')
        .attr('stop-color', c2)
        .attr('stop-opacity', a2)
        .attr('offset', o2);
    },

    emitStatName(stat) {
      if (!this.small && this.category == 'superindices') {
        if (stat != 'timeOnPage') {
          this.$eventBus.$emit('stat-name', stat);
          // console.log(`emitting stat name: ${stat}`);
        }
      }
    },

    // keep
    getStatValues(superindex) {
      const arr = [];

      const elements = superindex == 'heat'
        ? this.formula[superindex]
        : this.formula[superindex].slice(0, -1);

      elements.forEach((value) => {
        const statValue = this.stats.indices[value] ? this.stats.indices[value] : 0;
        arr.push(statValue);
      });

      return arr;
    },

    interpolateTimeOnPage(path, arc) {
      const tau = 2 * Math.PI;
      const maxAngle = 0.75;

      const arcTween = newAngle => function (d) {
        const interpolate = d3.interpolate(d.startAngle, newAngle);
        return function (t) {
          d.startAngle = interpolate(t);
          return arc(d);
        };
      };

      const interval = d3.interval(() => {
        const timeAngle = this.stats.sums.timeOnPage / this.stats.avgTimeOnPage * this.avgAngle;

        if (timeAngle > maxAngle) {
          interval.stop();
          return;
        }

        path
          .transition()
          .duration(1000)
          .attrTween('d', arcTween((1 - timeAngle) * tau));
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-fade-enter-active,
.chart-fade-leave-active {
  transition: opacity 250ms;
}

.chart-fade-enter,
.chart-fade-leave-to {
  opacity: 0;
}
</style>
