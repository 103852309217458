<template>
  <div class="index-initial-choice">
    <text-button
      class="btn--index btn--positive btn--pill"
      style="padding-top: 8px;"
      @click="emitToggleControl(true)"
    >
      <div>
        Take control of the algorithm.
      </div>
    </text-button>
    <span class="tag text-white">
      or
    </span>
    <text-button class="btn--index btn--bg btn--pill" @click="emitToggleControl(false)">
      <div>
        View our curated feed.
      </div>
    </text-button>
  </div>
</template>

<script>
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'index-initial-choice',
  components: { TextButton },
  methods: {
    emitToggleControl(choice) {
      this.$emit('toggle-control', choice);
    },
  },
};
</script>

<style lang="scss" scoped>
.index-initial-choice {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: var(--global-padding) 0 10px 0;
}
</style>
