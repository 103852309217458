<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10c5.52,0,10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8
			c4.41,0,8,3.59,8,8S16.41,20,12,20z"/>
		<path d="M15.88,8.29L10,14.17l-1.88-1.88c-0.39-0.39-1.02-0.39-1.41,0l0,0c-0.39,0.39-0.39,1.02,0,1.41l2.59,2.59
			c0.39,0.39,1.02,0.39,1.41,0L17.3,9.7c0.39-0.39,0.39-1.02,0-1.41l0,0C16.91,7.9,16.27,7.9,15.88,8.29z"/></svg>
</template>

<script>
export default {
  name: 'mdi-check-circle',
};
</script>

<style scoped>

</style>
