<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7022 16.8865L9.89569 14.6942C9.89569 14.6942 8.58512 16.1559 6.61979 16.1559C4.88075 16.1559 3.64606 14.6438 3.64606 12.2245C3.64606 9.12491 5.20832 8.01611 6.74577 8.01611C8.96328 8.01611 9.66882 9.45249 10.2738 11.2922L11.0803 13.8123C11.8865 16.2566 13.3986 18.2222 17.7582 18.2222C20.8833 18.2222 23 17.2647 23 14.7447C23 12.7035 21.8406 11.645 19.6733 11.1409L18.0606 10.7882C16.9519 10.5362 16.6243 10.0825 16.6243 9.3265C16.6243 8.46967 17.3045 7.96562 18.4135 7.96562C19.6231 7.96562 20.2783 8.41928 20.3791 9.50288L22.8991 9.20041C22.6975 6.93242 21.1352 6 18.5647 6C16.2968 6 14.0791 6.85682 14.0791 9.60367C14.0791 11.3173 14.9107 12.401 17.0021 12.905L18.716 13.308C20.0012 13.6105 20.4294 14.1397 20.4294 14.8706C20.4294 15.8028 19.5223 16.1809 17.8087 16.1809C15.2634 16.1809 14.2052 14.8453 13.6003 13.0056L12.7686 10.4857C11.7104 7.20959 10.0218 6 6.67008 6C2.96572 6 1 8.34359 1 12.3252C1 16.1558 2.96572 18.2221 6.49389 18.2221C9.34115 18.2221 10.7022 16.8865 10.7022 16.8865Z" fill="#D51007"/>
  </svg>
</template>

<script>
export default {
  name: 'LastFmLogo',
};
</script>

<style scoped>
</style>
