<template>
  <div class="pole-handle">
    act
  </div>
</template>

<script>
export default {
  name: 'PoleHandle',

}
</script>

<style lang="scss" scoped>
.pole-handle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background-color: var(--tint);
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
}
</style>

