<template>
  <div class="entity-chip">
    <AvatarIcon
      :class="avatarSize"
      :image="image"
    />
    <div
      v-if="!details"
      :class="[textClass, textTheme, 'ec__details', `details--${size}`]"
    >
      {{ name }}
    </div>
    <div
      v-else
      class="ec__text"
    >
      <div :class="[textClass, textTheme, 'ec__details']">
        {{ name }}
      </div>
      <div class="ec__details">
        <span class="text-footnote">
          {{ details.role }}&nbsp;
        </span>
        <span class="text-footnote-hint">
          since {{ details.since }}&nbsp;
        </span>
        <span
          v-if="details.is_founder"
          class="text-footnote-hint"
        >
          &bull;&nbsp; Founder
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';

export default {
  name: 'EntityChip',
  components: { AvatarIcon },
  props: {
    name: {
      type: String,
      default: 'Full Name'
    },
    image: {
      type: String,
      required: true,
    },
    details: {
      type: [Object,String],
      default: ''
    },
    size: {
      type: String,
      default: 'xs',
    },
    theme: {
      type: String,
      default: 'black',
    }
  },
  computed: {
    avatarSize() {
      const classes = {
        m: '',
        s: 'avatar--s',
        xs: 'avatar--xs'
      };
      
      return classes[this.size];
    },
    textClass() {
      const classes = {
        m: 'title-2',
        s: 'action-1',
        xs: 'tag'
      };

      return classes[this.size];
    },
    textTheme() {
      return this.theme == 'white'? 'text-white' : '';
    }
  },
};
</script>

<style lang="scss" scoped>
.entity-chip {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  min-height: 24px;
  max-width: 100%;
}

.ec__text {
  display: flex;
  flex-direction: column;
  height: 40px;
  > * {
    flex-shrink: 0;
  }
}

.ec__details {
  // white-space: nowrap;
  margin-left: 8px;
  margin-bottom: 0px;
  text-indent: 0px;
  line-height: 120%;
  overflow-x: visible;
}

.details--m {
  margin-left: 12px;
}
</style>
