<template>
  <div class="app-nav-head">
    <div
      class="anh__item tag text-sec-white"
      @click="goHome"
    >
      <MdiViewDashboard class="anh__icon" /> <span>HOME</span>
    </div>
    <AvatarIcon v-if="user" :image="user.image" @click="goToAccount" />
    <div
      class="anh__item tag text-sec-white"
      @click="goToLibrary"
    >
      <span>LIBRARY</span><MdiPackage class="anh__icon" />
    </div>


  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import MdiPackage from '@/components/graphics/MdiPackage.vue';
import MdiViewDashboard from '@/components/graphics/MdiViewDashboard.vue';

import { mapState } from 'vuex';

export default {
  name: 'app-nav-head',
  components: {
    AvatarIcon,
    MdiPackage,
    MdiViewDashboard,
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'unit-list' });
      this.$emit('toggle-app-nav');
    },
    goToAccount() {
      this.$router.push({ name: 'account' });
      this.$emit('toggle-app-nav');
    },
    goToLibrary() {
      this.$router.push({ name: 'purchases' });
      this.$emit('toggle-app-nav');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-nav-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 10px;
  height: 40px;
  @include for-size(phone-only) {
    right: 10px;
    left: 10px;
  }
  @include for-size(tablet-portrait-up) {
    left: 50%;
    transform: translate(-50%);
    width: 704px;
    transition: width 300ms ease;
  }
  @include for-size(small-desktop-up) {
    width: 640px;
    transition: width 300ms ease;
  }
}

.anh__item {
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  > :first-child {
    margin-right: 6px;
  }
}

.anh__icon {
  > :first-child {
    fill: white;
  }
}
</style>
