<template>
  <div class="bundle-info-section">
    <div class="bis__header bis__flex">

      <component :is="assetIcon" style="margin-right: 6px;">
      </component>

      <div class="tag text-primary">
        {{ media.length }} TRACKS
      </div>

      <component
        :is="isExpanded ? 'MdiChevronUp' : 'MdiChevronDown'"
        class="svg-toggle"
        @click="toggleExpansion"
      >
      </component>
      <!-- <MdiChevronDown v-if="!isExpanded" class="svg-toggle" />
      <MdiChevronUp v-else class="svg-toggle" /> -->
    </div>

    <transition-group
      name="toggle-item"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <template v-if="isExpanded">
        <div
          v-for="(item, index) in this.media"
          :key="'bundle-info-' + item.name"
          class="bis__flex bis__item"
          :class="{'is-expanded': isExpanded}"
        >
          <div class="body-2 text-secondary">
            {{ index + 1}}.
          </div>

          <div
            class="body-2 text-primary"
            style="margin-left: 10px; margin-right: 4px;"
          >
            {{ item.name }}
          </div>

          <div v-if="item.hasOwnProperty('duration_str')" class="text-footnote text-disabled" style="padding-top: 2px;">
            {{ item.duration_str }}
          </div>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
import MdiCamera from '@/components/graphics/MdiCamera.vue';
import MdiChevronDown from '@/components/graphics/MdiChevronDown.vue';
import MdiChevronUp from '@/components/graphics/MdiChevronUp.vue';
import MdiMovie from '@/components/graphics/MdiMovie.vue';
import MdiMusicNote from '@/components/graphics/MdiMusicNote.vue';

export default {
  name: 'bundle-info-section',
  components: {
    MdiCamera,
    MdiChevronDown,
    MdiChevronUp,
    MdiMovie,
    MdiMusicNote,
  },
  props: {
    media: Array,
    type: String,
  },
  data() {
    return {
      isExpanded: true,
    };
  },
  computed: {
    assetIcon() {
      if (this.type == 'Track') {
        return MdiMovie;
      } if (this.type == 'Video') {
        return MdiMovie;
      } if (this.type == 'Photo') {
        return MdiCamera;
      }
    },
  },
  methods: {
    // adjust these to not grab the actual dom
    beforeEnter(el) {
      el.style.height = '0';
      el.style.opacity = 0;
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = 1;
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.height = '0';
      el.style.opacity = 0;
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.bis__header {
  margin-bottom: 10px;

  svg {
    width: 16px;
    height: 16px;
    fill: var(--secondary);
  }

  .svg-toggle {
    cursor: pointer;
    margin-left: auto;
  }
}

.bis__flex {
  display: flex;
  align-items: center;
}

.bis__item {
  transition: 350ms ease-out;
  margin-bottom: 12px;
}
</style>
