<template>
  <div class="contract-cell">
    <div class="cc__flex cc__credits">
      <EntityChip
        v-for="entity in ['ensemble', 'series']"
        :key="`cc-${entity}`"
        :name="credits[entity].name"
        :image="credits[entity].image_url"
        size="xs"
        theme="white"
      />
    </div>
    <div
      class="cc__flex"
      style="margin-bottom: 10px;"
    >
      <div class="cc__rights-section">
        <div class="tag text-sec-white">
          RIGHTS
        </div>
        <ul :class="windowWidth < 370 ? 'body-3': 'body-2'">
          <li :class="controlTheme[0]">
            <component
              :is="controlTheme[1]"
              :class="controlTheme[2]"
            />
            Artistic control
          </li>
          <li :class="ownershipTheme[0]"> 
            <component
              :is="ownershipTheme[1]"
              :class="ownershipTheme[2]"
            />
            Ownership
          </li>
          <li class="text-white">
            <MdiEarth class="rights-icon--sec" />
            Covers {{ jurisdiction }}
          </li>
        </ul>
      </div>
      <div class="cc__profit-section">
        <div class="tag text-sec-white">
          PROFIT SHARE
        </div>
        <ProfitShare
          :share="details.ensemble_share"
          with-label
        />
        <div
          class="text-footnote text-sec-white"
          :class="{ 'footnote-neg-margin': (windowWidth > 370)}"
        >
          <i>After expenses</i>
        </div>
      </div>
    </div>

    <ContractTerms
      v-if="details.date"
      :start-date="details.date"
      :end-date="details.end_date"
    />
    <div
      v-else
      class="cc__flex text-footnote"
    >
      <div class="text-negative">
        <b>Hasn't signed yet</b>
      </div>
      <div class="text-negative">
        Terms may change
      </div>
    </div>
  </div>
</template>

<script>
import ContractTerms from '@/components/shared/ContractTerms.vue';
import EntityChip from '@/components/entities/EntityChip.vue';
import MdiCheck from '@/components/graphics/MdiCheck.vue';
import MdiClose from '@/components/graphics/MdiClose.vue';
import MdiEarth from '@/components/graphics/MdiEarth.vue';
import ProfitShare from '@/components/shared/ProfitShare.vue';

export default {
  name: 'ContractCell',
  components: {
    ContractTerms,
    EntityChip,
    MdiCheck,
    MdiClose,
    MdiEarth,
    ProfitShare,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  computed: {
    credits() {
      return this.$store.state.unit.project;
    },
    controlTheme() {
      const hasControl = this.details.artistic_control;

      const textColor = 'text-white';
      const icon = hasControl ? 'mdi-check' : 'mdi-close';
      const iconColor = hasControl ? 'rights-icon--true' : 'rights-icon--false';

      return [textColor, icon, iconColor];
    },
    jurisdiction() {
      const { jurisdiction } = this.details;
      return jurisdiction == 'United States' ? 'the U.S.' : jurisdiction;
    },
    ownershipTheme() {
      const owns = this.details.ownership;

      const textColor = 'text-white';
      const icon = owns ? 'mdi-check' : 'mdi-close';
      const iconColor = owns ? 'rights-icon--true' : 'rights-icon--false';

      return [textColor, icon, iconColor];
    },
    windowWidth() {
      return window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-cell {
  color:#000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // box-sizing: content-box;
  width: 100%;
  padding: 6px 10px;
  border-radius: 6px;
}

ul {
  list-style: none;
  padding-left: 0;
}

.cc__flex {
  display: flex;
  justify-content: space-between;
}

.cc__credits {
  margin-bottom: 20px;
}

.cc__rights-section, .cc__profit-section {
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 10px;
  }
}

.cc__profit-section {
  align-items: flex-end;

}

.footnote-neg-margin {
  margin-bottom: -8px;
}

.rights-icon--true {
  fill: var(--positive);
}

.rights-icon--false {
  fill: var(--negative);
}

.rights-icon--sec {
  fill: var(--secondary-white);
}
</style>
