<template>
  <div class="qa-thread">
    <QaBubble
      :avatar="question.owner.image_url"
      :key="'-1' + '-' + question.question_id"
      class="qa-thread__question"
      :class="{ 'qa-thread--is-expanded': isExpanded }"
      :editing="editing"
      :highlight="highlightQuestion()"
      :id="question.question_id"
      :num-answers="question.answers.length"
      :text="question.body"
      @toggle-qa-thread="toggleThread"
    />
    <transition-group
      name="toggle-thread"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <template v-if="isExpanded">
        <QaBubble
          v-for="(answer,i) in question.answers"
          :key="i + '-' + question.question_id"
          :avatar="answer.owner.image_url"
          class="qa-thread__answer"
          :class="{
            'is-expanded': isExpanded, 
            'hide': showFeatured && (inQaFeature !== i)
          }"
          :editing="editing"
          :highlight="highlightAnswer(i)"
          :id="answer.id"
          :text="answer.body"
          type="answer"
          @toggle-qa-thread="collapseThread"
        />
      </template>
    </transition-group>
  </div>
</template>

<script>
import QaBubble from '@/components/qa/QaBubble.vue';

export default {
  name: 'QaThread',
  components: {
    QaBubble,
  },
  props: {
    editing: {
      default: false,
      type: Boolean,
    },
    highlightMode: {
      default: false,
      type: Boolean,
    },
    inQaFeature: {
      default: false,
      type: [Boolean,Number],
    },
    question: {
      default() {
        return {};
      },
      type: Object
    },
  },
  data()  {
    return {
      showFeatured: this.inQaFeature !== false,
      isExpanded: this.inQaFeature > 0,
    };
  },
  computed: {
    userName() {
      return this.$store.state.user.name;
    }
  },
  methods: {
    collapseThread() {
      this.isExpanded = false;
      this.showFeatured = false;
    },
    toggleThread() {
      this.isExpanded = !this.isExpanded;
      this.showFeatured = false;
    },
    highlightQuestion() {
      return (this.inQaFeature === -1);
    },
    highlightAnswer(i) {
      return (this.inQaFeature === i);
    },
    beforeEnter(el) {
      el.style.height = '0';
      el.style.opacity = 0;
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = 1;
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.height = '0';
      el.style.opacity = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.qa-thread {
  width: 100%;
}

.qa-thread__answer {
  transition: 350ms ease-out;
}

.is-expanded {
  transition: 350ms ease-out;
}

.hide {
  display: none;
}

</style>
