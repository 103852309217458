<template>
  <div
    v-if="!loading"
    class="ask-tool__container"
  >
    <div class="at__contents">
      <div class="at__header">
        <div class="title-2">
          Ask a question about...
        </div>
        <IconButton
          class="icon-btn--close icon-btn--32"
          icon="mdi-close"
          @click="returnToUnit"
        />
      </div>

      <component
        :is="getCardType(unit.unit_type)"
        :key="unit.unit_id"
        style="margin-bottom: 32px;"
        :unit="unit"
      />

      <div class="at__recipients-header">
        <div class="action-1 text-secondary">
          Recipients
        </div>
        <div class="text-footnote">
          Select as many as you like
        </div>
      </div>

      <div class="at__user-selector">
        <div class="at__user">
          <div class="tag text-white">
            ...
          </div>
          <IconButton
            class="icon-btn--tint"
            icon="mdi-account"
            @click="handleSearch"
          />
          <div class="tag text-secondary">
            SEARCH
          </div>
        </div>
        <template v-for="(userOption, i) in this.userSelector">
          <div
            :key="userOption.name + '-' + i"
            class="at__user"
          >
            <div
              v-if="i == 0"
              class="tag text-black"
            >
              AUTHOR
            </div>
            <div
              v-if="i == 1"
              class="tag text-secondary"
            >
              ARTISTS
            </div>
            <div
              v-if="i > 1"
              class="tag text-white"
            >
              ...
            </div>
            <AvatarIcon
              :class="{'avatar--checked': userOption.isSelected }"
              :image="userOption.image"
              :title="userOption.name"
              @click="toggleSelectRecipient(userOption)"
            />
            <div :class="['tag', getUserNameClass(userOption.isSelected)]">
              {{ userOption.name.toUpperCase() }}
            </div>
          </div>
        </template>
      </div>

      <div class="action-1 text-secondary at__q-header">
        Write your question
      </div>
      <div class="at__question">
        <div class="at__question-btns">
          <text-button
            v-for="option in options"
            :key="'question-btn-' + option"
            :class="[
              selectedPurpose == option ? 'btn--tip-tint' : 'btn--white', 'btn--pill'
            ]"
            @click="selectPurpose(option)"
          >
            {{ option[0].toUpperCase() + option.slice(1) }}
          </text-button>
        </div>
        <div class="at__question-starter">
          <b>{{ this.question.purpose[0].toUpperCase() + this.question.purpose.slice(1) }}</b>
        </div>
        <textarea
          v-model="$v.question.body.$model"
          class="body-1 text-primary"
          :style="questionTextIndent"
          @input="errors = []"
          @focus="clearTemplate"
        />
        <AvatarIcon
          class="at__user-avatar avatar--xs"
          :image="user.image"
        />
        <div
          v-for="(error,i) in this.errors"
          :key="'error-' + i"
          class="tag text-negative"
        >
          {{ error }}
        </div>
      </div>

      <div class="at__inspiration">
        <div class="action-1 text-secondary">
          Seeking inspiration?
        </div>
        <IconButton
          class="icon-btn--tint"
          icon="mdi-dice"
          @click="randomizeQuestion"
        />
      </div>
      <div class="body-2 text-disabled at__sample-randomizer">
        <div>
          <div class="at__sample-q-header">
            <AvatarIcon
              :image="this.userSelector.slice(-1)[0].image"
              :title="this.userSelector.slice(-1)[0].name"
            />
            <b>{{ this.userSelector.slice(-1)[0].name }} &nbsp;</b> says:
          </div>
          <i>A ganze Hoiwe Biaschlegl a bissal wos gehd ollaweil nackata do wea nia ausgähd, kummt nia hoam wuid Graudwiggal vo de aba?</i>
        </div>
      </div>
    </div>

    <text-button
      :class="[
        'at__send-btn btn--tip', 
        {'at__send-btn--disabled': !$v.question.body.required }
      ]"
      :disabled="sent.length > 0"
      @click="sendQuestion"
    >
      Edit your question to send
    </text-button>
  </div>
</template>

<script>
import AvatarIcon from "@/components/entities/AvatarIcon.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import ImageCard from '@/components/unit/ImageCard.vue';
import MusicCard from '@/components/unit/MusicCard.vue';
import TextButton from "@/components/buttons/TextButton.vue";
import TextCard from '@/components/unit/TextCard.vue';

import { loadToken } from "@/store/localStorage";

import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AskTool",

  components: {
    AvatarIcon,
    IconButton,
    ImageCard,
    MusicCard,
    TextButton,
    TextCard,
  },

  validations: {
    question: {
      body: {
        required,
        maxLength: maxLength(300)
      }
    }
  },

  props: {
    unitId: {
      required: true,
      type: [String, Number]
    }
  },

  data() {
    return {
      question: {
        recipients: [],
        object: null,
        purpose: "what",
        body: "was the first person to support your work?"
      },
      errors: [],
      loading: true,
      options: ["what", "why", "how"],
      selectedPurpose: "what",
      sent: [],
      showQuestion: true,
      unit: null,
      // userSelector: [
      //   {
      //     name: "Terrence Thornton",
      //     image: `${require("@/assets/img/friends/pusha.png")}`,
      //     isSelected: false
      //   },
      //   {
      //     name: "Shingy",
      //     image: `${require("@/assets/img/friends/shingy.png")}`,
      //     isSelected: false
      //   },
      //   {
      //     name: "John Malkovich",
      //     image: `${require("@/assets/png/malkovich.png")}`,
      //     isSelected: false
      //   },
      //   {
      //     name: "Aubrey Graham",
      //     image: `${require("@/assets/img/purchases/drake.png")}`,
      //     isSelected: false
      //   },
      //   {
      //     name: "Carly Rae Jepsen",
      //     image: `${require("@/assets/img/friends/carly.png")}`,
      //     isSelected: false
      //   },
      //   {
      //     name: "Kehlani Parrish",
      //     image: `${require("@/assets/img/purchases/kehlani.png")}`,
      //     isSelected: false
      //   }
      // ],
      purposeTemplates: {
        why: {
          isSelected: false,
          templates: [
            "did you decide to become an artist?",
            "is idealog your favorite app of all time?",
            "did Adele win the Grammy for Album of the Year over Beyoncé?",
            "did Jon Snow join the Night's Watch?"
          ]
        },
        what: {
          isSelected: true,
          templates: [
            "is the template set going to contain? I’d like to see our editorial team help people question their assumptions about what sort of discourse thrives on online platforms.",
            "are you going to do to end the corrupting influence of money in politics?",
            "was the most difficult decision that you have had to make in your career?",
            "is the first thing you'll do on November 7th, win or lose?",
            "algorithm is primarily responsible for the performance advances of computer vision deep learning models?"
          ]
        },
        how: {
          isSelected: false,
          templates: [
            "many convolutional layers and fully-connected layers did the neural net have that won 1st place in the 2012 ImageNet competition?",
            "did your collaboration with Gorillaz come about?",
            "did post-WWII migration from the Caribbean to England influence the proliferation of calypso, reggae, and ska globally?",
            "do you calculate the valuation of a company by discounting its future cash flows?"
          ]
        }
      }
    };
  },

  computed: {
    questionTextIndent() {
      return this.question.purpose == "what"
        ? "text-indent: 44px;"
        : "text-indent: 36px;";
    },
    user() {
      return this.$store.state.user;
    },
    userSelector() {
      let owner = [
        {
          name: this.unit.asset_owner, 
          image: this.unit.asset_owner_image,
          isSelected: true,
        },
      ];

      const leader = [
        {
          ...this.unit.ensemble_leader,
          isSelected: false,
        }
      ]

      if (owner[0].name !== leader[0].name) {
        owner = owner.concat(leader);
      }

      const artists = this.unit.ensemble_members.map(user => {
        return {
          ...user,
          isSelected: false,
        }
      });

      return owner.concat(artists);
    }
  },

  created() {
    this.loading = true;
    const axiosOptions = { params: { auth_token: loadToken() } };
    this.$http
      .get(`/units/${this.unitId}.json`, axiosOptions)
      .then(response => {
        this.unit = response.data;
      })
      .then(() => {
        this.$store.dispatch("LOAD_USER");
      })
      .then(() => (this.loading = false));
  },

  mounted() {
    this.randomizeQuestion();
  },

  methods: {
    getCardType(unit_type) {
      if (unit_type == 'Audio' || unit_type == 'Playlist') {
        return 'music-card';
      } if (unit_type == 'Image' || unit_type == 'Video') {
        return 'image-card';
      } if (unit_type == 'Article') {
        return 'text-card';
      }
      return 'text-card';
    },
    getUserNameClass(isSelected) {
      return isSelected ? "text-primary" : "text-secondary";
    },
    handleSearch() {

    },
    clearTemplate() {
      const { purpose } = this.question;
      const { templates } = this.purposeTemplates[purpose];
      const questionIsTemplate = templates.includes(this.question.body);

      if (questionIsTemplate) {
        this.question.body = "";
      }
    },
    randomizeQuestion() {
      const { purpose } = this.question;
      const numTemplates = this.purposeTemplates[purpose].templates.length;
      const randomIndex = this.$_.random(numTemplates - 1);

      this.question.body = this.purposeTemplates[purpose].templates[
        randomIndex
      ];
    },
    returnToUnit() {
      this.$router.push({
        name: "unit-view",
        params: { unit_id: this.unitId }
      });
    },
    selectPurpose(option) {
      this.question.purpose = option;
      this.selectedPurpose = option;

      this.showQuestion = true;
      this.randomizeQuestion();
    },
    sendQuestion() {
      this.errors = [];
      if (!this.$v.question.body.required) {
        this.errors.push("YOU'RE SUPPOSED TO ASK A QUESTION...");
      } else {
        this.sent.push(this.question);
        const purpose =
          this.question.purpose.slice(0, 1).toUpperCase() +
          this.question.purpose.slice(1);
        let questionBody = `${purpose} ${this.question.body}`;
        if (this.question.body.slice(-1) !== "?") {
          questionBody += "?";
        }
        const url = `units/${this.unitId}/questions`;
        const params = {
          question: {
            body: questionBody,
            user_id: this.user.id
          }
        };
        this.$http
          .post(url, params)
          .then(response => {
            // eslint-disable-next-line
            console.log(response.data);
            this.returnToUnit();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    toggleSelectRecipient(userObj) {
      const user = this.userSelector.filter(item => item == userObj)[0];
      user.isSelected = !user.isSelected;

      if (user.isSelected) {
        this.question.recipients.push(user);
      } else if (this.question.recipients.includes(user)) {
        this.question.recipients = this.question.recipients.filter(
          recipient => recipient != user
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ask-tool__container {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

.at__contents {
  display: flex;
  flex-direction: column;
  height: calc(calc(var(--vh,1vh) * 100) - 44px);
  padding: 32px 20px;
  overflow-y: scroll;
  background-color: var(--background);
}

.at__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.at__recipients-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.at__user-selector {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 32px;
  max-width: 100vw;
  min-height: 104px;
  max-height: 128px;
  overflow-x: scroll;
  & > :not(:last-child) {
    margin-right: 12px;
  }
}

.at__user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  max-width: 70px;

  overflow: visible;
  & > :nth-child(1) {
    padding-bottom: 8px;
    text-align: center;
  }
  & > :nth-child(3) {
    padding-top: 4px;
    text-align: center;
    transition: color 300ms;
  }
}

.at__q-header {
    margin-bottom: 10px;
}

.at__question {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 24px;
  background-color: var(--primary-dark);
  padding: 10px;
  border-radius: 12px;
  .at__question-btns {
    display: flex;
    margin-bottom: 4px;
  }

  .at__question-starter {
    margin-bottom: -24px;
    line-height: 24px;
  }

  .at__user-avatar {
    position: absolute;
    bottom: 10px;
    right: -12px;
  }
}

textarea {
  min-height: 100px;
  z-index: 1;
  padding: 0px;
  border: none;
  resize: none;
  background-color: transparent;
  color: var(--primary);
  transition: opacity 300ms;
  &.hide-text-area {
    opacity: 0;
  }
  @include for-size(iphone-5-only) {
    font-size: 14px;
  }
}

.at__inspiration {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > :first-child {
    margin-right: 12px;
  }
}

.at__sample-randomizer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include for-size(iphone-5-only) {
    font-size: 12px;
    bottom: 60px;
  }
}

.at__sample-q-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  > :first-child {
    margin-right: 6px;
  }
}

.at__send-btn {
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  display: flex;
  border-radius: 0px;
  justify-content: center;
  &.at__send-btn--disabled {
    opacity: 0.3;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
  display: none;
}
</style>
