<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
    height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
  <g id="Bounding_Boxes">
    <path fill="none" d="M0,0h24v24H0V0z"/>
  </g>
  <g id="Outline">
    <g id="ui_x5F_spec_x5F_header">
    </g>
    <path d="M15,8v8H5V8H15 M16,6H4C3.45,6,3,6.45,3,7v10c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1v-3.5l4,4v-11l-4,4V7
      C17,6.45,16.55,6,16,6L16,6z"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: 'mdi-video-cam',
};
</script>

<style scoped>
</style>
