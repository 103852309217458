<template>
  <div
    class="qa-bubble-card card-br"
    @click="$emit('click')"
  >
    <div> {{ cardOptions.text }} </div>
    <div
      v-if="cardOptions.numAnswers"
      class="answers tag"
    >
      {{ cardOptions.numAnswers }} ANSWERS
    </div>
    <div
      v-if="(this.cardOptions.type == 'question') && !cardOptions.numAnswers && (cardOptions.numAnswers !== false)"
      class="no-answers tag"
    >
      PATIENTLY WAITING IN RECIPIENTS' INBOX
    </div>
    <AvatarIcon
      class="qbc__avatar avatar--xs avatar__border--black"
      :image="cardOptions.avatar"
    />
  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';

import { mapState } from 'vuex';

export default {
  name: 'QaBubbleCard',
  components: {
    AvatarIcon,
  },
  props: {
    cardOptions: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  computed: {
    ...mapState(['user']),
  },
};
</script>

<style lang="scss" scoped>
.qa-bubble-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 40px 10px 10px;
  color: var(--primary-dark);
  :first-child {
    padding-bottom: 5px;
  }
  .answers {
    color: rgba(255, 255, 255, 0.7);
  }
  .no-answers {
    color: rgba(255, 255, 255, 0.3);
  }
}

.card-br {
  border-radius: 10px;
}

.qbc__avatar {
  position: absolute;
  bottom: 8px;
  right: -12px;
  z-index: 10;
}
</style>

