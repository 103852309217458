<template>
  <div class="bundle-info-module">
    <BundleInfoSection
      v-if="unit.hasOwnProperty('tracks')"
      :media="unit.tracks"
      type="Track"
    />
  </div>
</template>

<script>
import BundleInfoSection from '@/components/sales/BundleInfoSection.vue';

export default {
  name: 'bundle-info-module',
  components: {
    BundleInfoSection,
  },
  props: {
    unit: Object,
  },
};
</script>

<style lang="scss" scoped>

</style>
