<template>
  <div
    class="media-card"
  >
    <div class="mc__face" @click="toggleExpansion">
      <div class="mc__image-wrap">
        <img :src="item.cover_image">
      </div>

      <div class="mc__header text-white">
        <div class="title-2">
          {{ item.title }}
        </div>
        <div class="body-2">
          By {{ item.ensemble }}
        </div>
      </div>

      <div class="mc__metadata tag text-sec-white">
        <div v-if="item.articles">
          {{ item.articles.length }} Articles
        </div>
        <div v-if="item.tracks">
          {{ item.tracks.length }} Tracks
        </div>
        <div v-if="item.videos">
          {{ item.videos.length }} Videos
        </div>
        <div v-if="item.images">
          {{ item.images.length }} Images
        </div>
      </div>
    </div>

    <transition
      name="toggle-media-list"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <MediaItemList
        v-if="isExpanded"
        :active-filters="activeFilters"
        :active-sort="activeSort"
        :artists="[item.ensemble]"
        :avatars="[item.cover_image]"
        class="mc__expanded"
        :list="item.tracks"
      />
    </transition>
  </div>
</template>

<script>
import MediaItemList from "@/components/entities/MediaItemList.vue";

export default {
  name: "MediaCard",
  props: {
    activeFilters: {
      required: true,
      type: Array,
    },
    activeSort: {
      required: true,
      type: String,
    },
    item: {
      required: true,
      type: Object,
    }
  },
  components: {
    MediaItemList,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    beforeEnter(el) {
      el.style.height = '0';
      el.style.opacity = 0;
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = 1;
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.height = '0';
      el.style.opacity = 0;
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    }
  }
}
</script>

<style lang="scss" scoped>
.media-card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  min-height: auto;
  min-height: 132px;
  padding-bottom: 12px;
  overflow: auto;
}

.mc__face {
  display: flex;
  flex-shrink: 0;
  height: 116px;
  padding-top: 12px;

  .mc__image-wrap {
    position: relative;
    width: 108px;
    max-width: 108px;
    height: 102px;
    max-height: 102px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.25);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .mc__header {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    > div:first-child {
      margin-bottom: 0px;
    }
  }

  .mc__metadata {
    display: flex;
    flex-direction: column; 
    align-items: flex-end;
    align-self: flex-end;
    margin-left: auto;
    font-weight: 400;
    > div {
      white-space: nowrap;
    }
  }
}

.mc__expanded {
  transition: height 350ms ease, opacity 350ms ease;
}
</style>
