<template>
  <div class="hexad-grid">
    <svg
      width="224"
      height="228"
    >
      <g>
        <path ref="h1" />
        <path ref="h2" />
        <path ref="h3" />
        <path ref="h4" />
        <path ref="h5" />
        <path ref="h6" />
        <path ref="h7" />
      </g>
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: "HexadGrid",
  mounted() {
    this.drawHexad();
  },
  methods: {
    getHexVertices(a,d) {
        return [
          { x: d/2, y: 0 },
          { x: d, y: a/2 },
          { x: d, y: a*(3/2) },
          { x: d/2, y: a*2 },
          { x: 0, y: a*(3/2) },
          { x: 0, y: a/2 },
        ];
    },
    drawHexad() {
      const els = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7'].map(h => {
        return d3.select(this.$refs[h]);
      });
      const tension = 0.5;
      const drawHexagon = 
        d3.line()
              .x(function(d) { return d.x; })
              .y(function(d) { return d.y; })
              .curve(d3.curveCardinalClosed.tension(tension));

      const root3 = Math.sqrt(3);
      const numHexagons = 7;
      const n = 14;
      const as = Array.from(Array(numHexagons), (x,i) => 112 - (i*n));
      const allHexVertices = as.map((a,i) => {
        const d = a * root3;
        const hexVertices = this.getHexVertices(a, d);
        return hexVertices;
      });

      allHexVertices.forEach((hexVertices, i) => {
        const xOffset = ((as[0]*2) - (as[0]*root3))/2;
        const yOffset = 2;
        const x = xOffset + (i * (n * root3 / 2));
        const y = (i * n) + yOffset;
        const _ = 
          els[i]
            .attr("d", drawHexagon(hexVertices))
            .attr("stroke", "rgba(255, 255, 255, 0.12)")
            .attr("stroke-width", 1)
            .attr("transform", `translate (${x},${y})`)
            .attr("fill", "rgba(255, 255, 255, 0)");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.hexad-grid {
  width: 224px;
  height: 228px;
}
</style>
