<template>
  <div class="profile-timeline-card">
    <div
      class="body-2 text-sec-white"
      style="margin-bottom: 10px;"
    >
      {{ cardTitle }}
    </div>
    <component :is="cardBlock" />
  </div>
</template>

<script>
import BundlePreview from '@/components/_unit/BundlePreview.vue';

export default {
  name: 'ProfileTimelineCard',
  components: {
    BundlePreview
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  props: {
    inCarousel: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cardBlock() {
      const blocks = {
        release: 'bundle-preview',
        support: 'bundle-preview',
        answer: 'qa-preview',
      };

      // return blocks[this.item.type];
      return 'bundle-preview';
    },
    cardTitle() {
      const titles = {
        release: 'Released a bundle:',
        support: 'Supported a project:',
        answer: 'Released a bundle:',
      }

      // return titles[this.item.type];
      return 'Released a bundle:';
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-timeline-card {
  @include for-size(medium-screen-up) {
    width: inherit;
  }
}

.ptc__swap {
  width: inherit;
  height: 255px;
  // background-color: lightgray;
  img {
    height: 100%;
    width: auto;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.25);
    margin: 0 auto;
  }
}
</style>


