<template>
  <div class="intro__container">
    <div class="intro__col">
      <router-link :to="{ name: 'signin'}">
        <text-button class="btn--neutral-inverse btn--pill btn--full text-center">
          Log in
        </text-button>
      </router-link>
    </div>

    <div class="intro__col">
      <router-link :to="{ name: 'register'}">
        <text-button class="btn--positive btn--pill btn--full text-center">
          Sign up
        </text-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'Intro',
  components: { TextButton },
};
</script>

<style lang="scss" scoped>
.intro__container {
  display: flex;
  justify-content: space-around;
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  padding: var(--global-padding);
  padding-top: 300px;
  background: var(--background);
}

.intro__col {
  height: 44px;
  width: 50%;
  margin: 0 5px;
}
</style>
