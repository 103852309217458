<template>
  <div class="registration-toolbelt">
    <a
      v-if="currentStep == 0"
      :class="[
        nameIsValid ? 'advance-flow-button' : 'disabled', 
        'flow-button']"
      @click="emitIncrement"
    >
      {{ nameIsValid ? "Save & continue" : "Introduce yourself to continue" }}
    </a>

    <a
      v-if="currentStep == 1"
      :class="[
        !credentialsRequired ? 'disabled' : 'advance-flow-button', 
        'flow-button'
      ]"
      @click="validateCredentials"
    >
      {{
        !credentialsRequired
          ? "Add an email & password to continue"
          : "Save & continue"
      }}
    </a>

    <a
      v-if="currentStep >= 3"
      :class="[
        currentStep == 5 ? 'now-flex': '',
        'flow-button',
        'exit-flow-button'
      ]"
      href="/units"
    >{{ currentStep == 5 ? "Save & return to home" : "Save & exit" }}</a>

    <a
      class="flow-button"
      v-if="currentStep >= 2 && currentStep <= 4"
      :class="{ 
        'disabled': !scrolledToBottom,
        'finalize-flow-button': scrolledToBottom && currentStep == 2, 'advance-flow-button': currentStep != 2
      }"
      @click="unlockFlow"
    >
      {{
        currentStep == 2 ? scrolledToBottom ? "Agree to terms & continue" : "Read to the bottom to continue" : "Continue"
      }}
    </a>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { saveToken } from '@/store/localStorage';

export default {
  name: 'RegistrationToolbelt',
  props: {
    currentStep: {
      required: true,
      type: Number,
    },
    scrolledToBottom: Boolean,
    validations: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      unlocked: false,
      userId: null,
    };
  },
  computed: {
    ...mapState(['user', 'units']),
    nameIsValid() {
      return (
        this.validations.firstName.required
        && this.validations.lastName.required
      );
    },
    credentialsRequired() {
      return (
        this.validations.email.required && this.validations.password.required
      );
    },
  },
  methods: {
    ...mapActions(['LOAD_USER', 'LOAD_UNITS_DUMMY', 'LOGIN']),
    validateCredentials() {
      if (this.validations.$invalid) {
        this.$emit('errors');
      } else {
        this.$emit('errors');
        this.createUser();
      }
    },
    async handleCreateUserSuccess(response) {
      this.userId = response.data.user_id;
      await saveToken(response.data.auth_token);

      const login = () => new Promise((resolve) => {
        this.LOGIN();
        setTimeout(() => {
          resolve();
        }, 0);
      });

      const loadStuff = () => new Promise((resolve) => {
        this.LOAD_USER(this.userId); // 134
        this.LOAD_UNITS_DUMMY();
        setTimeout(() => {
          resolve();
        }, 0);
      });

      login()
        .then(() => {
          loadStuff();
        })
        .then(() => {
          this.emitIncrement(2);
        });
    },
    handleCreateUserError() {
      console.log('handling create-user-error');
      this.$emit('errors', 'create-user');
    },
    async createUser() {
      const url = 'admin/sign_up.json';
      const v = this.validations;
      const name = `${v.$model.firstName} ${v.$model.lastName}`;
      const data = new FormData();
      data.append('user[email]', v.$model.email);
      data.append('user[password]', v.$model.password);
      data.append('user[full_name]', name);

      try {
        const response = await this.$http.post(url, data);
        console.log(response);

        if (response.data.error) {
          this.handleCreateUserError();
        } else {
          this.handleCreateUserSuccess(response);
        }
      } catch (error) {
        console.log(error);
      }
    },
    emitIncrement(step) {
      if (typeof step === 'number') {
        this.$emit('increment', step);
      } else {
        this.$emit('increment');
      }
    },
    unlockFlow() {
      this.emitIncrement();
      this.unlocked = true;
      this.$eventBus.$emit('unlock-flow');
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-toolbelt {
  display: flex;
  flex-direction: row;
}
.now-flex {
  width: 100%;
}
</style>
