<template>
  <div id="qa-module">
    <QaModuleExpanded
      v-if="isExpanded"
      key="qa-expanded"
    />
    <QaHead
      v-else
      class="qme__head"
      icon="mdi-comment-question"
    />
    <!-- <QaPreview
      v-else
      key="qa-preview"
    /> -->
  </div>
</template>

<script>
import QaHead from '@/components/qa/QaHead.vue';
import QaModuleExpanded from '@/components/qa/QaModuleExpanded.vue';
import QaPreview from '@/components/qa/QaPreview.vue';

export default {
  name: 'QaModule',
  components: {
    QaHead,
    QaModuleExpanded,
    QaPreview,
  },
  props: {
    contextState: {
      type: String,
      default: 'preview',
    },
  },
  computed: {
    isExpanded() {
      const expanded =
        this.contextState === "summary" || this.contextState === "active";
      return expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
#qa-module {
  box-sizing: border-box;
  overflow-y: scroll;
  height: inherit;
}

#qa-module,
#qa-module > div,
#qa-module > div > div {
  @include for-size(phone-only) {
    width: inherit;
  }
  @include for-size(tablet-portrait-up) {
    width: inherit;
  }
}

.qa-fade-enter-active {
  transition: all 400ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.qa-fade-leave-active {
  transition: all 150ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.qa-fade-enter, .qa-fade-leave-to {
  height: 0;
  opacity: 0;
}

.qme__head {
  padding: 0px 20px;
  @include for-size(medium-screen-up) {
    grid-column: 1 / 3;
  }
  @include for-size(small-desktop-up) {
    grid-column: 1 / 4;
  }
}
</style>
