<template>
  <div
    :class="['unit-grid', 'ug__grid', unitGridBottomPosition]"
    @click="handleContainerClick"
  >
    <UnitInfoAnswer class="uia" />
    <UnitInfoCreators class="uic" />
    <UnitInfoSources class="uis" />
    <TimelineModule
      v-if="String(unit.unit_id).length <= 3"
      class="tm"
    />
    <AssetModule class="am" />
    <ImpactModule class="im" />
    <SupportModule
      v-if="$store.state.unit.bundles.length"
      :bundle="$store.state.unit.bundles[0]"
      class="sm"
    />
  </div>
</template>

<script>
import AssetModule from '@/components/_unit/AssetModule.vue';
import ImpactModule from '@/components/impact/ImpactModule.vue';
import SupportModule from '@/components/_unit/SupportModule.vue';
import TimelineModule from '@/components/_timeline/TimelineModule.vue';
import UnitInfoAnswer from '@/components/_unit/UnitInfoAnswer.vue';
import UnitInfoCreators from '@/components/_unit/UnitInfoCreators.vue';
import UnitInfoSources from '@/components/_unit/UnitInfoSources.vue';

export default {
  name: 'UnitGrid',
  components: {
    AssetModule,
    ImpactModule,
    SupportModule,
    TimelineModule,
    UnitInfoAnswer,
    UnitInfoCreators,
    UnitInfoSources
  },
  props: {
    lowerState: {
      default: 'preview',
      type: String,
    },
  },
  computed: {
    unit() {
      return this.$store.state.unit;
    },
    unitGridBottomPosition() {
      return `lower-is-${this.lowerState}`;
    },
  },
  methods: {
    handleContainerClick() {
      if (this.lowerState === 'active' || this.lowerState === 'share') {
        console.log('hey');
        this.$emit('toggle-context-state', 'preview');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ug__grid {
  display: grid;
  grid-template-rows: minmax(min-content, max-content);
  @include for-size(small-screen-only) {
    grid-template-columns: 100%;
  }
  @include for-size(medium-screen-up) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
  @include for-size(small-desktop-up) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 18px;
  }
  @include for-size(desktop-up) {
    grid-template-columns: 1fr 560px 1fr;
    grid-column-gap: 18px;
  }
}

.uia {
  margin-bottom: 32px;
  @include for-size(small-screen-only) {
    grid-row: 1;
  }
  @include for-size(medium-screen-up) {
    grid-row: 1;
    grid-column: 2;
  }
}

.uic {
  margin-bottom: 20px;
  @include for-size(small-screen-only) {
    grid-row: 2;
  }
  @include for-size(medium-screen-up) {
    grid-row: 2;
    grid-column: 1;
  }
  @include for-size(small-desktop-up) {
    grid-row: 1;
    grid-column: 1;
  }
}

.uis {
  margin-bottom: 20px;
  @include for-size(small-screen-only) {
    grid-row: 3;
  }
  @include for-size(medium-screen-up) {
    grid-row: 2;
    grid-column: 2;
  }
  @include for-size(small-desktop-up) {
    grid-row: 1;
    grid-column: 3;
  }
}

.tm {
  @include for-size(small-screen-only) {
    grid-row: 4;
    margin-bottom: 20px;
  }
  @include for-size(medium-screen-up) {
    grid-row: 3;
    grid-column: 1;
  }
  @include for-size(small-desktop-up) {
    grid-row: 2;
    grid-column: 1;
  }
}

.am {
  @include for-size(small-screen-only) {
    grid-row: 5;
    margin-bottom: 20px;
  }
  @include for-size(medium-screen-up) {
    grid-row: 3;
    grid-column: 2;
  }
  @include for-size(small-desktop-up) {
    grid-row: 2;
    grid-column: 2;
  }
}

.im {
  @include for-size(small-screen-only) {
    grid-row: 6;
    margin-bottom: 28px;
  }
  @include for-size(medium-screen-up) {
    grid-row: 4;
    grid-column: 2;
  }
  @include for-size(small-desktop-up) {
    grid-row: 2;
    grid-column: 3;
  }
}

.sm {
  @include for-size(small-screen-only) {
    grid-row: 7;
  }
  @include for-size(medium-screen-up) {
    grid-row: 5;
    grid-column: 2;
  }
  @include for-size(small-desktop-up) {
    padding-top: 380px;
    grid-row: 2;
    grid-column: 3;
  }
}

.unit-grid {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: auto;
  padding: 64px 20px 16px;
  background-color: black;
  overflow-y: scroll;
  transition: bottom 300ms ease;
  > div {
    flex-shrink: 0;
  }
  &.lower-is-preview {
    bottom: 100px;
  }
  &.lower-is-summary,
  &.lower-is-react {
    bottom: 295px;
  }
  &.lower-is-active {
    opacity: 0.5;
    bottom: calc(100% - 60px);
  }
  &.lower-is-purchase {
    bottom: 152px;
  }
  &.lower-is-share {
    bottom: 208px;
  }
}

.ds__icon {
  background-color: 24px;
}
</style>

