<template>
  <div v-if="unit.unit_type == 'Video'" class="asset-player">
    <div class="clickable-areas">
      <div
        class="no-swipe clickable-area"
        @click="videoClick('back')"
      />
      <div
        class="no-swipe clickable-area right-clickable-area"
        @click="videoClick('forward')"
      />
      <div
        class="no-swipe clickable-area right-quarter-clickable-area"
        @mousedown="handlePressing"
        @mouseleave="handleMouseUp"
        @mouseup="handleMouseUp"
        @touchstart="handlePressing"
        @touchend="handleMouseUp"
        @touchcancel="handleMouseUp"
      />
    </div>
    <div class="video-wrapper">
      <video
        allowfullscreen
        :poster="unit.cover_image"
        :class="{ 'is-playing':this.isPlaying && !this.videoMuted }"
        :muted="videoMuted"
        autoplay
        playsinline
        ref="player"
        @waiting="handleWaiting"
        @seeking="handleSeeking"
        @seeked="handleSeeked"
        @progress="handleProgress"
        @playing="handlePlaying"
        @pause="handlePause"
        @timeupdate="handleTimeUpdate"
        @ended="handleEnded"
        @canplay="handleCanPlay"
        @canplaythrough="handleCanPlayThrough"
        @emptied="handleEmpty"
        @orientationchange="handleOrientationChange"
      >
        <source
          :src="unit.asset.file.url"
          type="video/mp4"
        >
        <source
          :src="unit.asset.file.ogv"
          type="video/ogg"
        >
      </video>
      <div class="video-button" />
    </div>
  </div>
  <div v-else class="asset-player">
    <div
      v-if="unit.unit_type == 'Audio'"
      class="audio-metadata text-sec-white body-3"
    >
      Audio Track<br>
      Duration: {{ unit.asset.duration }}<br>
      Released {{ unit.metadata.releaseDate }}
    </div>

    <div
      v-else
      class="audio-metadata body-3 text-sec-white"
    >
      {{ unit.tracks.length }} Audio Tracks<br>
      Duration: {{ unit.metadata.duration }}<br>
      Released {{ unit.metadata.releaseDate }}
    </div>

    <div
      class="audio-scene no-swipe"
      :class="{
        'is-waiting': isWaiting && !isPaused && !viz,
        'is-playing':isPlaying,
        'viz': viz
      }"
    >
      <canvas
        class="no-swipe"
        :class="{ 'viz': viz}" 
        id="phase-scope"
        ref="phase-scope"
        :width="this.canvasDimension"
        :height="this.canvasDimension"
        @click="handleVizClick"
      />
      <div
        class="audio-art"
        :style="'background-image:url('+unit.cover_image+')'"
        @click="audioClick"
        id="audio-art"
        ref="audioArt" 
        :class="{'viz': viz}"
      />
      <LoadingIndicator
        v-if="isLoading && !isPlaying && !isPaused && !isWaiting"
      />
    </div>

    <div
      v-if="unit.unit_type === 'Playlist'"
      ref="scrubber"
      class="scrubber-ref no-swipe" 
    />

    <audio
      ref="player"
      crossorigin="anonymous"
      @waiting="handleWaiting"
      @seeking="handleSeeking"
      @seeked="handleSeeked"
      @progress="handleProgress"
      @playing="handlePlaying"
      @pause="handlePause"
      @timeupdate="handleTimeUpdate"
      @ended="handleEnded"
      @canplay="handleCanPlay"
      @canplaythrough="handleCanPlayThrough"
      @emptied="handleEmpty"
      @stalled="handleWaiting"
      @suspend="handleWaiting"
    />

    <div
      v-if="unit.unit_type === 'Audio'"
      ref="scrubber"
      class="scrubber scrubber-ref no-swipe"
      :style="'background:'+unit.median_color"
      @touchmove="fingerScrub"
      @click="scrubClick"
      @mousedown="handleMouseDown"
      @mouseleave="handleMouseUp"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove($event, true)"
    >
      <AudioTrackBackground :song="unit.asset" />
      <AudioProgressBar
        :time="currentTime"
        :is-playing="isPlaying"
        :percent-elapsed="percentElapsed" 
        :percent-seekable="percentSeekable"
      />
    </div>

    <ul
      v-if="unit.unit_type === 'Playlist'"
      class="playlist"
      tabIndex="-1"
      @keyup.32.prevent="handleTrackClick(currentTrackIndex, unit)"
      @keydown.prevent=""
    >
      <li
        v-for="(track, index) in unit.tracks"
        :key="'track--'+ index"
        class="playlist-item no-swipe"
        :class="{
          active: currentTrackIndex === index,
          playing: isPlaying && currentTrackIndex === index
        }"
        @click="handleTrackClick(index, unit)"
      >
        <span class="truncate">
          {{ track.name }}
        </span>
        <span style="float:right">
          {{ track.duration_str }}
        </span>
        <div
          class="scrubber no-swipe" 
          :style="'background:' + unit.median_color"
          @touchmove="fingerScrub" 
          @click="scrubClick"
          @mousedown="handleMouseDown"
          @mouseleave="handleMouseUp"
          @mouseup="handleMouseUp"
          @mousemove="handleMouseMove($event, (currentTrackIndex === index))"
        >
          <AudioTrackBackground :song="track" />
          <AudioProgressBar
            :time="currentTime"
            :is-playing="isPlaying"
            :percent-elapsed="percentElapsed"
            :percent-seekable="percentSeekable"
            :is-current-track="currentTrackIndex === index"
            :color="unit.median_color"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import LoadingIndicator from '../graphics/LoadingIndicator';
import AudioTrackBackground from '@/components/unit/AudioTrackBackground';
import AudioProgressBar from '@/components/unit/AudioProgressBar';

import { loadToken } from '@/store/localStorage';

export default {
  name: 'AssetPlayer',
  components: {
    LoadingIndicator,
    AudioTrackBackground,
    AudioProgressBar
  },
  props: {
    unitId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentTrackIndex: 0,
      currentTime: ['00', '00'],
      isPlaying: false,
      isPlayable: false,
      isPaused:false,
      isWaiting: false,
      percentElapsed: '100%',
      percentSeekable:'0%',
      isLoading: false,
      audioExpected:false,
      delay: 301,
      clicks: 0,
      timer: null,
      canvasDimension: 296,
      viz: false,
      scrubbing: false,
      scrollPosition: 0,
      playlistWidths: [],
      audioCtx: {},
      audioWrap: {},
      canvas: {},
      ctx: {},
      videoMuted: true,
      request: null,
      leftBufferLength: 0,
      rightBufferLength: 0,
      leftAnalyser: {},
      rightAnalyser: {},
      leftData: [],
      rightData: [],
      moduleIsVisible: true,
    };
  },
  ready() {
    const self = this;
    window.addEventListener('resize', self.setCanvasDimension(self));
  },
  beforeMount() {
    if (this.unit.unit_type === 'Playlist') {
      const arr = new Array();
      const trackLength = this.unit.tracks.length;

      // eslint-disable-next-line
        for (var i = 0; i < trackLength; i++) {
        const trackLength = this.unit.tracks[i].duration_str;
        const a = trackLength.split(':');
        const seconds = (+a[0]) * 60 + (+a[1]);
        arr.push(seconds);
      }
      const longestDuration = parseInt(Math.max.apply(null, arr), 10);
      for (var i = 0; i < trackLength; i++) {
        const pct = (arr[i] * 100) / longestDuration;
        this.playlistWidths.push(pct);
      }
    }
  },
  created() {
    this.$eventBus.$on('handle-toolbelt-play', this.handleToobeltPlay);
  },
  mounted() {
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);


    this.$nextTick(() => {
      if (this.unit.unit_type == 'Audio' || this.unit.unit_type == 'Playlist') {
        this.setCanvasDimension(this);
        this.audioWrap = this.$refs.audioArt;
        this.audioWrap.addEventListener('touchend', this.activateAudioContext);
        this.audioWrap.addEventListener('click', this.activateAudioContext);
        this.canvas = this.$refs['phase-scope'];
        this.ctx = this.canvas.getContext('2d');
        this.drawText();
      }
    });
  },
  deactivated() {
    document.body.classList.remove('fullscreen');
    document.body.classList.remove('fullscreen-image');
  },
  beforeDestroy() {
    this.$eventBus.$off('handle-toolbelt-play');
  },
  computed: {
    unit() {
      return this.$store.state.oldUnit;
    }
  },
  methods: {
    handleToobeltPlay() {
      console.log('handling toolbelt play');
      const { player } = this.$refs;
      const type = this.unit.unit_type;
      if (type === 'Audio') {
        this.audioClick();
      } else if (type === 'Playlist') {
        player.src = this.unit.tracks[0].asset_url.mp3;
        this.currentTrackIndex = 0;
        this.audioExpected = true
        this.percentElapsed = "100%"
        this.percentSeekable = "100%"
        player.load();
        player.play();
      } else {
        this.videoClick('forward');
      }
    },
    setCanvasDimension(t) {
      let dpi = window.devicePixelRatio * .75;
      t.canvasDimension = t.$refs.audioArt.offsetWidth * dpi;
    },
    handleVizClick(){
      if (this.viz) {
        this.togglePlay();
      } else {
        this.viz = !this.viz
      }
    },
    handleOrientationChange() {
      const w = document.documentElement.clientWidth;
      const h = document.documentElement.clientHeight;
      if (h > w) {
        document.body.classList.remove('fullscreen');
      } else { // landscape
        document.body.classList.add('fullscreen');
      }
      this.setCanvasDimension(this);
    },
    handleTrackClick(id) {
      const { player } = this.$refs;
      if (this.currentTrackIndex !== id) {
        player.src = this.unit.tracks[id].asset_url.mp3;
        this.currentTrackIndex = id
        this.audioExpected = true
        this.percentElapsed = "100%"
        this.percentSeekable = "100%"
        player.load();
        player.play();
        
      } else {
        this.audioExpected = true
        player.play();
      }
    },
    handleMouseDown(e){
      this.scrubbing = true
    },
    handleMouseUp(e){
      this.scrubbing = false
    },
    handleMouseMove(e, ok){
      if(this.scrubbing && ok && this.isPlaying){
        const audio = this.$refs.player;
        const scrubBar = this.$refs.scrubber;
        var mousePosition = (e.pageX - scrubBar.getBoundingClientRect().left) / scrubBar.offsetWidth;
        var time = mousePosition * audio.duration;
        audio.currentTime = time;
        this.percentElapsed = `${parseFloat(100 - ((audio.currentTime / audio.duration) * 100))}%`;
      }
    },
    fingerScrub(e) {
      const audio = this.$refs.player;
      const scrubBar = this.$refs.scrubber;
      this.scrubbing = true;
      for (let i = 0; i < e.changedTouches.length; i++) {
        const fingerPosition = (e.targetTouches[i].pageX - scrubBar.getBoundingClientRect().left) / scrubBar.offsetWidth;
        const time = fingerPosition * audio.duration;
        audio.currentTime = time;
      }
    },
    scrubClick(e) {
      if (this.audioExpected) {
        const audio = this.$refs.player;
        const scrubBar = this.$refs.scrubber;
        const clickPosition = (e.clientX - scrubBar.getBoundingClientRect().left) / scrubBar.offsetWidth;
        const time = clickPosition * audio.duration;
        audio.currentTime = time;
        this.percentElapsed = `${parseFloat(100 - ((audio.currentTime / audio.duration) * 100))}%`;
      }
    },
    togglePlay() {
      const { player } = this.$refs;
      if (this.isPlaying) {
        player.pause();
        this.audioExpected = false
      } else {
        this.audioExpected = true
        player.play();
      }
    },
    audioClick() {
      const { player } = this.$refs;
      if (!player.src){
        if (this.unit.unit_type == 'Playlist'){
          player.src = this.unit.tracks[this.currentTrackIndex].asset_url.mp3;
        }else{
          player.src = this.unit.asset.file.url;
        }
        player.load()
      }
      this.scrubbing = false;
      if (this.isPlaying) {
        if(this.viz){
          this.viz = !this.viz
        }else{
          player.pause();
          this.audioExpected = false
        }
      } else {
        if(this.viz){
          this.viz = !this.viz
        }else{
          player.play();
          this.audioExpected = true
        }
      }
    },
    videoClick(origin) {
      const { player } = this.$refs;
      if (player.muted === true) {
        player.currentTime = 0;
        player.muted = false;
        this.videoMuted = false;
      } else {
        player.playbackRate = 1;
        this.scrubbing = false;
        this.clicks++;
        if (this.clicks === 1) {
          const self = this;
          self.timer = setTimeout(() => {
            if (self.isPlaying) {
              player.pause();
            } else {
              player.play();
            }
            self.clicks = 0;
          }, self.delay);
        } else { // is dbl or NONE??
          clearTimeout(this.timer);
          if (this.isPlaying) {
            if (origin === 'back') {
              player.currentTime -= (player.duration * 0.05);
            } else {
              player.currentTime += (player.duration * 0.05);
            }
          } else {
            player.play();
          }
          this.clicks = 0;
        }
      }
    },
    handlePressing() {
      const { player } = this.$refs;
      player.playbackRate = 4;
      this.scrubbing = true;
    },
    handleMouseUp() {
      if (this.scrubbing) {
        const { player } = this.$refs;
        player.playbackRate = 1;
        this.scrubbing = false;
      }
    },
    handleWaiting() {
      this.isPlayable = false;
      this.isWaiting = true;
    },
    handleSeeking() {
      this.isPlayable = false;
      this.isWaiting = true;
    },
    handlePlaying() {
      this.isPaused = false;
      this.isPlayable = true;
      this.isPlaying = true;
      this.isWaiting = false;
      this.isLoading = false;
    },
    handlePause() {
      this.isPlaying = false;
      this.isPaused = true;
      this.isWaiting = false;
    },
    handleSeeked() {
      this.isPlayable = true;
    },
    handleProgress() {
      this.isLoading = true
      const audio = this.$refs.player;
      var duration = audio.duration;
      if (duration > 0) {
        for (var i = 0; i < audio.buffered.length; i++) {
          if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
              this.percentSeekable = 100 - ((audio.buffered.end(audio.buffered.length - 1 - i) / duration) * 100) + "%";
              break;
          }
        }
      }
    },
    handleTimeUpdate() {
      const audio = this.$refs.player;
      const s = parseInt(audio.currentTime % 60, 10);
      const m = parseInt((audio.currentTime / 60) % 60, 10);
      let readOut = {};
      readOut = [((`0${m}`).slice(-2)), ((`0${s}`).slice(-2))];
      this.currentTime = readOut;
      const duration = audio.duration;
      this.percentElapsed = `${parseFloat(100 - ((audio.currentTime / duration) * 100))}%`;
      this.percentSeekable = 100 - ((audio.buffered.end(audio.buffered.length - 1) / duration) * 100) + "%";
    },
    handleEnded() {
      this.handleNext();
      this.isWaiting = true;
    },
    handleCanPlay() {
      this.isLoading = false;
      this.isWaiting = false;
    },
    handleCanPlayThrough() {
      this.isPlayable = true;
      this.isWaiting = false;
      this.isLoading = false;
    },
    handleEmpty() {
      this.isPlaying = false;
      this.isWaiting = true;
    },
    handleNext() {
      this.isWaiting = true;
      this.audioExpected = true
      const { player } = this.$refs;
      const count = parseInt(this.unit.tracks.length, 10);
      const nextIndex = parseInt(this.currentTrackIndex, 10) + 1 <= count - 1 ? (parseInt(this.currentTrackIndex, 10) + 1) : 0;
      this.currentTrackIndex = nextIndex;
      player.src = this.unit.tracks[this.currentTrackIndex].asset_url.mp3;
      player.load();
      player.play();
        this.percentElapsed = "100%"
        this.percentSeekable = "100%"
    },
    activateAudioContext() {
      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      this.audioWrap.removeEventListener('touchend', this.activateAudioContext);
      this.audioWrap.removeEventListener('click', this.activateAudioContext);
      this.audioInit();
    },
    audioInit() {
      this.audio = this.$refs.player;
      const audioSrc = this.audioCtx.createMediaElementSource(this.audio);
      const splitter = this.audioCtx.createChannelSplitter(2);
      this.leftAnalyser = this.audioCtx.createAnalyser();
      this.rightAnalyser = this.audioCtx.createAnalyser();
      this.leftAnalyser.smoothingTimeConstant = 0.8;
      this.rightAnalyser.smoothingTimeConstant = 0.8;

      audioSrc.connect(splitter);
      const merger = this.audioCtx.createChannelMerger(2);
      splitter.connect(this.leftAnalyser, 0, 0);
      splitter.connect(this.rightAnalyser, 1, 0);
      this.leftAnalyser.connect(merger, 0, 0); // input L, output L
      this.rightAnalyser.connect(merger, 0, 1); // input R, output R
      merger.connect(this.audioCtx.destination);

      this.leftBufferLength = this.leftAnalyser.frequencyBinCount;
      this.leftData = new Uint8Array(this.leftBufferLength);

      this.rightBufferLength = this.rightAnalyser.frequencyBinCount;
      this.rightData = new Uint8Array(this.rightBufferLength);
      this.audio.addEventListener('play', this.renderFrame);
      this.audio.addEventListener('pause', this.cancelVisual);
    },
    drawText() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.beginPath();
      this.ctx.moveTo(30, 30);
      this.ctx.lineTo(this.canvas.width - 30, this.canvas.height - 30);
      this.ctx.moveTo(this.canvas.width - 30, 30);
      this.ctx.lineTo(30, this.canvas.height - 30);
      this.ctx.strokeStyle = '#606060';
      this.ctx.stroke();
      this.ctx.beginPath();
      this.ctx.fillStyle = '#f0f0f0';
      this.ctx.font = '10pt Arial';
      this.ctx.fillText('+L', 12, 24);
      this.ctx.fillText('-L', this.canvas.width - 30, this.canvas.height - 12);
      this.ctx.fillText('+R', this.canvas.width - 30, 24);
      this.ctx.fillText('-R', 12, this.canvas.height - 15);
      this.ctx.fillStyle = '#606060';
      this.ctx.font = '10pt Arial';
      this.ctx.fillText('out of phase', this.canvas.width - 100, this.canvas.height / 2);
      this.ctx.fillText('out of phase', 25, this.canvas.height / 2);
    },
    renderFrame() {
      this.request = window.requestAnimationFrame(this.renderFrame);
      // update data in timeData
      this.leftAnalyser.getByteTimeDomainData(this.leftData);
      this.rightAnalyser.getByteTimeDomainData(this.rightData);
      this.draw();
    },
    draw() {
      let X; let
        Y;
      this.drawText();
      this.ctx.save();
      this.ctx.translate(this.canvas.width / 2, this.canvas.height / 2);
      this.ctx.rotate(45 * Math.PI / 180);
      for (let i = 0; i < this.rightBufferLength; i++) {
        X = this.leftData[i] - 128;
        Y = this.rightData[i] - 128;
        if ((X < -10 && Y > 10) || (X > 10 && Y < -10)) this.ctx.fillStyle = '#ffff00';
        else this.ctx.fillStyle = '#80ff00';
        this.ctx.fillRect(X, Y, 1, 1);
      }
      this.ctx.fill();
      this.ctx.restore();
    },
    cancelVisual() {
      window.cancelAnimationFrame(this.request);
    }

  },
}
</script>

<style lang="scss" scoped>
  .asset-player {
    position: relative;
    width: inherit;
    overflow-x: scroll;
    padding-top: 10px;
  }
  .tag{
    opacity:.3;
  }
  .attr-row{
    margin-bottom: 4px;
  }
  .clickable-areas {
    width: inherit;
    position: relative;
  }
  .clickable-area{
    z-index:2;
    background:transparent;
    touch-action:manipulation;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;
    height: calc((var(--vh, 1vh) * 50));
    position:absolute;
    top:0;
    width:50%;
  }
  .right-clickable-area{
    left:50%;
    width:30%;
  }
  .right-quarter-clickable-area{
    left:80%;
    width:20%;
  }
  .video-button{
    pointer-events:none;
    position:absolute;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    display:block;
    z-index:2;
    transition:opacity .2s ease-in-out;
    &:after{
      content:"SEE";
      position:absolute;
      top:30%;
      left:50%;
      margin-left: -38px;
      margin-top: 0;
      border:1px solid rgba(255,255,255,.8);
      background:rgba(0, 0, 0, 0.4);
      padding:10px var(--global-padding);
      text-align:center;
      color:white;
      z-index:2;
      display:block;
      opacity:1;
    }
  }
  .is-playing + .video-button{
    opacity:0;
  }
  .audio-metadata {
    padding-bottom: 7px;
    padding-left: 60px;
  }
  .audio-scene {
    position: relative;
    -webkit-backface-visibility: hidden;
    &.is-playing,&.is-playing{
      .audio-art{
        opacity:1;
        transition:opacity 2s ease-in;
      }
    }
    z-index:3;
    display:block;
    height:0;
    padding-bottom:100%;
    &:after{
      pointer-events:none;
      content:"LISTEN";
      position:absolute;
      top:50%;
      left:50%;
      width:130px;
      margin-left: -65px;
      margin-top: -18px;
      border:1px solid white;
      background:rgba(0,0,0,.5);
      color: white;
      padding:10px var(--global-padding);
      text-align:center;
      z-index:2;
      display:block;
      transition:opacity .2s 0 ease-in-out;
      opacity:1;
      perspective: 600px;
      margin-bottom:25px;
    }
    &.is-playing:after, &.is-waiting.is-playing:after, &.is-waiting.is-playing:after, &.viz.is-waiting:after, &.viz.is-waiting.is-playing:after{
      opacity:0;
      transition:opacity .8s 1.8s cubic-bezier(1,.8,.8,1);
      content:"PLAYING";
      color: white;
    }
    &.is-waiting:after, &.is-waiting:after{
      opacity:1;
      content:"WAITING";
      color: white;
    }
    &.viz:after{
      opacity:0;
      transition:none;
    }
    .dot-container{
      position:absolute;
      transform:scale(4);
      top:calc(50% - ((5px / 1.6) * 4));
      left:calc(50% - ((15px / 4) * 2));
    }
  }

  .audio-art{
    width:100%;
    height:0;
    opacity:.6;
    transition:none;
    padding-bottom:100%;
    margin:inherit auto;
    display:block;
    align-self:center;
    text-align:center;
    position:relative;
    background-size:cover;
    background-position:center;
    max-width:100%;
    margin-bottom:25px;
    touch-action: manipulation;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;
    position:relative;
    img, canvas{
      touch-action: manipulation;
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;
      transform-origin:top left;
      display:block;
    }
    &.viz{
      padding-bottom:0;
      margin-bottom:calc(100% - 25px);
      max-width:50px;
      border-radius:50%;
      max-height:50px;
      height:50px;
      top:-55px;
      left:0px;
    }
  }
  canvas#phase-scope{
    position:absolute;
    z-index:2;
    background:black;
    transform-origin: top left;
    width:50px;
    border-radius:50%;
    top:-55px;
    left:0px;
    display:block;
    border:1px solid rgba(127,127,127,.4);
    &.viz{
      border-radius:0%;
      min-width:100%;
      width:calc(100% + 2px);
      top:-1px;
      left:-1px;
      &:before,&:after{
        width:100%;
        position:absolute;
        display:block;
        color:white;
        z-index:-1;
        content:"+L +R";
        text-align:center;
      }
      &:after{
        content:"-R -L";
      }
    }
  }

  .scrubber {
    cursor: pointer;
    text-align: left;
    position: relative;
    z-index: 1;
    display: block;
    height: 22px;
    width: 100%;
    margin-bottom: 25px;
  }

  .playlist-item{
    position: relative;
    color:rgba(255,255,255,.6);
    span{
      pointer-events:none;
    }
  }
  ul {
    padding: 8px;
    list-style-type: none;
  }
  .playlist{

    li {
      font-size: 14px;
    }
  }

  video {
    width: 100%;
    max-width: 560px;
    height: auto;
  }
</style>
