<template>
  <div class="entity-stamp">
    <AvatarIcon
      class="avatar--l"
      :image="entity.image"
      :title="entity.name"
    />
    <div class="tag text-sec-white">
      {{ entity.name.toUpperCase() }}
    </div>
  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';

export default {
  name: 'EntityStamp',
  components: {
    AvatarIcon,
  },
  props: {
    entity: {
      default() {
        return {};
      },
      type: Object,
    }
  },
}
</script>

<style lang="scss" scoped>
.entity-stamp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > :nth-child(2) {
    max-width: 56px;
    padding-top: 4px;
    text-align: center;
  }
}
</style>

