<template>
  <div class="im__container">
    <div class="im__header">
      <div class="im__instructions">
        <div class="tag text-white">
          Take control of the algorithm.
        </div>
        <div>
          Select all that apply.
        </div>
      </div>

      <text-button
        class="btn--index btn--im btn--pill" 
        @click="emitToggleControlOff"
      >
        <div style="padding-top: 0px;">
          View our curated feed.
        </div>
      </text-button>
    </div>

    <section>
      <div class="action-2 text-white">
        Relationship
      </div>
      <div class="im__btn-group">
        <text-button
          v-for="(filter, i) in filters.slice(0,2)"
          :key="'rel' + i"
          class="btn--im-options"
          :class="filter.active ? 'active' : 'inactive'"
          @click="toggleFilter(filter.name)"
        >
          {{ filter.icon }}
        </text-button>
      </div>
    </section>

    <section>
      <div class="action-2 text-white">
        Role
      </div>
      <div class="im__btn-group">
        <text-button
          v-for="(filter, i) in filters.slice(4,6)"
          :key="'rel' + i"
          class="btn--im-options"
          :class="filter.active ? 'active' : 'inactive'"
          @click="toggleFilter(filter.name)"
        >
          {{ filter.icon }}
        </text-button>
      </div>
    </section>

    <section>
      <div class="action-2 text-white">
        Type
      </div>
      <div class="im__btn-group">
        <icon-button
          v-for="(filter, i) in filters.slice(6)"
          :key="'icon' + i"
          class="icon-btn--s"
          :class="filter.active ? 'filter-btn--active' : 'filter-btn--inactive'"
          :icon="filter.icon"
          @click="toggleFilter(filter.name)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'MobileIndexMenu',
  components: {
    IconButton,
    TextButton,
  },
  props: {
    isActiveFromIndex: {
      default() {
        return {};
      },
      type: Object
    },
  },
  data() {
    return {
      filterList: ['follow', 'follower', 'doppelganger', 'nemesis', 'creator', 'curator', 'audio', 'video', 'image', 'article'],
      filters: [
        {
          active: false,
          name: 'follow',
          icon: 'Follows',
        },
        {
          active: false,
          name: 'follower',
          icon: 'Followers',
        },
        {
          active: true,
          name: 'doppelganger',
          icon: 'Doppelgangers',
        },
        {
          active: true,
          name: 'nemesis',
          icon: 'Nemeses',
        },
        {
          active: true,
          name: 'creator',
          icon: 'Creators',
        },
        {
          active: true,
          name: 'curator',
          icon: 'Curators',
        },
        {
          active: false,
          name: 'audio',
          icon: 'mdi-music',
        },
        {
          active: false,
          name: 'video',
          icon: 'mdi-movie',
        },
        {
          active: false,
          name: 'image',
          icon: 'mdi-camera',
        },
        {
          active: false,
          name: 'article',
          icon: 'mdi-document',
        },
      ],
    };
  },
  mounted() {
    this.filterOnMount();
  },
  methods: {
    emitToggleControlOff() {
      this.$eventBus.$emit('emit-toggle-control-off', false);
    },

    filterOnMount() {
      console.log('filter on mount');
      const filterNames = Object.keys(this.isActiveFromIndex);
      const sourceFilterNames = filterNames.slice(0, 4);
      const typeFilterNames = filterNames.slice(4);

      sourceFilterNames.forEach((filterName) => {
        this.filters.filter(
          filter => filter.name == filterName,
        )[0].active = this.isActiveFromIndex[filterName];
      });

      // if all typeFilters are active, set them all to inactive
      const {
        follow, follower, creator, curator, ...typeFilters
      } = this.isActiveFromIndex;
      const typeFilterActivity = Object.values(typeFilters);
      const numActiveTypeFilters = typeFilterActivity.filter(el => el == true).length;

      if (numActiveTypeFilters == 4) {
        typeFilterNames.forEach((filterName) => {
          this.filters.filter(
            filter => filter.name == filterName,
          )[0].active = false;
        });
      } else {
        typeFilterNames.forEach((filterName) => {
          this.filters.filter(
            filter => filter.name == filterName,
          )[0].active = this.isActiveFromIndex[filterName];
        });
      }

      this.$eventBus.$emit('filter-index', { filters: this.filters });
    },

    toggleFilter(filterName) {
      let selectedFilter;

      if (this.filterList.includes(filterName)) {
        selectedFilter = this.filters.filter(item => item.name == filterName)[0];

        selectedFilter.active = !selectedFilter.active;
      }

      this.$eventBus.$emit('filter-index', { filters: this.filters });
    },
  },
};
</script>

<style lang="scss" scoped>
.im__container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  padding: 16px 12px;
  background-color: var(--primary);
}

.im__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;

  .im__instructions {
    display: flex;
    flex-direction: column;
    width: 128px;
  }

  :nth-child(2) {
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    line-height: 12px;
  }
}

.im__btn-group {
  display: flex;
  justify-content: flex-start;
  padding-top: 6px;
  padding-bottom: 6px;

  > * {
    margin-right: 6px;
  }
}

.is-expanded {
  height: 254px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  // transition: height .25s ease-in-out;
}

.is-collapsed {
  height: 60px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: height 300ms ease-in-out;
}

.rm__expansion-container {
  display: flex;
  height: 194px;
  padding: 10px;
  transition: height 300ms ease-in-out;
}

.rm__expansion__closed {
  display: flex;
  height: 0px;
  transition: height 300ms ease-in-out;
}

.expanded-container {
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

.rm__source {
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 10px;
  > :first-child {
    margin-right: 12px;
  }
}

.rm__btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  height: 35px;
  width: 110px;
  border-radius: 50px;
  margin-left: auto;
  padding: 7px 12px;
  border: 1px solid transparent;
  border-radius: 22px;
  background-color: var(--positive);
  border-color: var(--positive);
  color: var(--background);
  cursor: pointer;
}

.rm__icon-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: 10px;
  > div {
    margin-right: 7px;
  }

  .rm__icon-bar-types {
    display: flex;
    justify-content: flex-start;
    > div {
      margin-right: 7px;
    }
  }
}

.rm__check {
  margin-left: 10px;
  fill: var(--positive);
}

.rm__chevron {
  margin-left: auto;
}

.opacity-enter-active {
  transition: opacity 300ms 200ms;
}

.opacity-2-enter-active {
  transition: opacity 400ms 400ms;
}

.opacity-leave-active {
  transition: opacity 300ms;
}

.opacity-2-leave-active {
  transition: opacity 0ms;
}

.opacity-enter,
.opacity-leave-to,
.opacity-2-enter,
.opacity-2-leave-to {
  opacity: 0;
}
</style>
