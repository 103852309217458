<template>
  <div class="media-channel">
    <div
      v-if="content.primary .units.length"
      class="headline text-sec-white"
    >
      By {{ entityName }}
    </div>
    <UnitPreview
      v-for="unit in content.primary.units"
      :key="'primary-media-' + unit.unit_id"
      class="previews"
      :unit="unit"
    />
    <div
      v-if="content.featured.units.length"
      class="headline text-sec-white"
    >
      Featuring {{ entityName }}
    </div>
    <UnitPreview
      v-for="unit in content.featured.units"
      :key="'featured-media-' + unit.unit_id"
      class="previews"
      :unit="unit"
    />
  </div>
</template>

<script>
import UnitPreview from '@/components/unit/UnitPreview.vue';
import { previews } from '@/helpers';

export default {
  name: 'MediaChannel',
  components: {
    UnitPreview,
  },
  props: {
    entityName: {
      type: String,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      previews
    }
  }
}
</script>

<style lang="scss" scoped>
.previews {
  margin: 0 auto 10px;
}
</style>

