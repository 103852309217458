export default {
  data() {
    return {
      windowHeight: null,
    }
  },
  mounted() {
    this.calculateDocumentVariables();
    window.addEventListener('resize', this.$_.debounce(this.calculateDocumentVariables, 200, {
      leading: true,
      trailing: false,
    }));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateDocumentVariables);
  },
  methods: {
    calculateDocumentVariables() {
      if (!this.windowHeight || this.windowHeight !== window.innerHeight) {
        this.windowHeight = window.innerHeight;
        let vh = this.windowHeight * 0.01;
        let vw = window.innerWidth * 0.01;
        const ms = (this.windowHeight - 224) / this.windowHeight;
        const ts = (this.windowHeight - 336) / this.windowHeight;
        const ds = 336 / this.windowHeight;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}`);
        document.documentElement.style.setProperty('--ms', `${ms}`);
        document.documentElement.style.setProperty('--ts', `${ts}`);
        document.documentElement.style.setProperty('--ds', `${ds}`);
      }
    },
  }
}