<template>
  <div class="qa-bubble">
    <FeatureButton
      v-if="editing"
      class="qb__btn fb-position"
      :featured="featured"
      @click="toggleFeaturedState"
    />
    <QaBubbleCard
      :card-options="{ avatar, numAnswers, text, type }"
      :class="[typeClasses, {'editing': editing, 'highlighted': highlight}]"
      @click="emitQaThreadToggle"
    />
    <VisibilityButton
      v-if="editing"
      class="qb__btn vb-position"
      :showing="showing"
      :type="visibilityType"
      @click="toggleShowingState"
    />
  </div>
</template>

<script>

import FeatureButton from '@/components/buttons/FeatureButton.vue';
import QaBubbleCard from '@/components/qa/QaBubbleCard.vue';
import VisibilityButton from '@/components/buttons/VisibilityButton.vue';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'QaBubble',
  components: {
    FeatureButton, 
    QaBubbleCard, 
    VisibilityButton
  },
  props: {
    avatar: {
      default: '', 
      type: String
    },
    editing: {
      default: false,
      type: Boolean,
    },
    highlight: {
      default: false,
      type: Boolean,
    },
    id: {
      default: 0,
      type: Number,
    },
    numAnswers: {
      default: 0,
      type: [Boolean, Number],
    },
    text: {
      default: '', 
      type: String
    },
    type: {
      default: 'question',
      type: String,
      validator(value) {
        return ['answer', 'comment', 'question'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapState(['hiddenQaIds', 'qa', 'user']),
    featuredId() {
      return this.$store.state.featuredQa.featured_id;
    },
    featured() {
      return this.featuredId === this.id;
    },
    showing() {
      const inHiddenList = this.hiddenQaIds.find(id => id === this.id);
      return !inHiddenList;
    },
    typeClasses() {
      return [`${this.type}-colors`, `${this.type}-margin`];
    },
    visibilityType() {
      const ownerIsCurrentUser = this.avatar == this.user.image;
      return ownerIsCurrentUser ? 'privacy' : 'visibility';
    },
  },
  methods: {
    ...mapMutations(['addHiddenQaId', 'removeHiddenQaId']),
    emitQaThreadToggle() {
      this.$emit('toggle-qa-thread');
    },
    setFeaturedQa() {
      return this.$store.mutations.setFeaturedQa;
    },
    defaultFeature() {
      return {
          question: null,
          index: 0,
          featured_id: 0,
      };
    },
    findMatchingQuestion() {
      const question = this.qa.questions.find(question => {
          return question.question_id === this.id
        });
      const index = -1;

      return [question, index];
    },
    newFeature() {
      let question = null;
      let index = null;
      if (this.type === 'question') {
        [question, index] = this.findMatchingQuestion();
      } else {
        question = this.qa.questions.find(question => {
          if (!question.answers) { return false; }

          const answerIds = question.answers.map(answer => answer.id);
          if (answerIds.indexOf(this.id) !== -1) {
            index = answerIds.indexOf(this.id);
            return true;
          }
        });
      }

      return {
        question,
        index,
        featured_id: this.id,
      };
    },
    async toggleFeaturedState() {     
      const featuredQa = await this.featured
        ? this.defaultFeature() 
        : this.newFeature();
      this.$store.commit('setFeaturedQa', { featuredQa });
    },
    async toggleShowingState() {
      const toggleShowingState = this.showing
        ? this.addHiddenQaId
        : this.removeHiddenQaId;

      toggleShowingState({id: this.id});
    },
  },
};
</script>

<style lang="scss" scoped>
.qa-bubble {
  position: relative;
  display: flex;
  width: calc(100% + 10px);
  margin-left: -10px;
  padding-bottom: 2px;
  justify-content: flex-end;
  z-index: 5;
};

// Refactor to properly name these selectors!
// Add group style to modify border radiuses
.qa-thread__question.qa-thread--is-expanded > .card-br {
  border-bottom-right-radius: 0;
}

.qa-thread__answer:not(:last-child) > .card-br {
  border-radius: 0px;
}

.qa-thread__answer:last-child > .card-br {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.question-colors {
  background-color: var(--tint-white);
}

.question-colors.highlighted {
  background-color: var(--tip);
}

.question-margin {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  &.editing {
    margin-left: 42px;
    margin-right: 32px;
  }
}

.answer-colors {
  background-color: var(--stroke-white);
}

.answer-colors.highlighted {
  color: var(--primary);
  background-color: var(--warning);
}

.answer-margin {
  width: 100%;
  margin-left: 10px;
  margin-right: 0px;
  &.editing {
    margin-left: 52px;
    margin-right: 32px;
  }
}

.qb__btn {
  position: absolute;
  top: calc(50% - 20px);
  z-index: 10;
  &.fb-position {
    left: -2px;
  }
  &.vb-position {
    right: -12px;
  }
}
</style>
