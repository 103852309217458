<template>
  <div class="financials-block">
    <div
      class="fb__bar fb__investment-bar"
      :style="barStyles.investmentStyles"
    />
    <div
      class="fb__bar fb__sales-bar"
      :style="barStyles.salesStyles"
    />
    <div
      class="fb__text tag"
      :style="textStyles.salesStyles"
    >
      <span class="text-sec-white">Sales</span><br>
      <span class="text-white">${{ details.totalSales }}</span>
    </div>
    <div
      class="fb__text tag text-white"
      :style="textStyles.investmentStyles"
    >
      <span class="text-sec-white">Investment</span><br>
      <span class="text-white">${{ details.investment }}</span>
    </div>
    <keep-alive>
      <FinancialsChart :details="details" />
    </keep-alive>
  </div>
</template>

<script>
import FinancialsChart from '@/components/_timeline/FinancialsChart.vue';

export default {
  name: 'FinancialsBlock',
  components: {
    FinancialsChart,
  },
  props: {
    details: {
      type: Object,
      required: true,
    }
  },
  computed: {
    barStyles() {
      const { investment, totalSales } = this.details;
      const lowerHeight = this.isProfitable
        ? investment / totalSales * 120
        : totalSales / investment * 120;
      const lowerStyles = {
        height: `${lowerHeight}px`,
        top: `${120 - lowerHeight}px`
      };
      const higherStyles = { height: '120px', top: '0px' };
      let salesStyles = this.isProfitable
        ? higherStyles
        : lowerStyles;
      salesStyles = { ...salesStyles, zIndex: 2 };
      let investmentStyles = this.isProfitable
        ? lowerStyles
        : higherStyles;
      
      return { investmentStyles, salesStyles };
    },
    isProfitable() {
      const { investment, totalSales } = this.details;
      return totalSales > investment;
    },
    textStyles() {

      const invTop = this.isProfitable ? '60px': '10px';
      const salesTop = this.isProfitable ? '10px': '60px';
      
      const investmentStyles = { top: invTop, left: '65px', fontWeight: 400 };
      const salesStyles = { top: salesTop, left: '0px', textAlign: 'right', fontWeight: 400 }
      
      return { investmentStyles, salesStyles };
    }
  }
}
</script>

<style lang="scss" scoped>
.financials-block {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
}

svg {
  position: absolute;
  top: 0;
  right: 0;
}

.fb__bar {
  position: absolute;
  left: 50px;
  width: 12px;
}

.fb__investment-bar {
  background: linear-gradient(#545454 0%,
rgba(73, 73, 73, 0) 100%);
}

.fb__sales-bar {
  background: linear-gradient(#FF1212 0%,
#3A6700 100%);
  z-index: 2;
}

.fb__text {
  position: absolute;
  top: 40px;
}
</style>

