<template>
  <IconButton
    :class="['icon-btn--32', 'vb__bg', activeFillClass]"
    :icon="activeIcon"
    @click="$emit('click')"
  />
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";

export default {
  name: "VisibilityButton",
  components: {
    IconButton
  },
  props: {
    showing: {
      default: true,
      type: Boolean,
    },
    type: {
      default: "privacy",
      type: String,
      validator: function(value) {
        return ["privacy", "visibility"].indexOf(value) !== -1;
      }
    }
  },
  computed: {
    activeFillClass() {
      const useShowingFill =
        (this.type === "visibility" && this.showing) || this.type === "privacy";
      return useShowingFill ? "showing-fill" : "not-showing-fill";
    },
    activeIcon() {
      let activeIcon;
      if (this.type === "privacy") {
        activeIcon = this.showing ? "mdi-lock-plus" : "mdi-lock-reset";
      } else if (this.type === "visibility") {
        activeIcon = this.showing ? "mdi-eye" : "mdi-eye-off-outline";
      }

      return activeIcon;
    }
  },
};
</script>

<style lang="scss" scoped>
.vb__bg {
  background-color: rgba(255, 255, 255, 0.06);
}

.showing-fill {
  & > :nth-child(1) {
    fill: var(--secondary-white);
  }
}

.not-showing-fill {
  & > :nth-child(1) {
    fill: var(--hint-white);
  }
}
</style>

