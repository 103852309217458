<template>
  <div
    class="tool-button"
    @click="$emit('click')"
  >
    <div :class="['tag', `text-${theme}`, `text-${position}-btn`]">
      {{ text }}
    </div>
    <component
      :is="icon"
      :class="[`svg-${theme}`, `icon-${position}-btn`]"
    />
  </div>
</template>

<script>
import MdiPencil from '@/components/graphics/MdiPencil.vue';
import MdiShare from '@/components/graphics/MdiShare.vue';

export default {
  name: 'ToolButton',
  components: { MdiPencil, MdiShare },
  props: {
    icon: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'left',
      validator(value) {
        return ['left', 'right'].indexOf(value) !== -1;
      },
    },
    text: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'neutral',
    },
  }
}
</script>

<style lang="scss" scoped>
.tool-button {
  display: grid;
  align-items: center;
  grid-template-rows: 100%;
  grid-template-columns: minmax(min-content, max-content);
  grid-column-gap: 8px;
  height: 32px;
  padding: 0 12px;
  cursor: pointer;
  > div {
    grid-row: 1;
  }
}

.icon-right-btn, .text-left-btn {
  grid-column: 2;
}

.icon-left-btn, .text-right-btn {
  grid-column: 1;
}

svg {
  width: 20px;
  height: 20px;
}
</style>

