<template>
  <g>
    <text
      :class="attrs.class"
      :style="attrs.style"
      :x="attrs.x"
      :y="attrs.y"
    >
      {{ this.getName(this.statName) }}
    </text>
    <text
      v-if="this.category != 'superindices'"
      :class="attrs.class"
      style="fill: rgba(255, 255, 255, 0.7); font-weight: normal;"
      :x="secondTextX"
      :y="attrs.y"
    >
      {{ this.statValue }}
    </text>
  </g>
</template>

<script>
export default {
  name: 'StatsAnnotation',

  props: {
    category: String,
    statName: String,
    stats: Object,
    attrs: Object,
  },

  computed: {
    statValue() {
      const name = this.statName;
      const { sums } = this.stats;
      const { indices } = this.stats;
      const exceptions = [
        'purchases',
        'participants',
        'investments',
        'productionLength',
      ];

      if (exceptions.includes(name)) {
        if (sums[name] === undefined) {
          return 'N/A';
        }
      }

      if (name == 'timeOnPage') {
        const secs = Math.floor(sums[name]);
        return `${secs} secs`;
      } if (name == 'newsworthiness') {
        return indices[name].toFixed(2);
      } if (name == 'answersPerQuestion') {
        return sums.questions == 0
          ? 0
          : (sums.answers / sums.questions).toFixed(2);
      } if (name == 'investments') {
        return `$${String(sums[name])}`;
      } if (name == 'productionLength') {
        return `${sums[name].split(':').slice(0, 1)} days`;
      }
      return String(sums[name]);
    },
    secondTextX() {
      if (this.category == 'heat') {
        return '140px';
      } if (this.category == 'newsworthiness') {
        return '120px';
      }
      return '142px';
    },
  },

  methods: {
    getName(str) {
      if (str == 'answersPerQuestion') {
        return 'Answers/Q';
      } if (/[A-Z]/.test(str)) {
        const name = str
          .replace(/([A-Z])/g, str => ` ${str.toLowerCase()}`)
          .replace(/^./, str => str.toUpperCase());

        return name;
      }
      return str.charAt(0).toUpperCase() + str.substr(1);
    },
  },
};
</script>
