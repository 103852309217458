<template>
  <div
    v-if="unit"
    class="unit-preview"
    @click="goToUnit"
  >
    <div class="up__header">
      <div
        class="body-2 text-white"
        style="font-weight: 600; margin-right: 10px;"
      >
        {{ unit.title }}
      </div>
      <button
        v-if="showCtaBtn"
        class="cta-btn"
        @click="goToUnit"
      >
        {{ isMusic ? "LISTEN" : "WATCH" }}
      </button>
    </div>

    <div class="up__visuals">
      <div :style="{ display: imageDisplay, height: imageHeight + 'px', position: 'relative'}">
        <div
          v-if="unit.unit_type === 'playlist'"
          class="tracks-overlay tag text-white"
        >
          {{ unit.playlist.tracks.length }}
          TRACKS
        </div>
        <img
          class="up__image"
          :src="unit.image_url"
        >
      </div>
      <AudioWaveForm
        v-if="isMusic && waves"
        :waves="waves"
      />
      <div
        v-if="unit.unit_type === 'article'"
        class="body-3 text-gray"
        style="padding-top: 8px;"
      >
        {{ unit.description }}
      </div>
    </div>

    <div class="up__details">
      <div class="owner">
        <AvatarIcon
          class="avatar--xs"
          :image="unit.owner.image_url"
        />
        <div class="body-3 text-white">
          {{ unit.owner.name }}
        </div>
      </div>
      <div class="up__reaction">
        <div class="reaction-preview">
          <img :src="require('@/assets/png/reaction-preview.png')">
        </div>
      </div>

      <QaPreviewSummary
        v-if="questions"
        class="up__qps"
        small
        :answers-count="unit.qa.summary.answers_count"
        :questions-count="unit.qa.summary.questions_count"
      />
      <div class="up__bundle">
        <MdiCheckboxMultiple />
      </div> 
    </div>
  </div>
</template>

<script>
import AudioWaveForm from '@/components/shared/AudioWaveForm';
import AvatarIcon from '@/components/entities/AvatarIcon';
import MdiCheckboxMultiple from '@/components/graphics/MdiCheckboxMultiple';
import QaPreviewSummary from '@/components/qa/QaPreviewSummary.vue';

import { loadToken } from '@/store/localStorage';

export default {
  name: 'UnitPreview',
  components: {
    AudioWaveForm,
    AvatarIcon,
    MdiCheckboxMultiple,
    QaPreviewSummary,
  },
  props: {
    size: {
      type: String,
      default: 'large',
      validator: function (value) {
        return ['small', 'large'].indexOf(value) !== -1;
      }
    },
    unit: { 
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      questions: null,
    }
  },
  computed: {
    imageDisplay() {
      const hideCriteria = (this.unit.unit_type !== 'image') && (this.unit.unit_type !== 'video') && (this.size !== 'large');

      return hideCriteria ? 'none' : 'block';
    },
    imageHeight() {
      let height;
      
      if (this.unit.unit_type === 'audio' && this.size === 'large') {
        height = 350;
      } else if (this.size === 'large') {
        height = 250;
      } else {
        height = 15;
      }
      return height;
    },
    isMusic() {
      const type = this.unit.unit_type;
      return (type === 'audio') || (type === 'playlist');
    },
    showCtaBtn() {
      const types = ['audio', 'playlist', 'video'];
      return types.includes(this.unit.unit_type);
    },
    waves() {
      if (!this.isMusic) return;
      return this.unit.unit_type === 'audio'
        ? this.unit.audio.color_bars
        : this.unit.playlist.tracks[0].color_bars;
    }
  },
  methods: {
    goToUnit() {
      this.$router.push({
        name: 'unit-view',
        params: { unit_id: this.unit.unit_id }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.unit-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 8px;
  background-color: black;
  width: 100%;
  > * { width: inherit; }
}

.up__header {
  display: flex;
  justify-content: space-between;
  flex: 0 0 32px;
  margin-bottom: 16px;
}

.up__visuals {
  margin-bottom: 10px;
  .tracks-overlay {
    position: absolute;
    right: 8px;
    bottom: 10px;
    width: 60px;
    height: 36px;
    padding: 4px;
    background-color: var(--disabled-dark);
    z-index: 10;
  }
}

.up__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.25);
}

.cta-btn {
  width: auto;
  flex-shrink: 0;
  @include for-size(iphone-5-only) {
    opacity: 0;
  }
}

.up__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 4px 0px;
  > * {
    height: 32px;
  }
  .owner {
    display: flex;
    align-items: center;
    padding-right: 12px;
    > :nth-child(2) {
      @include for-size(iphone-5-only) {
        display: none;
      }
      margin-left: 6px;
      line-height: 12px;
    }
  }
  .up__reaction {
    padding: 0px 8px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
  .up__qps {
    max-width: 120px;
    padding-left: 4px;
  }
  .up__bundle {
    padding-left: 8px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.reaction-preview {
  width: 25px;
  height: 22px;
  margin-left: 4px;
  margin-right: 4px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
