<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
require('./assets/css/normalize.css');
require('./assets/css/global.css.scss');
require('vue-croppa/dist/vue-croppa.css');

export default {
  name: 'App',
  data() {
    return {
      userShookPhone: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
  },
  mounted() {
    this.registerShakeEvent();
    this.calculateVH();
    window.addEventListener('resize', this.$_.debounce(this.calculateVH, 500, {
      leading: true,
      trailing: false,
    }));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateVH);
  },
  methods: {
    calculateVH() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    registerShakeEvent() {
      // See this awesome blog post: http://www.jeffreyharrell.com/blog/2010/11/creating-a-shake-event-in-mobile-safari/


      if (typeof window.DeviceMotionEvent !== 'undefined') {
        // Shake sensitivity (a lower number is more)
        const sensitivity = 20;

        // Position variables
        let x1 = 0; let y1 = 0; let z1 = 0; let x2 = 0; let y2 = 0; let
          z2 = 0;

        // Listen to motion events and update the position
        window.addEventListener('devicemotion', (e) => {
          x1 = e.accelerationIncludingGravity.x;
          y1 = e.accelerationIncludingGravity.y;
          z1 = e.accelerationIncludingGravity.z;
        }, false);

        // Periodically check the position and fire
        // if the change is greater than the sensitivity
        setInterval(() => {
          const change = Math.abs(x1 - x2 + y1 - y2 + z1 - z2);

          if (change > sensitivity && change != 0) {
            // document.getElementById('app').classList.remove('bad');
            document.body.classList.add('show-hotjar');
          }

          // Update new position
          x2 = x1;
          y2 = y1;
          z2 = z1;
        }, 150);
      }
    },
  },
};
</script>

<style lang="scss">
main {
  padding: 0 var(--global-padding-sides);
}
@media only screen and (max-width: 640px) {
  #_hj_feedback_container {
    visibility: hidden !important;
  }
  .show-hotjar #_hj_feedback_container {
    visibility: visible !important;
  }
}
</style>
