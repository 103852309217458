<template>
  <div class="pt__container">
    <div class="pt__bundle-summary text-white">
      <div class="title-2">
        {{ bundle.title }}
      </div>
      <div class="tag">
        <MdiMusic /> {{ bundle.tracks.length }}
        {{ bundle.tracks.length > 1 ? "TRACKS" : "TRACK" }}
      </div>
    </div>

    <div class="pt__financial-info">
      <div class="headline text-sec-white">
        ${{ bundle.price }}
      </div>
      <text-button
        v-if="bundle"
        class="btn--positive btn--pill"
        @click="goToSales"
      >
        Support this project
      </text-button>
      <div class="text-footnote text-hint-white">
        ${{ amountToBreakEven }} to break even
      </div>
    </div>
  </div>
</template>

<script>
import MdiMusic from '@/components/graphics/MdiMusic.vue';
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'PurchaseTool',
  components: { MdiMusic, TextButton },
  computed: {
    amountToBreakEven() {
      return this.bundle.price * 1250;
    },
    bundle() {
      return this.$store.state.bundle;
    },
  },
  methods: {
    async goToSales() {
      const unitId = this.bundle.unit_id;
      console.log({unitId})
      this.$router.push({ name: 'unit-checkout', params: { unit_id: unitId } });
    },
  },
};
</script>

<style lang="scss" scoped>
.pt__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 488px;
  padding: 10px 20px;
  margin: 0 auto;
  background-color: var(--background-dark);
  // background-color: var(--primary);
}

.pt__bundle-summary {
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-child {
    margin-bottom: 8px;
  }

  svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    fill: var(--secondary-white);
  }
}

.pt__financial-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 100%;

  > :first-child, > :nth-child(2) {
    margin-bottom: 4px;
  }
}

</style>
