<template>
  <div class="unit-preview-list">
    <transition-group
      name="list"
      mode="out-in" 
      tag="div"
    >
      <component
        v-for="item in this.units"
        :is="getCardType(item)"
        :key="item.unit_id"
        class="upl__cards"
        :unit="item"
        is-grid
      />
    </transition-group>

    <transition name="tools">
      <div class="index__tools-gradient">
        <IndexTools
          class="ru__tools"
          @sort="applySort"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import ImageCard from '@/components/unit/ImageCard.vue';
import IndexTools from '@/components/tools/IndexTools.vue';
import MusicCard from '@/components/unit/MusicCard.vue';
import TextCard from '@/components/unit/TextCard.vue';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'UnitPreviewList',
  data() {
    return {
      search: '',
    };
  },
  components: {
    ImageCard,
    IndexTools,
    MusicCard,
    TextCard,
  },
  computed: {
    ...mapState(['units']),
  },
  methods: {

    applySort(sort) {
      function compare(a, b) {
        let itemA; let
          itemB;
        if (sort == 'time') {
          // getting UNDEFINED - need to fix
          itemA = Date.parse(a.created_at);
          itemB = Date.parse(b.created_at);
        } else {
          itemA = a.superindices[sort];
          itemB = b.superindices[sort];
        }

        let comparison = 0;
        if (itemA > itemB) {
          comparison = -1;
        } else if (itemA < itemB) {
          comparison = 1;
        }
        return comparison;
      }

      if (sort != 'select') {
        const sortedUnits = this.units.slice().sort(compare);
        this.$store.commit('setUnits', { list: sortedUnits });
      }
    },
    getCardType(unit) {
      if (unit.unit_type == 'Audio' || unit.unit_type == 'Playlist') {
        return 'music-card';
      } if (unit.unit_type == 'Image' || unit.unit_type == 'Video') {
        return 'image-card';
      } if (unit.unit_type == 'Article') {
        return 'TextCard';
      }
      return 'TextCard';
    },
  },
};
</script>

<style lang="scss" scoped>
.unit-preview-list {
  height: calc(100vh - 24px - 75px);
  width: 100vw;
  max-width: 375px;
  margin: 0 0 0 -15px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 10px;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    > :nth-child(odd) {
      margin-left: 3%;
      margin-right: 3%;
    }
  }

  .upl__cards {
    margin-bottom: 12px;
    align-self: flex-start;
  }

  & > * > :last-child {
    margin-bottom: 50px;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

// .index__tools-gradient {
//   position: fixed;
//   bottom: 99px;
//   width: 100%;
//   height: 104px;
//   z-index: 100;
//   background-image: linear-gradient(rgba(0, 0, 0, 0), #000000);
// }

.ru__tools {
  position: fixed;
  bottom: 54px;
  right: 10px;
  z-index: 5000;
  background-color: transparent;
}

.tools-enter-active {
  transition: opacity 250ms 50ms;
}

.tools-enter,
.tools-leave-to {
  opacity: 0;
}

.list-enter-active {
  transition: opacity 350ms 350ms;
}

.list-leave-active {
  transition: opacity 350ms;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-move {
  transition: transform 500ms;
}
</style>
