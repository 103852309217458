<template>
  <div :class="['avatar-group', `avatar-group--${size}`]">
    <AvatarIcon
      v-for="(entity,i) in uniqueEntities.slice(0, length)"
      :class="[`avatar--${size}`, iconTheme]"
      :key="'ag-' + entity.name + '-' + i"
      :image="entity.image_url"
      :title="entity.name"
    />
    <div
      v-if="this.remainderCounter > 0"
      :class="['ag__remainder-counter', 'tag', `dims-${size}`, remainderTheme]"
    >
      +{{this.remainderCounter}}
    </div>
  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';

export default {
  name: 'avatar-group',
  components: { AvatarIcon },
  props: {
    entities: Array,
    length: Number,
    size: {
      type: String,
      default: 'xs',
    },
    theme: {
      type: String,
      default: 'dark',
    },
  },
  computed: {
    uniqueEntities() {
      const set = new Set(this.entities.map(entity => entity.id));
      const uniqueIds = Array.from(set);
      const uniques = [];
      uniqueIds.forEach((id) => {
        const entity = this.entities.find(entity => entity.id == id);
        uniques.push(entity);
      });

      return uniques;
    },
    iconTheme() {
      if (this.theme == 'light') {
        return 'avatar__border--white';
      }
      return 'avatar__border--black';
    },
    remainderCounter() {
      return this.uniqueEntities.length - this.length;
    },
    remainderTheme() {
      if (this.theme == 'light') {
        return 'ag__rc--light';
      }
      return 'ag__rc--dark';
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-group {
  display: flex;
  &--s {
    height: 32px;
  }
  > div:not(:last-child) {
    margin-right: -8px;
  }
}
.ag__remainder-counter {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  padding: 4px 0;
  margin-left: 2px;
  &.size-xs {
    height: 24px;
    width: 24px;
  }
  &.size-s {
    height: 32px;
    width: 32px;
  }
}

.ag__rc--dark {
  background-color: var(--tint-white);
  color: var(--hint-white);
}

.ag__rc--light {
  background-color: var(--tint);
  color: var(--disabled);
}
</style>
