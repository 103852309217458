<template>
  <div class="entity-members">
    <EntityChipList
      v-if="activeMembers"
      :main-entities="activeMembers"
      :expandable-entities="formerMembers"
      :header="`${entity.name} is:`"
      :expandable-header="formerMembersHeader"
    />
    <div v-if="editingProfile && entity.type !== 'user'">
      <SettingsButton
        v-for="(btn, i) in membersSettingsButtons"
        :key="'members-setting-btn-' + i"
        :label="btn.label"
        :setting="btn.setting"
        :showing="entity.settings[btn.setting]"
        style="margin-bottom: 10px;"
      />
      <ThemedButton
        style="display: block; margin-bottom: 10px;"
        text="Invite someone"
        theme="neutral"
      />
    </div>
  </div>
</template>

<script>
import EntityChipList from '@/components/entities/EntityChipList.vue';
import SettingsButton from '@/components/buttons/SettingsButton.vue';
import ThemedButton from '@/components/buttons/ThemedButton.vue';

import { mapState } from 'vuex';

export default {
  name: 'EntityMembers',
  components: {
    EntityChipList,
    SettingsButton,
    ThemedButton,
  },
  computed: {
    ...mapState(['editingProfile', 'entity']),
    activeMembers() {
      const ms = this.entity.members.filter(m => m.is_active);
      return this.$_.sortBy(ms, ['is_founder']);
    },
    formerMembersHeader() {
      const num = this.formerMembers.length;
      return `${num} FORMER MEMBER${num > 1 ? 'S':''}`;
    },
    formerMembers() {
      return this.entity.members.filter(m => !m.is_active);
    },
    membersSettingsButtons() {
      if (!this.editingProfile || this.entity.type === 'series') return;
      
      return [
        { label: 'Members', setting: 'members_active' },
        { label: 'Former members', setting: 'members_former' },
        { label: 'Roles', setting: 'members_roles' },
        { label: 'Dates', setting: 'members_dates' },
        { label: 'Founders', setting: 'members_founders' },
      ];
    },
  }
}
</script>

<style lang="scss" scoped>
.entity-members {
  @include for-size(small-screen-only) {
    padding-top: 32px;
  }
}
</style>

