<template>
  <div class="st__container">
    <div class="tools">
      <template v-for="(provider, i) in this.providers">
        <div
          :key="i"
          :class="provider.class"
        >
          <svg
            v-if="!inProfile"
            width="138"
            height="138"
          >
            <circle
              :r="globalRingRadiuses[i]"
              cx="69"
              cy="69"
              stroke="rgba(255, 255, 255, 0.12)"
              stroke-width="1"
              fill="none"
            />
            <circle
              :r="unitRingRadiuses[i]"
              cx="69"
              cy="69"
              :stroke="provider.color"
              stroke-width="1"
              fill="none"
            />
          </svg>
          <ShareProviderButton
            :icon="provider.icon"
            :success="provider.success"
            @click="provider.method"
          />
          <div class="tag st__text">
            {{ provider.text }}
          </div>
        </div>
      </template>
    </div>
    <input
      ref='copyLink'
      style="position:absolute; top:0; left:-500px;"
    >
  </div>
</template>

<script>
import ShareProviderButton from '@/components/tools/ShareProviderButton.vue';

export default {
  name: 'ShareTool',
  components: {
    ShareProviderButton,
  },
  props: {
    inProfile: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      providers: [
        {
          name: 'twitter',
          class: 'one',
          color: '#1da1f2',
          icon: 'mdi-twitter',
          method: this.tweetHook,
          success: false,
          text: 'TWITTER',
        },
        {
          name: 'facebook',
          class: 'two',
          color: '#3b5998',
          icon: 'mdi-facebook-box',
          method: this.fbHook,
          success: false,
          text: 'FACEBOOK',
        },
        {
          name: 'web',
          class: 'three',
          color: 'rgba(255, 255, 255, 0.7)',
          icon: 'mdi-link-variant',
          method: this.copyHook,
          success: false,
          text: 'OPEN WEB',
        },
      ],
      globalShare: [],
      globalRingRadiuses: [],
      unitRingRadiuses: [],
    };
  },
  computed: {
    unit() {
      return this.$store.state.unit;
    },
    shareUrl() {
      return encodeURIComponent(`${this.$http.defaults.viewURL}${this.$route.path}`);
    }
  },
  created() {
    this.getGlobalData();
    this.mapActivityToRadius();
  },
  methods: {
    copyHook() {
      const copyText = this.$refs.copyLink;
      copyText.value = `${this.$http.defaults.viewURL}${this.$route.path}`;
      copyText.select();
      document.execCommand('copy');
      const web = this.providers.find(o => o.name == 'web');

      web.success = true;
      web.text = 'LINK COPIED!';
    },
    fbHook() {
      if (!this.inProfile) {
        const url = `units/${this.unit.unit_id}/shares`;
        this.$http.post(url, {
          share: {
            platform: 'facebook',
          },
        })
        .then((response) => {
        // eslint-disable-next-line
        console.log(response.data)
        });
      }

      const fbShare = `https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}&amp;src=sdkpreparse`;
      window.open(fbShare, '_target');
    },
    getGlobalData() {
      if (!this.inProfile) {
        const url = `units/${this.unit.unit_id}/shares.json`;

        this.$http.get(url).then((response) => {
          const data = response.data.global_share_count;

          const fb = Number((data.facebook / data.total_shares).toFixed(3));
          const tw = Number((data.twitter / data.total_shares).toFixed(3));
          const web = Number((data.web / data.total_shares).toFixed(3));

          this.globalShare = [fb, tw, web];
        });
      }
    },
    mapActivityToRadius() {
      const globalShare = [0.25, 0.125, 0.625];
      // const globalShare = this.globalShare;
      const unitShare = [0.52, 0.35, 0.13];
      const radiusMap = {
        0.327: 35.5,
        0.512: 44.5,
        0.641: 51,
        0.740: 55.5,
        0.821: 59.5,
        0.889: 62.5,
        0.948: 65.5,
      };

      function round(value, decimals) {
        return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
      }

      function mapValueToRadius(value) {
        const rounded = (12.5 * Math.round(value * 100 / 12.5)) / 100;
        const logged = round(Math.log10(9 * rounded + 1), 3);

        return radiusMap[logged];
      }

      globalShare.forEach((v) => {
        this.globalRingRadiuses.push(mapValueToRadius(v));
      });

      unitShare.forEach((v) => {
        this.unitRingRadiuses.push(mapValueToRadius(v));
      });
    },
    tweetHook() {
      if (!this.inProfile) {
        const url = `units/${this.unit.unit_id}/shares`;
        this.$http.post(url, {
          share: {
            platform: 'twitter',
          },
        })
        .then((response) => {
        // eslint-disable-next-line
        console.log(response.data)
        });
      }
      const shareText = 'Check out Idealog!'
      const TTShare = `https://twitter.com/intent/tweet?url=${this.shareUrl}&text=${shareText}`;
      window.open(TTShare, '_target');
    },
  },
};
</script>

<style lang="scss" scoped>
.st__container {
  position: relative;
  width: 100vw;
  padding-right: 20px;
  padding-left: 20px;
  background-color: var(--primary);
  z-index: 1000;
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 488px;
  margin: 0 auto;
}

.st__text {
  padding-top: 5px;
  color: white;
  z-index: 7;
}

.one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 138px;
  height: 138px;
  margin-right: -80px;
}

.two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 138px;
  min-height: 138px;
  margin-right: -80px;
}

.three {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 138px;
  min-height: 138px;
}

svg {
  margin-top: 0;
  margin-top: -19px;
  margin-bottom: -89px;
}
</style>
