<template>
  <div class="ac-container" v-cloak>

    <section class="profile">
      <UserProfile
        @edit="editingProfile = !editingProfile"
        :user="user"
        v-if="!editingProfile && !loading"
      />
      <UserProfileEditor
        @edit="editingProfile = !editingProfile"
        :user="user"
        v-if="editingProfile && !loading"
      />
    </section>


    <section v-if="!loading && !editingProfile" class="btn-group l-follow-bar">
      <router-link :to="{ name: 'following'}">
        <text-button class="btn--neutral btn--pill">
          Following {{ user.followsCount }}
        </text-button>
      </router-link>

      <router-link :to="{ name: 'followers'}">
        <text-button class="btn--primary btn--pill text--neutral">
          {{ user.followersCount }} followers
        </text-button>
      </router-link>

    </section>


    <section
      v-if="!loading"
      class="l-gallery"
      :class="{ 'btn-group--vertical': user.liked && (purchases.length > 0) }"
    >

      <text-button v-if="user.liked" class="btn--col btn--full btn--negative">
        {{ user.likesCount }} likes
        <div class="thumbnails" slot="secondary">
            <AvatarIcon
              v-for="(like, i) in user.liked.slice(0,4)"
              :key="'like-' + i"
              class="avatar--l avatar--square"
              :image="like.cover_image"
            />
        </div>
      </text-button>

      <router-link :to="{ name: 'purchases', params: { id: user.id }}">
        <text-button
          v-if="purchases.length > 0"
          class="btn--col btn--full btn--positive"
        >
          <div>
            {{ purchases.length }} purchases
          </div>
          <div class="thumbnails" slot="secondary">
            <AvatarIcon
              v-for="(purchase, i) in purchases.slice(0,4)"
              :key="'purchase-' + i"
              class="avatar--l avatar--square"
              :image="purchase.cover_image"
            />
          </div>
        </text-button>
      </router-link>

    </section>


    <section class="l-credentials btn-group--vertical">
      <div @click="editingEmail = !editingEmail">
        <text-button
          v-if="!editingEmail && !loading"
          class="btn--full btn--primary btn--pill text--neutral"
        >
          Update email
          <div class="body-2 btn__sub-text" slot="secondary">
            {{ user.email }}
          </div>
        </text-button>
      </div>

      <EmailEditor
        @editCredentials="editingEmail = !editingEmail"
        :email.sync="user.email"
        v-if="editingEmail && !loading"
      />

      <div @click="editingPw = !editingPw">
        <text-button
          v-if="!editingPw && !loading"
          class="btn--full btn--primary btn--pill text--neutral"
        >
          Change password
        </text-button>
      </div>

      <PasswordEditor
        @editCredentials="editingPw = !editingPw"
        :password.sync="dummy.password"
        v-if="editingPw && !loading"
      />

    </section>


  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import TextButton from '@/components/buttons/TextButton.vue';
import CloseButton from '../buttons/CloseButton.vue';
import EmailEditor from './EmailEditor.vue';
import PasswordEditor from './PasswordEditor.vue';
import UserProfile from './UserProfile.vue';
import UserProfileEditor from './UserProfileEditor.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AccountComponent',
  data() {
    return {
      dummy: {
        password: 'hello',
      },
      editingProfile: false,
      editingEmail: false,
      editingPw: false,
    };
  },
  components: {
    AvatarIcon,
    TextButton,
    CloseButton,
    EmailEditor,
    PasswordEditor,
    UserProfile,
    UserProfileEditor,
  },
  created() {
    if (!this.user) {
      this.LOAD_USER().then(() => {
        this.LOAD_PURCHASES(this.$store.state.user.id);
      });
    } else {
      this.LOAD_PURCHASES(this.$store.state.user.id);
    }
  },
  computed: {
    ...mapState(['loading', 'purchases', 'user']),
  },
  methods: {
    ...mapActions(['LOAD_PURCHASES', 'LOAD_USER']),
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] > * {
  display: none;
}

.ac-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-height: 812px;
  margin: 0 auto;
  padding: 82px 20px 20px 20px;
  background: var(--background);
}

.l-follow-bar {
  padding-top: 20px;
  margin-bottom: 32px;
}

.l-credentials {
  padding-top: 32px;
}

.thumbnails {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 8px;
  margin-bottom: -10px;
  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
</style>
