<template>
  <div class="tool-btn" @click="$emit('click')">
    <span :is="icon" />
    <!-- <div class="tool-btn__border"></div> -->
  </div>
</template>

<script>
import {
  MdiAccount,
  MdiAccountCheck,
  MdiAccountGroup,
  MdiAccountMultiple,
  MdiAccountMultiplePlus,
  MdiAccountPlus,
  MdiAlien,
  MdiAsterisk,
  MdiBrush,
  MdiCamera,
  MdiCartPlus,
  MdiCheck,
  MdiCheckCircle,
  MdiChevronDown,
  MdiChevronLeft,
  MdiCommentCheck,
  MdiCommentQuestion,
  MdiContentSave,
  MdiClose,
  MdiCube,
  MdiFacebookBox,
  MdiFire,
  MdiFolderDownload,
  MdiHeart,
  MdiHeartOutline,
  MdiInformationVariant,
  MdiLinkVariant,
  MdiDice,
  MdiLeaf,
  MdiList,
  MdiMusic,
  MdiMusicNote,
  MdiPencil,
  MdiPlus,
  MdiPhoto,
  MdiShare,
  MdiTimerSand,
  MdiTwitter,
  MdiUpload,
  MdiVideoCam,
  MdiWater,
} from '@/components/graphics';

export default {
  name: 'tool-button',
  components: {
    MdiAccount,
    MdiAccountCheck,
    MdiAccountGroup,
    MdiAccountMultiple,
    MdiAccountMultiplePlus,
    MdiAccountPlus,
    MdiAlien,
    MdiAsterisk,
    MdiBrush,
    MdiCamera,
    MdiCartPlus,
    MdiCheck,
    MdiCheckCircle,
    MdiChevronDown,
    MdiChevronLeft,
    MdiClose,
    MdiCommentCheck,
    MdiCommentQuestion,
    MdiContentSave,
    MdiCube,
    MdiFacebookBox,
    MdiFire,
    MdiFolderDownload,
    MdiHeart,
    MdiHeartOutline,
    MdiInformationVariant,
    MdiLinkVariant,
    MdiDice,
    MdiLeaf,
    MdiList,
    MdiMusic,
    MdiMusicNote,
    MdiPencil,
    MdiPhoto,
    MdiPlus,
    MdiShare,
    MdiTimerSand,
    MdiTwitter,
    MdiUpload,
    MdiVideoCam,
    MdiWater,
  },
  props: {
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
.tool-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // width: 120px;
  background-color: var(--primary);
  height: 40px;
  cursor: pointer;
  & > :nth-child(1) {
    fill: var(--object-fill-dark);
  }
}

// Turn this into a function
.tool-btn--negative {
  background-color: var(--negative);
  border: 1px solid var(--negative);
}
.tool-btn--neutral {
  background-color: var(--neutral);
  border: 1px solid var(--neutral);
}
.tool-btn--positive {
  background-color: var(--positive);
  border: 1px solid var(--positive);
  }
.tool-btn--tip {
  background-color: var(--tip);
  border: 1px solid var(--tip);
}
.tool-btn--warning {
  background-color: var(--warning);
  border: 1px solid var(--warning);
  }
.tool-btn--black {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

// Provisional new colors
.tool-btn--ask {
  & > :first-child {
    fill: #aed9eb;
  }
}

.tool-btn--increment {
  & > :first-child {
    fill: #da7091;
  }
}

.tool-btn--purchase {
  & > :first-child {
    fill: #669065;
  }
}

.tool-btn--share {
  & > :first-child {
    fill: #dc4f00;
  }
}

.tool-btn--fill-dark {
  & > :nth-child(1) {
    fill: var(--object-fill-dark);
  }
}

.tool-btn--fill-primary {
  & > :nth-child(1) {
    fill: var(--primary);
  }
}

.tool-btn__border {
  position: absolute;
  top: 7px;
  bottom: 7px;
  right: 0;
  border-right: 1px solid #2d2d2d;
  height: 26px;
}
</style>
