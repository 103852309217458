<template>
  <div
    class="dropdown-menu"
    :class="{ 'btn-group--vertical': isOpen && this.options.length > 1 }"
    v-click-outside="clickOutside"
  >

    <text-button
      key="option-0"
      :dropdownTheme="dropdownTheme"
      isDropdown
      @click="toggleDropdown(0)"
    >
      {{ capitalize(this.options[0]) }}
    </text-button>

    <template v-if="isOpen && this.options.length > 1">
      <text-button
        v-for="(option,i) in this.options.slice(1)"
        :key="'option-' + (i+1)"
        class="dm__expanded-options btn__layout--dropdown-item btn--pill"
        :class="'btn--' + dropdownTheme"
        @click="toggleDropdown(i+1)"
      >
        {{ capitalize(option) }}
      </text-button>
    </template>

  </div>
</template>

<script>
import TextButton from '@/components/buttons/TextButton.vue';

import clickOutside from '@/helpers/click-outside';

export default {
  name: 'dropdown-menu',

  components: {
    TextButton,
  },

  mixins: [clickOutside],

  props: {
    options: {
      type: Array,
      default() {
        return [
          'Option 0',
          'Option 1',
          'Option 2',
          'Option 3',
          'Option 4',
        ];
      },
    },
    dropdownTheme: {
      default: 'tip',
      type: String,
    },
  },

  data() {
    return {
      isOpen: false,
      selectedOption: null,
    };
  },

  mounted() {
    this.selectedOption = this.options[0];
  },

  computed: {
    dropdownDefault() {
      return this.options[0][0].toUpperCase() + this.options[0].slice(1);
    },
  },

  methods: {
    capitalize(str) {
      return str[0].toUpperCase() + str.slice(1);
    },
    clickOutside() {
      if (this.isOpen) this.isOpen = false;
    },
    toggleDropdown(i) {
      if (this.isOpen) {
        this.selectedOption = this.options[i];
        this.$emit('selected-option', this.selectedOption);
      } else {
        this.$emit('open');
      }

      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
}

.transition-div {
  display: flex;
  flex-direction: column;
}

</style>
