var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qa-entity-menu"},[_c('div',{staticClass:"qem__header"},[_c('text-button',{class:[
        'btn__layout--small',
        'btn--pill',
        _vm.askedFilter ? 'btn--tip' : 'filter-btn--inactive'
      ],attrs:{"small-text":""},on:{"click":_vm.handleAskedButtonClick}},[_vm._v("\n      Asked\n    ")]),_c('EntityStamp',{attrs:{"entity":_vm.entityPlaceholder}}),_c('text-button',{class:[
        'btn__layout--small',
        'btn--pill',
        _vm.answeredFilter ? 'btn--warning' : 'filter-btn--inactive'
      ],attrs:{"small-text":""},on:{"click":_vm.handleAnsweredButtonClick}},[_vm._v("\n      Answered\n    ")])],1),_c('div',{staticClass:"qem__tabs btn-group"},_vm._l((_vm.tabs),function(tab,i){return _c('text-button',{key:("qem-tab-" + i),class:[
        _vm.mode == tab ? 'btn--black-white' : 'btn--tint-white',
        'btn__layout--small', 
        'btn--pill'
      ],attrs:{"small-text":""},on:{"click":function($event){_vm.handleTabClick(tab)}}},[_vm._v("\n      "+_vm._s(("" + (tab.slice(0,1).toUpperCase()) + (tab.slice(1))))+"\n    ")])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }