<template>
  <div
    v-if="bundle"
    class="bundle-preview"
  >
    <div class="bp__title">
      {{ bundle.name }}
    </div>
    <div class="bp__content">
      <div class="content-image">
        <img :src="bundle.image_url">
      </div>
      <UnitInfoCreators
        :bundle-credits="bundle.project.credits"
        class="content-uic"
        hide-more-info
        :size="size"
      />
    </div>
    <div class="bp__financials">

    </div>
    <div class="bp__cta">
      <ThemedButton
        text="View"
        theme="neutral"
      />
    </div>
  </div>
</template>

<script>
import ThemedButton from '@/components/buttons/ThemedButton.vue';
import UnitInfoCreators from '@/components/_unit/UnitInfoCreators.vue';

import faker from 'faker';

export default {
  name: 'EntityPreview',
  components: {
    ThemedButton,
    UnitInfoCreators
  },
  props: {
    // bundle: { 
    //   type: Object,
    //   required: true,
    // },
    size: {
      type: String,
      default: 's'
    }
  },
  data() {
    return {
      currencyFormat: {
        style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
      }
    }
  },
  computed: {
    bundle() {
      const name = `${faker.commerce.productAdjective()} ${faker.random.word()}`;
      const image = faker.random.image();
      return {
        type: 'bundle',
        id: 0,
        name,
        unit_id: 78,
        image_url: image,
        project: {
          credits: {
            creators: [
              {
                id: 1,
                name: faker.name.firstName(),
                image_url: faker.image.avatar(),
              },
              {
                id: 2,
                name: faker.name.firstName(),
                image_url: faker.image.avatar(),
              },
              {
                id: 3,
                name: faker.name.firstName(),
                image_url: faker.image.avatar(),
              }
            ],
            ensemble: {
                id: 4,
                name: faker.name.lastName(),
                image_url: faker.image.people(),
                is_active: true,
            },
            series: {
                id: 5,
                name: faker.address.streetName(),
                image_url: faker.image.nightlife(),
                is_active: true,
            },
          },
          financials: {
            investment: 25000,
            totalSales: 37500,
            total_supporters: 852,
          }
        }
      };
    },
  },
  methods: {
    goToSalesFlow() {
      console.log('go to sales flow');
    },
  },
}
</script>

<style lang="scss" scoped>
.bundle-preview {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 8px;
  // width: calc(100% - 20px);
  height: 330px;
  > * { width: inherit; }
}

.bp__title {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
}

.bp__content {
  display: flex;
  margin-bottom: 16px;
}

.content-image {
  position: relative;
  flex-shrink: 0;
  width: calc(50% - 6px);
  height: 0;
  padding-top: 50%;
  background-color: blue;
  margin-right: 12px;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-uic {
  max-width: calc(50% - 6px);
}

.bp__financials {
  height: 34px;
  margin-bottom: 6px;
}

.bp__cta {
  display: flex;
  justify-content: flex-end;
}
</style>
