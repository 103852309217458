<template>
  <div v-if="timeline" class="timeline-module">
    <div
      v-if="context === 'unit'"
      class="tm__unit-title text-footnote text-sec-white"
    >
      Project timeline
    </div>

    <div
      v-if="context === 'profile'"
      class="body-1 text-sec-white"
      style="padding: 20px 0px;"
    >
      {{ entityName }}'s activity
    </div>

    <div
      v-if="context === 'artists'"
      class="headline text-sec-white"
    >
      Artists
    </div>
    <div class="timeline-module medium-screen-up artist-width">
      <swiper
        v-if="context === 'artists'"
        ref="swiper"
        :options="swiperOption"
      >
        <template v-for="item in timeline">
          <swiper-slide :key="`tm-slide-${item.type}`">
            <EntityPreview
              v-if="context === 'artists'"
              :type="item.type"
              :entity="item"
            />
            <component
              v-else
              :is="`${context}-timeline-card`"
              in-carousel
              :item="item"
            />
          </swiper-slide>
          <div
            :key="`page-${item.type}`"
            class="swiper-pagination"
            slot="pagination"
          />
        </template>
      </swiper>
      <!-- <template v-if="context === 'artists'">
        <EntityPreview
          v-for="item in timeline"
          :key="`timeline-item-${item.type}`"
          :type="item.type"
          :entity="item"
        />      
      </template> -->
      <component
        v-else
        v-for="item in timeline"
        :class="`${context}-card`"
        :is="`${context}-timeline-card`"
        :key="`timeline-item-${item.type}`"
        :item="item"
      />
    </div>
    <keep-alive>
      <swiper
        ref="swiper"
        class="timeline-module small-screen"
        :options="swiperOption"
      >
        <template v-for="item in timeline">
          <swiper-slide :key="`tm-slide-${item.type}`">
            <EntityPreview
              v-if="context === 'artists'"
              :type="item.type"
              :entity="item"
            />
            <component
              v-else
              :is="`${context}-timeline-card`"
              in-carousel
              :item="item"
            />
          </swiper-slide>
          <div
            :key="`page-${item.type}`"
            class="swiper-pagination"
            slot="pagination"
          />
        </template>
      </swiper>
    </keep-alive>
  </div>
</template>

<script>
import EntityPreview from '@/components/entities/EntityPreview.vue';
import ProfileTimelineCard from '@/components/_timeline/ProfileTimelineCard.vue';
import UnitTimelineCard from '@/components/_timeline/UnitTimelineCard.vue';

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'TimelineModule',
  components: {
    swiper,
    swiperSlide,
    EntityPreview,
    ProfileTimelineCard,
    UnitTimelineCard,
  },
  props: {
    context: {
      type: String,
      default: 'unit',
      validator: function(value) {
        return ['profile', 'unit', 'artists'].indexOf(value) !== -1;
      }
    },
    entityName: {
      type: String,
      default: '',
    },
    timelineItems: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      swiperOption: {
        init: true,
        slidesPerView: 1,
        centeredSlides: true,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        //   bulletClass: 'swiper-pagination-bullet',
        //   bulletActiveClass: 'swiper-pagination-bullet-active'
        // }
      },
    };
  },
  computed: {
    unitTimeline() {
      if (this.context !== 'unit') return;
      let timeline = this.$store.state.unit.timeline.slice();
      if (!timeline) return null;
      const contract = this.$_.remove(timeline, el => el.type === 'contract');
      timeline.unshift(contract[0]);
      return timeline;
    },
    timeline() {
      return this.context === 'unit' ? this.unitTimeline : this.timelineItems;
    }, 
    onSmallScreen() {
      return window.innerWidth < 768;
    }
  },
}
</script>

<style lang="scss" scoped>
.timeline-module {
  width: inherit;
  > div:not(:last-child) {
    margin-bottom: 4px;
  }
}

.timeline-module.small-screen {
  display: block;
  @include for-size(medium-screen-up) {
    display: none;
  }
}

.timeline-module.medium-screen-up {
  display: block;
  height: 300px;
  @include for-size(small-screen-only) {
    display: none;
  }
  > div {
    box-sizing: content-box;
    margin: 0px auto 4px;
  }
  > .profile-card {
  margin-bottom: 20px;
  }
}


.tm__unit-title {
  margin-left: 4px;
  @include for-size(small-screen-only) {
    margin-bottom: -15px;
  }
}

.swiper-container {
  position: relative;
  width: 100%;
  padding-top: 20px;
}

.swiper-slide {
  max-width: calc(100vw - 40px) !important;
}

.swiper-pagination {
  position: absolute;
  top: -8px;
  right: 10px;
  left: auto;
  width: 80px;
}

.artist-width {
  @include for-size(medium-screen-up) {
    max-width: calc(50vw - 20px);
  }
  @include for-size(small-desktop-up) {
    max-width: calc(33vw - 20px);
  }
}
</style>


