<template>
  <div class="desktop-index-menu">
    <div class="dim__section">
      <div class="body-2 text-white">Relationship</div>
      <div class="im__btn-group">
        <text-button
          v-for="(filter, i) in filters.slice(0,2)"
          :key="'rel' + i"
          class="btn--im-options"
          :class="filter.active ? 'active' : 'inactive'"
          @click="toggleFilter(filter.name)"
        >{{ filter.icon }}</text-button>
      </div>
    </div>

    <div class="dim__section">
      <div class="body-2 text-white">Role</div>
      <div class="im__btn-group">
        <text-button
          v-for="(filter, i) in filters.slice(4,6)"
          :key="'rel' + i"
          class="btn--im-options"
          :class="filter.active ? 'active' : 'inactive'"
          @click="toggleFilter(filter.name)"
        >{{ filter.icon }}</text-button>
      </div>
    </div>

    <div class="dim__section">
      <div class="im__icon-bar">
        <div class="button-text">
          <div class="body-2 text-white">Type</div>
          <div v-if="onDesktop" class="body-3">Select media type</div>
        </div>

        <div class="im__icon-bar__btns">
          <div>
            <icon-button
              v-for="(filter, i) in filters.slice(6,8)"
              :key="'icon' + i"
              class="icon-btn--s dim__icon-btn"
              :class="filter.active ? 'filter-btn--active' : 'filter-btn--inactive'"
              :icon="filter.icon"
              @click="toggleFilter(filter.name)"
            />
          </div>
          <div>
            <icon-button
              v-for="(filter, i) in filters.slice(8)"
              :key="'icon' + i"
              class="icon-btn--s dim__icon-btn"
              :class="filter.active ? 'filter-btn--active' : 'filter-btn--inactive'"
              :icon="filter.icon"
              @click="toggleFilter(filter.name)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="dim__section">
      <div class="im__icon-bar im__sort-section">
        <div class="button-text im__sort">
          <div class="body-2 text-white">Sort</div>
          <div v-if="onDesktop" class="body-3">Choose the sort order</div>
          <text-button
            v-if="!onDesktop"
            class="btn--curated btn--pill"
            @click="emitToggleControlOff"
          >View our curated feed.</text-button>
        </div>

        <div class="im__icon-bar__sort">
          <icon-button
            v-for="(tool, key) in tools"
            :key="'index-tool-' + key"
            class="icon-btn--s dim__icon-btn"
            :class="tool.active ? 'filter-btn--active' : 'filter-btn--inactive'"
            :icon="tool.svg"
            @click="handleClick(tool)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'DesktopIndexMenu',
  components: {
    IconButton,
    TextButton,
  },
  props: {
    onDesktop: Boolean,
    isActiveFromIndex: Object,
  },
  data() {
    return {
      filterList: [
        'follow',
        'follower',
        'doppelganger',
        'nemesis',
        'creator',
        'curator',
        'audio',
        'video',
        'image',
        'article',
      ],
      filters: [
        {
          active: false,
          name: 'follow',
          icon: 'Follows',
        },
        {
          active: false,
          name: 'follower',
          icon: 'Followers',
        },
        {
          active: true,
          name: 'doppelganger',
          icon: 'Doppelgangers',
        },
        {
          active: true,
          name: 'nemesis',
          icon: 'Nemeses',
        },
        {
          active: true,
          name: 'creator',
          icon: 'Creators',
        },
        {
          active: true,
          name: 'curator',
          icon: 'Curators',
        },
        {
          active: false,
          name: 'audio',
          icon: 'mdi-music',
        },
        {
          active: false,
          name: 'video',
          icon: 'mdi-movie',
        },
        {
          active: false,
          name: 'image',
          icon: 'mdi-camera',
        },
        {
          active: false,
          name: 'article',
          icon: 'mdi-document',
        },
      ],
      tools: {
        newsworthiness: {
          svg: 'mdi-water',
          active: false,
        },
        effort: {
          svg: 'mdi-leaf',
          active: false,
        },
        heat: {
          svg: 'mdi-fire',
          active: false,
        },
        time: {
          svg: 'mdi-timer-sand',
          active: true,
        },
        select: {
          svg: 'mdi-dice',
          active: false,
        },
      },
    };
  },
  mounted() {
    this.filterOnMount();
  },
  methods: {
    emitToggleControlOff() {
      this.$eventBus.$emit('emit-toggle-control-off', false);
    },

    filterOnMount() {
      console.log('filter on mount');
      const filterNames = Object.keys(this.isActiveFromIndex);
      const sourceFilterNames = filterNames.slice(0, 4);
      const typeFilterNames = filterNames.slice(4);

      sourceFilterNames.forEach((filterName) => {
        this.filters.filter(
          filter => filter.name == filterName,
        )[0].active = this.isActiveFromIndex[filterName];
      });

      // if all typeFilters are active, set them all to inactive
      const {
        follow, follower, creator, curator, ...typeFilters
      } = this.isActiveFromIndex;
      const typeFilterActivity = Object.values(typeFilters);
      const numActiveTypeFilters = typeFilterActivity.filter(el => el == true).length;

      if (numActiveTypeFilters == 4) {
        typeFilterNames.forEach((filterName) => {
          this.filters.filter(
            filter => filter.name == filterName,
          )[0].active = false;
        });
      } else {
        typeFilterNames.forEach((filterName) => {
          this.filters.filter(
            filter => filter.name == filterName,
          )[0].active = this.isActiveFromIndex[filterName];
        });
      }

      this.$eventBus.$emit('filter-index', { filters: this.filters });
    },

    toggleFilter(filterName) {
      let selectedFilter;

      if (this.filterList.includes(filterName)) {
        selectedFilter = this.filters.filter(
          item => item.name == filterName,
        )[0];

        selectedFilter.active = !selectedFilter.active;
      }

      this.$eventBus.$emit('filter-index', { filters: this.filters });
    },
    emitToggle(tool) {
      const tools = Object.keys(this.tools);
      const selected = tools.filter(f => this.tools[f] == tool);
      const notSelected = tools.filter(f => this.tools[f] !== tool);

      // If selected tool was not active, make it active, make others active
      // Otherwise, do nothing
      if (!this.tools[selected].active) {
        this.tools[selected].active = true;
        notSelected.forEach(v => (this.tools[v].active = false));
      }

      this.$eventBus.$emit('desktop-tool-sort', selected[0]);
    },
    handleClick(tool) {
      if (tool.svg == 'mdi-dice') {

      } else {
        this.emitToggle(tool);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-index-menu {
  position: absolute;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1001;
  background-color: var(--primary);

  @include for-size(phone-only) {
    display: none;
  }
  @include for-size(tablet-portrait-up) {
    height: 138px;
    padding: 24px 36px 0px 36px;
  }
  @include for-size(desktop-up) {
    height: 98px;
    padding: 20px 48px 0px 48px;
  }
}

.dim__section {
  @include for-size(tablet-portrait-up) {
    margin-right: 24px;
  }
  @include for-size(desktop-up) {
    margin-right: 24px;
  }
}

.im__btn-group {
  display: flex;
  justify-content: flex-start;
  padding-top: 6px;
  padding-bottom: 6px;

  @include for-size(tablet-portrait-up) {
    flex-direction: column;
    > :first-child {
      margin-bottom: 12px;
    }
  }
  @include for-size(desktop-up) {
    flex-direction: row;
    > * {
      margin-right: 6px;
    }
  }
}

.im__icon-bar {
  display: flex;
  .im__icon-bar__btns {
    display: flex;
    margin-left: 16px;
    > div {
      display: flex;
    }
    @include for-size(tablet-portrait-up) {
      flex-direction: column;
      > :first-child {
        margin-bottom: 8px;
      }
    }
    @include for-size(desktop-up) {
      flex-direction: row;
      align-self: center;
      > :first-child {
        margin-bottom: 0px;
      }
    }
  }
  .im__icon-bar__sort {
    display: flex;
    // margin-left: 16px;
    @include for-size(tablet-portrait-up) {
      padding-top: 23px;
      > :first-child {
        margin-left: 0px;
      }
    }
    @include for-size(desktop-up) {
      padding-top: 0px;
      margin-left: 16px;
      align-self: center;
      > :first-child {
        margin-left: 8px;
      }
    }
  }
}

.im__icon-bar.im__sort-section {
  @include for-size(tablet-portrait-up) {
    flex-direction: column;
  }
  @include for-size(desktop-up) {
    flex-direction: row;
  }
}

.button-text {
  display: flex;
  flex-direction: column;
  width: 68px;
  > :nth-child(2) {
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 68px;
    color: #868585;
  }
}

.button-text.im__sort {
  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  @include for-size(desktop-up) {
    flex-direction: column;
    width: 68px;
  }
}

.dim__icon-btn {
  margin-left: 8px;
}

.is-expanded {
  height: 254px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  // transition: height .25s ease-in-out;
}

.is-collapsed {
  height: 60px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: height 300ms ease-in-out;
}

.rm__expansion-container {
  display: flex;
  height: 194px;
  padding: 10px;
  transition: height 300ms ease-in-out;
}

.rm__expansion__closed {
  display: flex;
  height: 0px;
  transition: height 300ms ease-in-out;
}

.expanded-container {
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

.rm__source {
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 10px;
  > :first-child {
    margin-right: 12px;
  }
}

.rm__btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  height: 35px;
  width: 110px;
  border-radius: 50px;
  margin-left: auto;
  padding: 7px 12px;
  border: 1px solid transparent;
  border-radius: 22px;
  background-color: var(--positive);
  border-color: var(--positive);
  color: var(--background);
  cursor: pointer;
}

.rm__icon-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: 10px;
  > div {
    margin-right: 7px;
  }

  .rm__icon-bar-types {
    display: flex;
    justify-content: flex-start;
    > div {
      margin-right: 7px;
    }
  }
}

.rm__check {
  margin-left: 10px;
  fill: var(--positive);
}

.rm__chevron {
  margin-left: auto;
}

.opacity-enter-active {
  transition: opacity 300ms 200ms;
}

.opacity-2-enter-active {
  transition: opacity 400ms 400ms;
}

.opacity-leave-active {
  transition: opacity 300ms;
}

.opacity-2-leave-active {
  transition: opacity 0ms;
}

.opacity-enter,
.opacity-leave-to,
.opacity-2-enter,
.opacity-2-leave-to {
  opacity: 0;
}
</style>
