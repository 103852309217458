<template>
  <div
    v-if="mainEntities.length"
    class="entity-chip-list"
  >
    <div
      v-if="header"
      class="text-footnote-hint"
    >
      {{ header }}
    </div>
    <EntityChip 
      v-for="(entity, i) in mainEntities"
      :key="'main-entity-' + i"
      :image="entity.image_url"
      :name="entity.name"
      size="m"
      theme="white"
    />
    <div
      v-if="expandableEntities.length"
      class="tag text-sec-white"
      style="padding-top: 10px;"
    >
      {{ expandableHeader }}
      <component
        :is="isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        @click="toggleExpansion"
      />
      <transition-group
        name="toggle-item"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <template v-if="isExpanded">
          <div
            v-for="(entity, i) in expandableEntities"
            :key="'expandable-entity-' + i"
            class="toggle-item"
          >
            <EntityChip 
              :image="entity.image_url"
              :name="entity.name"
              size="m"
              style="display: flex;"
              theme="white"
            />
          </div>
        </template>
      </transition-group>
    </div>
  </div>
</template>

<script>
import EntityChip from '@/components/entities/EntityChip.vue';
import MdiChevronDown from '@/components/graphics/MdiChevronDown.vue';
import MdiChevronUp from '@/components/graphics/MdiChevronUp.vue';
import toggleExpansion from '@/helpers/toggle-expansion.js';

export default {
  name: 'EntityChipList',
  mixins: [toggleExpansion],
  components: {
    EntityChip,
    MdiChevronDown,
    MdiChevronUp
  },
  props: {
    mainEntities: {
      type: Array,
      default() {
        return [];
      }
    },
    expandableEntities: {
      type: Array,
      default() {
        return [];
      }
    },
    header: {
      type: String,
      default: '',
    },
    expandableHeader: {
      type: String,
      default: '',
    }
  },
}
</script>

<style lang="scss" scoped>
.entity-chip-list {
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 10px;
  }
}

.toggle-item {
  padding-top: 10px;
  transition: 350ms ease-out;
}

svg {
  width: 16px;
  height: 16px;
  fill: var(--secondary-white);
}
</style>

