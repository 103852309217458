<template>
  <button
    :class="['btn', `btn__layout--${size}`]"
    @click="$emit('click')"
  >
    <div :class="textSize" style="white-space: nowrap;">
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  name: 'TextButton',
  props: {
    dropdownTheme: {
      type: String,
      default: 'tip',
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    textSize() {
      const sizes = {
        xs: 'tag',
        small: 'action-2',
        default: 'action-1'
      };
      return sizes[this.size];
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid;
}

.btn__layout--default {
  height: 44px;
  padding: 10px 12px;
}

.btn__layout--small {
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
}

.btn__layout--xs {
  height: 32px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
}

.btn:disabled {
  opacity: 0.3;
  cursor: default;
}

.btn--full {
  display: flex;
  width: 100%;
}

.btn--index {
  justify-content: center;
  align-items: center;
  width: 45%;
  > div {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }
}

/*
 * Button colors -- primary
 */

.btn--primary {
  background-color: #fff;
  border-color: #fff;
  color: var(--primary);
}

.btn--neutral {
  background-color: var(--neutral);
  border-color: var(--neutral);
  color: var(--background);
}

.btn--positive {
  background-color: var(--positive);
  border-color: var(--positive);
  color: var(--background);
}

.btn--warning {
  background-color: var(--warning);
  border: var(--warning);
  color: var(--primary);
}

.btn--tip {
  background-color: var(--tip);
  border: var(--tip);
  color: var(--background);
}

.btn--negative {
  background-color: var(--negative);
  border-color: var(--negative);
  color: var(--background);
}

/*
 * Button colors -- primary tints
 */

.btn--negative-tint {
  border: none;
  background-color: var(--negative-tint);
  color: var(--negative);
}

.btn--neutral-tint {
  border: none;
  background-color: var(--neutral-tint);
  color: var(--neutral);
}

.btn--positive-tint {
  border: none;
  background-color: var(--positive-tint);
  color: var(--positive);
}

.btn--tip-tint {
  border: none;
  background-color: var(--tip-tint);
  color: var(--tip);
}

.btn--warning-tint {
  border: none;
  background-color: var(--warning-tint);
  color: var(--warning-tint);
}



/*
 * Button colors -- primary
 */

.btn--white {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
  color: var(--neutral);
}

.btn--black-white {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
  color: var(--primary);
}

.btn--tint-white {
  background-color: var(--tint-white);
  border-color: var(--primary);
  color: var(--primary-dark);
}

.btn--bg {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
  color: var(--primary-dark) !important;
}

.btn--im {
  background-color: #0f0f0f;
  border-color: #0f0f0f;
  color: #777777 !important;
}

.btn--checkout {
  justify-content: center;
  background-color: #fff;
  border-color: #000;
  border-radius: 22px;
  color: var(--primary);
}

.btn--white {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
  color: var(--hint);
}

.btn--hint {
  background-color: var(--background);
  border-color: var(--background);
  color: var(--hint);
}

.btn--hint-bg {
  background-color: var(--hint);
  // border-color: var(--hint);
  color: var(--background);
}

.btn--tip-tint {
  background-color: rgba(164, 79, 255, 0.1);
  border: none;
  color: var(--tip);
}

.filter-btn--inactive {
  background-color: #0f0f0f;
  border-color: #0f0f0f;
  color: var(--object-fill-dark);
}

.filter-btn--active {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
  color: var(--primary);
}


/*
 * Utilities that should go into base styles
 */

.rect {
  border-radius: 0px;
}

.rounded {
  border-radius: 10px;
}

.btn--pill {
  border-radius: 20px;
}

.rounded--circle {
  border-radius: 50%;
}

.border--dark {
  border-color: #000;
}

.text--neutral {
  color: var(--neutral);
}

.btn__sub-text {
  color: var(--secondary);
  margin-left: 8px;
}

/*
 * Button-group styling
 */

.btn-group > .btn:not(:first-child):not(:last-child),
.btn-group > :not(:first-child):not(:last-child) > .btn,
.btn-group--vertical > .btn:not(:first-child):not(:last-child),
.btn-group--vertical > :not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn:first-child,
.btn-group > a:first-child > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child,
.btn-group > :last-child > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group--vertical > .btn:first-child,
.btn-group--vertical > :first-child > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group--vertical > .btn:last-child,
.btn-group--vertical > :last-child > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
