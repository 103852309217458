<template>
  <div
    v-if="featuredQa"
    class="unit-info-answer"
  >
    <div class="uia__question text-footnote text-sec-white">
      {{ featuredQa.question.body }}
    </div>
    <QaBubble
      :avatar="featuredQa.answer.owner.image_url"
      :id="featuredQa.answer.id"
      :text="answerBody"
      type="answer"
      @toggle-qa-thread="handleToggleQaThread"
    />
  </div>
</template>

<script>
import QaBubble from '@/components/qa/QaBubble.vue';

export default {
  name: 'UnitInfoAnswer',
  components: {
    QaBubble
  },
  props: ['qaFeatured'],
  computed: {
    featuredQa() {
      const { unit, entity } = this.$store.state;
      if (!unit && !entity) {
        return this.qaFeatured;
      }
      return this.$store.state.qa.featured ? this.$store.state.qa.featured[0] : null;
    },
    answerBody() {
      const ipsum = 'Cupcake ipsum dolor sit amet candy canes cake. Brownie toffee sesame snaps cheesecake.'
      return this.featuredQa.answer.body || ipsum;
    }
  },
  methods: {
    handleToggleQaThread() {
      return;
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-info-answer {
  display: flex;
  flex-direction: column;
  width: inherit;
  min-height: 72px;
  z-index: 10;
}

.uia__question {
  margin-bottom: 8px;
  padding: 0px 8px;
}
</style>


