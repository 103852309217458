<template>
  <div class="release-block">
    <EntityChip
      class="rb__item-margins"
      :name="leader.name"
      :image="leader.image_url"
      size="xs"
      theme="white"
    />
    <div class="tag text-white rb__item-margins">
      PRODUCTION
    </div>
    <EntityChip
      v-for="entity in details.credits.creators.slice(1)"
      :key="`rb-credit-${entity.id}`"
      class="rb__item-margins"
      :name="entity.name === 'Jeffrey Riggs' ? 'Rick Ross' : entity.name"
      :image="entity.image_url"
      size="xs"
      theme="white"
    />
  </div>
</template>

<script>
import EntityChip from '@/components/entities/EntityChip.vue';

export default {
  name: 'ReleaseBlock',
  components: {
    EntityChip,
  },
  props: {
    details: {
      type: Object,
      required: true,
    }
  },
  computed: {
    leader() {
      return this.details.credits.creators[0];
    }
  }
}
</script>

<style lang="scss" scoped>
.rb__item-margins {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>

