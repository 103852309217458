<template>
  <div class="title-text">

    <div class="title-1">{{ title }}</div>

    <div class="headline text-secondary">{{ description }}</div>

  </div>
</template>

<script>
export default {
  name: 'title-text',
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  div:first-child {
    margin-bottom: 0;
  }
}
</style>
