<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
    height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
  <g id="Bounding_Boxes">
    <g id="ui_x5F_spec_x5F_header_copy_2">
    </g>
    <path fill="none" d="M0,0h24v24H0V0z"/>
  </g>
  <g id="Outline">
    <g id="ui_x5F_spec_x5F_header">
    </g>
    <g>
      <path id="XMLID_48_" d="M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3
        L19,3z"/>
      <path id="XMLID_46_" d="M14.14,11.86l-3,3.87L9,13.14L6,17h12L14.14,11.86L14.14,11.86z"/>
    </g>
  </g>
  </svg>
</template>

<script>
export default {
  name: 'mdi-photo',
};
</script>

<style scoped>
</style>
