<template>
  <div class="index-page">
    <IndexNav />
    <div class="ip__promo-grid">
      <PromoCard
        v-for="(card, i) in activeCards"
        :key="'promo-card-' + i"
        :content="card"
        @close-promo-card="handleClosePromoCard"
      />
    </div>
    <MasonryLayout
      :columns="columns"
      :gap="24"
    />
    <CardPanel
      v-if="menu === 'initial'"
      :cards="initialChoiceCards"
      class="ip__ctrl-panel"
      @handle-initial-choice="handleInitialChoice"
    />
  </div>
</template>

<script>
import CardPanel from '@/components/cards/CardPanel.vue';
import IndexNav from '@/components/_index/IndexNav.vue';
import MasonryLayout from '@/components/shared/MasonryLayout.vue';
import PromoCard from '@/components/cards/PromoCard.vue';

import { mapState } from 'vuex';

export default {
  name: 'IndexPage',
  components: {
    CardPanel,
    IndexNav,
    MasonryLayout,
    PromoCard
  },
  data() {
    return {
      menu: 'initial',
      selection: {
        mode: 'curated',

      },
      windowWidth: null,
      windowSize: null,
      promoCards: [
        {
          title: 'Create + Curate',
          subtitle: 'Take control of your creative work flow.  Start a project and get support. Assemble pages and engage your community.',
          imageUrl: `${require('@/assets/png/promo_create_and_curate.png')}`,
          type: 'create',
          display: true,
        },
        {
          title: 'Show Work',
          subtitle: 'Show up and get work out there. Manage your identity. Build connections. Present yourself in relationship to your body of work.',
          imageUrl: `${require('@/assets/png/promo_show_work.png')}`,
          type: 'show',
          display: true,
        },
        {
          title: 'Get Involved',
          subtitle: 'React and measure impact. Annotate and discuss works. Share projects and advocate for a series. Enter a direct conversation with creators.',
          imageUrl: `${require('@/assets/png/promo_get_involved.png')}`,
          type: 'involved',
          display: true,
        },
      ],
      initialChoiceCards: [
        {
          type: 'curated',
          text: 'Keep scrolling through the curated feed',
          icon: 'mdi-chevron-down',
          theme: 'white',
          position: 'left',
        },
        {
          type: 'custom',
          text: 'Take control of the algorithm',
          icon: 'mdi-pencil',
          theme: 'tip',
          position: 'right',
        },
      ]
    };
  },
  computed: {
    ...mapState(['loading', 'units', 'user']),
    activeCards() {
      return this.promoCards.filter(c => c.display);
    },
    numColumns() {
      if (this.windowSize === 's') {
        return 1;
      } else if (this.windowSize === 'm') {
        return 2;
      } else if (this.windowSize === 'l') {
        return 3;
      }
    },
    columns() {
      const items = this.units.map(u => {
        return {
          component: 'unit-preview',
          props: {
            unit: u,
          },
        }
      })
      const columnLength = Math.ceil(items.length / this.numColumns);
      return this.$_.chunk(items, columnLength);
    }
  },
  created() {
    window.addEventListener('resize', this.calculateWidthAndVH);
    this.calculateWidthAndVH();
    this.$store.dispatch('LOAD_UNITS');
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateWidthAndVH);
  },
  methods: {
    calculateWidthAndVH() {
      this.calculateWidth();
      this.calculateVH();
    },
    calculateWidth() {
      let newSize;
      const oldSize = this.windowSize;
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 768) {
        newSize = 's';
      } else if (this.windowWidth < 1024) {
        newSize = 'm';
      } else {
        newSize = 'l';
      }

      if (newSize !== oldSize) {
        this.windowSize = newSize;
      }
    },
    calculateVH() {
      this.$_.debounce(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 500);
    },
    handleClosePromoCard(cardType) {
      const card = this.promoCards.find(c => c.type === cardType);
      card.display = false;
    },
    handleInitialChoice(type) {
      this.menu = type === 'curated' ? 'summary' : 'menu';
    }
  }
}
</script>

<style lang="scss" scoped>
.index-page {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: black;
  padding: 32px 48px;
  @include for-size(phone-only) {
    padding: 8px 12px 32px;
    transition: padding 300ms ease;
  }
  overflow-y: scroll;
}

.ip__promo-grid {
  padding-top: 100px;
  display: grid;
  grid-template-rows: minmax(min-content, max-content);
  grid-template-columns: 100%;
  grid-row-gap: 16px;
  margin-bottom: 16px;
  @include for-size(medium-screen-up) {
    padding-top: 56px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
  }
}

.pc--create {
  grid-row: 1;
  @include for-size(medium-screen-up) {
    grid-column: 1/3;
  }
}

.pc--show {
  grid-row: 2;
  @include for-size(medium-screen-up) {
    grid-column: 1;
  }
}

.pc--involved {
  grid-row: 3;
  @include for-size(medium-screen-up) {
    grid-row: 2;
    grid-column: 2;
  }
}

.ip__ctrl-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
</style>

