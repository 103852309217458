<template>
  <div class="support-module">
    <button
      :class="['action-1', {'white': bundle.purchased }]"
      @click="handleButtonClick"
    >
      {{ buttonText }}
    </button>
    <div class="tag text-sec-white subtitle">
      {{ buttonSubtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportModule',
  props: {
    bundle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    buttonText() {
      
      return this.bundle.purchased
        ? "View this in your library"
        : `Support this project`;
    },
    buttonSubtitle() {
      const purchased = `You contributed $${this.bundle.price_cents / 100} on ${this.bundle.purchase_date}`;
      const notPurchased = `Contribute for access to ${this.bundle.name} and exclusive updates, events, & content`

      return this.purchased? purchased : notPurchased;
    },
  },
  methods: {
    handleButtonClick() {
      const handle = this.bundle.purchased
        ? this.goToLibrary
        : this.goToSales;

      handle();
    },
    goToLibrary() {
      this.$router.push({ name: 'purchases' });
    },
    goToSales() {
      this.$router.push({
        name: 'unit-checkout',
          params: { unit_id: this.bundle.unit_id }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.support-module {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding-bottom: 20px;
  > * {
    flex-shrink: 0;
  }
}
.subtitle {
  align-self: flex-start;
  padding-top: 8px;
  font-style: italic;
  font-weight: 400;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: inherit;
  height: 40px;
  padding: 10px 12px;
  border: none;
  border-radius: 20px;
  background-color: rgba(107, 241, 120, 0.1);
  color: var(--positive);
  text-align: center;
  &.white {
    background-color: rgba(255, 255, 255, 0.06);
    color: white;
  }
}
</style>

