<template>
  <header class="new-index-header">
    <div class="new-idealog-logo">
      <img
        :src="require('@/assets/png/idealog-logo-trans.png')"
      >
    </div>

    <nav class="new-index-header-nav">
      <ul>
        <li>
          <AvatarIcon
            :image="user.image"
            :link="true"
            :link-props="{ name: 'account'}"
            :name="user.id + '_account'"
            :title="user.name"
          />
        </li>
        <li>
          <IconButton
            class="icon-btn--32 icon-btn--info"
            icon="mdi-information-variant"
          />
        </li>
        <li>
          <button
            class="exit-class"
            @click="signOut"
          >
            Logout
          </button>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import IconButton from '../buttons/IconButton.vue';
import AvatarIcon from '../entities/AvatarIcon.vue';
import { removeToken } from '@/store/localStorage';

export default {
  name: 'IndexNav',
  props: {
    name: {
      default: '',
      type: String,
    },
    user: {
      required: true,
      type: Object,
    }
  },
  methods: {
    signOut() {
      if (confirm('Are you sure you want to logout?')) {
        removeToken();
        this.$store.dispatch('LOGOUT');
        window.location = '/';
      }
    },
  },
  components: {
    AvatarIcon,
    IconButton,
  },
};
</script>

<style lang="scss" scoped>
.exit-class {
 font-size: 12px;
 color: white;
 border: solid yellow 1px;
 border-radius: 20px;
 background: black;
}

.new-index-header {
  width: 100%;
  letter-spacing: -0.025em;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.new-idealog-logo {
  width: 60px;
  height: 45px;
  padding: 0;
  align-self img {
    width: 100%;
    height: auto;
  }
}

.new-index-header-nav {
  margin-left: auto;
}

.new-index-header-nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  li {
    line-height: 1;
    margin-left: 4px;
  }
}
</style>
