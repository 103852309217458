<template>
  <div class="pw-editor" v-click-outside="clickOutside">
        <input
          class="headline"
          @input="checkErrors()"
          type="password"
          placeholder="Old password"
          v-model="pwAttempt"
        >
        <input
          class="headline"
          @input="checkErrors()"
          type="password"
          placeholder="New password"
          v-model="newPw"
        >
      <text-button
        v-if="errors"
        class="btn--positive btn--full pw"
        @click="showErrors=true"
        :disabled="emptyField"
      >
        Save password
      </text-button>
      <text-button
        v-else
        class="btn--positive btn--full pw"
        @click="$emit('update:password', newPw); $emit('editCredentials');"
      >
        Save password
      </text-button>
      <ul>
        <li class="tag error" v-if="showErrors" v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
  </div>
</template>

<script>
import TextButton from '@/components/buttons/TextButton.vue';
import clickOutside from '@/helpers/click-outside';

export default {
  name: 'PasswordEditor',
  components: { TextButton },
  mixins: [clickOutside],
  data() {
    return {
      pwAttempt: '',
      newPw: '',
      errors: true,
      emptyField: true,
      showErrors: false,
    };
  },
  props: {
    password: String,
  },
  methods: {
    checkErrors() {
      this.errors = [];
      this.showErrors = false;
      let valid = true;

      if (!this.pwAttempt) {
        valid = false;
        this.errors.push('PASSWORD REQUIRED');
        this.emptyField = true;
      } else if (this.pwAttempt !== this.password) {
        valid = false;
        this.errors.push('INVALID PASSWORD');
        this.emptyField = false;
      }

      if (!this.newPw) {
        valid = false;
        this.errors.push('PLEASE ENTER A NEW PASSWORD');
        this.emptyField = true;
      } else if (!this.validPw(this.newPw)) {
        valid = false;
        this.errors.push('PASSWORDS MUST BE AT LEAST 5 CHARACTERS');
        this.emptyField = false;
      }

      if (valid) {
        this.errors = false;
        this.emptyField = false;
      }
    },
    validPw(pw) {
      if (pw.length < 5) {
        return false;
      }
      return true;
    },
    clickOutside() {
      this.$emit('editCredentials');
    },
  },
  directives: {},
};
</script>

<style lang="scss" scoped>
.pw-editor {
  width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  input {
    width: 335px;
    height: 58px;
    background: white;
    border: none;
    padding: 20px 12px;
  }
  .pw {
    border-radius: 0px 0px 10px 10px;
  }
}

input:focus {
  outline: none;
}

btn-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.empty {
  opacity: 0.25;
}

ul {
  width: 335px;
  list-style: none;
  padding: 10px 0px;
}

.error {
  color: #a44fff;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}
</style>
