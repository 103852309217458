<template>
  <div class="app-nav-wrapper">
    <AppNavHead @toggle-app-nav="appNavIsActive = !appNavIsActive" />
    <AppNavFooter />
    <div
      id="library-wrap"
      :class="{ 'with-app-nav': appNavIsActive }"
      @click.self="deactivateAppNav"
    >
      <div :class="['library-contents', { 'disable-during-app-nav': appNavIsActive }]">
        <LibraryHeader />
        <FilterSummary
          :active-filters="activeFilters"
          :active-sort="activeSort"
          class="filter-summary-position"
          @click.native="toggleMenu"
        />
        <div
          ref="llc"
          class="library-list-container"
          @scroll.native="hideMenuOnScroll"
        >
          <MediaItemList
            v-if="activeAssets && assetView"
            ref="assetList"
            :active-filters="activeFilters"
            :active-sort="activeSort"
            :avatars="[purchases[purchases.length-1].cover_image, purchases[0].cover_image]"
            :list="activeAssets"
            @scroll.native="hideMenuOnScroll"
          />
          <LibraryList
            v-if="purchases && bundleView"
            ref="bundleList"
            :active-filters="activeFilters"
            :active-sort="activeSort"
            :artist-summary="ensembles"
            :image-summary="images"
            :list="purchases"
            @scroll.native="hideMenuOnScroll"
          />
          <div
            v-if="assetsByCreator && creatorView"
            ref="creatorList"
            @scroll="hideMenuOnScroll"
          >
            <MediaItemList
              v-for="(assets, ensemble) in assetsByCreator"
              :key="'creator-view-' + ensemble"
              :active-filters="activeFilters"
              :active-sort="activeSort"
              :artists="[ensemble]"
              :avatars="images"
              creator-view
              :list="assets"
              @scroll.native="hideMenuOnScroll"
            />
          </div>
        </div>
        <div
          :class="['lc__gradient', {'without-menu': hideMenu}]"
        />
        <FilterMenu
          v-show="!hideMenu"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppNavHead from '@/components/nav/AppNavHead.vue';
import AppNavFooter from '@/components/nav/AppNavFooter.vue';
import FilterMenu from "@/components/shared/FilterMenu.vue";
import FilterSummary from "@/components/menus/FilterSummary.vue";
import LibraryHeader from "@/components/library/LibraryHeader.vue";
import LibraryList from "@/components/library/LibraryList.vue";
import MediaItemList from "@/components/entities/MediaItemList.vue";

import { mapState } from "vuex";
const Chance = require('chance');
const chance = new Chance();

export default {
  name: "LibraryContainer",
  components: {
    AppNavHead,
    AppNavFooter,
    FilterMenu,
    FilterSummary,
    LibraryHeader,
    LibraryList,
    MediaItemList,
  },
  data() {
    return {
      activeFilters: ['music', 'video', 'image', 'article'],
      activeSort: 'date',
      activeView: 'bundle',
      appNavIsActive: false,
      hideMenu: false,
    }
  },
  computed: {
    ...mapState(['purchases', 'user']),
    activeAssets() {
      if (this.purchases) {

        let assets = this.purchases.map(bundle => {
          const tracks = bundle.tracks.reduce(
            (accumulator, track) => {
              return accumulator.concat({
                ...track,
                cover_image: bundle.cover_image,
                ensemble: bundle.ensemble,
                series: 'Series Name',
                releaseDate: chance.date({ string: true, year: 2018 }),
              });
            },
            []
          );
          return tracks;
        })
        assets = this.$_.flattenDeep(assets);
        return assets;
      }
      return null;
    },
    assetsByCreator() {
      if (this.activeAssets) {
        const assets = this.ensembles.reduce((data, ensemble) => {
          let assetsByThisEnsemble = this.activeAssets.filter(asset => {
            return asset.ensemble === ensemble
          });
          data[ensemble] = assetsByThisEnsemble;
          return data;
        }, {});

        return assets;
      }

      return null;
    },
    assetView() {
      return this.activeView == 'asset';
    },
    ensembles() {
      let ensembles = this.purchases.map(purchase => purchase.ensemble);
      return [...new Set(ensembles)];
    },
    bundleView() {
      return this.activeView == 'bundle';
    },
    creatorView() {
      return this.activeView == 'creator';
    },
    images() {
      let images = this.purchases.map(purchase => purchase.cover_image);
      return [...new Set(images)].slice(1,5);
    },
  },
  created() {
    if (!this.purchases) {
      this.$store.dispatch('LOAD_PURCHASES');
    }
    this.$eventBus.$on('activate-app-nav', this.activateAppNav);
    this.$eventBus.$on('update-sort', this.handleUpdateSort);
    this.$eventBus.$on('update-type-filters', this.handleUpdateTypeFilters);
    this.$eventBus.$on('update-view', this.handleUpdateView);
  },
  mounted() {
    this.calculateDocumentVariables();
  },
  beforeDestroy() {
    this.$eventBus.$off('activate-app-nav');
    this.$eventBus.$off('update-sort');
    this.$eventBus.$off('update-type-filters');
    this.$eventBus.$off('update-view');
  },
  methods: {
    activateAppNav() {
      this.appNavIsActive = true;
    },
    deactivateAppNav() {
      if (this.appNavIsActive) {
        this.appNavIsActive = false;
      }
    },
    calculateDocumentVariables() {
      if (!this.windowHeight || this.windowHeight !== window.innerHeight) {
        this.windowHeight = window.innerHeight;
        // let vh = this.windowHeight * 0.01;
        const ms = (this.windowHeight - 224) / this.windowHeight;
        const ts = (this.windowHeight - 336) / this.windowHeight;
        const ds = 336 / this.windowHeight;
        // document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty('--ms', `${ms}`);
        document.documentElement.style.setProperty('--ts', `${ts}`);
        document.documentElement.style.setProperty('--ds', `${ds}`);
      }
    },
    hideMenuOnScroll() {
      const view = this.activeView + 'List';
      const llcScroll = this.$refs.llc.scrollTop;
      const listScroll = this.$refs[view].$el ? this.$refs[view].$el.scrollTop : this.$refs[view].scrollTop;
      const hideMenu = Boolean(llcScroll) || Boolean(listScroll);

      if (hideMenu) {
        this.hideMenu = hideMenu;
      }
    },
    handleUpdateSort(activeSort) {
      this.activeSort = activeSort;
    },
    handleUpdateTypeFilters(activeFilters) {
      this.activeFilters = activeFilters;
    },
    handleUpdateView(activeView) {
      this.activeView = activeView;
    },
    toggleMenu() {
      this.hideMenu = !this.hideMenu;
    }
  }
}
</script>

<style lang="scss" scoped>
.app-nav-wrapper {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 100%;
  max-height: 100%;
  resize: both;
  background-color: var(--background-dark);
}

.disable-during-app-nav {
  pointer-events: none;
}

// The contianer
#library-wrap {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms ease;
  transform-origin: center center;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  background-color: var(--primary);
  z-index: 2;
}

#library-wrap.with-app-nav {
  @include for-size(phone-only) {
    transform: translate(-50%, -50%) scale(var(--ms)) !important;
    transition: transform 300ms ease;
  }
  @include for-size(tablet-portrait-up) {
    transform: translate(-50%, -50%) scale(var(--ts)) !important;
    transition: transform 300ms ease;
  }
  @include for-size(small-desktop-up) {
    transform: translate(-50%, -50%) scale(var(--ds)) !important;
    transition: transform 300ms ease;
  }
}

.library-contents {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: auto;
  margin: 0 auto;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 12px;
  padding-bottom: 0px;
  width: 100vw;
  max-width: 1024px;
  height: calc(var(--vh, 1vh) * 100);
  transition: bottom 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
  @include for-size(small-desktop-up) {
    padding-top: 32px;
    transition: padding-top 300ms ease;
  }
}

.filter-summary-position {
  position: absolute;
  top: 28px;
  left: calc(50% - 210px / 2);
  z-index: 100;
  cursor: pointer;
  @include for-size(tablet-portrait-up) {
    top: 42px;
    transition: top 300ms ease;
  }
}

.library-list-container {
  height: calc(100% - 120px);
  overflow-y: scroll;
  > div {
    height: calc(100% + 10px);
    overflow-y: scroll; 
  }
}

.lc__gradient {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 215px;
  z-index: 999;
  height: 62px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), #000000);
  pointer-events: none;
  @include for-size(small-desktop-up) {
    bottom: 70px;
    height: 85px;
  }
  &.without-menu {
    bottom: 0px;
  }
}
</style>
