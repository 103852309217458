import Vue from 'vue';
import Vuex from 'vuex';
import axiosInstance from './axiosInstance';
import $node from './axiosV2';
import { checkAuth, loadToken } from './localStorage';

Vue.use(Vuex);

const state = {
  loading: true,
  loggedIn: checkAuth(),
  user: null,
  units: [],
  users: [],
  activeUnitId: null,
  oldUnit: null,
  unit: null,
  activeBundleId: null,
  bundle: null,
  purchases: null,
  featuredQa: {
    question: null,
    index: 0,
    featured_id: 0,
  },
  hiddenQaIds: [], 
  qa: null,
  entity: null,
  editingProfile: false,
};

const getters = {
  getUnits: state => state.units,
  getUnitLikeById: state => (id) => {
    const unit = state.units.find(unit => unit.unit_id === id);
    return unit ? unit.liked : false;
  },
  getUserFollowById: state => (id) => {
    const user = state.users.find(user => user.user_id === id);
    return user ? user.following : false;
  },
  getLoginState: state => state.loggedIn,
  getUnitFollowById: state => (id) => {
    const unit = state.units.find(unit => unit.unit_id === id);
    return unit ? unit.following : false;
  },
  getUnitbyId: state => id => state.units.find(unit => unit.unit_id === id),
};

const mutations = {
  setEntity(state, { entity }) {
    state.entity = entity;
  },
  setQa(state, { qa }) {
    state.qa = qa;
  },
  setEditingProfile(state, { bool }) {
    state.editingProfile = bool;
  },
  setUserFollow(state, payload) {
    state.users.find(user => user.user_id === payload.id).following = payload.following;
  },
  setUnits(state, { list }) {
    state.units = list;
  },
  setUnit(state, { unit }) {
    state.unit = unit;
  },
  setOldUnit(state, { unit }) {
    state.oldUnit = unit;
  },
  setActiveUnitId(state, { id }) {
    state.activeUnitId = id;
  },
  setBundle(state, { unit }) {
    state.bundle = unit;
  },
  setActiveBundleId(state, { id }) {
    state.activeBundleId = id;
  },
  setPurchases(state, { list }) {
    state.purchases = list;
  },
  setLoading(state, { bool }) {
    state.loading = bool;
  },
  setQuestions(state, { questions }) {
    state.questions = questions;
  },
  setFeaturedQa(state, { featuredQa }) {
    state.featuredQa = featuredQa;
  },
  setUser(state, { user }) {
    state.user = user;
  },
  setReaction(state, { type, value }) {
    state.reactions[type] = value;
  },
  addHiddenQaId(state, { id }) {
    let ids = state.hiddenQaIds.slice();
    ids.push(id);
    state.hiddenQaIds = ids;
  },
  removeHiddenQaId(state, { id }) {
    let ids = state.hiddenQaIds.filter(hiddenQaId => hiddenQaId !== id);
    state.hiddenQaIds = ids;
  },
  AUTH_SUCCESS(state) {
    state.loggedIn = true;
  },
  AUTH_FAILURE(state) {
    state.loggedIn = false;
  },
};

const getAxiosOptions = function () {
  return { params: { auth_token: loadToken() } };
}
const getUserId = async () => {
  const axiosOptions = getAxiosOptions();
  const response = await axiosInstance.get('units.json', axiosOptions);
  return response.data.user.id;
};

const actions = {
  toggleEditingProfile({ commit }, { bool }) {
    commit('setEditingProfile', { bool });
  },
  async LOAD_ENTITY({ state, commit, dispatch }, { entityType, entityId }) {
    commit('setLoading', { bool: true });

    if (!state.user) await dispatch('LOAD_USER');
    const url = `/entities/${entityType}/${entityId}?user-id=${state.user.id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await $node.get(url);
        const { qa, ...entity } = response.data;
        commit('setEntity', { entity });
        commit('setQa', { qa });
        const randomIx = Math.floor(Math.random() * qa.questions.length);
        const featuredQa = {
          question: qa.questions[randomIx],
          index: -1,
          featured_id: qa.questions[randomIx].question_id,
        };
        commit('setFeaturedQa', { featuredQa });
        commit('setLoading', { bool: false });
        resolve();
      } catch (err) {
        console.log(err);
        reject();
      }
    });
  },
  async LOAD_UNITS({ commit, dispatch }) {
    commit('setLoading', { bool: true });

    if (!state.user) await dispatch('LOAD_USER');
    const url = `/units`;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await $node.get(url);
        commit('setUnits', { list: response.data.units });
        commit('setLoading', { bool: false });
        resolve();
      } catch (err) {
        console.log(err);
        reject();
      }
    });
  },
  async LOAD_UNITS_DUMMY({ commit }) {
    try {
      const axiosOptions = getAxiosOptions();
      const units = await axiosInstance.get('units.json', axiosOptions);

      commit('setUnits', { list: units.data.units });
      commit('setLoading', { bool: false });
    } catch (err) {
      // eslint-disable-next-line
      console.log(`Error: ${err}`);
    }
  },
  LOAD_UNIT({ state, commit }, { id, isBundle }) {
    commit('setLoading', { bool: true });
    const mutations = isBundle ? ['setBundle', 'setActiveBundleId'] : ['setUnit', 'setActiveUnitId'];
    const params = {
      liberation: [`/units/${id}.json`, getAxiosOptions()],
      node: [`/units/${id}`]
    };
    return new Promise(async (resolve, reject) => {
      try {
        let oldUnit;
        if (String(id).length < 4) {
          oldUnit = await axiosInstance.get(...params.liberation);
          oldUnit = oldUnit.data;
        } 
        const unit = await $node.get(...params.node);
        const { qa, ...newUnit } = unit.data;
        commit('setOldUnit', { unit: oldUnit });
        commit('setUnit', { unit: newUnit });
        commit('setActiveUnitId', { id: newUnit.unit_id });
        commit('setQa', { qa });
        const randomIx = Math.floor(Math.random() * qa.questions.length);
        const featuredQa = {
          question: qa.questions[randomIx],
          index: -1,
          featured_id: qa.questions[randomIx].question_id,
        };
        commit('setFeaturedQa', { featuredQa });
        commit('setLoading', { bool: false });
        resolve();
      } catch (err) {
        console.log(err);
        reject();
      }
    });
  },
  async LOAD_USER({ commit }, id = null) {
    try {
      let user_id = id || await getUserId();
      const axiosOptions = getAxiosOptions();
      const user = await axiosInstance.get(`/users/${user_id}.json`, axiosOptions);

      commit('setUser', { user: user.data.user });
      commit('setLoading', { bool: false });
    } catch (err) {
      // eslint-disable-next-line
      console.log(`Error: ${err}`);
    }
  },
  async LOAD_QUESTIONS({ state, commit }, {unit_id = null}) {
    return new Promise(async (resolve, reject) => {
      try {
        const axiosOptions = getAxiosOptions();
        const url = `units/${unit_id}/questions`;
        const response = await axiosInstance.get(url, axiosOptions);
        const questions = response.data.questions;
        const randomIx = Math.floor(Math.random() * questions.length);
        const featuredQa = {
          question: questions[randomIx],
          index: -1,
          featured_id: questions[randomIx].question_id,
        };

        commit('setQuestions', { questions });
        commit('setFeaturedQa', { featuredQa });
        commit('setLoading', { bool: false });
        resolve();
      } catch (err) {
        console.log(`Error: ${err}`);
        reject();
      }
    });
  },
  LOAD_PURCHASES({ state, commit, dispatch }, userId = null) {
    return new Promise(async (resolve, reject) => {
      try {
        const axiosOptions = getAxiosOptions();
  
        if (!userId && !state.user) {
          await dispatch('LOAD_USER');
        }
  
        const url = `/users/${state.user.id}/my_purchases.json`;
        const purchases = await axiosInstance.get(url, axiosOptions);
        commit('setPurchases', { list: purchases.data.purchasedBundles });
        resolve();
      } catch (err) {
        // eslint-disable-next-line
        console.log(`Error: ${err}`);
        reject();
      }
    })
  },
  LOGIN({ commit }) {
    return new Promise(resolve => {
      commit('AUTH_SUCCESS');
      resolve();
    })
    
  },
  LOGOUT({ commit }) {
    return new Promise(resolve => {
      commit('AUTH_FAILURE');
      resolve();
    })
  },
};

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
