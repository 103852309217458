<template>
  <svg width="40" height="14" viewBox="0 0 44 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.21533 13C3.32177 9.35154 6.35148 6.32183 9.99994 4.21539C13.6484 2.10895 17.7871 1 21.9999 1C26.2128 1 30.3515 2.10895 33.9999 4.21539C37.6484 6.32183 40.6781 9.35154 42.7845 13" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ReactionSvg',
};
</script>

<style scoped>
svg {
  position: relative;
  stroke: white;
}
</style>
