<template>
  <div v-if="!showCompletionMessage" class="qa-prompt-thread">
    <QaBubble
      :avatar="question.owner.image_url"
      :key="'-1' + '-' + question.question_id"
      class="qa-thread__question qa-thread--is-expanded"
      highlight
      :num-answers="numAnswers"
      :text="question.body"
    />
    <AnswerInputBubble
      :answer-input="answerInput"
      :avatar="avatar"
      @update-answer-input="updateAnswerInput"
    />
    <text-button
      class="btn__layout--small btn--pill btn--black-white qpt__btn"
      :disabled="!answerInput"
      small-text
      @click="sendYourAnswer"
    >
      Send your answer
    </text-button>
  </div>
  <div
    v-else
    style="display: flex; flex-direction: column; align-items: center;"
  >
    <div
      class="text-white action-2"
      style="margin-bottom: 10px; text-align: center;"
    >
      Thank you for sending your answer!
    </div>
    <text-button
      class="btn__layout--small btn--pill btn--black-white"
      small-text
      @click="sendAnother"
    >
      Send another
    </text-button>
  </div>
</template>

<script>
import AnswerInputBubble from '@/components/qa/AnswerInputBubble.vue';
import QaBubble from '@/components/qa/QaBubble.vue';
import TextButton from '@/components/buttons/TextButton.vue';
import { loadToken } from "@/store/localStorage";

import { mapState } from 'vuex';

export default {
  name: 'QaPromptThread',
  components: {
    AnswerInputBubble,
    QaBubble,
    TextButton,
  },
  props: {
    question: {
      default() {
        return {};
      },
      type: Object
    },
  },
  data() {
    return {
      answerInput: '',
      numAnswers: false,
      showCompletionMessage: false,
    }
  },
  computed: {
    ...mapState(['qa', 'user']),
    avatar() {
      return this.$store.state.user.image;
    },
    userId() {
      return this.$store.state.user.id;
    }
  },
  methods: {
    sendAnother() {
      this.showCompletionMessage = false;
      this.answerInput = '';
    },
    sendYourAnswer() {
      console.log('sending answer...');
      const type = this.qa.type === 'unit' ? 'unit' : this.qa.entity.type;
      const id = this.qa[this.qa.type].id || this.qa[this.qa.type].unit_id;
      const url = `${type}/${id}/questions/${this.question.question_id}/answers`;
      const data = {
        answer: {
          question_id: this.question.question_id,
          user_id: this.userId,
          body: this.answerInput,
          active: true,
        }
      };

      // console.log({ url, data })

      // this.showCompletionMessage = true;

      this.$node
        .post(url, data)
        .then(response => {
          console.log(response.data);
          this.showCompletionMessage = true;
          this.$store.commit('setQa', { qa: response.data });
        })
        .catch(err => {
          console.log(err);
          this.showErrorMessage = true;
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000);
        });
    },
    updateAnswerInput(value) {
      this.answerInput = value;
    } 
  }
}
</script>

<style lang="scss" scoped>
.qa-thread {
  width: 100%;
}

.qa-thread__answer {
  transition: 350ms ease-out;
}

.qpt__btn {
  width: calc(100% - 10px);
  max-width: 200px;
  margin-left: calc(100% - 200px);
}
</style>
