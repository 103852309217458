<template>
  <svg
    width="120"
    height="120"
  >
    <g ref="fb-chart">
      <path
        ref="ensembleShare"
        :style="translate"
      />
      <path
        ref="seriesShare"
        :style="translate"
      />
      <text
        y="30"
        fill="white"
        font-size="10"
        font-weight="bold"
      >
        <tspan
          x="15"
          dy="1.2em"
        >
          Series
        </tspan>
        <tspan
          x="15"
          dy="1.2em"
        >
          {{ details.series_share }}%
        </tspan>
      </text>
      <text
        y="30"
        fill="white"
        font-size="10"
        font-weight="bold"
      >
        <tspan
          x="65"
          dy="1.2em"
        >
          Ensemble
        </tspan>
        <tspan
          x="65"
          dy="1.2em"
        >
          {{ details.ensemble_share }}%
        </tspan>
      </text>
    </g>
  </svg>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'FinancialsChart',
  props: {
    details: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.drawChart();
  },
  computed: {
    translate() {
      return { transform: 'translate(60px, 60px)' };
    },
  },
  methods: {
    drawChart() {
      const ensembleShare = d3.select(this.$refs.ensembleShare);
      const seriesShare = d3.select(this.$refs.seriesShare);

      const tau = Math.PI * 2;
      const ensembleAngle = tau * (Number(this.details.ensemble_share) / 100);

      const ensembleArc = d3
        .arc()
        .innerRadius(0)
        .outerRadius(60)
        .startAngle(0)
        .endAngle(ensembleAngle);

      const seriesArc = d3
        .arc()
        .innerRadius(0)
        .outerRadius(60)
        .endAngle(tau)
        .startAngle(ensembleAngle);

      seriesShare
        .style('fill', 'rgba(255, 255, 255, 0.12)')
        .attr('d', seriesArc);

      const defs = d3.select(this.$refs['fb-chart']).append('defs');

      const gradient = defs
        .append('linearGradient')
        .attr('id', 'ensembleGradient')
        .attr('x1', '0%') // potentially update these angles
        .attr('x2', '100%')
        .attr('y1', '0%')
        .attr('y2', '100%');

      gradient
        .append('stop')
        .attr('class', 'start')
        .attr('stop-color', '#044AFD')
        .attr('offset', "0%");

      gradient
        .append('stop')
        .attr('class', 'end')
        .attr('stop-color', 'rgba(108, 255, 132, 0.5)')
        .attr('offset', "100%");

      ensembleShare
        .style('fill', "url(#ensembleGradient)")
        .attr('d', ensembleArc);
    

    },
  }
}
</script>

