<template>
  <div class="entity-relationships">
    <TimelineModule
      v-if="entity.type === 'series'"
      context="artists"
      :timeline-items="entity.relationships.related_entities"
    />
    <EntityChipList
      v-if="entity.type === 'ensemble' && activeRelations"
      :main-entities="activeRelations"
      :expandable-entities="formerRelations"
      header="Signed to:"
      :expandable-header="formerLabelsHeader"
    />
    <EntityChipList
      v-if="entity.type === 'user' && activeRelations"
      :main-entities="activeRelations"
      :expandable-entities="formerRelations"
      header="Works with:"
      :expandable-header="formerCollaboratorsHeader"
    />
    <div v-if="editingProfile && entity.type !== 'series'">
      <SettingsButton
        v-for="(btn, i) in relationsSettingsButtons"
        :key="'relation-setting-btn-' + i"
        :label="btn.label"
        :setting="btn.setting"
        :showing="entity.settings[btn.setting]"
        style="margin-bottom: 10px;"
      />
      <ThemedButton
        style="display: block; margin-bottom: 10px;"
        text="Manage in Wholetone"
        theme="neutral"
      />
    </div>
  </div>
</template>

<script>
import EntityChipList from '@/components/entities/EntityChipList.vue';
import SettingsButton from '@/components/buttons/SettingsButton.vue';
import ThemedButton from '@/components/buttons/ThemedButton.vue';
import TimelineModule from '@/components/_timeline/TimelineModule.vue';

import { mapState } from 'vuex';

export default {
  name: 'EntityRelationships',
  components: {
    EntityChipList,
    SettingsButton,
    ThemedButton,
    TimelineModule,
  },
  computed: {
    ...mapState(['editingProfile', 'entity']),
    activeRelations() {
      if (this.entity.type !== 'ensemble') return;
      const { related_entities } = this.entity.relationships;
      return related_entities.filter(e => e.is_active);
    },
    formerRelations() {
      if (this.entity.type !== 'ensemble') return;
      const { related_entities } = this.entity.relationships;
      return related_entities.filter(e => !e.is_active);
    },
    relationsSettingsButtons() {
      if (!this.editingProfile || this.entity.type === 'series') return;
      const relation = this.entity.type === 'ensemble'
        ? 'Labels'
        : 'Collaborators';
      const formerRelation = `Former ${relation.toLowerCase()}`;
      
      return [
        { label: relation, setting: 'relations_active' },
        { label: formerRelation, setting: 'relations_former' },
      ];
    },
    formerCollaboratorsHeader() {
      const num = this.formerRelations.length;
      return `${num} FORMER COLLABORATOR${num > 1 ? 'S':''}`;
    },
    formerLabelsHeader() {
      const num = this.formerRelations.length;
      return `${num} FORMER LABEL${num > 1 ? 'S':''}`;
    },
  }
}
</script>

<style lang="scss" scoped>
.entity-relationships {
}
</style>

