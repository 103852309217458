<template>
  <div class="unit-timeline-card">
    <UnitTimelineCardTitle
      :asset-title="assetTitle"
      :in-carousel="inCarousel"
      :is-expanded="isExpanded"
      :item="item"
      @toggle-card="handleToggleCardEvent"
    />
    <transition-group
      name="toggle-item"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <template v-if="isExpanded || inCarousel">
        <div
          key="tc"
          class="utc__expansion"
        >
          <component
            :is="cardBlock"
            :details="item"
          /> 
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
import BudgetBlock from '@/components/_timeline/BudgetBlock.vue';
import ContractCell from '@/components/timeline/ContractCell.vue';
import EventBlock from '@/components/_timeline/EventBlock.vue';
import FinancialsBlock from '@/components/_timeline/FinancialsBlock.vue';
import ReleaseBlock from '@/components/_timeline/ReleaseBlock.vue';
import UnitTimelineCardTitle from '@/components/_timeline/UnitTimelineCardTitle.vue';


export default {
  name: 'UnitTimelineCard',
  components: {
    BudgetBlock,
    ContractCell,
    EventBlock,
    FinancialsBlock,
    ReleaseBlock,
    UnitTimelineCardTitle
  },
  data() {
    return {
      isExpanded: true,
    }
  },
  props: {
    assetTitle: {
      type: String,
      default: 'FIRST RELEASE'
    },
    inCarousel: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cardBlock() {
      const blocks = {
        budget: 'budget-block',
        contract: 'contract-cell',
        event: 'event-block',
        release: 'release-block',
        financials: 'financials-block'
      };

      return blocks[this.item.type];
    },
  },
  methods: {
    beforeEnter(el) {
      el.style.height = '0';
      el.style.opacity = 0;
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = 1;
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.height = '0';
      el.style.opacity = 0;
    },
    handleToggleCardEvent() {
      this.isExpanded = !this.isExpanded;
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-timeline-card {
  box-sizing: border-box;
  padding: 8px 12px;
  overflow-y: auto;
  background-color: var(--tint-white);
  @include for-size(medium-screen-up) {
    width: inherit;
  }
}

.utc__expansion {
  padding-top: 8px;
  height: 188px;
  transition: 350ms ease-out;
}
</style>


