<template>
  <div :style="poleStyle">
    <svg
      width="34"
      height="112"
      viewBox="0 0 34 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :d="`M${path.m1[0]} ${path.m1[1]}
        L${path.l1[0]} ${path.l1[1]}
        C${path.c1cp1[0]} ${path.c1cp1[1]}
        ${path.c1cp2[0]} ${path.c1cp2[1]}
        ${path.c1end[0]} ${path.c1end[1]}
        C${path.c2cp1[0]} ${path.c2cp1[1]}
        ${path.c2cp2[0]} ${path.c2cp2[1]}
        ${path.c2end[0]} ${path.c2end[1]}
        L${path.l2[0]} ${path.l2[1]}Z`"
        fill="url(#paint0_linear)"
        fill-opacity="0.7"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17"
          y1="0"
          x2="17"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stop-color="#6666FF"
            stop-opacity="0.3"
          />
          <stop
            offset="1"
            stop-color="#FF6666"
          />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "ImpactPole",
  props: {
    reacting: {
      default: false,
      type: Boolean,
    },
    scale: {
      default: 1,
      type: Number,
    },
    position: {
      default: "ra",
      type: String,
    },
  },
  data() {
    return {
      center: 17,
      maxY: 112,
      r: (112 - 92.3115),
      defaultXs: [17, 33.4842, 35.317, 27.4259, 17, 6.57415, -1.31695, 0.515813, 17],
      defaultYs: [0, 92.3115, 102.156, 112, 112, 112, 102.156, 92.3115, 0] // changed 8th from 92.3114, changed 102.575 to 102.156
    };
  },
  computed: {
    height() {
      return this.path.c1end[1];
    },
    path() {
        const { scale, r, maxY } = this;
        const y = (scale * maxY);

        return {
          m1: [17,0],
          l1: [33.4842, y - r],
          c1cp1: [35.317, y - (r/2)], 
          c1cp2: [27.4259, y],
          c1end: [17, y],
          c2cp1: [6.57415, y],
          c2cp2: [-1.31695, y - (r/2)],
          c2end: [0.515813, y - r],
          l2: [17, 0]
        };
    },
    poleStyle() {
      const [deg, origin] = this.transform;

      return {
        transform: `rotate(${deg}deg)`,
        transformOrigin: `${origin}`
      };
    },
    transform() {
      const pos = this.position;
      let origin = 'top center';
      let deg;
      switch(pos) {
        case 'ic':
          deg = 60;
          break;
        case 'rb':
          deg = 120;
          break;
        case 'ia':
          deg = 180;
          break;
        case 'rc':
          deg = 240;
          break;
        case 'ib':
          deg = 300;
          break;
        default:
          deg = 0;
          origin = 'center';
      };
      return [deg, origin];
    }
  },
  mounted() {
    this.$emit('record-pole-height', {
      position: this.position,
      height: this.height,
    })
  },
};
</script>
