<template>
  <div class="payment-info-module">
    <div class="pim__card">
      <DropdownMenu
        class="pim__dropdown"
        :class="{ 'expanded-height': showSavedCards }"
        dropdown-theme="white"
        :options="cardOptions"
        @open="showSavedCards = true"
        @selected-option="selectSavedCard"
      />

      <form
        v-show="!savedCardIsSelected"
        ref="stripeForm"
        class="pim__inputs"
      >
        <div style="margin-bottom: 4px">
          <div id="card-number" />
        </div>

        <div
          class="tag text-negative"
          style="height: 16px; margin-bottom: 4px;"
        >
          <span v-if="error.cardNumberError">{{ error.cardNumberError.toUpperCase() }}</span>
        </div>

        <div style="display: flex; justify-content: space-between; margin-bottom: 4px;">
          <div style="height: 20px; flex: 1;">
            <div id="card-expiry" />
          </div>

          <div style="height: 20px; flex: 1;">
            <div id="card-cvc" />
          </div>
        </div>

        <div
          class="tag text-negative"
          style="height: 16px; margin-bottom: 4px;"
        >
          <span v-if="error.cardExpiryError">
            {{ error.cardExpiryError.toUpperCase() }}
          </span>
          <span v-if="error.cardCvcError">
            {{ error.cardCvcError.toUpperCase() }}
          </span>
        </div>
      </form>

      <div
        v-if="savedCardIsSelected"
        class="headline text-disabled pim__saved-card"
      >
        {{ passwordChars }}{{ cardOptions[0].split(" ")[1] }}
      </div>
    </div>

    <div class="body-2 text-secondary pim__special">
      Want to feel special?
    </div>

    <div v-if="unit" class="pim__other-methods">
      <div id="payment-request-button" />
      <div class="paypal-button-container">
        <PayPal
          :amount="unit.price"
          :button-style="paypal.style"
          :client="paypal.credentials"
          currency="USD"
          env="sandbox"
          :items="paypal.items"
          @payment-cancelled="handlePayPalPaymentCancelled"
          @payment-completed="handlePayPalPaymentCompleted"
          style="width: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DropdownMenu from '@/components/shared/DropdownMenu.vue';
import PayPal from 'vue-paypal-checkout';

const stripeKey = 'pk_test_gq2OXLu20ERr8lrnCuUVNV8J';

export default {
  name: 'PaymentInfoModule',
  components: {
    DropdownMenu,
    PayPal,
  },
  props: {
    unit: Object,
  },
  data() {
    return {
      card: {
        number: '',
        expiry: '',
        cvc: '',
      },

      // elements
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      prButton: '',
      stripe: null,

      error: {
        stripeError: '',
        cardNumberError: '',
        cardExpiryError: '',
        cardCvcError: '',
      },

      paypal: {
        credentials: {
          sandbox:
            'ATHWyEObonEr_ZUBZKjqtl9xzBh90tFkb6Q0Wy6EkVSIqbKGMJTrT6bVkTLtNBV4FWE4jO0W2mZHlkbO',
          production:
            'ASJgRm-3WwgNYXSKZaAidj5DukNaMth-lAtIX2fX56FGLk-4rDscVIUW8ul_YiHD003bxxdbKzSvZA7z',
        },
        items: [
          {
            name: this.unit.title,
            description: this.unit.description,
            quantity: 1,
            price: this.unit.price,
            currency: 'USD',
          },
        ],
        style: {
          // label: "checkout",
          size: 'responsive',
          shape: 'rect',
          color: 'silver',
        },
      },
      cardOptions: ['New card', 'Visa 0240'],
      savedCardIsSelected: false,
      showSavedCards: false,
      passwordChars: '\u2022\u2022\u2022\u2022 '.repeat(3),
    };
  },
  computed: {},
  created() {
    this.$eventBus.$on('validate-stripe', this.validateToSendToken);
  },
  mounted() {
    this.setUpStripe();
  },
  beforeDestroy() {
    this.$eventBus.$off('validate-stripe');
  },
  watch: {
    card: {
      deep: true,
      handler() {
        const allTrue = Object.keys(this.card).every(key => this.card[key]);

        if (allTrue) {
          this.$eventBus.$emit('validate-payment-info', true);
        }
      },
      immediate: true,
    },
  },
  methods: {
    clearCardErrors() {
      this.error.stripeError = '';
      this.error.cardCvcError = '';
      this.error.cardExpiryError = '';
      this.error.cardNumberError = '';
    },
    listenForErrors() {
      const vm = this;

      this.cardNumber.addEventListener('change', (event) => {
        vm.toggleError(event);
        // vm.error.cardNumberError = "";
        vm.card.number = !!event.complete;
      });

      this.cardExpiry.addEventListener('change', (event) => {
        vm.toggleError(event);
        // vm.error.cardExpiryError = "";
        vm.card.expiry = !!event.complete;
      });

      this.cardCvc.addEventListener('change', (event) => {
        vm.toggleError(event);
        // vm.error.cardCvcError = "";
        vm.card.cvc = !!event.complete;
      });
    },
    selectSavedCard(option) {
      let options = this.cardOptions.slice();

      options = options.filter(item => item !== option);
      options.unshift(option);

      this.cardOptions = options;
      this.showSavedCards = false;

      if (option == 'New card') {
        this.savedCardIsSelected = false;
        this.$eventBus.$emit('validate-payment-info', false);
        // setTimeout(() => {
        //   this.setUpStripe();
        // }, 0);
      } else {
        this.savedCardIsSelected = true;
        this.$eventBus.$emit('validate-payment-info', true);
      }
    },

    setUpStripe() {
      if (window.Stripe === undefined) {
        alert('Stripe V3 library not loaded!');
      } else {
        const stripe = window.Stripe(stripeKey);
        this.stripe = stripe;

        const fonts = {
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=IBM+Plex+Sans',
            },
          ],
        };

        const elements = stripe.elements(fonts);

        const style = {
          style: {
            base: {
              fontFamily: 'IBM Plex Sans, sans-serif',
              fontSize: '20px',
              color: '#000000',
              '::placeholder': {
                color: 'rgba(0, 0, 0, 0.25)',
              },
            },
          },
        };

        const paymentRequest = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: this.unit.title,
            amount: Number(this.unit.price) * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        this.prButton = elements.create('paymentRequestButton', {
          paymentRequest,
          style: {
            paymentRequestButton: {
              type: 'buy',
              theme: 'dark',
              height: '40px',
            },
          },
        });

        this.cardCvc = elements.create('cardCvc', style);
        this.cardExpiry = elements.create('cardExpiry', style);
        this.cardNumber = elements.create('cardNumber', style);

        this.cardCvc.mount('#card-cvc');
        this.cardExpiry.mount('#card-expiry');
        this.cardNumber.mount('#card-number');

        paymentRequest.canMakePayment().then((result) => {
          if (result) {
            this.prButton.mount('#payment-request-button');
          } else {
            document.getElementById('payment-request-button').style.display = 'none';
            // eslint-disable-next-line
            console.log(
              'not in apple-pay supported browser or os, did not mount payment-request',
            );
          }
        });

        paymentRequest.on('token', (event) => {
          const url = `buy/${this.unit.bundle_id}`;
          const stripeToken = event.token.id;

          this.$http
            .post(url, {
              stripeToken,
            })
            .then((response) => {
              // eslint-disable-next-line
              console.log(response);
              event.complete('success');

              const paymentInfo = {
                method: 'paymentRequest',
                success: false,
              };

              this.$emit('other-methods-purchase', paymentInfo);
            })
            .catch((err) => {
              // eslint-disable-next-line
              console.log(err);
              window.alert(
                'There was an error completing your purchase. Please try again.',
              );
              event.complete('fail');
            });
        });

        this.listenForErrors();
      }
    },
    toggleError(event) {
      if (event.error) {
        this.categorizeErrors(event.error.message);
      } else {
        this.clearCardErrors();
      }
    },
    categorizeErrors(errorMessage) {
      if (errorMessage.includes('Your card number')) {
        this.error.cardNumberError = "THIS NUMBER DOESN'T LOOK RIGHT";
      } else if (errorMessage.includes("Your card's expiration")) {
        this.error.cardExpiryError = 'INVALID EXPIRATION DATE';
      } else if (errorMessage.includes("Your card's security")) {
        this.error.cardCvcError = 'INVALID SECURITY CODE';
      } else {
        this.error.stripeError = errorMessage;
      }
    },
    validateToSendToken() {
      this.clearCardErrors();
      let valid = true;

      if (this.savedCardIsSelected) {
        this.emitPurchase();
        return;
      }
      if (!this.card.number) {
        valid = false;
        this.error.cardNumberError = 'Card number is required.';
      }
      if (!this.card.cvc) {
        valid = false;
        this.error.cardCvcError = 'CVC is required.';
      }
      if (!this.card.expiry) {
        valid = false;
        this.error.cardExpiryError = 'Expiration is required.';
      }

      if (this.error.stripeError) {
        valid = false;
      }
      if (valid) {
        this.emitPurchase();
      }
    },
    handlePayPalPaymentCancelled(response) {
      // eslint-disable-next-line
      console.log({ msg: "payment cancelled", paypalResponse: response });
      window.alert(
        'There was an error completing your purchase. Please try again.',
      );
    },
    handlePayPalPaymentCompleted(response) {
      const paymentInfo = {
        method: 'paypal',
        paypalTransactionId: response.id,
        success: false,
      };

      this.$emit('other-methods-purchase', paymentInfo);
    },
    emitPurchase() {
      let paymentInfo;

      if (this.cardOptions[0] != 'New card') {
        paymentInfo = {
          cardNumber: null,
          error: null,
          stripe: null,
          success: false,
          token: '',
          last4: this.cardOptions[0].split(' ')[1],
        };
        this.$emit('purchase', paymentInfo);
      } else {
        paymentInfo = {
          cardNumber: this.cardNumber,
          error: null,
          stripe: this.stripe,
          success: false,
          token: '',
        };
        this.$emit('purchase', paymentInfo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$width: calc(100vw - 40px);

.payment-info-module {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 188px;
  bottom: -52px;
}
.pim__card {
  position: absolute;
  left: 20px;
  right: 20px;
  width: 280px;
  height: 178px;
  left: calc(50% - 280px / 2);
  top: 0px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--tint);
  // z-index: 0;
}

.pim__dropdown {
  width: 136px;
  margin-bottom: 32px;
  z-index: 10;
  transition: height 300ms;
  &.expanded-height {
    height: 84px;
    margin-bottom: -12px;
    transition: height 300ms;
  }
}

form {
  padding: 0px 10px;
}

.pim__inputs {
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    display: block;
    outline: none;
    border: none;
    z-index: 2;
  }
}

.pim__saved-card {
  padding: 1px 10px;
  line-height: 22.4px;
}

.pim__errors {
  position: absolute;
  left: 20px;
  top: 188px;
}

.pim__special {
  position: absolute;
  left: 20px;
  top: 208px;
}

.pim__other-methods {
  display: flex;
  align-items: center;
  height: 64px;
  position: absolute;
  left: 20px;
  top: 238px;
}

#payment-request-button {
  width: 140px;
  min-height: 40px;
  max-height: 64px;
  border-radius: 4px;
}

.paypal-button-container {
  width: 120px;
  height: 40px;
  margin-left: 12px;
  padding-top: 8px;
}
</style>
