<template>
  <div :class="['promo-card', `pc--${content.type}`]">
    <IconButton
      v-if="closable"
      class="icon-btn--32 icon-btn--tint-sec pc__close"
      icon="mdi-close"
      @click="closeCard"
    />
    <div class="pc__content">
      <div class="title-1 text-white">
        {{ content.title }}
      </div>
      <div
        class="body-1 text-sec-white small-font"
        style="max-width: 450px;"
      >
        {{ content.subtitle }}
      </div>
      <div :class="['pc__btns', `pc__btns--${content.type}`]">
        <component
          :is="leftButton.component"
          v-bind="leftButton.props"
        />
        <component
          :is="rightButton.component"
          v-bind="rightButton.props"
        />
      </div>
    </div>
    <div class="pc__image-wrap">
      <img
        class="pc__image"
        :src="content.imageUrl"
      >
      <div class="pc__image-gradient" />
    </div>
    <div class="pc__gradient" />
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';
import ThemedButton from '@/components/buttons/ThemedButton.vue';
import ToolButton from '@/components/buttons/ToolButton.vue';

export default {
  name: 'PromoCard',
  components: { IconButton, ThemedButton, ToolButton },
  props: {
    closable: {
      type: Boolean,
      default: true
    },
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    leftButton() {
      const options = {
        create: {
          component: 'themed-button',
          props: {
            text: 'Start a project',
            theme: 'positive',
          }
        },
        show: {
          component: '',
          props: {}
        },
        involved: {
          component: 'tool-button',
          props: {
            icon: 'mdi-pencil',
            position: 'left',
            text: 'ANNOTATE & DISCUSS',
            theme: 'warning',
          }
        },
      }

      return options[this.content.type];
    },
    rightButton() {
      const options = {
        create: {
          component: 'themed-button',
          props: {
            text: 'Create a page',
            theme: 'neutral',
          }
        },
        show: {
          component: 'themed-button',
          props: {
            text: 'Manage your profile',
            theme: 'negative',
          }
        },
        involved: {
          component: 'tool-button',
          props: {
            icon: 'mdi-share',
            position: 'right',
            text: 'CREATE & SHARE',
            theme: 'neutral',
          }
        },
      }

      return options[this.content.type];
    }
  },
  methods: {
    closeCard() {
      this.$emit('close-promo-card', this.content.type);
    }
  }
}
</script>

<style lang="scss" scoped>
.promo-card {
  position: relative;
  width: 100%;
  border-radius: 12px;
  background-color: #181818;
}

.pc__close {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 100;
}

.pc--create {
  height: 230px;
  @include for-size(medium-screen-up) {
    height: 186px;
  }
}

.pc--show, .pc--involved {
  height: 230px;
}

.pc__image-wrap {
  position: relative;
  float: right;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.pc__image-gradient {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 2;
}

.pc__gradient {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 70%;
  height: 100%;
  border-radius: 12px;
  z-index: 3;
  background: linear-gradient(90deg, #1D1919 30%, rgba(24, 24, 24, 0.5) 70%, rgba(24, 24, 24, 0) 100%);
  @include for-size(phone-only) {
    background: linear-gradient(90deg, #1D1919 0%, rgba(24, 24, 24, 0) 80%);
    width: 40%;
  }
}

img {
  width: auto;
  max-width: 100%;
  height: 150%;
  object-fit: cover;
  object-position: center center;
  border-radius: 12px;
  z-index: 1;
  box-shadow: 0px 4px 100px rgba(105, 44, 44, 0.25);
}

.pc__content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  z-index: 100;
  pointer-events: none;
}

.pc__btns {
  position: absolute;
  right: 16px;
  bottom: 16px;
  left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;  
  flex-wrap: wrap;
}

.pc__btns--show {
  justify-content: flex-end;
}
</style>

