<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8616 14.4281C12.0337 14.532 12.2513 14.5227 12.4139 14.4045C12.5765 14.2863 12.6525 14.0822 12.6068 13.8864L11.5821 9.49368L14.994 6.53795C15.1458 6.4064 15.2042 6.19673 15.1421 6.00564C15.0801 5.81455 14.9097 5.67915 14.7095 5.66189L10.216 5.2744L8.46017 1.13801C8.38177 0.953315 8.20056 0.833374 7.99992 0.833374C7.79927 0.833374 7.61806 0.953315 7.53967 1.13801L5.78389 5.2744L1.2903 5.66189C1.09021 5.67914 0.919862 5.81444 0.857759 6.00542C0.795656 6.19641 0.85385 6.40602 1.00552 6.53766L4.41151 9.49368L3.39284 13.8871C3.34746 14.0828 3.42364 14.2867 3.58624 14.4047C3.74884 14.5227 3.96628 14.5319 4.13829 14.4281L7.99992 12.0974L11.8616 14.4281Z" fill="color" stroke="black" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Star',
  props: {
    color: {
      default: '#FFFFFF',
      type: String,
    },
  },
};
</script>
