<template>
  <div class="channels-module">
    <div class="cm__header">
      <div
        v-for="(tab,i) in activeTabs"
        :key="'cm-tab-' + i"
        :class="[
          'cm__tab',
          'tag',
          activeTab === tab ? 'text-white' : 'text-hint-white'
        ]"
        @click="toggleTab"
      >
        {{ tab.toUpperCase() }}
      </div>
    </div>
    <div class="cm__main">
      <keep-alive>
        <component
          :is="currentTabComponent"
          :entity-name="entity.name"
          :content="entity.channels[activeTab]"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import MediaChannel from '@/components/profile/MediaChannel.vue';
import SupportChannel from '@/components/profile/SupportChannel.vue';

import { mapState } from 'vuex';

export default {
  name: 'ChannelsModule',
  components: {
    MediaChannel,
    SupportChannel
  },  
  data() {
    return {
      tabs: ['media', 'support'],
      activeTab: 'media',
    }
  },
  computed: {
    ...mapState(['entity']),
    activeTabs() {
      const { media, support } = this.entity.channels;
      const mediaIsActive = media.primary.units.length || media.featured.units.length;
      const supportIsActive = Boolean(support.bundles.length);

      const tabStatus = [mediaIsActive, supportIsActive];
      return this.tabs.filter((tab, i) => tabStatus[i]);
    },
    currentTabComponent() {
      return `${this.activeTab}-channel`;
    }
  },
  methods: {
    toggleTab(event) {
      const tab = event.target.innerText.toLowerCase();
      if (['projects', 'pages'].includes(tab)) return;
      if ((this.entity.type !== 'user') && (tab === 'support')) return;
      this.activeTab = tab;
    }
  }
}
</script>

<style lang="scss" scoped>
.channels-module {
}
.cm__header {
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid var(--stroke-white);
  > :not(:last-child) {
    margin-right: 20px;
  }
}

.cm__tab {
  cursor: pointer;
}

.cm__main {
  padding: 20px 0px;
}
</style>

