<template>
  <div class="app-logo">
    <div class="mobile">
      <img :src="require('@/assets/png/idealog-logo-trans.png')">
    </div>
    <div class="medium-screen-up">
      idealog
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLogo',
}
</script>

<style lang="scss" scoped>
.medium-screen-up {
  display: none;
  @include for-size(medium-screen-up) {
    display: block;
    font-weight: 600;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.05em;
    color: white;
  }
}

.mobile {
  display: block;
  width: 65px;
  height: 45px;
  padding: 0;
  @include for-size(medium-screen-up) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
}
</style>

