<template>
  <div
    id="ct-container"
    class="contract-terms text-footnote text-sec-white"
  >
    <span>
      Signed {{ this.$moment(this.startDate).format("MM/DD/YY") }}
    </span>
    <div
      ref="bar"
      class="ct__track"
    >
      <div
        class="progress-bar"
        :style="{width: progressTrackLength}"
      />
      <div
        class="clear-bar"
        :style="{width: '10px', 'margin-left': progressDotMargin }"
      />
      <div
        class="progress-dot"
        :style="{'margin-left': progressDotMargin }"
      />
    </div>
    <span>
      Expires {{ this.$moment(this.endDate).format("M/D/YY") }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'contract-terms',
  props: {
    startDate: String,
    endDate: String,
  },
  data() {
    return {
      trackLength: 0,
    };
  },
  computed: {
    progressTrackLength() {
      const contractDuration = this.$moment(this.endDate).diff(this.$moment(this.startDate), 'days');
      const contractElapsedAsOfNow = this.$moment().diff(this.$moment(this.startDate), 'days');

      const progressTrackLength = Math.round(contractElapsedAsOfNow / contractDuration * this.trackLength);

      return progressTrackLength + "px";
      // return '6px';
    },
    progressDotMargin() {
      const progressTrackLength = Number(this.progressTrackLength.slice(0, 1));

      return `${progressTrackLength - 5}px`;
    },
    windowWidth() {
      return window.innerWidth;
    },
  },
  mounted() {
    const width = document.getElementById('ct-container').offsetWidth;
    this.trackLength = width - 200;
  },
};
</script>

<style lang="scss" scoped>
.contract-terms {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ct__track {
  position: relative;
  height: 2px;
  width: calc(100% - 195px);
  background-color: var(--stroke-dark);

  .progress-bar {
    // width: 50%;
    height: 2px;
    background-color: var(--secondary);
  }

  .clear-bar {
    height: 2px;
    background-color: white;
    margin-top: -2px;
    // z-index: 2;
  }

  .progress-dot {
    min-height: 10px;
    min-width: 10px;
    max-width: 10px;
    max-height: 10px;
    transform: translateY(-6px);
    border: 2px solid white;
    border-radius: 50%;
    background: gray;
    z-index: 2;
  }
}
</style>
