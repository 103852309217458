<template>
  <div class="profile-toolbelt">
    <div class="pt__wrap">
      <div
        class="pt__btn"
        @click="handleLeftButtonClick"
      >
        <component
          :is="leftButtonState.icon"
          :class="leftButtonState.svgClass"
          style="margin-right: 8px;"
        />
        <div :class="['tag', leftButtonState.textClass]">
          {{ leftButtonState.text }}
        </div>
      </div>
      <div class="impact-preview">
        <img :src="require('@/assets/png/toolbelt-hexagon.png')">
      </div>
      <div
        class="pt__btn pt__btn--right"
        @click="handleRightButtonClick"
      >
        <div :class="['tag', rightButtonState.textClass]">
          {{ rightButtonState.text }}
        </div>
        <component
          :is="rightButtonState.icon"
          :class="rightButtonState.svgClass"
          style="margin-left: 8px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MdiChevronDown from '@/components/graphics/MdiChevronDown.vue';
import MdiClose from '@/components/graphics/MdiClose.vue';
import MdiContentSave from '@/components/graphics/MdiContentSave.vue';
import MdiHeart from '@/components/graphics/MdiHeart.vue';
import MdiHeartOutline from '@/components/graphics/MdiHeartOutline.vue';
import MdiPlay from '@/components/graphics/MdiPlay.vue';
import MdiSettings from '@/components/graphics/MdiSettings.vue';
import MdiShare from '@/components/graphics/MdiShare.vue';
import ToolButton from '@/components/tools/ToolButton.vue';

import { mapState } from 'vuex';

export default {
  name: 'ProfileToolbelt',
  components: {
    MdiChevronDown,
    MdiClose,
    MdiContentSave,
    MdiHeart,
    MdiHeartOutline,
    MdiPlay,
    MdiSettings,
    MdiShare,
    ToolButton,
  },
  props: {
    lowerState: {
      default: 'preview',
      type: String,
    },
    type: {
      type: String,
      default: 'publicLove',
    },
  },
  data() {
    return {
      rightButton: {
        editing: {
          icon: 'mdi-content-save',
          text: 'SAVE',
          svgClass: 'svg-positive',
          textClass: 'text-positive'
        },
        default: {
          icon: 'mdi-share',
          text: 'SHARE THIS',
          svgClass: 'svg-neutral',
          textClass: 'text-neutral'
        },        
      },
      leftButton: {
        editing: {
          icon: 'mdi-close',
          text: 'CANCEL',
          svgClass: 'svg-negative',
          textClass: 'text-negative'
        },
        publicPlayable: {
          icon: 'mdi-play',
          text: 'PLAY NOW',
          svgClass: 'svg-positive',
          textClass: 'text-positive'
        },
        publicLove: {
          icon: 'mdi-heart-outline',
          text: 'SEND LOVE',
          svgClass: 'svg-negative',
          textClass: 'text-negative'
        },
        publicLoveSent: {
          icon: 'mdi-heart',
          text: 'LOVE SENT',
          svgClass: 'svg-negative-disabled',
          textClass: 'text-negative-disabled'
        },
        private: {
          icon: 'mdi-settings',
          text: 'YOUR SETTINGS',
          svgClass: 'svg-white',
          textClass: 'text-white'
        }
      },
      entityCache: null,
      settingsCache: null,
    };
  },
  computed: {
    ...mapState(['editingProfile', 'entity', 'unit']),
    leftButtonState() {
      const state = this.editingProfile ? 'editing' : this.type;
      return this.leftButton[state];
    },
    rightButtonState() {
      const state = this.editingProfile ? 'editing' : 'default';
      return this.rightButton[state];
    },
    nameKey() {
       return this.entity.type === 'user' ? 'full_name' : 'name';
    }
  },
  created() {
    this.$eventBus.$on('emit-toggle-setting', this.toggleProfileSetting);
    this.$eventBus.$on('new-name', this.handleNewName);
    this.$eventBus.$on('new-location', this.handleNewLocation);
    // this.resetCaches();
  },
  mounted() {
    if (this.entity) {
      this.resetCaches();
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('emit-toggle-setting');
    this.$eventBus.$off('new-name');
    this.$eventBus.$off('new-location');
  },
  methods: {
    pause(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    handleNewName(newName) {
      this.entityCache[this.nameKey] = newName;
    },
    handleNewLocation({ key, value }) {
      this.entityCache[key] = value;
    },
    handleLeftButtonClick() {
      if (this.type === 'publicLove') {
        this.$emit('toggle-profile-love', true);
      } else if (this.type === 'publicLoveSent') {
        return;
      } else if (this.type === 'private' && !this.editingProfile) {
        this.$store.dispatch('toggleEditingProfile', { bool: true });
      } else if (this.type === 'private' && this.editingProfile) {
        this.resetCaches();
        this.$store.dispatch('toggleEditingProfile', { bool: false });
      } else if (this.type === 'publicPlayable') {
        this.$eventBus.$emit('handle-toolbelt-play');
      } else {
        return;
      }
    },
    resetCaches() {
      this.settingsCache = {...this.entity.settings};
      const [city, state, country] = this.entity.location.split(', ');
      this.entityCache = {
        [this.nameKey]: this.entity.name,
        city,
        state,
        country,
        image_url: this.entity.image_url,
      }
    },
    validateEntityInput() {
      const keys = Object.keys(this.entityCache);
      console.log({ keys })
      const empty = keys.filter(key => this.entityCache[key].length == 0);
      empty.forEach(key => {
        if (key !== 'full_name') {
          this.entityCache[key] = this.entity[key];
        } else {
          this.entityCache[key] = this.entity.name;
        }
      });

      this.entityCache.image_url = 'mics.jpg';
    },
    async saveProfileEdits() {
      this.validateEntityInput();
      const userId = this.$store.state.user.id;
      const type = this.entity.type;
      const id = this.entity.id;
      const url = `/entities/${type}/${id}?user-id=${userId}`;

      const data = {
        entity: this.entityCache,
        settings: this.settingsCache,
      };

      const response = await this.$node.put(url, data);
      this.$store.commit('setEntity', { entity: response.data });
      this.$store.commit('setEditingProfile', { bool: false });
    },
    shareProfile() {
      const newState = this.lowerState === 'share' ? 'preview' : 'share';
      const vars = {
        share: {
          icon: 'mdi-chevron-down',
          text: 'CLOSE SHARE TOOL',
          svgClass: 'svg-white',
          textClass: 'text-white'
        }, 
        preview: {
          icon: 'mdi-share',
          text: 'SHARE THIS',
          svgClass: 'svg-neutral',
          textClass: 'text-neutral'
        }, 
      };
      this.$emit('toggle-share-tool', newState);
      setTimeout(() => {
        this.rightButton.default = vars[newState];    
      }, 100);
    },
    handleRightButtonClick() {
      console.log('handling');
      const handle = this.editingProfile
        ? this.saveProfileEdits
        : this.shareProfile;
      handle();
    },
    toggleProfileSetting({ setting, showing }) {
      this.settingsCache[setting] = showing;
    }
  },
};
</script>


<style scoped lang="scss">
[v-cloak] > * {
  display: none;
}

.profile-toolbelt {
  display: flex;
  justify-content: center;
  background-color: black;
  border-top: 1px solid var(--stroke-white);
}

.pt__wrap {
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  // max-width: 480px;
  overflow: hide;
  padding: 10px;
  > div {
    height: 100%;
  }
}

.pt__btn {
  display: flex;
  align-items: center;
  // width: 50%;
  padding: 0 12px;
  cursor: pointer;
  &--right {
    justify-content: flex-end;
  }
}

svg {
  width: 20px;
  height: 20px;
}

.impact-preview {
  flex: 0 0 auto;
  width: 40px;
}
</style>
