<template>
  <div class="library-list">
    <div class="library-summary">
      <div
        class="title-1 text-white"
        style="margin-bottom: 32px;"
      >
        Library
      </div>
      <div
        class="body-2 text-white"
        style="margin-bottom: 4px;"
      >
        Supporting:
      </div>
      <div
        class="body-2 text-sec-white"
        style="margin-bottom: 16px;"
      >
        {{ artistSummary.join(", ") }}
      </div>
      <div class="summary-block">
        <div class="img-stack">
          <img
            v-for="(image,i) in imageSummary"
            :key="'library-summary-' + i"
            :src="image"
          >
        </div>
        <div class="body-2 text-white">
          {{ numTracks }} Tracks
        </div>
      </div>
    </div> 
    <MediaCard
      v-for="(item,i) in list.slice(1,8)"
      :active-filters="activeFilters"
      :active-sort="activeSort"
      class="ll--card-border"
      :item="item"
      :key="'media-card-' + i"
    />
  </div>
</template>

<script>
import MediaCard from "@/components/entities/MediaCard.vue";

export default {
  name: "LibraryList",
  props: {
    activeFilters: {
      required: true,
      type: Array,
    },
    activeSort: {
      required: true,
      type: String,
    },
    artistSummary: {
      required: true,
      type: Array,
    },
    imageSummary: {
      required: true,
      type: Array,
    },
    list: {
      required: true,
      type: Array,
    }
  },
  components: {
    MediaCard
  },
  computed: {
    assets() {
      let assets = this.list.map(bundle => bundle.tracks);
      assets = this.$_.flattenDeep(assets);
      return [...new Set(assets)];
    },
    numTracks() {
      return this.assets.length;
    }
  },
}
</script>

<style lang="scss" scoped>
.library-list {
  display: flex;
  flex-direction: column;
  height: calc(100% + 10px);
  overflow-y: scroll; 
}

.ll--card-border {
  border-top: 1px solid #2d2d2d;
}

.library-summary {
  display: block;
  min-height: 350px;
  margin-bottom: 40px;
}

.summary-block {
  display: flex;
  justify-content: space-between;
}

.img-stack {
  position: relative;
  z-index: 10;
  height: 218px;
  width: 218px;
  img {
    position: absolute;
    height: 170px;
    width: 170px;
    object-fit: cover;
    object-position: center center;
    background-color: rgba(0, 0, 0, 0.25);
  }

  :nth-child(1) {
    z-index: 7;
    left: 0px;
    top: 0px;
  }
  :nth-child(2) {
    z-index: 8;
    left: 16px;
    top: 16px;
  }
  :nth-child(3) {
    z-index: 9;
    left: 32px;
    top: 32px;
  }
  :nth-child(4) {
    z-index: 10;
    left: 48px;
    right: 0px;
    top: 48px;
    bottom: 0px;
  }
}
</style>
