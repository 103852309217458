<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9999 2C6.47724 2 2 6.47723 2 12.0001C2 17.5231 6.47724 22 11.9999 22C17.5232 22 22 17.5231 22 12.0001C22 6.47759 17.5232 2.00048 11.9998 2.00048L11.9999 2ZM16.5858 16.423C16.4067 16.7167 16.0222 16.8099 15.7285 16.6295C13.3806 15.1954 10.4249 14.8706 6.94403 15.6659C6.6086 15.7423 6.27424 15.5321 6.19782 15.1966C6.12104 14.861 6.33037 14.5266 6.66663 14.4502C10.4759 13.5796 13.7434 13.9546 16.3793 15.5656C16.673 15.7459 16.7662 16.1292 16.5858 16.423ZM17.8098 13.6997C17.5841 14.0669 17.1041 14.1827 16.7375 13.957C14.0495 12.3044 9.95214 11.826 6.77279 12.7911C6.36046 12.9156 5.92496 12.6832 5.79982 12.2716C5.67563 11.8593 5.90812 11.4246 6.31974 11.2992C9.95142 10.1973 14.4663 10.731 17.5531 12.6279C17.9197 12.8536 18.0355 13.3337 17.8098 13.6998V13.6997ZM17.9149 10.8643C14.692 8.94996 9.37454 8.77394 6.29741 9.70789C5.80328 9.85775 5.28073 9.5788 5.13099 9.08466C4.98125 8.59028 5.25995 8.06808 5.75444 7.91785C9.28677 6.84551 15.1589 7.05269 18.8695 9.25554C19.3149 9.51933 19.4606 10.0934 19.1967 10.5372C18.934 10.9817 18.3584 11.1282 17.9154 10.8643H17.9149Z" fill="#1ED760"/>
  </svg>
</template>

<script>
export default {
  name: 'SpotifyLogo',
};
</script>

<style scoped>
</style>
