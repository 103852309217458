<template>
  <div class="qa-module-expanded">
    <IconButton
      v-if="isMyProfile || isMyEntity"
      :class="[
        'edit-btn',
        editing ? 'filter-btn--active' : 'filter-btn--inactive'
      ]"
      icon="mdi-pencil"
      @click="editing = !editing"
    />
    <QaHead
      class="qme__head"
      icon="mdi-chevron-down"
    />
    <QaFeature class="qme__feature" />
    <div class="qme__list">
      <QaEntityMenu
        v-if="isMyProfile || isMyEntity"
        :asked-filter="askedFilter"
        :answered-filter="answeredFilter"
        :mode="mode"
        @filter-asked="filterAsked"
        @filter-answered="filterAnswered"
        @toggle-mode="handleToggleMode"
      />
      <QaThread
        v-for="question in activeQuestions"
        :editing="editing"
        :key="question.question_id + (Math.random()).toFixed(2)"
        :question="question"
      />
      <div
        v-if="showEmptyMessage"
        class="text-white action-2"
        style="text-align: center;"
      >
        {{ 
          mode === 'hidden'
            ? 'You have not hidden any questions.' 
            : 'There are no questions in your inbox.'
        }}
      </div>
    </div>
    <QaPrompt
      v-if="questionPrompt"
      class="qme__prompt"
      :question="questionPrompt"
    />
  </div>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";
import QaEntityMenu from '@/components/qa/QaEntityMenu.vue';
import QaFeature from '@/components/qa/QaFeature.vue';
import QaHead from '@/components/qa/QaHead.vue';
import QaPrompt from '@/components/qa/QaPrompt.vue';
import QaThread from '@/components/qa/QaThread.vue';

import { mapState } from 'vuex';

export default {
  name: "QaModuleExpanded",
  components: {
    IconButton,
    QaEntityMenu,
    QaFeature,
    QaHead,
    QaPrompt,
    QaThread,
  },
  props: {
    isUnit: {
      default: false,
      type: Boolean,
    },
    state: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      askedFilter: false,
      answeredFilter: false,
      editing: false,
      hiddenIxs: [],
      mode: 'public',
    }
  },
  computed: {
    ...mapState(['entity', 'featuredQa', 'hiddenQaIds', 'qa', 'user']),
    isMyProfile() {
      return (this.qa.type === 'entity') && (this.qa.entity.type === 'user') && (this.user.id === this.entity.id);
    },
    isMyEntity() {
      if (this.qa.type === 'unit' || this.qa.entity.type === 'user') return;
      const admins = this.entity.members.filter(m => m.role === 'admin');
      const me = admins.find(a => a.id === this.userId);
      return Boolean(me);
    },
    questions() {
      return this.qa.questions;
    },
    activeQuestions() {
      return this[`${this.mode}Questions`];
    },
    askedQuestions() {
      const askedQuestions = this.questions.filter(question => {
        return question.owner.name === this.user.name;
      });

      return askedQuestions;
    },
    answeredQuestions() {
      const answeredQuestions = this.questions.filter(question => {
        const answered = question.answers.filter(answer => {
          return answer.owner.full_name === this.user.name;
        })
        return answered.length;
      });

      return answeredQuestions;
    },
    filteredQuestions() {
      let filteredQuestions;
      if (!this.askedFilter && !this.answeredFilter) {
        filteredQuestions = this.questions;
      } else if (this.askedFilter && this.answeredFilter) {
        filteredQuestions = this.askedQuestions.concat(this.answeredQuestions);
      } else if (this.askedFilter) {
        filteredQuestions = this.askedQuestions;
      } else if (this.answeredFilter) {
        filteredQuestions = this.answeredQuestions;
      }

      return filteredQuestions;
    },
    hiddenQuestions() {
      const hiddenQuestions = this.filteredQuestions.filter(q =>  {
        const ids = this.getAllIds(q)
        const isHidden = ids.some(id => this.hiddenQaIds.includes(id));
        return isHidden;
      });

      return hiddenQuestions;
    },
    inboxQuestions() {
      return this.filteredQuestions.filter(question => question.recipients);
    },
    publicQuestions() {
      const publicQuestions = this.$_.difference(
        this.filteredQuestions,
        this.hiddenQuestions
      );
      return publicQuestions;
    },
    questionPrompt() {
      // TO-DO: Update API to make this possible
      // Filter this.questions for questions asked of current user
      // Pick one
      // Return it
      return this.questions[0];
    },
    showEmptyMessage() {
      return (this.mode !== 'public') && !this.activeQuestions.length;
    }
  },
  methods: {
    filterAsked() {
      this.askedFilter = !this.askedFilter;
    },
    filterAnswered() {
      this.answeredFilter = !this.answeredFilter;
    },
    getAllIds(question) {
      let ids = [question.question_id];
      const answerIds = question.answers.map(a => a.id);
      return ids.concat(answerIds);
    },
    handleToggleMode(tab) {
      this.mode = tab;
    }
  }
};
</script>

<style lang="scss" scoped>
.qa-module-expanded {
  position: relative;
  height: inherit;
  overflow-y: scroll;
  padding: 20px 20px 32px;
  background-color: var(--primary);
  @include for-size(medium-screen-up) {
    min-width: 768px;
    display: grid;
    grid-template-rows: 1fr 3fr 3fr;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    z-index: 5;
  }
  @include for-size(small-desktop-up) {
    min-width: 1024px;
    display: grid;
    grid-template-rows: 1fr 6fr;
    grid-template-columns: repeat(3, 1fr);
  }
}

.qme__head {
  @include for-size(medium-screen-up) {
    grid-column: 1 / 3;
  }
  @include for-size(small-desktop-up) {
    grid-column: 1 / 4;
  }
}

.qme__feature {
  @include for-size(medium-screen-up) {
    grid-row: 2;
    grid-column: 1;
  }
}

.qme__list {
  @include for-size(medium-screen-up) {
    grid-row: 2 / 4;
    grid-column: 2;
  }
}

.qme__prompt {
  @include for-size(medium-screen-up) {
    grid-row: 3;
    grid-column: 1;
  }
  @include for-size(small-desktop-up) {
    grid-row: 2;
    grid-column: 3;
  }
}

.edit-btn {
  position: absolute;
  top: 20px;
  right: 64px;
}
</style>
