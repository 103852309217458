<template>
  <div
    v-if="entity"
    class="entity-preview"
  >
    <div class="ep__title">
      {{ entity.name }}
    </div>

    <div :class="['ep__content', contentClass]">
      <div :class="['content-image', imageClass]">
        <img :src="entity.image_url">
      </div>
      <div
        v-if="type === 'user'"
        class="body-2 text-white"
        style="grid-column: 2; text-align: left; overflow-y: hidden; max-height: 162px;"
      >
        {{ entity.bio }}
      </div>
      <div
        v-if="type === 'series'"
        class="body-2 text-white content-text--series"
        style="overflow-y: hidden; max-height: 162px;"
      >
        {{ entity.description }}
      </div>
      <div v-if="type === 'ensemble'">
        <div
          class="tag text-sec-white"
          style="margin-bottom: 6px;"
        >
          MEMBERS
        </div>
        <template v-for="member in sortedMembers.slice(0,4)">
          <EntityChip
            v-if="member.image_url"
            :key="`rb-credit-${member.id}`"
            class="ep__item-margins"
            :name="member.name === 'Jeffrey Riggs' ? 'Rick Ross' : member.name"
            :image="member.image_url"
            size="xs"
            theme="white"
          />
          <div
            v-else
            :key="member.id"
            class="body-2 text-white"
          >
            {{ member.name }}
          </div>
        </template>
        <div
          v-if="entity.members.length > 4"
          class="ep__remainder-counter tag"
        >
          +{{ entity.members.length - 4 }}
        </div>
      </div>
    </div>

    <div class="ep__details">
      <div class="ep__reaction">
        <div class="reaction-preview">
          <img :src="require('@/assets/png/reaction-preview.png')">
        </div>
      </div>
      <QaPreviewSummary
        v-if="entity.questions"
        class="ep__qps"
        small
        :questions="entity.questions"
      />
      <div class="ep__bundle">
        <MdiCheckboxMultiple />
      </div> 
      <button
        class="action-1"
        style="cursor: pointer; z-index: 1000;"
        @click="goToProfile"
      >
        View
      </button>
    </div>
  </div>
</template>

<script>
import EntityChip from '@/components/entities/EntityChip.vue';
import MdiCheckboxMultiple from '@/components/graphics/MdiCheckboxMultiple';
import QaPreviewSummary from '@/components/qa/QaPreviewSummary.vue';

export default {
  name: 'EntityPreview',
  components: {
    EntityChip,
    MdiCheckboxMultiple,
    QaPreviewSummary,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['user', 'series', 'ensemble'].indexOf(value) !== -1;
      }
    },
    entity: { 
      type: Object,
      required: true,
    },
  },
  computed: {
    contentClass() {
      return `ep__content--${this.type}`;
    },
    imageClass() {
      return `content-image--${this.type}`;
    },
    sortedMembers() {
      if (this.type !== 'ensemble') {
        return;
      }
      const members = this.entity.members.slice();
      return members.sort((a, b) => (a.image_url < b.image_url) ? 1 : -1);
    }
  },
  methods: {
    goToProfile() {
      console.log('go to profile');
      // const { entityType, entityId } = this.$route.params;
      // this.$router.push({
      //   name: 'profile',
      //     params: { entityType: this.entity.type, entityId: this.entity.id }
      // });
    },
  },
}
</script>

<style lang="scss" scoped>
.entity-preview {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding: 8px;
  background-color: black;
  width: calc(100% - 20px);
  height: 245px;
  > * { width: inherit; }
}

.ep__title {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
}

.ep__content {
  display: grid;
  grid-template-rows: minmax(min-content, max-content);
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5%;
  margin-bottom: 10px;
}

.content-image {
  width: 162px;
  height: 162px;
  margin: 0 auto;
  box-shadow: 0px 0px 100px rgba(255, 255, 255, 0.25);
  border-radius: 12px;
  overflow: hidden;
  grid-column: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-image--user {
  border-radius: 50%;
}

.content-image--series {
  grid-column: 2;
}

.content-text--series {
  grid-row: 1;
  grid-column: 1;
  width: 180px;
  padding: 10px 12px;
  margin: 0 auto;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.12);
  text-align: left;
}

.ep__item-margins {
  margin-right: 6px;
  margin-bottom: 4px;
}

.ep__remainder-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  background-color: var(--stroke-white);
  color: var(--secondary-white);
}

.ep__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 4px 0px;
  > * {
    height: 32px;
  }
  .ep__reaction {
    padding: 0px 8px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
  .ep__qps {
    max-width: 120px;
    padding-left: 4px;
  }
  .ep__bundle {
    padding-left: 8px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.reaction-preview {
  width: 25px;
  height: 22px;
  margin-left: 4px;
  margin-right: 4px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

button {
  height: 32px;
  padding: 2px 10px;
  background-color: rgba(53, 167, 255, 0.1);
  border-radius: 20px;
  font-weight: bold;
  color: var(--neutral);
  cursor: pointer;
}
</style>
