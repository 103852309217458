<template>
  <div class="rp-bg">
    <head>
      <div
        class="new-idealog-logo"
        style="cursor: pointer;"
        @click="$router.push({name: 'unit-list'})"
      >
        <img :src="require('@/assets/png/idealog-logo-trans.png')">
      </div>
      <a
        class="close-btn"
        @click="$router.go(-1)"
      >
        <MdiClose />
      </a>
    </head>

    <div
      v-cloak
      class="rp__container"
    >
      <div class="title-2 text-white">
        Forgot Password
      </div>
      <div
        class="body-2 text-sec-white"
        style="margin-bottom: 18px;"
      >
        Enter your email address to send recovery email
      </div>
      <input
        v-model="$v.email.$model"
        class="headline"
        placeholder="EMAIL"
      >
      <div style="display: flex; justify-content: flex-end;">
        <text-button
          class="btn--neutral btn--pill text-center"
          :disabled="!this.$v.email.required"
          style="width: 65px;"
          @click="validateCredentials()"
        >
          Send
        </text-button>
      </div>
      <ul
        v-if="errors"
        class="si__errors"
      >
        <li
          v-for="(error, index) in errors"
          :key="index"
          class="tag"
        >
          {{ error }}
        </li>
      </ul>
      <div
        v-if="linkSent"
        class="text-footnote text-sec-white"
        style="padding-top: 16px;"
      >
        We have sent you an email with a link to reset your password.
        <br>
        Redirecting you to the sign-in page...
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import MdiClose from '../graphics/MdiClose.vue';
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'ResetPassword',
  components: {
    MdiClose,
    TextButton,
  },
  validations: {
    email: { required, email },
  },
  data() {
    return {
      errors: null,
      email: null,
      linkSent: false,
    };
  },
  methods: {
    validateCredentials() {
      this.errors = [];
      const vm = this;

      if (vm.$v.email.$invalid) {
        vm.errors.push('INVALID EMAIL ADDRESS');
      }

      if (!vm.errors || (vm.errors.length == 0)) {
        this.handleForgotPassword();
      }
    },
    handleForgotPassword() {
      console.log('handle');
      const url = 'admin/password.json';
      // TO-DO: REPLACE WITH POST REQUEST TO NODE API
      this.$http.post(url, { user: { email: this.email } })
        .then(() => new Promise((resolve) => {
          this.linkSent = true;
          resolve();
        }))
        .then(() => {
          setTimeout(() => {
            this.$router.push({ name: 'signin' });
          }, 2000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] > * {
  display: none;
}

.rp-bg {
  background-color: var(--primary);
  height: 100vh;
}

.rp__container {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  margin: 0 auto;
}

head {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 160px;
  padding: 20px 20px 0px 20px;
}

input {
  width: 100%;
  padding: 6px 8px;
  background-color: #1f1f1f;
  border: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #7e7e7e;
  margin-bottom: 56px;
}

.si__errors {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  justify-content: space-around;
  padding: 10px 0;
  color: var(--negative);
  li {
    margin-bottom: 5px;
  }
}


button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.close-btn {
  fill:white;
  height: 40px;
  width: 40px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-idealog-logo {
  width: 45px;
  height: 30px;
  padding: 0;
  align-self img {
    width: 100%;
    height: auto;
  }
}
</style>
