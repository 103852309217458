<template>
  <div class="tc__title">
    <div class="tc__text">
      <div class="tag text-white">
        {{ cardTitle }}
      </div>
      <div
        v-if="this.type === 'budget'"
        class="subtitle text-positive"
      >
        {{ (item.total / 100).toLocaleString('en-US', this.currencyFormat) }}
      </div>
      <div
        v-if="this.type === 'contract'"
        class="subtitle"
      >
        <span class="text-positive">
          {{ item.ensemble_share }}% 
        </span>
        <span class="text-sec-white">
          to {{ credits.ensemble.name }}, 
        </span>
        <span class="text-positive">
          {{ 100 - item.ensemble_share }}% 
        </span>
        <span class="text-sec-white">
          to {{ credits.series.name }}
        </span>
      </div>
      <div
        v-if="this.type === 'event'"
        class="subtitle text-white"
      >
        <MdiCalendarClock
          style="height: 14px; width: 14px; fill: rgba(255,255,255,0.7);"
        /> 
        &nbsp;{{ this.$moment(item.date).format("MMMM D, YYYY") }}
      </div>
      <div
        v-if="this.type === 'release'"
        class="subtitle text-sec-white"
      >
        Released {{ this.$moment(item.date).format("MMMM D, YYYY") }}
      </div>
      <div
        v-if="this.type === 'financials'"
        class="subtitle"
      >
        <span class="text-positive">
          {{ (item.totalSales).toLocaleString('en-US', this.currencyFormat) }}
        </span>
        <span class="text-warning">
          {{ pctProfit }}
        </span>
      </div>
    </div>
    <component
      :is="titleIcon"
      @click="handleTitleIconClick"
    />
  </div>
</template>

<script>
import MdiCalendarClock from '@/components/graphics/MdiCalendarClock.vue';
import MdiChevronDown from '@/components/graphics/MdiChevronDown.vue';
import MdiChevronUp from '@/components/graphics/MdiChevronUp.vue';
import MdiInformation from '@/components/graphics/MdiInformation.vue';

export default {
  name: 'UnitTimelineCardTitle',
  components: {
    MdiCalendarClock,
    MdiChevronDown,
    MdiChevronUp,
    MdiInformation,
  },
  props: {
    assetTitle: {
      type: String,
      default: 'FIRST RELEASE'
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    inCarousel: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencyFormat: {
        style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
      }
    }
  },
  computed: {
    cardTitle() {
      const titles = {
        budget: 'PROJECT BUDGET',
        contract: 'CONTRACT',
        event: (this.item.title || 'SUMMER CONCERT').toUpperCase(),
        release: this.assetTitle.toUpperCase(),
        financials: 'INVESTMENT & RECOUP'
      }

      return titles[this.item.type];
    },
    pctProfit() {
      if (this.type !== 'financials') {
        return;
      }
      if (this.item.investment === 0) {
        return `net of investment`;
      }
      const pct = parseInt(this.item.totalSales / this.item.investment - 1);
      return `${pct}% vs. investment`;
    },
    credits() {
      return this.$store.state.unit.project;
    },
    titleIcon() {
      if (this.inCarousel) {
        return 'mdi-information';
      } else if (!this.isExpanded) {
        return 'mdi-chevron-down';
      } else {
        return 'mdi-chevron-up';
      }
    },
    type() {
      return this.item.type;
    }
  },
  methods: {
    handleTitleIconClick() {
      if (this.inCarousel) {
        return;
      }
      this.$emit('toggle-card');
    }
  }
}
</script>

<style lang="scss" scoped>
.tc__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

svg {
  fill: var(--hint-white);
}

.subtitle {
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  font-style: italic;
}
</style>

