<template>
  <div class="card-panel">
    <div
      v-for="(card, i) in cards"
      :key="'card-panel-' + i"
      :class="['card', `card-${card.position}`, `bg-theme-${card.theme}`]"
      @click="handleCardClick(card.type)"
    >
      <div :class="['text', `text-${card.theme}`, `text-${card.position}-card`]">
        {{ card.text }}
      </div>
      <IconButton
        :icon="card.icon"
        :class="[`icon-btn--${card.theme}-tint`, `icon-${card.position}-btn`]"
      />
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';
export default {
  name: 'CardPanel',
  components: {
    IconButton,
  },
  props: {
    cards: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleCardClick(type) {
      this.$emit('handle-initial-choice', type);
    }
  }
}
</script>

<style lang="scss" scoped>
.card-panel {
  display: flex;
  width: 100%;
  height: 80px;
  background-color: black;
}

.card {
  padding: 20px;
  flex: 1;
  display: grid;
  align-items: center;
  grid-template-rows: 100%;
  grid-template-columns: minmax(min-content, max-content);
  grid-column-gap: 12px;
  height: 100%;
  cursor: pointer;
  > div {
    grid-row: 1;
  }
}

.bg-theme-white {
  background-color: black;
}

.bg-theme-tip {
  background-color: var(--tip-tint);
}

.card-right {
  justify-content: end;
}

.icon-right-card, .text-left-card {
  text-align: right;
  grid-column: 2;
}

.icon-left-card, .text-right-card {
  grid-column: 1;
}

.text {
  font-weight:500;
  font-size:12px;
  line-height:18px;
  @include for-size(medium-screen-up) {
    font-weight:bold;
    font-size:16px;
    line-height:24px;
  }
}
</style>

