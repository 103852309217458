<template>
  <div class="support-channel">
    <div class="headline text-sec-white">
      Placeholder until BundlePreviews are built
    </div>
  </div>
</template>

<script>


export default {
  name: 'SupportChannel',
}
</script>

<style lang="scss" scoped>
</style>

