<template>
  <div class="budget-block text-white">
    <div class="bb__row">
      <div class="title-2 text-positive">
        {{ (details.total / 100).toLocaleString('en-US', currencyFormat) }}
      </div>
      <div
        class="text-footnote text-sec-white"
        style="margin-left: 40px;"
      >
        {{ details.total_vs_avg }} Higher than AVG on Platform
      </div>
    </div>
    <div
      v-for="budgetItem in details.items"
      :key="`bb-item-${budgetItem.label}`"
      class="bb__row"
    >
      <div class="bb__item-label">
        {{ budgetItem.label }}
      </div>
      <div class="tag text-sec-white">
        {{ (budgetItem.amount / 100).toLocaleString('en-US', currencyFormat) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BudgetBlock',
  props: {
    details: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      currencyFormat: {
        style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.budget-block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.bb__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

// margin-right, font color and size
.bb__total {
}

// font color, size, italics
.bb__vs-avg {
  text-align: right;
}

.bb__item-label {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: var(--secondary-white);
}
</style>

