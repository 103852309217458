<template>
  <div
    ref="ic"
    class="index__container"
  >
    <IndexNav
      v-if="!loading"
      :class="['index-nav', { 'with-index-feed-summary': showIndexFeedSummary}]"
      name="idealog"
      :user="user"
    />

    <transition name="menu-opacity">
      <IndexMenu
        v-if="showFilterMenu"
        :is-active="isActive"
        :on-mobile="onMobile"
        :on-desktop="onDesktop"
      />
    </transition>

    <IndexFeedSummary
      v-if="showIndexFeedSummary"
      :class="['index-feed-summary', { 'hide': !showIndexFeedSummary}]"
      :is-active="isActive"
      :select-user-control="selectUserControl"
      @toggle-control-on="handleToggleControl"
    />

    <div
      v-if="onMobile"
      :class="['index__header',{
        'menu-is-collapsed': !showFilterMenu,
        'initial-height': showInitialChoice,
        'summary-height': !showInitialChoice && !showFilterMenu,
      }]"
    >
      <IndexInitialChoice
        v-if="showInitialChoice && !loading"
        @toggle-control="handleToggleControl"
      />
    </div>

    <div
      v-if="!onMobile && showInitialChoice && endDelay"
      class="desktop-user-choice"
    >
      <div
        class="duc-btn body-1 text-white croll"
        @click="handleToggleControl(false)"
      >
        Keep scrolling to load more of the curated feed
      </div>
      <div
        class="duc-btn body-1 text-white control"
        @click="handleToggleControl(true)"
      >
        Take control of the algorithm
      </div>
    </div>

    <div
      ref="sc"
      :class="[
        'index__scroll-container',
        { 'with-desktop-menu': (showFilterMenu && !onMobile) || (!onMobile && showInitialChoice && endDelay) }
      ]"
      @scroll="checkScroll"
    >
      <div
        v-if="this.show"
        ref="cc"
        class="index__card-container"
        @scroll="checkScroll"
      >
        <div
          v-if="!onMobile"
          class="dim__promo"
        >
          <component
            v-if="show.length"
            :is="getCardType(show[0])"
            :key="show[0].unit_id + '-card'"
            class="index__cards promo-hero"
            :unit="show[0]"
          />

          <div class="promo-secondary">
            <component
              v-for="item in this.show.slice(1,3)"
              :is="getCardType(item)"
              :key="item.unit_id + '-card'"
              class="index__cards promo-twins"
              :unit="item"
            />
          </div>
        </div>

        <MasonryLayout
          :columns="numberOfMasonryColumns"
          :gap="20"
          :items="onMobile ? show : show.slice(3)"
        />
      </div>
    </div>

    <div
      v-if="!onMobile"
      :class="[{'with-menu': showFilterMenu },'desktop-gradient']"
    />

    <div
      v-if="onMobile"
      class="index__tools-gradient"
    />
    <IndexTools
      v-if="onMobile"
      class="index__tools"
      @sort="applySort"
    />
  </div>
</template>

<script>
import {
  MdiCamera,
  MdiList,
  MdiMusic,
  MdiVideoCam,
} from '@/components/graphics';
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import ImageCard from '@/components/unit/ImageCard.vue';
import IndexFeedSummary from '@/components/index/IndexFeedSummary.vue';
import IndexMenu from '@/components/index/IndexMenu.vue';
import IndexInitialChoice from '@/components/index/IndexInitialChoice.vue';
import IndexNav from '@/components/index/IndexNav.vue';
import IndexTools from '@/components/tools/IndexTools.vue';
import MasonryLayout from '@/components/shared/MasonryLayout.vue';
import MusicCard from '@/components/unit/MusicCard.vue';
import RelatedMenu from '@/components/unit/RelatedMenu.vue';
import TextButton from '@/components/buttons/TextButton.vue';
import TextCard from '@/components/unit/TextCard.vue';
import UnitPreview from '@/components/unit/UnitPreview.vue';
import { mapState } from 'vuex';

export default {
  name: 'AppIndex',


  components: {
    AvatarIcon, ImageCard, IndexFeedSummary, IndexMenu, IndexInitialChoice, IndexNav, MusicCard, RelatedMenu, IndexTools, MasonryLayout, MdiCamera, MdiList, MdiMusic, MdiVideoCam, TextButton, TextCard, UnitPreview
  },


  data() {
    return {
      // allIds: [11, 14, 58, 72, 113, 128, 136, 139, 168, 172, 173, 178, 179],
      cardsScrolled: false,
      endDelay: false,
      localLoading: false,
      showInitialChoice: true,
      showFilterMenu: false,
      selectUserControl: null,
      isActive: {
        follow: false,
        follower: false,
        creator: true,
        curator: true,
        audio: true,
        image: true,
        article: true,
        video: true,
      },
      unitsByRelationship: {
        follow: [],
        follower: [],
        mutual: [],
        all: [],
      },
      unitTypes: ['audio', 'image', 'article', 'video'],
      typeIcons: {
        audio: 'mdi-music',
        image: 'mdi-camera',
        article: 'mdi-list',
        video: 'mdi-video-cam',
      },
      show: [],
      windowWidth: 0,
    };
  },


  computed: {
    ...mapState(['loading', 'units', 'user']),

    activeTypes() {
      const active = this.unitTypes.filter(type => this.isActive[type]);
      return active;
    },

    numberOfMasonryColumns() {
      if (this.onMobile) {
        return 1;
      } if (this.onDesktop) {
        return 3;
      }
      return 2;
    },

    onMobile() {
      return this.windowWidth < 640;
    },

    onDesktop() {
      return this.windowWidth >= 1120;
    },

    showIndexFeedSummary() {
      if (this.onMobile) {
        return !this.showFilterMenu && !this.showInitialChoice;
      }
      return !this.showInitialChoice;
    },
  },

  created() {
    this.$store.dispatch('LOAD_UNITS');
    this.$eventBus.$on('desktop-tool-sort', this.applySort);
    this.$eventBus.$on('filter-index', this.updateFilterStatus);
    this.$eventBus.$on('emit-toggle-control-off', this.handleToggleControl);
    window.addEventListener('resize', this.calculateWidthAndVH);
    this.calculateWidthAndVH();
  },

  mounted() {


  },

  beforeDestroy() {
    this.$eventBus.$off('desktop-tool-sort');
    this.$eventBus.$off('filter-index');
    this.$eventBus.$off('emit-curated');
    window.removeEventListener('resize', this.calculateWidthAndVH);
  },

  watch: {
    user: {
      handler() {
        this.categorizeUnitsByRelationship();
      },
    },
  },


  methods: {
    calculateWidthAndVH() {
      this.calculateWidth();
      this.calculateVH();
    },

    calculateWidth() {
      this.windowWidth = window.innerWidth;
    },

    calculateVH() {
      this.$_.debounce(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 500);
    },

    handleResize() {

    },
    // Called once, once this.user is loaded - divides units into mutually exclusive categories (units owned by follower, owned by follow, owned by 'mutual' (both follower and follow), or owned by anyone without relationship constraint)
    categorizeUnitsByRelationship() {
      this.localLoading = true;
      const cats = this.unitsByRelationship;
      let [followUnits, followerUnits, mutualUnits] = [[], [], []];

      this.user.follows.forEach((follow) => {
        const unitsOwnedByThisFollow = this.units.filter(unit => unit.owner.full_name == follow.name);

        followUnits.push(unitsOwnedByThisFollow);
      });

      this.user.followers.forEach((follower) => {
        const unitsOwnedByThisFollower = this.units.filter(unit => unit.owner.full_name == follower.name);

        followerUnits.push(unitsOwnedByThisFollower);
      });

      followUnits = this.$_.flattenDeep(followUnits);
      followerUnits = this.$_.flattenDeep(followerUnits);

      mutualUnits = this.$_.intersection(followUnits, followerUnits);

      cats.mutual = mutualUnits;
      cats.follow = this.$_.without(followUnits, mutualUnits);
      cats.follower = this.$_.without(followerUnits, mutualUnits);
      cats.all = this.units;

      // .then? do this async?
      this.handleFilterUpdate();

      this.localLoading = false;
    },

    // Callback when filter is toggled on IndexMenu. Simply updates the status for selected filter on AppIndex then calls event handler: handleFilterUpdate.
    updateFilterStatus(payload) {
      const { filters } = payload;
      const filtersList = ['follow', 'follower', 'creator', 'curator', 'audio', 'image', 'article', 'video'];
      const typeFilters = filters.filter(filter => filtersList.slice(4).includes(filter.name));
      console.log;

      filters.forEach((filter) => {
        const { name } = filter;
        const filterIsActive = filter.active;
        this.isActive[name] = filterIsActive;
      });
      // if (filtersList.includes(filterName)) {
      //   this.isActive[filterName] = filterIsActive;
      // }

      // if all unit_type filters are false, make them all true
      const numInactiveTypeFilters = typeFilters.filter((filter, i) => typeFilters[i].active == false).length;

      if (numInactiveTypeFilters == 4) {
        filtersList.slice(4).forEach((filter) => {
          this.isActive[filter] = true;
        });
      }

      this.handleFilterUpdate();
    },

    // Re-calculates which units to display based on most up-to-date set of activated filters.
    handleFilterUpdate() {
      const { isActive } = this;
      let [activeUnitsByRel, activeUnitsByRole, activeUnitsByUnitType] = [[], [], []];
      this.localLoading = true;


      if (isActive.follow && isActive.follower) {
        activeUnitsByRel.push(this.unitsByRelationship.mutual);
      } else if (!isActive.follow && !isActive.follower) {
        activeUnitsByRel.push(this.unitsByRelationship.all);
      } else if (isActive.follow) {
        activeUnitsByRel.push(this.unitsByRelationship.follow);
      } else {
        activeUnitsByRel.push(this.unitsByRelationship.follower);
      }

      activeUnitsByRel = this.$_.flattenDeep(activeUnitsByRel);


      const allRoles = (isActive.creator && isActive.curator) || (!isActive.creator && !isActive.curator);

      if (allRoles) {
        activeUnitsByRole = activeUnitsByRel;
      } else {
        activeUnitsByRole = activeUnitsByRel.filter(unit => unit['by_creator?'] == isActive.creator);
      }


      const activeTypes = this.unitTypes.filter(type => this.isActive[type]);

      if (activeTypes.includes('audio')) activeTypes.push('playlist');
      console.log({ activeUnitsByRole });
      console.log({ activeTypes });

      activeUnitsByUnitType = activeUnitsByRole.filter(unit => activeTypes.includes(unit.unit_type.toLowerCase()));

      console.log({ activeUnitsByUnitType });
      this.show = this.$_.flattenDeep(activeUnitsByUnitType);
      this.localLoading = false;
    },

    // Handles sorting event emitted from IndexTool. Sorts by recency or ranking in terms of superindices (heat, newsworthiness, effort).
    applySort(sort) {
      function compare(a, b) {
        let itemA; let
          itemB;

        if (sort == 'time') {
          itemA = Date.parse(a.created_at);
          itemB = Date.parse(b.created_at);
        } else {
          itemA = a.superindices[sort];
          itemB = b.superindices[sort];
        }

        let comparison = 0;

        if (itemA > itemB) {
          comparison = -1;
        } else if (itemA < itemB) {
          comparison = 1;
        }

        return comparison;
      }

      // "select" is the dice button - do nothing if this is the one clicked
      if (sort != 'select') {
        this.show = this.show.sort(compare);
      }
    },

    // Checks if card-container div (or its parent, the scroll-container div) has scrolled (scrollTop > 0) and collapses menu if so.
    checkScroll() {
      const cardContainerScrolled = this.$refs.cc.scrollTop;
      const cardsScrolled = Boolean(this.$refs.sc.scrollTop) || Boolean(cardContainerScrolled);


      if (cardsScrolled && this.onMobile) {
        this.showFilterMenu = false;
      } else if ((cardContainerScrolled > 250) && !this.onMobile && this.showInitialChoice) {
        this.endDelay = true;
      }
    },

    // Returns the correct unit preview component based on unit.unit_type.
    getCardType(unit) {
      if (unit.unit_type == 'Audio' || unit.unit_type == 'Playlist') {
        return 'music-card';
      } if (unit.unit_type == 'Image' || unit.unit_type == 'Video') {
        return 'image-card';
      } if (unit.unit_type == 'Article') {
        return 'TextCard';
      }
      return 'TextCard';
    },

    // Sets user choice to "control" or "curated" and affects menu state and header classes accordingly
    handleToggleControl(selectUserControl) {
      this.selectUserControl = selectUserControl;
      this.showFilterMenu = selectUserControl;
      this.showInitialChoice = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-gradient {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 116px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), #000000);

  @include for-size(phone-only) {
    display: none;
  }

  &.with-menu {
    bottom: 98px;
    transition: bottom 300ms ease-in-out;
  }
}

.dim__promo {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  .promo-hero {
    @include for-size(tablet-portrait-up) {
      height: 190px;
      margin-bottom: 12px;
    }
    @include for-size(tablet-landscape-up) {
      height: 300px;
      margin-bottom: 24px;
    }
  }

  .promo-secondary {
    display: flex;
    justify-content: space-between;
  }

  .promo-twins:first-child {
    margin-right: 16px;
  }

  .promo-twins {
    @include for-size(tablet-portrait-up) {
      height: 145px;
    }
    @include for-size(tablet-landscape-up) {
      height: 225px;
    }
  }
}

.desktop-user-choice {
  position: absolute;
  display: flex;
  // align-items: center;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1001;
  background-color: var(--primary);

  .duc-btn {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
    cursor: pointer;
  }

  .control {
    background-image: linear-gradient(rgba(164, 79, 255, 0) 0%, rgba(164, 79, 255, 0.3) 100%);
  }

  @include for-size(phone-only) {
    display: none;
  }
  @include for-size(tablet-portrait-up) {
    height: 101px;
  }
}

.index__container {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  background-color: var(--primary);

  @include for-size(phone-only) {
    padding: 15px 12px;
  }
  @include for-size(tablet-portrait-up) {
    padding-top: 32px;
    padding-right: 36px;
    padding-bottom: 32px;
    padding-left: 36px;
  }
  @include for-size(tablet-landscape-up) {
    padding-right: 48px;
    padding-left: 48px;
  }
}

.index-nav {
  margin-bottom: 0px;
  @include for-size(tablet-portrait-up) {
    margin-bottom: 22px;
  }
}

.index-nav.with-index-feed-summary {
  @include for-size(tablet-portrait-up) {
    margin-bottom: -23px;
  }
}

.index-feed-summary {
  padding-top: 0px;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 250ms ease-in-out, margin-bottom 300ms ease-in-out;


  &.hide {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  @include for-size(phone-only) {
    padding-top: 10px;
    transition: margin-bottom 300ms ease-in-out;
  }

  @include for-size(tablet-portrait-up) {
    margin-bottom: 22px;
    transition: margin-bottom 300ms ease-in-out;
  }
}

.index__header {
  // &.menu-is-collapsed {
  //   border-bottom-left-radius: 25px;
  //   border-bottom-right-radius: 25px;
  //   background-image: linear-gradient(#000000 65.75%, #1c1c1c 100%);
  //   padding-left: 12px;
  //   padding-right: 12px;
  //   margin-left: -12px;
  //   margin-right: -12px;
  //   // margin-bottom: 10px;
  //   transition: all 300ms ease-in-out;
  // }
  &.summary-height {
    margin-top: -20px;
    height: 27px;
  }
  &.initial-height {
    height: 75px;
  }
}

.index__scroll-container {
  position: relative;
  height: calc(100% - 70px);
  margin: 0 auto;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  &.with-desktop-menu {
    height: calc(100% - 160px);
  }
}

.index__card-container {
  position: relative;
  height: calc(100% + 4px);
  margin: 0 auto;
  overflow: scroll;
  overflow-x: hidden;
  padding-top: 10px;

}

.index__tools-gradient {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 104px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), #000000);
  z-index: 10;
  pointer-events: none;
}

.index__tools {
  position: absolute;
  bottom: 8px;
  right: 16px;
  width: 60px;
  z-index: 1001;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}

.list-enter-active {
  transition: opacity 350ms 350ms;
}

.list-leave-active {
  transition: opacity 350ms;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-move {
  transition: transform 500ms;
}

.menu-opacity-enter-active,
.menu-opacity-leave-active {
  opacity: 1;
  @include for-size(tablet-portrait-up) {
    height: 0px;
  }
  transition: all 250ms;
}

.menu-opacity-enter,
.menu-opacity-leave-to {
  opacity: 0;
  height: 0px;
}
</style>
