<template>
  <div class="hexad-grid">
    <svg
      width="224"
      height="230"
    >
      <g>
        <path
          ref="rubberband"
          :class="{ hide: mode !== 'all' }"
        />
        <path
          ref="reactionBand"
          :class="{ hide: mode !== 'reactions' }"
        />
        <path
          ref="insightsBand"
          :class="{ hide: mode !== 'project' }"
        />
      </g>
    </svg>
    <PoleHandle
      v-for="(coords, i) in reactionCoordinates"
      :key="'pole-handle-' + i"
      :style="getPoleHandleStyle(coords, i)"
    />
    <div
      v-for="(position, i) in ['rc', 'ra', 'rb']"
      :key="'slider-' + position"
      :id="'slider-' + position"
      :class="['slider', `slider-${position}`]"
      :style="getPoleHandleStyle(reactionCoordinates[i], i)"
    />
    <ReactionSvg
      v-for="(pos, i) in ['rc', 'ra', 'rb']"
      :key="'reaction-' + pos"
      :class="[
        'reaction',
        reactions[pos] < 0 ? `r-neg-${pos}` : `r-${pos}`,
        reactions[pos] == 0 ? 'hide' : '',
      ]"
      :style="{
        ...getPoleHandleStyle(reactionCoordinates[i], i),
        'opacity': Math.abs(reactions[pos]),
      }"
    />
    <div
      v-for="(position, i) in coordinates"
      v-html="labels[i]"
      :key="'label-' + i"
      class="text-footnote"
      :style="getPoleLabelStyle(position, i)"
    />
  </div>
</template>

<script>
import PoleHandle from "@/components/impact/PoleHandle.vue";
import ReactionSvg from "@/components/impact/ReactionSvg.vue";

import * as d3 from 'd3';
import 'nouislider/distribute/nouislider.css';
import noUiSlider from 'nouislider';

export default {
  name: "Rubberband",
  components: {
    PoleHandle,
    ReactionSvg
  },
  props: {
    heights: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    reactions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      raSlider: null,
      rbSlider: null,
      rcSlider: null, 
      positions: ['ia', 'rc', 'ib', 'ra', 'ic', 'rb'],
      labels: [
        'Sales<br>212',
        'Rating',
        'Engagement<br>30.4x avg',
        'Missionworthiness',
        'Investment<br>$55k, 404hr',
        'Effort'
      ]
    }
  },
  computed: {
    coordinates() {
      const { ra, ic, rb, ia, rc, ib } = this.heights;
      const origin = 112;
      const root3 = Math.sqrt(3);
      // heights are 2x, adjacent of 60deg is x, adj of 30deg is x*root3
      // ia = 112, 112 - height
      // rc = (112 + (h/2) * root 3), 112 - h/2
      // ib = (112 + (h/2)*rt3), 112 + h/2
      // ra = 112, height
      // ic = (112 - (h/2) * root 3), 112 + h/2
      // rb = (112 - (h/2) * root 3), 112 - h/2


      return [
        { x: origin, y: origin - ia - 1 },
        { x: origin + (rc / 2 * root3), y: origin - (rc / 2 + 1) },
        { x: origin + (ib / 2 * root3), y: origin + (ib / 2 + 2) }, 
        { x: origin, y: origin + ra + 1  }, 
        { x: origin - (ic / 2 * root3), y: origin + (ic / 2 + 2) },
        { x: origin - (rb / 2 * root3), y: origin - (rb / 2 + 1) },
      ];
    },
    handleStyle() {
      return 'background: transparent !important; border: none !important; box-shadow: none !important; cursor: pointer !important;'
    },
    tooltipStyle() {
      return 'background: transparent !important; border: none !important; color: white !important; font-size: 12px; font-weight: 700;'
    },
    insightsCoordinates() {
      const [ia, rc, ib, ra, ic, rb] = this.coordinates;
      return [ia, ib, ic];
    },
    reactionCoordinates() {
      let [ia, rc, ib, ra, ic, rb] = this.coordinates;
      const newRb = { x: rb.x - 2, y: rb.y + 2 };
      return [rc, ra, newRb];
    },
  },
  mounted() {
    setTimeout(() => {
      this.drawHexad();
      this.drawTriangles();
      this.addSliders();
      this.styleSlider();
      this.addSliderEventListeners();
    }, 300);
  },
  beforeDestroy() {
    this.removeSliderEventListeners();
  },
  methods: {
    addSliderEventListeners() {
      this.raSlider.on('change', (values) => {
        this.updateReactionValue(Number(values[0]), 'ra');
      });
      this.rbSlider.on('change', (values) => {
        this.updateReactionValue(Number(values[0]), 'rb');
      });
      this.rcSlider.on('change', (values) => {
        this.updateReactionValue(Number(values[0]), 'rc');
      });
    },
    removeSliderEventListeners() {
      this.raSlider.off();
      this.rbSlider.off();
      this.rcSlider.off();
    },
    updateReactionValue(value, position) {
      const payload = { position, value };
      this.$emit('update-reaction', payload);
    },
    styleSlider() {
      const handles = document.getElementsByClassName('noUi-handle');
      handles[0].setAttribute('style', this.handleStyle);
      handles[1].setAttribute('style', this.handleStyle);
      handles[2].setAttribute('style', this.handleStyle);

      const tips = document.getElementsByClassName('noUi-tooltip');
      tips[0].setAttribute('style', this.tooltipStyle);
      tips[1].setAttribute('style', this.tooltipStyle);
      tips[2].setAttribute('style', this.tooltipStyle);
    },
    addSliders() {
      ['rc', 'ra', 'rb'].forEach((key, i) => {
        const slider = document.getElementById(`slider-${key}`);
        this[`${key}Slider`] = noUiSlider.create(slider, {
          range: {
            'min': -1,
            'max': 1,
          },
          step: 0.1,
          start: [this.reactions[key]],
          direction: 'rtl',
          orientation: 'vertical',
          behaviour: 'tap-drag',
          tooltips: true,
          // format: wNumb({
          //     decimals: 0
          // }),
        })
      })
    },
    drawHexad() {
      const origin = 112;
      const root3 = Math.sqrt(3);
      const rubberband = d3.select(this.$refs.rubberband);
      const tension = -0.1;
      const drawHexagon = 
        d3.line()
              .x(function(d) { return d.x; })
              .y(function(d) { return d.y; })
              .curve(d3.curveCardinalClosed.tension(tension));

      const _ = 
        rubberband
          .attr("d", drawHexagon(this.coordinates))
          .attr("stroke", "white")
          .attr("stroke-width", 1)
          .attr("transform", `translate (1,1)`)
          .attr("fill", "rgba(255, 255, 255, 0)");
    },
    drawTriangles() {
      const tension = -0.2;
      const draw = 
        d3.line()
              .x(d => d.x)
              .y(d => d.y)
              .curve(d3.curveCardinalClosed.tension(tension));

      const reactionBand = d3.select(this.$refs.reactionBand);
      const insightsBand = d3.select(this.$refs.insightsBand);


      insightsBand
        .attr("d", draw(this.insightsCoordinates))
        .attr("stroke", "white")
        .attr("stroke-width", 1)
        .attr("transform", `translate (1,1)`)
        .attr("fill", "rgba(255, 255, 255, 0)");

     reactionBand
        .attr("d", draw(this.reactionCoordinates))
        .attr("stroke", "white")
        .attr("stroke-width", 1)
        .attr("transform", `translate (1,1)`)
        .attr("fill", "rgba(255, 255, 255, 0)");
    },
    getPoleHandleStyle({ x, y }, i) {
      let style = { position: 'absolute' };
      if (i === 0) {
        style.left = `${x - 34 + 4}px`;
        style.top = `${y - 5}px`;
      } else if (i === 1) {
        style.left = `${x - 17 + 1}px`;
        style.top = `${y - 34 + 1}px`;
      } else {
        style.left = `${x - 1}px`;
        style.top = `${y - 7}px`;
      }
      if (this.mode !== 'reactions') {
        style.display = 'none';
      }
      return style;
    },
    getHexVertices(a,d) {
        return [
          { x: d/2, y: 0 },
          { x: d, y: a/2 },
          { x: d, y: a*(3/2) },
          { x: d/2, y: a*2 },
          { x: 0, y: a*(3/2) },
          { x: 0, y: a/2 },
        ];
    },
    getPoleLabelStyle({ x, y }, i) {
      const d = 115 * Math.sqrt(3);
      const vs = this.getHexVertices(115, d);
      let style = { position: 'absolute', textAlign: 'center' };
      const styles = {
        ia: { ...style, left: `${vs[0].x}px`, top: `${vs[0].y - 28}px`},
        rc: { ...style, left: `${vs[1].x + 17}px`, top: `${vs[1].y - 17}px`},
        ib: { ...style, left: `${vs[2].x + 14}px`, top: `${vs[2].y - 7}px`},
        ra: { ...style, left: `${vs[3].x - 30}px`, top: `${vs[3].y + 6}px`},
        ic: { ...style, left: `${vs[4].x - 54}px`, top: `${vs[4].y - 14}px`},
        rb: { ...style, left: `${vs[5].x - 20}px`, top: `${vs[5].y - 10}px`},
      }
      const pos = this.positions[i];

      return styles[pos];
    }
  }
};
</script>

<style lang="scss" scoped>
.hexad-grid {
  position: relative;
  width: 224px;
  height: 228px;
}

.hide {
  display: none;
}

.slider {
  z-index: 1000;
  height: 100px;
  &-ra {
    transform: translate(10px, -35px);
  }
  &-rb {
    transform: translate(9px, -35px) rotate(300deg);
  }
  &-rc {
    transform: translate(8px, -31px) rotate(60deg);
  }
}

.noUi-target {
  background: transparent;
  border: none;
  box-shadow: none;
}

.noUi-vertical .noUi-handle:before,
.noUi-handle:before,
.noUi-vertical .noUi-handle:after,
.noUi-handle:after {
  height: 0px !important;
  width: 0px !important;
  background: transparent !important;
}

.reaction {
  z-index: 100;
  &.r-neg-ra {
    transform: translate(-3px, 27px) rotate(180deg);
  }
  &.r-ra {
    transform: translate(-3px, -7px);
  }
  &.r-neg-rb {
    transform: translate(11px, 19px) rotate(120deg);
  }
  &.r-rb {
    transform: translate(-18px, 1px) rotate(300deg);
  }
  &.r-neg-rc {
    transform: translate(-18px, 19px) rotate(240deg);
  }
  &.r-rc {
    transform: translate(12px, 2px) rotate(60deg);
  }
}
</style>
