<template>
  <div class="library-header">
    <img
      class="app-nav-activator"
      :src="require('@/assets/png/app-nav-activator.png')"
      @click="activateAppNav"
    >
    <img
      class="queue-indicator"
      :src="require('@/assets/png/queue-indicator.png')"
    >
  </div>
</template>

<script>
export default {
  name: 'LibraryHeader',
  methods: {
    activateAppNav() {
      this.$eventBus.$emit('activate-app-nav');
    }
  }
}
</script>

<style lang="scss" scoped>
.library-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 6px;
  @include for-size(medium-screen-up) {
    margin-bottom: 48px;
  }
}

.app-nav-activator {
  flex: 0 0 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}

.queue-indicator {
  padding-top: 2px;
  flex: 0 0 36px;
  height: 36px;
  img {
    width: 100%;
    height: 100%;
  }
}

.new-idealog-logo {
  width: 45px;
  height: 30px;
  padding: 0;
  align-self img {
    width: 100%;
    height: auto;
  }
}
</style>

