<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12,3l0.01,10.55c-0.59-0.34-1.27-0.55-2-0.55C7.79,13,6,14.79,6,17c0,2.21,1.79,4,4.01,4S14,19.21,14,17V7h4V3H12z
      M10.01,19c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C12.01,18.1,11.11,19,10.01,19z"/>
  </svg>
</template>

<script>
export default {
  name: 'mdi-music-note',
};
</script>

<style scoped>

</style>
