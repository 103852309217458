var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"registration-toolbelt"},[(_vm.currentStep == 0)?_c('a',{class:[
      _vm.nameIsValid ? 'advance-flow-button' : 'disabled', 
      'flow-button'],on:{"click":_vm.emitIncrement}},[_vm._v("\n    "+_vm._s(_vm.nameIsValid ? "Save & continue" : "Introduce yourself to continue")+"\n  ")]):_vm._e(),(_vm.currentStep == 1)?_c('a',{class:[
      !_vm.credentialsRequired ? 'disabled' : 'advance-flow-button', 
      'flow-button'
    ],on:{"click":_vm.validateCredentials}},[_vm._v("\n    "+_vm._s(!_vm.credentialsRequired
        ? "Add an email & password to continue"
        : "Save & continue")+"\n  ")]):_vm._e(),(_vm.currentStep >= 3)?_c('a',{class:[
      _vm.currentStep == 5 ? 'now-flex': '',
      'flow-button',
      'exit-flow-button'
    ],attrs:{"href":"/units"}},[_vm._v(_vm._s(_vm.currentStep == 5 ? "Save & return to home" : "Save & exit"))]):_vm._e(),(_vm.currentStep >= 2 && _vm.currentStep <= 4)?_c('a',{staticClass:"flow-button",class:{ 
      'disabled': !_vm.scrolledToBottom,
      'finalize-flow-button': _vm.scrolledToBottom && _vm.currentStep == 2, 'advance-flow-button': _vm.currentStep != 2
    },on:{"click":_vm.unlockFlow}},[_vm._v("\n    "+_vm._s(_vm.currentStep == 2 ? _vm.scrolledToBottom ? "Agree to terms & continue" : "Read to the bottom to continue" : "Continue")+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }