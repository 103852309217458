<template>
  <div
    v-if="qa"
    class="qa-preview"
    @click="toggleContextState"
  >
    <QaPreviewSummary
      :answers-count="qa.summary.answers_count"
      :questions-count="qa.summary.questions_count"
    />
    <AvatarGroup
      :entities="qa.summary.owners"
      :length="4"
    />
    <div class="triangle" />
  </div>
</template>

<script>
import AvatarGroup from '@/components/entities/AvatarGroup.vue';
import QaPreviewSummary from '@/components/qa/QaPreviewSummary.vue';

import { mapState } from 'vuex';

export default {
  name: 'QaPreview',
  components: {
    AvatarGroup,
    QaPreviewSummary
  },
  computed: {
    ...mapState(['qa']),
  },
  methods: {
    toggleContextState() {
      this.$eventBus.$emit('toggle-context-state', 'active');
    },
  },
};
</script>

<style lang="scss" scoped>

.qa-preview {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 4px 20px 0 20px;
  height: 100%;
  background-color: var(--primary);
}

.qa-preview__stats-summary {
  display: flex;
  flex-direction: column;
  flex: 0.75;
  align-items: flex-start;
  justify-content: center;
  > div {
    display: flex;
    align-items: center;
  }
}

.summary-bar-wrap {
  // position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  // left: 110px;
  // line-height: 16px;
  height: 32px;
  width: 100%;

  > div {
    height: 16px;
    padding-top: 4px;
  }
}

.summary-bar {
  // position: absolute;
  height: 6px;
  border-radius: 3px;
}

.triangle {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid var(--background-dark);
  margin-top: -2px;
}
</style>
