<template>
  <router-link
    v-cloak
    class="music-card"
    :class="cardLayout"
    :title="unit.name"
    :to="{ name: 'unit-view', params: { unit_id: unit.unit_id }
    }"
  >

    <div class="mc__mask mc__dimmer"></div>

    <div
      class="mc__mask mc__bg-blur"
      :style="{ 'background-image': 'url(' + unit.cover_image + ')' }"
    >
    </div>

    <div :class="[isGrid ? 'mc__track-cover--grid' : 'mc__track-cover--full', unit.unit_type=='Playlist' ? 'mc__tc--pl' : '']">

      <div :class="imgClass">
        <img :src="unit.cover_image" />
        <img
          v-if="unit.unit_type=='Playlist'"
          :src="unit.cover_image"
        />
        <img
          v-if="unit.unit_type=='Playlist'"
          :src="unit.cover_image"
        />
      </div>

      <img
        v-if="unit.unit_type=='Audio'"
        :src="require('@/assets/png/music-card-wave-form.png')"
        class="mc__wave-form"
      />
    </div>


    <div v-if="isGrid" class="action-2 text-white" style="z-index: 10;">
      {{ unit.title }}
    </div>

    <div v-else class="mc__desc-section">
      <div class="action-2 text-white" >
        {{ unit.title }}
      </div>

      <div v-if="this.unit.unit_type == 'Playlist'" class="body-3 text-sec-white">
        <i>17 Tracks</i>
      </div>

      <div class="mc__cta">
        <button class="body-2 cta-btn" @click="goToUnit">
          LISTEN
        </button>
      </div>
    </div>


  </router-link>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import { MdiPhoto, MdiVideoCam } from '@/components/graphics';
import PartnerLogo from '@/components/graphics/PartnerLogo.vue';

export default {
  name: 'MusicCard',
  props: {
    isGrid: {
      type: Boolean,
      default: false,
    },
    unit: Object,
  },
  components: {
    AvatarIcon,
    MdiPhoto,
    MdiVideoCam,
    PartnerLogo,
  },
  computed: {
    cardLayout() {
      return this.isGrid ? 'l-grid' : 'l-full';
    },
    imgClass() {
      if (this.unit.unit_type == 'Playlist') {
        return 'mc__cover-img-stack';
      }
      return 'mc__cover-img';
    },
    unitCardIcon() {
      const iconMap = {
        Image: 'mdi-photo',
        Video: 'mdi-video-cam',
      };

      return iconMap[this.unit.unit_type];
    },
  },
  methods: {
    goToUnit() {
      // placeholder - ask CJ: where does this route to if not to the unitview itself?
      // eslint-disable-next-line
      console.log("goes to unit");
    },
  },
};
</script>

<style lang="scss" scoped>

// General layout & background

[v-cloak] > * {
  display: none;
}

.music-card {
  position: relative;
  display: flex;
  // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}

.l-full {
  display: flex;
  width: 100%;
  min-height: 122px;
}

.l-grid {
  display: flex;
  flex-direction: column;
  width: 140px;
}

.mc__mask {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}

.mc__bg-blur {
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(30px);
}

.mc__dimmer {
  z-index: 2;
  background-color: var(--disabled);
  background-blend-mode: overlay;
}

// Track cover: grid/mini vs. full size

.mc__track-cover--grid {
  position: relative;
  width: 76px;
  height: 86px;
  margin-left: calc(50% - 76px/2);
  margin-top: 10px;
  margin-bottom: 12px;

  .mc__cover-img-stack {
    height: 76px;
    width: 76px;
    left: calc(50% - 76px/2);

    img {
      height: 56px;
      width: 56px;
    }
  }
}

.mc__track-cover--grid.mc__tc--pl {
  height: 76px;
}

.mc__track-cover--full {
  position: relative;
  width: 102px;
  height: 112px;

  .mc__cover-img-stack {
    height: 102px;
    width: 102px;

    img {
      height: 82px;
      width: 82px;
    }
  }
}

.mc__track-cover--full.mc__tc--pl {
  height: 102px;
}

.mc__cover-img {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 10px;
  z-index: 10;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.mc__cover-img-stack {
  position: absolute;
  z-index: 10;
  img {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    background-color: rgba(0, 0, 0, 0.25);
  }

  :nth-child(1) {
    z-index: 8;
    left: 0px;
    top: 0px;
  }
  :nth-child(2) {
    z-index: 9;
    left: 10px;
    top: 10px;
  }
  :nth-child(3) {
    z-index: 10;
    left: 20px;
    right: 0px;
    top: 20px;
    bottom: 0px;
  }
}

.mc__wave-form {
  position: absolute;
  left: 0%;
  right: 0%;
  width: 100%;
  bottom: 0%;
  height: 10px;
  z-index: 10;
}

// Text section

.mc__desc-section {
  position: absolute;
  left: 122px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  z-index: 10;
}


.mc__cta {
  position: absolute;
  right: 0;
  bottom: 0;
  .cta-btn {
    cursor: pointer;
    width: 84px;
    height: 32px;
    background-color: var(--disabled);
    border: 1px solid white;
    color: white;
  }
}
</style>
