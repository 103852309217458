<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.625 1.5L2.625 6.75V17.25L11.625 22.5V1.5Z" fill="#BA478F"/>
    <path d="M12.375 1.5L21.375 6.75V17.25L12.375 22.5V1.5Z" fill="#EB743B"/>
  </svg>
</template>

<script>
export default {
  name: 'MusicBrainzLogo',
};
</script>

<style scoped>
</style>
