<template>
  <svg style="height:24px;width:24px;" viewBox="0 0 24 24"><path d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
</template>

<script>
export default {
  name: 'mdi-information',
};
</script>

<style scoped>

</style>
