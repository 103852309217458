<template>
  <div class="registration-funnel">

    <div class="nodes tag text-sec-white">
      <div>FACEBOOK</div>
      <div>SEARCH</div>
      <div class="text-neutral">ELSEWHERE</div>
    </div>

    <div class="nodes">
      <div class="dir-wrap"><div class="dir top"></div></div>
      <div class="dir-wrap"><div class="dir"></div></div>
      <div class="dir-wrap"><div class="dir bot" style="background-color: var(--neutral);"></div></div>
    </div>

    <div class="funnel">
      <div
        class="funnel-step"
        v-for="n in 6"
        :key="'funnel-step-' + n"
        :class="{ 'completed-step' : currentStep > n - 1, 'active-step': currentStep == n - 1 }"
        @click="emitIncrement(n-1)"
      >
        <star v-if="n == 3" v-bind:class="{'yellow' : unlocked }"/>

        <div class="completion-wrap">
          <div :style="{ height: incompletionRates[n-1] + 'px' }"></div>
          <div class="complete" :style="{ height: completionRates[n-1] + 'px' }"></div>
        </div>
      </div>
    </div>

    <div class="nodes">
      <div class="dir-wrap">
        <div class="dir top" :style="homeArrowColor">
        </div>
      </div>
      <div class="dir-wrap"><div class="dir"></div></div>
      <div class="dir-wrap"><div class="dir bot"></div></div>
    </div>

    <div class="nodes tag text-sec-white">
      <div :class=" currentStep === 5 ? 'text-positive': 'text-neutral'">
        HOME
      </div>
      <div>CHECKOUT</div>
      <div>ELSEWHERE</div>
    </div>

  </div>
</template>

<script>
import star from '@/components/graphics/Star.vue';

export default {
  name: 'registration-funnel',
  components: {
    star,
  },
  props: {
    currentStep: Number,
  },
  data() {
    return {
      incompletionRates: [4, 8, 16, 24, 28, 32],
      completionRates: [36, 32, 24, 16, 12, 8],
      unlocked: false,
    };
  },
  computed: {
    homeArrowColor() {
      return this.currentStep == 5 ? 'background-color: var(--positive);' : 'background-color: var(--neutral);';
    },
  },
  created() {
    this.$eventBus.$on('unlock-flow', () => {
      this.unlocked = true;
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('unlock-flow');
  },
  methods: {
    emitIncrement(step) {
      if (typeof step === 'number') {
        this.$emit('increment', step);
      } else {
        this.$emit('increment');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-funnel {
  display: flex;
  justify-content: space-around;
  height: 60px;
  background: #000;
  padding: 0 25px;
}
.nodes {
  display: flex;
  flex-direction: column;
  padding: 6px 2px;

  .dir-wrap {
    height: 40px;
    padding-top: 6px;
  }
}
.dir {
  width: 16px;
  height: 3px;
  background-color: var(--hint-white);
  transform-origin: center center;
}
.top {
  transform: rotate(10deg);
}
.bot {
  transform: rotate(-10deg);
}

.funnel {
  min-width: 140px;
  // margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 2px;
  .funnel-step {
    height: 40px;
    display: block;
    flex: 1;
    flex-basis: 15%;
    margin: 0 1px;
    background: #444;
    pointer-events: none;
    position: relative;
    z-index: 1;
    &.active-step {
      background: var(--positive);
    }
    &.completed-step {
      pointer-events: all;
      background: var(--neutral);
    }
    svg {
      position: absolute;
      fill: white;
      top: -8px;
      left: 1px;
      z-index: 2;
      &.yellow {
        fill: #ffe74c;
      }
    }
    .completion-wrap {
      height: 40px;
      margin: 0;
      pointer-events: none;
      position: relative;
      z-index: 1;
    }
    .complete {
      background-color: var(--hint-white);
    }
  }
}
</style>
