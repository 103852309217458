<template>
  <div class="data-sources">
    <span class="text-footnote-hint" style="height: 16px;">
      with data from
    </span>
    <DiscogsLogo />
    <LastFmLogo />
    <MusicBrainzLogo />
    <SpotifyLogo />
    <IconButton
      class="icon-btn--tint-hint"
      icon="mdi-information"
    />
  </div>
</template>

<script>
import DiscogsLogo from '@/components/graphics/DiscogsLogo.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import LastFmLogo from '@/components/graphics/LastFmLogo.vue';
import MusicBrainzLogo from '@/components/graphics/MusicBrainzLogo.vue';
import SpotifyLogo from '@/components/graphics/SpotifyLogo.vue';

export default {
  name: 'DataSources',
  components: {
    DiscogsLogo,
    IconButton,
    LastFmLogo,
    MusicBrainzLogo,
    SpotifyLogo,
  }
}
</script>

<style lang="scss" scoped>
.data-sources {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: inherit;
  height: 24px;
}

svg, .icon-btn {
  margin-left: 6px;
}
</style>


