<template>
  <IconButton
    :class="['icon-btn--32', 'fb__bg', featureFillClass]"
    :icon="featureIcon"
    @click="$emit('click')"
  />
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'FeatureButton',
  components: {
    IconButton,
  },
  props: {
    featured: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    featureFillClass() {
      return this.featured ? 'featured-fill' : 'not-featured-fill';
    },
    featureIcon() {
      return this.featured ? 
        'mdi-bookmark-remove' : 
        'mdi-bookmark-plus-outline';
    },
  }
}
</script>

<style lang="scss" scoped>
.fb__bg {
  background-color: rgba(255, 255, 255, 0.06);
}

.featured-fill {
  & > :nth-child(1) { fill: var(--negative); }
}

.not-featured-fill {
  & > :nth-child(1) { fill: var(--secondary-white); }
}

</style>

