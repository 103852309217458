<template>
  <div class="ru__wrap" :class="{'is-open': open, 'is-closed': !open }">
    <div class="rt__container no-swipe">
        <IconButton
          v-for="(tool, key) in tools"
          :key="key + '-tool'"
          class="icon-btn--tint-white"
          :class="{ 'is-active': tool.active, 'toolset-icon': (tool.svg != 'mdi-dice'), 'toolset-dice': (!open && (tool.svg == 'mdi-dice')) }"
          :icon="tool.svg"
          @click="handleClick(tool)"
        />
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'IndexTools',
  components: { IconButton },
  data() {
    return {
      open: false,
      tools: {
        newsworthiness: {
          svg: 'mdi-water',
          active: false,
        },
        effort: {
          svg: 'mdi-leaf',
          active: false,
        },
        heat: {
          svg: 'mdi-fire',
          active: false,
        },
        time: {
          svg: 'mdi-timer-sand',
          active: true,
        },
        select: {
          svg: 'mdi-dice',
          active: false,
        },
      },
    };
  },
  methods: {
    toggleToolset() {
      this.open = !this.open;
    },
    emitToggle(tool) {
      const tools = Object.keys(this.tools);
      const selected = tools.filter(f => this.tools[f] == tool);
      const notSelected = tools.filter(f => this.tools[f] !== tool);

      // If selected tool was not active, make it active, make others active
      // Otherwise, do nothing
      if (!this.tools[selected].active) {
        this.tools[selected].active = true;
        notSelected.forEach(v => (this.tools[v].active = false));
      }

      this.$emit('sort', selected[0]);
    },
    handleClick(tool) {
      if (tool.svg == 'mdi-dice') {
        this.toggleToolset();
      } else {
        this.emitToggle(tool);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.ru__wrap {
    overflow:visible;
    display:flex;
    justify-content: flex-end;
    transition:width .25s ease-in-out;
}
.rt__container {
  position: relative;
  display: inline-flex;
  // flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  min-height: 60px;
  max-height: 60px;
  max-width: 60px;
  min-width: 60px;
  border-radius: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--background-dark);
  transition: min-width 250ms ease-in-out;
  transition:opacity .15s ease-in-out;
  .toolset-icon {
    // background-color: red;
    &:nth-child(1){
      transform:scale(0);
      transition-delay: .05s;
    }
    &:nth-child(2){
      transform:scale(0);
      transition-delay: .1s;
    }
    &:nth-child(3){
      transform:scale(0);
      transition-delay: .15s;
    }
    &:nth-child(4){
      transform:scale(0);
      transition-delay: .15s;
    }
    // &:nth-child(5){
    //   transform:scale(0);
    //   transition-delay: .15s;
    // }
  }


}

.toolset-icon {
  transition:transform .25s ease-in-out, opacity .25s ease-in-out;
  // margin-bottom: 10px;
}

.toolset-dice {
  min-height: 40px;
  min-width: 40px;
  margin-left: -200px;
}

.ru__wrap.is-open .rt__container {
  transition: min-width 250ms ease-in-out;
  min-width: 260px;
  opacity:1;
  .toolset-icon{
    transform:scale(1)
  }
}

.ru__wrap.is-closed .rt__container {
  transition: min-width 250ms ease-in-out;
  min-width: 60px;
  max-width: 60px;
}

.is-active {
  background-color: var(--object-fill-dark);
  & > :nth-child(1) {
    fill: var(--primary);
  }
}

// .opaque {
//   opacity: 0.6;
// }
</style>
