<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.36615 15.8007L8.42134 15.7454L9.06532 15.0443L9.08372 15.0258L9.43289 14.6388L9.4517 14.6568L9.4333 14.6384L9.43289 14.6388C8.74495 13.9757 8.32935 13.0422 8.32935 12C8.32935 9.95203 9.98528 8.29151 12.0276 8.29151C12.7084 8.29151 13.3339 8.47602 13.8675 8.78967L14.6035 7.46126L14.6403 7.38745L15.3763 6.02214L15.4131 5.94834L16.149 4.58303L16.1858 4.50923L16.5722 3.78967L16.8482 3.27306C15.4499 2.47971 13.8491 2.01845 12.138 2H11.9724C6.48942 2 2.0552 6.42804 2 11.9078V12C2 14.8598 3.21435 17.4428 5.14628 19.2694L5.64305 18.7343L6.13983 18.1993L6.19503 18.1439L7.24379 17L7.29899 16.9447L8.36615 15.8007ZM18.9641 4.82288L18.0073 5.85609L17.9521 5.91144L16.9034 7.05535L16.8482 7.1107L15.7994 8.25461L15.7442 8.30996L15.3579 8.73432L14.7139 9.41697C15.3395 10.0812 15.7258 10.9852 15.7258 11.9631C15.7258 14.0111 14.0699 15.6716 12.046 15.6716C11.4204 15.6716 10.85 15.524 10.3349 15.2472L9.59888 16.5756L8.55012 18.4391L7.99815 19.417L7.96135 19.4908L7.24377 20.7638C8.66052 21.5387 10.2981 22 12.0276 22C17.529 22 22 17.5166 22 12C21.9816 9.17712 20.8224 6.64944 18.9641 4.82288ZM11.3284 11.9816C11.3284 11.5941 11.6412 11.2805 12.0276 11.2805C12.414 11.2805 12.7268 11.5941 12.7268 11.9816C12.7268 12.369 12.414 12.6827 12.0276 12.6827C11.6412 12.6827 11.3284 12.369 11.3284 11.9816Z" fill="#F5DF2E"/>
  </svg>
</template>

<script>
export default {
  name: 'DiscogsLogo',
};
</script>

<style scoped>
</style>
