<template>
  <div class="im">
    <MobileIndexMenu
      v-if="onMobile"
      :is-active-from-index="isActive"
    />
    <DesktopIndexMenu
      v-else
      :is-active-from-index="isActive"
      :on-desktop="onDesktop"
    />
  </div>
</template>

<script>
import DesktopIndexMenu from '@/components/index/DesktopIndexMenu.vue';
import MobileIndexMenu from '@/components/index/MobileIndexMenu.vue';

export default {
  name: 'IndexMenu',
  components: {
    DesktopIndexMenu,
    MobileIndexMenu,
  },
  props: {
    isActive: {
      default() {
        return {};
      },
      type: Object
    },
    onMobile: Boolean,
    onDesktop: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.im {
  position: static;
}
</style>
