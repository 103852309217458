<template>
  <div class="profile-component">
    <transition name="tools">
      <ProfileEditNav v-if="editingProfile" />
    </transition>
    <div
      id="profile-split-matrix"
      :class="{ 'with-app-nav': appNavIsActive }"
      @click.self="deactivateAppNav"
    >
      <img
        class="app-nav-activator"
        :src="require('@/assets/png/app-nav-activator.png')"
        @click="appNavIsActive = !appNavIsActive"
      >
      <ProfileGrid
        v-if="entity"
        :lower-state="contextState"
      />
      <transition name="tools">
        <ProfileToolbelt
          v-if="toolbeltType"
          :class="[contextTrayHeight, { 'disable-during-app-nav': appNavIsActive }]"
          :lower-state="contextState"
          :type="toolbeltType"
          @toggle-profile-love="handleToggleProfileLove"
          @toggle-share-tool="activateContextState"
        />
      </transition>
      <div :class="[contextTrayHeight, 'tools-loading']" />

      <ContextTray
        ref="ct"
        :class="['context-tray', contextTrayHeight, { 'disable-during-app-nav': appNavIsActive }]"
        :context-state="contextState"
        in-profile
      />
    </div>
  </div>
</template>

<script>
import ContextTray from '@/components/unit/ContextTray.vue';
import ProfileEditNav from '@/components/profile/ProfileEditNav.vue';
import ProfileGrid from '@/components/profile/ProfileGrid.vue';
import ProfileToolbelt from '@/components/tools/ProfileToolbelt.vue';
import faker from 'faker';
import { mapState } from 'vuex';

export default {
  name: 'ProfileComponent',
  components: {
    ContextTray,
    ProfileEditNav,
    ProfileGrid,
    ProfileToolbelt
  },
  props: {
    // userId: {
    //   type: Number,
    //   default: 0,
    // }
  },
  data() {
    return {
      appNavIsActive: false,
      contextState: 'preview',
      loading: false,
      toolbeltType: null,
      windowHeight: null,
      // entity: null,
    }
  },
  computed: {
    ...mapState(['editingProfile', 'entity', 'user']),
    userId() {
      return this.user ? this.user.id : 134;
    },
    contextTrayHeight() {
      return `context-height--${this.contextState}`;
    },
    isMyProfile() {
      return (this.entity.type === 'user') && (this.userId === this.entity.id);
      // return true;
    },
    isMyEntity() {
      if (this.entity.type === 'user') return;
      const admins = this.entity.members.filter(m => m.role === 'admin');
      const me = admins.find(a => a.id === this.userId);
      return Boolean(me);
    },
    mediaUnits() {
      const { primary, featured } = this.entity.channels.media;
      const units = this.$_.concat(primary.units, featured.units);
      return units;
    },
    isPlayable() {
      if (this.isMyProfile || this.isMyEntity || this.entity.type !== 'ensemble') return;

      return Boolean(this.mediaUnits.length);
    },
    currentUserLiked() {
      return this.entity.relationships.current_user_liked;
    }
  },
  watch: {
    $route: 'fetchEntity',
  },
  created() {
    this.$eventBus.$on('toggle-context-state', this.activateContextState);
    this.$eventBus.$on('handle-toolbelt-play', this.handleToobeltPlay);
    this.fetchEntity();
  },
  mounted() {
    this.calculateDocumentVariables();
    window.addEventListener('resize', this.$_.debounce(this.calculateDocumentVariables, 200, {
      leading: true,
      trailing: false,
    }));
  },
  beforeDestroy() {
    this.$eventBus.$off('toggle-context-state');
    this.$eventBus.$off('handle-toolbelt-play');
    window.removeEventListener('resize', this.calculateDocumentVariables);
  },
  methods: {
    setToolbeltType() {
      if (this.isMyProfile || this.isMyEntity) {
        return 'private';
      } else if (this.isPlayable) {
        return 'publicPlayable';
      }
      return this.currentUserLiked ? 'publicLoveSent' : 'publicLove';
    },
    async fetchEntity() {
      const { entityType, entityId } = this.$route.params;
      this.$store.dispatch('LOAD_ENTITY', { entityType, entityId}).then(() => {
          const type = this.setToolbeltType();
          console.log({ type })
          this.toolbeltType = type;
      });
    },
    pause(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    calculateDocumentVariables() {
      if (!this.windowHeight || this.windowHeight !== window.innerHeight) {
        this.windowHeight = window.innerHeight;
        let vh = this.windowHeight * 0.01;
        let vw = window.innerWidth * 0.01;
        const ms = (this.windowHeight - 224) / this.windowHeight;
        const ts = (this.windowHeight - 336) / this.windowHeight;
        const ds = 336 / this.windowHeight;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}`);
        document.documentElement.style.setProperty('--ms', `${ms}`);
        document.documentElement.style.setProperty('--ts', `${ts}`);
        document.documentElement.style.setProperty('--ds', `${ds}`);
      }
    },
    deactivateAppNav() {
      if (this.appNavIsActive) {
        this.appNavIsActive = false;
      }
    },
    activateContextState(newState) {
      this.contextState = newState;
    },
    handleToggleProfileLove(payload) {
      this.toolbeltType = payload ? 'publicLoveSent' : 'publicLove';
    },
    handleToobeltPlay() {
      const unit_id = this.mediaUnits[0].unit_id;
      this.$router.push({
        name: 'unit-view',
        params: { unit_id }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
/*
 * Component container & App navigator
 */

.profile-component {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 100%;
  max-height: 100%;
  resize: both;
  background-color: var(--background-dark);
}

.app-nav-activator {
  flex: 0 0 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  height: 40px;
  z-index: 1000;
  img {
    width: 100%;
    height: 100%;
  }
}

.disable-during-app-nav {
  pointer-events: none;
}

/*
 * The split matrix container
 */

#profile-split-matrix {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms ease;
  transform-origin: center center;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  background-color: #000; /*DARKMODE*/
  z-index: 2;
}

#profile-split-matrix.with-app-nav {
  @include for-size(phone-only) {
    transform: translate(-50%, -50%) scale(var(--ms)) !important;
    transition: transform 300ms ease;
  }
  @include for-size(tablet-portrait-up) {
    transform: translate(-50%, -50%) scale(var(--ts)) !important;
    transition: transform 300ms ease;
  }
  @include for-size(small-desktop-up) {
    transform: translate(-50%, -50%) scale(var(--ds)) !important;
    transition: transform 300ms ease;
  }
}

/*
 * Tools
 */

.profile-toolbelt,
.tools-loading {
  position: absolute;
  margin: 0px 0 0 0;
  width: 100%;
  width: inherit;
  z-index: 3;
  bottom: auto;
  transition: bottom 300ms ease;
  &.context-height--active {
    bottom: calc(var(--vh, 1vh) * 100 - 100px);
  }
  &.context-height--summary {
    bottom: 255px;
  }
  &.context-height--preview {
    bottom: 60px;
  }
  &.context-height--share {
    bottom: 168px;
  }
}

.tools-loading {
  height: 60px;
  background-color: black;
  z-index: 0;
}

/*
 * Context tray
 */

.context-tray {
  z-index: 2;
  display: flex;
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
  position: absolute;
  bottom: 0;
  width: inherit;
  height: inherit;
  background-color: black;
  transition: height 300ms ease;
  &.context-height--active {
    height: calc(var(--vh, 1vh) * 100 - 120px);
    > div {
      height: calc(var(--vh, 1vh) * 100 - 120px);
    }
  }
  &.context-height--summary {
    height: 255px;
    > div {
      height: 255px;
    }
  }
  &.context-height--preview {
    height: 60px;
    > div {
      height: 60px;
      min-height: 60px;
    }
  }
  &.context-height--share {
    height: 168px;
    > div {
      height: 168px;
      min-height: 168px;
    }
  }
}

/*
 * Transitions
 */

.tools-enter-active {
  transition: opacity 250ms;
}

.tools-enter,
.tools-leave-to {
  opacity: 0;
}
</style>

