<template>
  <transition name="feature">
    <div
      v-if="featuredQa.question"
      class="qa-feature"
    >
      <div class="tag text-sec-white">
        FEATURED {{ (featuredQa.index === -1) ? "QUESTION" : "ANSWER" }}
      </div>
      <QaThread
        key="qf-thread"
        :in-qa-feature="featuredQa.index"
        :question="featuredQa.question"
      />
    </div>
  </transition>
</template>

<script>
import QaThread from '@/components/qa/QaThread.vue';

export default {
  name: "QaFeature",
  components: {
    QaThread
  },
  computed: {
    featuredQa() {
      return this.$store.state.featuredQa;
    }
  },
};
</script>

<style lang="scss" scoped>
.qa-feature {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--stroke-white);
  @include for-size(small-desktop-up) {
    border-bottom: none;
  }
  > :first-child {
    margin-bottom: 10px;
  }
}

.feature-enter-active, .feature-leave-active {
  transition: opacity 300ms ease, max-height 300ms ease;
}
.feature-enter, .feature-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  max-height: 0px;
}
</style>
