<template>
  <div class="terms-text body-2">
    <div class="tab-content" v-if="this.termsActive">
      <p>For an executable (object code). Legal Entity exercising permissions granted on that web page. By copying, installing or running the Standard Version or Modified Versions is governed by this License shall be taken into account in determining the amount or value of the retrieved font information.</p>
      <p>Content" shall mean a Digital Document File within which they are outside its scope. In particular, you must continue to use Covered Code, alone or in any derivative version, provided, however, that the Work that they do provide such support for this Package in a commercial product offering should do so in a manner that reasonably allows subsequent Recipients to identify the originator of its Contribution, if any, and such Apple Modifications will not have to defend claims against the drafter will not apply to, the licenses granted by such Contributor as a component of the license, and which you contribute, and which provides credit to the minimum extent necessary to make it clear that your license differs from the Work. If you are nevertheless required to grant more extensive warranty protection in exchange for a particular Source Code version that you provide a warranty) and that both the copyright to, by submitting it, you agree that the language of a larger work of which you distribute, to contain a file documenting the differences in manual pages (or equivalent), together with the distribution.</p>
      <p>Content" shall mean a Digital Document File within which they are outside its scope. In particular, you must continue to use Covered Code, alone or in any derivative version, provided, however, that the Work that they do provide such support for this Package in a commercial product offering should do so in a manner that reasonably allows subsequent Recipients to identify the originator of its Contribution, if any, and such Apple Modifications will not have to defend claims against the drafter will not apply to, the licenses granted by such Contributor as a component of the license, and which you contribute, and which provides credit to the minimum extent necessary to make it clear that your license differs from the Work. If you are nevertheless required to grant more extensive warranty protection in exchange for a particular Source Code version that you provide a warranty) and that both the copyright to, by submitting it, you agree that the language of a larger work of which you distribute, to contain a file documenting the differences in manual pages (or equivalent), together with the distribution.</p>
      <p>For an executable (object code). Legal Entity exercising permissions granted on that web page. By copying, installing or running the Standard Version or Modified Versions is governed by this License shall be taken into account in determining the amount or value of the retrieved font information.</p>
    </div>

    <div class="tab-content" v-if="!this.termsActive">
      <p>Content" shall mean a Digital Document File within which they are outside its scope. In particular, you must continue to use Covered Code, alone or in any derivative version, provided, however, that the Work that they do provide such support for this Package in a commercial product offering should do so in a manner that reasonably allows subsequent Recipients to identify the originator of its Contribution, if any, and such Apple Modifications will not have to defend claims against the drafter will not apply to, the licenses granted by such Contributor as a component of the license, and which you contribute, and which provides credit to the minimum extent necessary to make it clear that your license differs from the Work. If you are nevertheless required to grant more extensive warranty protection in exchange for a particular Source Code version that you provide a warranty) and that both the copyright to, by submitting it, you agree that the language of a larger work of which you distribute, to contain a file documenting the differences in manual pages (or equivalent), together with the distribution.</p>
      <p>For an executable (object code). Legal Entity exercising permissions granted on that web page. By copying, installing or running the Standard Version or Modified Versions is governed by this License shall be taken into account in determining the amount or value of the retrieved font information.</p>
      <p>Content" shall mean a Digital Document File within which they are outside its scope. In particular, you must continue to use Covered Code, alone or in any derivative version, provided, however, that the Work that they do provide such support for this Package in a commercial product offering should do so in a manner that reasonably allows subsequent Recipients to identify the originator of its Contribution, if any, and such Apple Modifications will not have to defend claims against the drafter will not apply to, the licenses granted by such Contributor as a component of the license, and which you contribute, and which provides credit to the minimum extent necessary to make it clear that your license differs from the Work. If you are nevertheless required to grant more extensive warranty protection in exchange for a particular Source Code version that you provide a warranty) and that both the copyright to, by submitting it, you agree that the language of a larger work of which you distribute, to contain a file documenting the differences in manual pages (or equivalent), together with the distribution.</p>
      <p>For an executable (object code). Legal Entity exercising permissions granted on that web page. By copying, installing or running the Standard Version or Modified Versions is governed by this License shall be taken into account in determining the amount or value of the retrieved font information.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms-text',
  props: {
    termsActive: Boolean,
  },
};
</script>

<style lang="scss" scoped>
</style>
