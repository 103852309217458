<template>
  <div class="sales-toolbelt">

    <a
      v-if="currentStep <= 1"
      :class="['flow-button', 'wholetone-flow-button']"
      style="font-size: 12px; font-weight: 400; padding-top: 12px;"
    >
      <i>Powered by Wholetone  </i>
      <MdiInformation
        style="width: 13.3px; height: 13.3px; margin-top: -2px;"
      />
    </a>

    <a
      v-if="currentStep == 0"
      class="advance-flow-button flow-button"
      @click="emitIncrement"
    >
      Continue
    </a>

    <a
      v-if="currentStep == 1"
      class="advance-flow-button flow-button"
      @click="emitIncrement"
    >
      Continue
    </a>


    <a
      v-if="currentStep == 2"
      :class="[!paymentInfoIsValid ? 'disabled' : 'advance-flow-button', 'flow-button']"
      @click="emitPurchase"
    >
      {{ !paymentInfoIsValid ? disabledText : "Check out" }}
    </a>

    <a
      v-if="currentStep == 3"
      class="exit-flow-button flow-button"
      style="flex: 2;"
      @click="returnToUnit"
    >
      Return to {{ nextPageInfo.title }}
    </a>
  </div>
</template>

<script>
import MdiInformation from '@/components/graphics/MdiInformation.vue';

export default {
  name: 'sales-toolbelt',
  props: {
    currentStep: Number,
    nextPageInfo: Object,
    validations: Object,
  },
  components: {
    MdiInformation,
  },
  data() {
    return {
      paymentInfoIsValid: false,
      disabledText: 'Add payment info to check out',
    };
  },
  created() {
    this.$eventBus.$on('update-disabled-text', this.updateDisabledText);
    this.$eventBus.$on('validate-payment-info', this.validatePaymentInfo);
  },
  beforeDestroy() {
    this.$eventBus.$off('update-disabled-text');
    this.$eventBus.$off('validate-payment-info');
  },
  methods: {
    emitIncrement(step) {
      if (typeof step === 'number') {
        this.$emit('increment', step);
      } else {
        this.$emit('increment');
      }
    },
    emitPurchase() {
      this.$eventBus.$emit('validate-stripe');
    },
    returnToUnit() {
      if (this.nextPageInfo.title === 'the Index') {
        this.$router.push({ name: 'unit-list'});
      } else {
        this.$router.push({ name: 'unit-view', params: { unit_id: this.nextPageInfo.unit_id } });
      }
    },
    updateDisabledText() {
      this.paymentInfoIsValid = false;
      this.disabledText = 'Fix your card details to continue';
    },
    validatePaymentInfo(boolean) {
      this.paymentInfoIsValid = boolean;
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-toolbelt {
  display: flex;
}
.now-flex {
  width: 100%;
}
</style>
