<template>
  <email-container>
    <div class="text-white">
      <div class="title-1">
        $NAME. Greetings!
      </div>
      <div class="headline">
        <p>Our sincere thanks on deciding to be a part of Idealog. This place can only exist through your involvement. From the bottom of our hearts, we’re grateful.</p>
        <p>There’s a lot of ways to explore Idealog.</p>
      </div>
      <div v-for="(card, i) in promoCards" :key="`card-${i}`">
        <PromoCard
          :closable="false"
          :content="card"
        />
        <div
          class="body-2 text-sec-white"
          style="padding-top: 10px; margin-bottom: 15px;"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod quis viverra nibh cras pulvinar.
        </div>
      </div>
      <div class="headline">
        <p>Warmly,</p>
        <p>The Idealog Team</p>
      </div>
    </div>
  </email-container>
</template>

<script>
import EmailContainer from '@/components/_email/EmailContainer.vue';
import PromoCard from '@/components/cards/PromoCard.vue';

export default {
  name: 'EmailWelcome',
  components: {
    EmailContainer,
    PromoCard,
  },
  data() {
    return {
      promoCards: [
        {
          title: 'Create + Curate',
          subtitle: 'Take control of your creative work flow.  Start a project and get support. Assemble pages and engage your community.',
          imageUrl: `${require('@/assets/png/promo_create_and_curate.png')}`,
          type: 'create',
          display: true,
        },
        {
          title: 'Show Work',
          subtitle: 'Show up and get work out there. Manage your identity. Build connections. Present yourself in relationship to your body of work.',
          imageUrl: `${require('@/assets/png/promo_show_work.png')}`,
          type: 'show',
          display: true,
        },
        {
          title: 'Get Involved',
          subtitle: 'React and measure impact. Annotate and discuss works. Share projects and advocate for a series. Enter a direct conversation with creators.',
          imageUrl: `${require('@/assets/png/promo_get_involved.png')}`,
          type: 'involved',
          display: true,
        },
      ],
    };
  }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  margin: 0 auto;
  margin-bottom: 24px;
}
</style>

