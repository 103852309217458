<template>
  <div
    :class="['index-nav', { 'disable-during-app-nav': appNavIsActive }]"
  >
    <AppLogo />
    <ThemedButton
      class="hide-medium-screen-up"
      text="LOG OUT"
      theme="neutral"
      @click="signOut"
    />
    <AppNavActivator class="hide-medium-screen-up" />
    <div class="hide-mobile">
      <div
        class="body-1 text-white"
        style="margin-bottom: 40px;"
      >
        {{ title }}
      </div>
      <ThemedButton
        text="LOG OUT"
        theme="neutral"
        @click="signOut"
      />
    </div>
  </div>
</template>

<script>
import AppLogo from '@/components/graphics/AppLogo.vue';
import AppNavActivator from '@/components/nav/AppNavActivator.vue';
import ThemedButton from '@/components/buttons/ThemedButton.vue';

import { removeToken } from '@/store/localStorage';

export default {
  name: 'IndexNav',
  components: {
    AppLogo,
    AppNavActivator,
    ThemedButton
  },
  props: {
    appNavIsActive: {
      type: Boolean,
      default: false,
    },
    // loggedIn: {
    //   type: Boolean,
    //   default: false,
    // }
  },
  data() {
    return {
      title: 'Idealog is a website that will take your mind and turn it inside itself to look at things from a place of perspective within and without.'
    }
  },
  methods: {
    goToSignIn() {
      this.$router.push({ name: 'signin' });
    },
    signOut() {
      if (confirm('Are you sure you want to logout?')) {
        removeToken();
        this.$store.dispatch('LOGOUT');
        window.location = '/';
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.index-nav {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px 0px;
  background-color: black;
  @include for-size(medium-screen-up) {
    position: relative;
    padding: 0px;
    transition: padding 300ms ease;
    z-index: 1;
  }
}

.disable-during-app-nav {
  pointer-events: none;
  position: absolute;
  z-index: 1;
}

.hide-medium-screen-up {
  @include for-size(medium-screen-up) {
    display: none;
  }
}

.hide-mobile {
  display: none;
  @include for-size(medium-screen-up) {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 0px 16px;
    border-left: solid #242424 2px;
    > :nth-child(2) {
      max-width: 90px;
    }
  }
}
</style>

