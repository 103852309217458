<template>
  <div class="sort-tool">
    <IconButton
      v-for="(icon, key) in types"
      :key="'sort-type-' + key"
      :class="{
        'filter-btn--active': isActive[key], 
        'filter-btn--inactive': !isActive[key], 
      }"
      :icon="icon"
      @click="toggleSort(key)"
    />
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'SortTool',
  components: { IconButton },
  data() {
    return {
      isActive: {
        alpha: false,
        date: true,
      },
      types: {
        alpha: 'mdi-sort-alphabetical',
        date: 'mdi-timer-sand'
      }
    }
  },
  methods: {
    toggleSort(key) {
      const sorts = ['alpha', 'date'];
      const activeSort = sorts.find(sort => sort === key);
      const inactiveSort = sorts.find(sort => sort !== key);
      this.isActive[activeSort] = true;
      this.isActive[inactiveSort] = false;
    
      this.$eventBus.$emit('update-sort', activeSort);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
