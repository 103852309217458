<template>
  <div class="icon-btn" @click="$emit('click')">
    <span :is="icon" />
  </div>
</template>

<script>
import {
  MdiAccount,
  MdiAccountCheck,
  MdiAccountGroup,
  MdiAccountMultiple,
  MdiAccountMultiplePlus,
  MdiAccountPlus,
  MdiAsterisk,
  MdiBookmarkPlusOutline,
  MdiBookmarkRemove,
  MdiBrush,
  MdiCamera,
  MdiCartPlus,
  MdiCheck,
  MdiCheckCircle,
  MdiChevronDown,
  MdiChevronLeft,
  MdiCommentCheck,
  MdiCommentQuestion,
  MdiContentSave,
  MdiClose,
  MdiCube,
  MdiDice,
  MdiDocument,
  MdiEye,
  MdiEyeOffOutline,
  MdiFacebookBox,
  MdiFire,
  MdiFolderDownload,
  MdiInformation,
  MdiInformationVariant,
  MdiLinkVariant,
  MdiLeaf,
  MdiList,
  MdiLockPlus,
  MdiLockReset,
  MdiMovie,
  MdiMusic,
  MdiMusicNote,
  MdiPencil,
  MdiPlus,
  MdiPhoto,
  MdiSettings,
  MdiShare,
  MdiSortAlphabetical,
  MdiTimerSand,
  MdiTwitter,
  MdiUpload,
  MdiVideoCam,
  MdiWater,
} from '@/components/graphics';

export default {
  name: 'IconButton',
  components: {
    MdiAccount,
    MdiAccountCheck,
    MdiAccountGroup,
    MdiAccountMultiple,
    MdiAccountMultiplePlus,
    MdiAccountPlus,
    MdiAsterisk,
    MdiBookmarkPlusOutline,
    MdiBookmarkRemove,
    MdiBrush,
    MdiCamera,
    MdiCartPlus,
    MdiCheck,
    MdiCheckCircle,
    MdiChevronDown,
    MdiChevronLeft,
    MdiClose,
    MdiCommentCheck,
    MdiCommentQuestion,
    MdiContentSave,
    MdiCube,
    MdiDice,
    MdiDocument,
    MdiEye,
    MdiEyeOffOutline,
    MdiFacebookBox,
    MdiFire,
    MdiFolderDownload,
    MdiInformation,
    MdiInformationVariant,
    MdiLinkVariant,
    MdiLeaf,
    MdiList,
    MdiLockPlus,
    MdiLockReset,
    MdiMovie,
    MdiMusic,
    MdiMusicNote,
    MdiPencil,
    MdiPhoto,
    MdiPlus,
    MdiSettings,
    MdiShare,
    MdiSortAlphabetical,
    MdiTimerSand,
    MdiTwitter,
    MdiUpload,
    MdiVideoCam,
    MdiWater,
  },
  props: {
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
.icon-btn {
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: var(--tip);
  cursor: pointer;
  border-radius: 50%;
  & > :nth-child(1) {
    fill: white;
  }
}

.icon-btn--128 {
  min-width: 128px;
  min-height: 128px;
}

.icon-btn--xs {
  height: 24px;
  width: 24px;
  > svg {
    max-height: 18px;
    max-width: 18px;
  }
}

.icon-btn--32 {
  height: 32px;
  width: 32px;
  > svg {
    max-height: 20px;
    max-width: 20px;
  }
}

.icon-btn--s {
  min-height: 36px;
  min-width: 36px;
  max-height: 36px;
  max-width: 36px;
  & > :nth-child(1) {
    max-height: 24px;
    max-width: 24px;
  }
}

.icon-btn--hint {
  background-color: var(--hint);
  & > :nth-child(1) {
    fill: var(--secondary);
  }
}

.icon-btn--neutral {
  background-color: var(--neutral);
  & > :nth-child(1) {
    fill: white;
  }
}

.icon-btn--neutral-tint {
  background-color: rgba(53, 167, 255, 0.1);
  & > :nth-child(1) {
    fill: var(--neutral);
  }
}

.icon-btn--tip-fill {
  background-color: var(--tint);
  & > :nth-child(1) {
    fill: var(--tip);
  }
}

.icon-btn--tint-white {
  background-color: var(--tint-white);
  & > :nth-child(1) {
    fill: #fff;
  }
}

.icon-btn--tint-hint {
  background-color: var(--tint-white);
  & > :nth-child(1) {
    fill: var(--hint-white);
  }
}

.icon-btn--tint-sec {
  background-color: var(--tint-white);
  & > :nth-child(1) {
    fill: var(--secondary-white);
  }
}

.icon-btn--sec-white {
  background-color: black;
  & > :nth-child(1) {
    fill: var(--secondary-white);
  }
}

.icon-btn--fill-white {
  & > :nth-child(1) {
    fill: var(--object-fill-dark);
  }
}

.icon-btn--black {
  background-color: var(--primary);
  & > :nth-child(1) {
    fill: var(--hint-white);
  }
}

.icon-btn--black-white {
  background-color: black;
  & > :nth-child(1) {
    fill: white;
  }
}

.icon-btn--info {
  background-color: rgba(164, 79, 255, 0.2);
  & > :nth-child(1) {
    fill: #c4c4c4;
  }
}

.icon-btn--fb {
  background-color: var(--primary-dark);
  & > :nth-child(1) {
    fill: var(--facebook);
  }
}

.icon-btn--twitter {
  background-color: var(--primary-dark);
  & > :nth-child(1) {
    fill: var(--twitter);
  }
}

.icon-btn--web {
  background-color: var(--primary-dark);
  & > :nth-child(1) {
    fill: var(--secondary);
  }
}

.icon-btn--stroke {
  background-color: var(--stroke-dark);
  & > :nth-child(1) {
    fill: var(--secondary);
  }
}

.icon-btn--stroke-related {
  background-color: var(--stroke);
  & > :nth-child(1) {
    fill: var(--primary);
  }
}

.icon-btn--related {
  background-color: var(--background-dark);
  & > :nth-child(1) {
    fill: var(--object-fill-dark);
  }
}

.icon-btn--tint {
  background-color: var(--tint);
  & > :nth-child(1) {
    fill: var(--secondary);
  }
}

.icon-btn--web--success {
  background-color: var(--positive);
  & > :nth-child(1) {
    fill: var(--primary-dark);
  }
}

.filter-btn--inactive {
  background-color: #0f0f0f;
  & > :nth-child(1) {
    fill: var(--object-fill-dark);
  }
}

.filter-btn--active {
  background-color: var(--primary-dark);
  & > :nth-child(1) {
    fill: var(--primary);
  }
}

.icon-btn--close {
  max-height: 40px;
  background-color: rgba(127, 127, 127, 0.25);
  & > :nth-child(1) {
    fill: var(--object-fill-dark);
  }
}

.icon-btn--hide {
  background-color: rgba(255, 89, 100, 0.1);
  > :nth-child(1) {
    fill: var(--negative);
  }
}

.icon-btn--show {
  background-color: rgba(107, 241, 120, 0.1);
  > :nth-child(1) {
    fill: var(--positive);
  }
}

/*
 * Button colors -- primary tints
 */

.icon-btn--negative-tint {
  background-color: var(--negative-tint);
  > :nth-child(1) {
    fill: var(--negative);
  }
}

.icon-btn--neutral-tint {
  background-color: var(--neutral-tint);
  > :nth-child(1) {
    fill: var(--neutral);
  }
}

.icon-btn--positive-tint {
  background-color: var(--positive-tint);
  > :nth-child(1) {
    fill: var(--positive);
  }
}

.icon-btn--tip-tint {
  background-color: var(--tip-tint);
  > :nth-child(1) {
    fill: var(--tip);
  }
}

.icon-btn--warning-tint {
  background-color: var(--warning-tint);
  > :nth-child(1) {
    fill: var(--warning-tint);
  }
}

.icon-btn--white-tint {
  background-color: var(--tint-white);
  > :nth-child(1) {
    fill: white;
  }
}
</style>
