var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"ic",staticClass:"index__container"},[(!_vm.loading)?_c('IndexNav',{class:['index-nav', { 'with-index-feed-summary': _vm.showIndexFeedSummary}],attrs:{"name":"idealog","user":_vm.user}}):_vm._e(),_c('transition',{attrs:{"name":"menu-opacity"}},[(_vm.showFilterMenu)?_c('IndexMenu',{attrs:{"is-active":_vm.isActive,"on-mobile":_vm.onMobile,"on-desktop":_vm.onDesktop}}):_vm._e()],1),(_vm.showIndexFeedSummary)?_c('IndexFeedSummary',{class:['index-feed-summary', { 'hide': !_vm.showIndexFeedSummary}],attrs:{"is-active":_vm.isActive,"select-user-control":_vm.selectUserControl},on:{"toggle-control-on":_vm.handleToggleControl}}):_vm._e(),(_vm.onMobile)?_c('div',{class:['index__header',{
      'menu-is-collapsed': !_vm.showFilterMenu,
      'initial-height': _vm.showInitialChoice,
      'summary-height': !_vm.showInitialChoice && !_vm.showFilterMenu,
    }]},[(_vm.showInitialChoice && !_vm.loading)?_c('IndexInitialChoice',{on:{"toggle-control":_vm.handleToggleControl}}):_vm._e()],1):_vm._e(),(!_vm.onMobile && _vm.showInitialChoice && _vm.endDelay)?_c('div',{staticClass:"desktop-user-choice"},[_c('div',{staticClass:"duc-btn body-1 text-white croll",on:{"click":function($event){_vm.handleToggleControl(false)}}},[_vm._v("\n      Keep scrolling to load more of the curated feed\n    ")]),_c('div',{staticClass:"duc-btn body-1 text-white control",on:{"click":function($event){_vm.handleToggleControl(true)}}},[_vm._v("\n      Take control of the algorithm\n    ")])]):_vm._e(),_c('div',{ref:"sc",class:[
      'index__scroll-container',
      { 'with-desktop-menu': (_vm.showFilterMenu && !_vm.onMobile) || (!_vm.onMobile && _vm.showInitialChoice && _vm.endDelay) }
    ],on:{"scroll":_vm.checkScroll}},[(this.show)?_c('div',{ref:"cc",staticClass:"index__card-container",on:{"scroll":_vm.checkScroll}},[(!_vm.onMobile)?_c('div',{staticClass:"dim__promo"},[(_vm.show.length)?_c(_vm.getCardType(_vm.show[0]),{key:_vm.show[0].unit_id + '-card',tag:"component",staticClass:"index__cards promo-hero",attrs:{"unit":_vm.show[0]}}):_vm._e(),_c('div',{staticClass:"promo-secondary"},_vm._l((this.show.slice(1,3)),function(item){return _c(_vm.getCardType(item),{key:item.unit_id + '-card',tag:"component",staticClass:"index__cards promo-twins",attrs:{"unit":item}})}),1)],1):_vm._e(),_c('MasonryLayout',{attrs:{"columns":_vm.numberOfMasonryColumns,"gap":20,"items":_vm.onMobile ? _vm.show : _vm.show.slice(3)}})],1):_vm._e()]),(!_vm.onMobile)?_c('div',{class:[{'with-menu': _vm.showFilterMenu },'desktop-gradient']}):_vm._e(),(_vm.onMobile)?_c('div',{staticClass:"index__tools-gradient"}):_vm._e(),(_vm.onMobile)?_c('IndexTools',{staticClass:"index__tools",on:{"sort":_vm.applySort}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }