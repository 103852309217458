<template>
  <div
    v-cloak
    class="si__container"
  >
    <head>
      <CloseButton />
    </head>

    <div class="l-stepper">
      <div>
        <input
          v-model="$v.email.$model"
          autofocus="autofocus"
          class="headline"
          placeholder="Email address"
        >
        <input
          v-model="$v.password.$model"
          class="headline"
          placeholder="Password"
          type="password"
          @keyup.enter="validateCredentials"
        >
      </div>
    </div>

    <div style="display: flex; justify-content: space-between; align-items: center; max-width: 335px;">
      <router-link
        class="body-2 text-footnote"
        style="color: gray;"
        :to="{ name: 'register' }"
      >
        New to Idealog? Sign up now >>
      </router-link>
      <text-button
        class="btn--positive btn--pill text-center"
        :disabled="!this.$v.email.required || !this.$v.password.required"
        @click="validateCredentials"
      >
        Log in
      </text-button>
    </div>
    <ul
      v-if="errors"
      class="si__errors"
    >
      <li
        class="tag"
        v-for="(error, index) in errors"
        :key="index"
      >
        {{ error }}
      </li>
    </ul>
    <div style="padding-top: 32px;">
      <text-button
        class="btn--pill btn--hint-bg"
        style="height: 32px;"
        @click="handleForgotPasswordClick"
      >
        <div
          class="tag"
          style="margin-top: -2px;"
        >
          Forgot your password?
        </div>
      </text-button>
    </div>
    
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import CloseButton from '@/components/buttons/CloseButton.vue';
import TextButton from '@/components/buttons/TextButton.vue';
import { checkAuth, saveToken } from '@/store/localStorage';
import NProgress from 'nprogress';

export default {
  name: 'SignInContainer',
  components: {
    CloseButton,
    TextButton,
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
  },
  data() {
    return {
      errors: null,
      email: null,
      password: null,
    };
  },
  methods: {
    pause(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    handleForgotPasswordClick() {
      this.$router.push({ name: 'reset-password' });
    },
    validateCredentials() {
      this.errors = [];
      const vm = this;

      if (vm.$v.email.$invalid) {
        vm.errors.push('INVALID EMAIL ADDRESS');
      }

      if (!vm.$v.password.minLength) {
        vm.errors.push('PASSWORD MUST BE 6+ CHARACTERS');
      }

      if (!vm.errors || (vm.errors.length == 0)) {
        this.signInUser();
      }
    },
    signInUser() {
      const url = 'admin/sign_in.json';
      const data = new FormData();
      data.append('user[email]', this.email);
      data.append('user[password]', this.password);
      this.$http
        .post(url, data)
        .then(async (response) => {
          console.log({'data': response.data });
          await saveToken(response.data.auth_token);
          const userId = response.data.user_id;
          this.$store.dispatch('LOGIN')
            .then(async () => {
              console.log('fetching data');
              this.$store.dispatch('LOAD_UNITS');
            })
            .then(() => {
              this.$router.push({ name: 'unit-list'});
            })
        })
        .catch((error) => {
          this.errors = [error.response.data.message];
          NProgress.done();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] > * {
  display: none;
}

.si__container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-height: 812px;
  margin: 0 auto;
  padding: var(--global-padding);
  background: var(--background);
}

head {
  display: flex;
  margin-bottom: 264px;
  h1 {
    padding-top: 32px;
  }
}

.l-stepper {
  display: flex;
  flex-direction: column;
  max-width: 335px;
  margin-bottom: 10px;

  input {
    width: 100%;
    padding: 10px 12px;
  }
}

.si__errors {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  justify-content: space-around;
  padding: 10px 0;
  color: var(--tip);
  li {
    margin-bottom: 5px;
  }
}


button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}
</style>
