<template>
  <div
    class="settings-button"
    @click="emitToggleSetting"
  >
    <IconButton
      :class="['icon-btn--32', iconClass]"
      :icon="activeIcon"
    />
    <div :class="['action-2', textColor]">
      {{ label }}
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";

export default {
  name: 'SettingsButton',
  components: {
    IconButton
  },
  props: {
    setting: {
      type: String,
      required: true,
    },
    showing: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showingTemp: this.showing
    };
  },
  computed: {
    textColor() {
      return this.showingTemp ? 'text-positive' : 'text-negative';
    },
    iconClass() {
      return this.showingTemp ? 'icon-btn--show' : 'icon-btn--hide';
    },
    activeIcon() {
      return this.showingTemp ? "mdi-eye" : "mdi-eye-off-outline";
    }
  },
  methods: {
    emitToggleSetting() {
      const { setting, showing } = this;
      this.showingTemp = !this.showingTemp;
      const update = { setting, showing: this.showingTemp }
      this.$eventBus.$emit('emit-toggle-setting', update);
    }
  }
}
</script>

<style lang="scss">
.settings-button {
  display: inline-flex;
  align-items: center;
  height: 32px;
  border-radius: 12px;
  margin-right: 20px;
  cursor: pointer;
  > :first-child {
    margin-right: 6px;
  }
}

.show-bg {
  background-color: rgba(107, 241, 120, 0.1);
}

.hide-bg {
  background-color: rgba(255, 89, 100, 0.1);
}
</style>

