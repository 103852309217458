<template>
  <div>
    <div class="js-click-outside" v-click-outside="clickOutside">

      <div class="editor-header">
        <ImageUploader ref="uploader" :image="user.image" :name="user.name" />

        <div class="headline name-input">
          <input
            @input="this.checkError"
            placeholder="First name"
            v-model="$v.newFirstName.$model"
          >
          <div class="tag error">
            <div v-if="!$v.newFirstName.required">
              FIRST NAME REQUIRED
            </div>
          </div>


            <input
              @input="this.checkError"
              placeholder="Last name"
              v-model="$v.newLastName.$model"
            >
            <div class="tag error">
              <div v-if="!$v.newLastName.required">
                LAST NAME REQUIRED
              </div>
            </div>
        </div>

      </div>

      <textarea
        class="body-1"
        placeholder="What's your deal?"
        v-model="newBio"
      ></textarea>

      <text-button
        class="btn--positive btn--pill"
        :disabled="error"
        @click="validateProfile"
        style="margin-bottom: 20px;"
      >
        Save profile
      </text-button>

      <div v-if="showError" class="tag error">
        <div v-if="$v.newBio.$invalid">
          BIO CAN NOT EXCEED 500 CHARACTERS.
        </div>
      </div>

    </div>
    <div class="click-outside-mask"></div>
  </div>
</template>

<script>
import TextButton from '@/components/buttons/TextButton.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import ImageUploader from '@/components/account/ImageUploader.vue';
import { mapActions, mapState } from 'vuex';
import { maxLength, required } from 'vuelidate/lib/validators';
import clickOutside from '@/helpers/click-outside';
import { loadToken } from '@/store/localStorage';

export default {
  name: 'UserProfileEditor',
  mixins: [clickOutside],
  data() {
    return {
      newFirstName: this.user.firstName,
      newLastName: this.user.lastName,
      newImage: null,
      newBio: this.user.bio,
      error: false,
      cropper: null,
      showError: false,
    };
  },
  props: {
    user: Object,
  },
  validations: {
    newFirstName: { required },
    newLastName: { required },
    newBio: { maxLength: maxLength(500) },
  },
  components: {
    IconButton,
    ImageUploader,
    TextButton,
  },
  computed: {
    ...mapState(['loading', 'purchases']),
    editingImage() {
      if (this.cropper === null) {
        return false;
      }
      return !!this.cropper.chosenFile;
    },
  },
  created() {},
  methods: {
    ...mapActions(['LOAD_PURCHASES', 'LOAD_USER']),
    checkError() {
      this.error = '';
      if (!this.newFirstName && this.newLastName) {
        this.error = "WHAT'S YOUR FIRST NAME?";
      } else if (this.newFirstName && !this.newLastName) {
        this.error = "WHAT'S YOUR LAST NAME?";
      } else if (!this.newFirstName && !this.newLastName) {
        this.error = "WHAT'S YOUR FULL NAME?";
      } else {
        this.error = false;
      }
    },
    clickOutside() {
      this.$emit('edit');
    },
    updateProfile() {
      const url = `users/${this.$store.state.user.id}`;
      const file = this.$refs.uploader.cropper.getChosenFile();
      this.$refs.uploader.cropper.generateBlob((blob) => {
        const formData = new FormData();
        formData.append('user[first_name]', this.newFirstName);
        formData.append('user[last_name]', this.newLastName);
        formData.append('user[bio]', this.newBio);
        formData.append('auth_token', loadToken());
        if (blob) {
          formData.append('user[image]', blob, file.name);
        }
        this.$http
          .put(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            // eslint-disable-next-line
            console.log(response);
            this.LOAD_USER().then(() => {
              this.$emit('edit');
            });
          });
      });
    },
    validateProfile() {
      if (this.$v.newBio.$invalid) {
        this.showError = true;
      } else {
        this.updateProfile();
      }
    },
  },
  directives: {},
};
</script>

<style lang="scss" scoped>
.editor-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
  .name-input {
    display: flex;
    flex-direction: column;
  }
  span {
    height: 20px;
  }
  input {
    width: 80%;
    height: 28px;
    padding: 2px;
    border: none;
    border-radius: 4px;
    background-color: transparent;
  }
}

.error {
  color: #a44fff;
  height: 20px;
  padding-top: 4px;
}

textarea {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  padding: 2px;
  margin-bottom: 20px;
  display: block;
  border: none;
  resize: none;
  background-color: transparent;
}

input:focus,
textarea:focus {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.click-outside-mask {
  height: 44px;
  min-width: 83%;
  margin-top: -44px;
  margin-left: 115px;
  z-index: 3;
}
</style>
