<template>
  <div 
    v-if="!loading && user"
    class="sales-flow-matrix"
  >
    <div class="sales-flow-tray">
      <div class="sfm-title">
        <div class="title-1">
          {{ activeTitle }}
        </div>

        <div class="headline text-secondary">
          <span
            v-if="(currentStep == 0) && !alreadyPurchased"
            :class="{'body-1': descriptionIsLong}"
          >{{ bundle.description }}</span>

          <span v-if="(currentStep == 1) && !alreadyPurchased">How we got here</span>

          <span v-if="(currentStep == 2) && !alreadyPurchased">
            Contribute for access to
            <span
              class="text-primary"
              @click="currentStep == 0"
            >
              <b>{{ bundle.title }}</b>
            </span> and exclusive updates, events, and content
          </span>

          <span v-if="currentStep == 3 || alreadyPurchased">
            You are now a stakeholder in
            <span
              class="text-primary"
              @click="goToWholetonePage"
            >
              <b>{{ bundle.metadata.project }}</b>
            </span>
          </span>
        </div>
      </div>

      <BundleInfoModule
        v-if="(currentStep == 0) && !alreadyPurchased"
        :unit="bundle"
      />

      <ProjectInfoModule
        v-if="(currentStep == 1) && !alreadyPurchased"
        :artist="bundle.ensemble_leader"
        :series="bundle.series[0].series_leader"
      />

      <PaymentInfoModule
        v-show="(currentStep == 2) && !alreadyPurchased"
        @increment="incrementAfterPaymentRequest"
        @other-methods-purchase="completeOtherMethodsPurchase"
        @purchase="completeStripePurchase"
        :unit="bundle"
      />

      <PurchaseCompleteModule
        v-if="currentStep == 3 || alreadyPurchased"
        :download-url="bundle.asset.download.url"
        :user-id="user.id"
      />

      <div id="card-number-to-send" />
    </div>

    <div class="sales-toolbelt-wrap">
      <SalesToolbelt
        :current-step="currentStep"
        :next-page-info="nextPageInfo"
        @increment="incrementStep"
      />
    </div>

    <div class="sales-bottom-tray">
      <SalesBottomTray
        :current-step="currentStep"
        :price="bundle.price"
      />
    </div>
  </div>
</template>

<script>
import BundleInfoModule from '@/components/sales/BundleInfoModule.vue';
import PaymentInfoModule from '@/components/sales/PaymentInfoModule.vue';
import PurchaseCompleteModule from '@/components/sales/PurchaseCompleteModule.vue';
import ProjectInfoModule from '@/components/sales/ProjectInfoModule.vue';
import SalesBottomTray from '@/components/sales/SalesBottomTray.vue';
import SalesToolbelt from '@/components/sales/SalesToolbelt.vue';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'SalesFlowMatrix',
  components: {
    BundleInfoModule, PaymentInfoModule, PurchaseCompleteModule, ProjectInfoModule, SalesToolbelt, SalesBottomTray
  },
  data() {
    return {
      alreadyPurchased: null,
      currentStep: 0,
      creditCard: {
        provider: 'VISA',
        lastFour: 9273,
      },
      otherMethodsPaymentInfo: {},
      stripePaymentInfo: {
        cardNumber: null,
        error: null,
        stripe: null,
        success: false,
        token: '',
      },
    };
  },
  computed: {
    ...mapState(['activeBundleId', 'bundle', 'loading', 'unit', 'user']),
    activeTitle() {
      let title;
      switch (this.currentStep) {
        case 0:
          title = `${this.bundle.title}`;
          break;
        case 1:
          title = 'About the project';
          break;
        case 2:
          title = 'Payment info';
          break;
        case 3:
          title = 'Thanks!';
          break;
      }

      if (this.alreadyPurchased) {
        title = 'Thanks!';
      }

      return title;
    },
    activeSubtitle() {
      let subtitle;

      switch (this.currentStep) {
        case 0:
          subtitle = `${this.bundle.description}`;
          break;
        case 1:
          subtitle = 'How we got here';
          break;
        case 2:
          subtitle = `Contribute for access to ${
            this.bundle.title
          } and exclusive updates, events, and content`;
          break;
        case 3:
          subtitle = `You are now a stakeholder in ${this.bundle.metadata.project}`;
          break;
        default:
          subtitle = 'Subitle';
          break;
      }

      return subtitle;
    },

    descriptionIsLong() {
      return this.bundle.description.length > 200;
    },
    profitShare() {
      const contract = this.bundle.asset_timeline.filter(
        o => o.type == 'contract',
      )[0];

      return contract.details.profit_share;
    },
    nextPageInfo() {
      let info;
      if (this.unit) {
        info = {
          title: this.unit.title,
          unit_id: this.unit.unit_id,
        }
      } else {
        info = {
          title: 'the Index',
          unit_id: null,
        }
      }

      return info;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.loadBundle();  
      },
      immediate: true,
    },
  },
  // activated() {
  //   const currentStep = this.alreadyPurchased ? 3 : 0;
  //   this.currentStep = currentStep;
  // },
  mounted() {
    if (!this.user) {
      this.LOAD_USER();
    }

    this.calculateVH;
    window.addEventListener('resize', this.calculateVH);
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateVH);
  },
  methods: {
    ...mapActions(['LOAD_USER']),
    async loadBundle() {
      if (this.$route.params.unit_id && !this.bundle) {
        await this.$store.dispatch('LOAD_UNIT', {
          id: this.$route.params.unit_id,
          isBundle: true,
        });

        this.currentStep = this.bundle['already_purchased?'] ? 3 : 0;
        this.alreadyPurchased = this.bundle['already_purchased?'];
      }
    },
    completeOtherMethodsPurchase(paymentInfo) {
      this.otherMethodsPaymentInfo = paymentInfo;

      // set up POST request to record transaction in DB
      // let url = "buy/" + this.bundle.bundle_id;

      // this.$http
      //   .post(url, paymentInfo)
      //   .then(response => {
      //     // eslint-disable-next-line
      //     console.log(response);
      //   });

      this.otherMethodsPaymentInfo.success = true;
      setTimeout(() => {
        this.incrementStep();
      }, 500);
    },

    completeStripePurchase(paymentInfo) {
      this.stripePaymentInfo = paymentInfo;
      const cardIsSaved = Object.keys(paymentInfo).includes('last4');

      if (cardIsSaved) {
        this.purchaseWithSavedCard();
      } else {
        this.purchaseWithNewCard();
      }
    },

    calculateVH() {
      this.$_.debounce(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 500);
    },

    goToWholetonePage() {
      // eslint-disable-next-line
      console.log("hook to go to wholetone page");
    },

    purchaseWithNewCard() {
      this.stripePaymentInfo.stripe
        .createToken(this.stripePaymentInfo.cardNumber)
        .then((result) => {
          if (result.error) {
            this.stripePaymentInfo.error = result.error.message;
          } else {
            this.stripePaymentInfo.token = result.token.id;
            const url = `buy/${this.bundle.bundle_id}`;
            this.$http
              .post(url, {
                stripeToken: this.stripePaymentInfo.token,
              })
              .then((response) => {
                // eslint-disable-next-line
                console.log(response);
                this.stripePaymentInfo.success = true;
                setTimeout(() => {
                  this.incrementStep();
                }, 500);
              });
          }
        });
    },

    purchaseWithSavedCard() {
      // let url = "buy/" + this.bundle.bundle_id;
      // this.$http
      //   .post(url, {
      //     last4: this.paymentInfo.last4,
      //   })
      //   .then(response => {
      //     // eslint-disable-next-line
      //     console.log(response);
      //     this.paymentInfo.success = true;
      //     setTimeout(() => {
      //       this.incrementStep();
      //     }, 500);
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   })

      this.paymentInfo.success = true;
      setTimeout(() => {
        this.incrementStep();
      }, 500);
    },

    incrementAfterPaymentRequest() {
      this.paymentInfo.success = true;
      this.currentStep++;
    },

    incrementStep(step) {
      if (typeof step === 'number') {
        this.currentStep = step;
      } else {
        this.currentStep++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/*
* Layout of main components: flow tray, toolbelt, bottom tray
*/

.sales-flow-matrix {
  position: relative;
  max-width: 600px;
  height: calc(var(--vh, 1vh) * 100);
}

.sales-flow-tray {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 100px;
  max-height: calc(var(--vh, 1vh) * 100);
  padding: var(--global-padding);
  overflow-y: scroll;
  background-color: var(--background);
}

#card-number-to-send {
  display: none;
}

.sales-toolbelt-wrap {
  position: absolute;
  height: 40px;
  left: 0px;
  right: 0px;
  bottom: 60px;
}

.sales-bottom-tray {
  position: absolute;
  height: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: var(--primary);
}

/*
* Layout of main components: flow tray, toolbelt, bottom tray
*/

.sfm-title {
  position: relative;
  padding-top: 12px;
  margin-bottom: 32px;

  div:first-child {
    margin-bottom: 0;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
</style>
