import Vue from 'vue';
import Vuex from 'vuex';
import 'es6-promise/auto';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import Croppa from 'vue-croppa';
import Vuelidate from 'vuelidate';
import moment from 'moment';
import Toast from 'vue2-toast';
import 'vue2-toast/lib/toast.css';
import TapAndHold from 'vue-tap-and-hold';
import _ from 'lodash';
import { ObserveVisibility } from 'vue-observe-visibility';
import axiosInstance from './store/axiosInstance.js';
import $node from './store/axiosV2';
import store from './store/store.js';
import router from './router';
import App from './App.vue';

Vue.prototype.$http = axiosInstance;
Vue.prototype.$node = $node;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$moment = moment;
Vue.prototype.$_ = _;

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VuePlyr);
Vue.use(Croppa);
Vue.use(Vuelidate);

Vue.use(Toast, {
  type: 'center',
  duration: 2000,
  wordWrap: true,
  width: 'auto',
});

Vue.use(TapAndHold, {
  holdTime: 701,
  tapTime: 20,
});

Vue.directive('scroll', {
  inserted(el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

Vue.directive('observe-visibility', ObserveVisibility);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});

Vue.config.keyCodes = {
  previous: [37, 38],
  next: [39, 40],
};
