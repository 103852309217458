<template>
  <div class="made-with-wholetone">
    <div class="micro">
      <div class="text-footnote">
        <span>Made with </span>
        <span class="text-white"><b>Wholetone</b></span>
      </div>
      <IconButton
        class="icon-btn--tint-hint"
        icon="mdi-information"
      />
    </div>
    <div class="small-screen-up">
      <div class="text-footnote-hint">
        Made with
      </div>
      <div
        class="title-2 text-white"
        style="display: flex; align-items: center; margin-bottom: 0px;"
      >
        Wholetone &nbsp;
        <IconButton
          class="icon-btn--tint-hint"
          icon="mdi-information"
        />
      </div>
      <div class="text-footnote">
        Equitable, transparent <br>
        creative collaboration for all
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'MadeWithWholetone',
  components: {
    IconButton,
  },
}
</script>

<style lang="scss" scoped>
.made-with-wholetone {
  width: inherit;
}

.micro {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div:first-child {
    margin-right: 12px;
  }
  @include for-size(medium-screen-up) {
    display: none;
  }
}

.small-screen-up {
  @include for-size(small-screen-only) {
    display: none;
  }
}
</style>


