<template>
  <text-button
    :class="[`btn--${theme}-tint`, 'btn--pill', 'smaller-btn']"
    size="small"
    @click="$emit('click')"
  >
    {{ text }}
  </text-button>
</template>

<script>
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'ThemedButton',
  components: {
    TextButton
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'positive'
    }
  },
}
</script>

