<template>
  <input
    :value="value"
    class="headline"
    :placeholder="placeholder"
    :required="required"
    :type="password ? 'password' : 'text'"
    @input="emitValue"
  >
</template>

<script>
export default {
  name: 'text-input',
  props: {
    field: String,
    password: {
      default: false,
      type: Boolean,
    },
    placeholder: String,
    required: {
      default: false,
      type: Boolean,
    },
    value: String,
    // add option to include label
    quick: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    emitValue(e) {
      this.$emit('input', { value: e.target.value, field: this.field });
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
  display: block;
  outline: none;
  border: none;
  margin-bottom: var(--global-padding);
  &:invalid {
    opacity: 0.5;
  }
}
</style>
