<template>
  <div class="profit-share">
    <svg
      width="40"
      height="40"
    >
      <g>
        <path
          ref="artistShare"
          :style="translate"
        />
        <path
          v-if="full"
          ref="seriesShare"
          :style="translate"
        />
      </g>
    </svg>
    <div
      v-if="withLabel"
      :class="[windowWidth < 370 ? 'title-2' : 'title-1', 'text-white']"
    >
      {{ pctShare }}
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'profit-share',
  props: {
    full: {
      default: false,
      type: Boolean,
    },
    share: Number,
    withLabel: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    this.drawArcs();
  },
  computed: {
    pctShare() {
      return `${this.share}%`;
    },
    translate() {
      return { transform: 'translate(20px, 20px)' };
    },
    windowWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    drawArcs() {
      const artistShare = d3.select(this.$refs.artistShare);
      const seriesShare = d3.select(this.$refs.seriesShare);

      const tau = Math.PI * 2;
      const artistAngle = tau * (Number(this.share) / 100);
      // const seriesEndAngle = tau * ((1 - this.share))

      const artistArc = d3
        .arc()
        .innerRadius(8)
        .outerRadius(20)
        .startAngle(0)
        .endAngle(artistAngle);

      const seriesArc = d3
        .arc()
        .innerRadius(8)
        .outerRadius(20)
        .endAngle(tau)
        .startAngle(artistAngle);

      artistShare
        .style('fill', '#6bf178') // var(--positive);
        .attr('d', artistArc);

      seriesShare
        .style('fill', 'rgba(0,0,0,.5)')
        .attr('d', seriesArc);
    },
  },
};
</script>

<style lang="scss" scoped>
.profit-share {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

svg {
  margin-right: 6px;
}
</style>
