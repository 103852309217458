<template>
  <div class="sort-tool">
    <text-button
      v-for="(state, key) in isActive"
      :key="'sort-type-' + key"
      :class="['btn--pill', {
        'filter-btn--active': state, 
        'filter-btn--inactive': !state, 
      }]"
      @click="toggleView(key)"
    >
      <div>
        {{ key.slice(0,1).toUpperCase() + key.slice(1) }}
      </div>
    </text-button>
  </div>
</template>

<script>
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'ViewTool',
  components: { TextButton },
  data() {
    return {
      isActive: {
        asset: false,
        bundle: true,
        creator: false,
      },
    }
  },
  methods: {
    toggleView(key) {
      const views = ['asset', 'bundle', 'creator'];
      const activeView = views.find(view => view === key);
      const inactiveViews = views.filter(view => view !== key);

      this.isActive[activeView] = true;
      inactiveViews.forEach(view => {
        this.isActive[view] = false;
      });
      this.$eventBus.$emit('update-view', activeView);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
