<template>
  <div>
    <div v-if="!selectUserControl" class="index__menu-summary" @click="emitToggleControlOn">
      Showing <span class="options">curated feed</span>
    </div>

    <div v-if="selectUserControl" class="index__menu-summary" @click="emitToggleControlOn">
      Showing
      <span v-for="(type,i) in activeTypes" :key="'type-' + i">
        <component :is="typeIcons[type]" class="type-icon"></component>
      </span>
        from
      <span v-if="activeRoles" class="options">{{ activeRoles }} </span>

      <span v-if="activeRels"> who are <span class="options">{{ activeRels }} </span></span>
      <span v-if="activeTaste"> that are <span class="options">{{ activeTaste }}</span> to you </span>
    </div>
  </div>
</template>

<script>
import {
  MdiCamera,
  MdiList,
  MdiMusic,
  MdiVideoCam,
} from '@/components/graphics';

export default {
  name: 'index-feed-summary',
  components: {
    MdiCamera,
    MdiList,
    MdiMusic,
    MdiVideoCam,
  },
  props: {
    isActive: Object,
    selectUserControl: Boolean,
  },
  data() {
    return {
      typeIcons: {
        audio: 'mdi-music',
        image: 'mdi-camera',
        article: 'mdi-list',
        video: 'mdi-video-cam',
      },
      unitTypes: ['audio', 'image', 'article', 'video'],
    };
  },
  computed: {
    activeRels() {
      const relationships = Object.keys(this.isActive).slice(0, 2);
      const active = relationships.filter(type => this.isActive[type]);

      let text;
      if (active.length == 1) {
        text = `${active[0]}s`;
      } else if (active.length == 2) {
        text = 'mutuals';
      } else {
        text = 'everyone';
      }
      return text;
    },

    activeRoles() {
      const roles = Object.keys(this.isActive).slice(2, 4);
      const active = roles.filter(type => this.isActive[type]);

      const text = active.length == 1 ? `${active[0]}s` : 'creators & curators';

      return text;
    },

    activeTaste() {
      // const taste = Object.keys(this.related).slice(2,4);
      const active = [];

      let text;
      if (active.length == 1 && active.includes('doppelganger')) {
        text = 'similar';
      } else if (active.length == 1 && active.includes('nemesis')) {
        text = 'dissimilar';
      } else if (active.length == 2) {
        text = 'similar & dissimilar';
      } else {
        text = null;
      }

      return text;
    },
    activeTypes() {
      const active = this.unitTypes.filter(type => this.isActive[type]);
      return active;
    },
  },
  methods: {
    emitToggleControlOn() {
      this.$emit('toggle-control-on', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.index__menu-summary {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 10px;
  line-height: 12px;
  padding-bottom: 5px;
  .options {
    color: var(--primary-dark);
  }
  .type-icon {
    fill: white;
    width: 16px;
    height: 12px;
  }
}
</style>
