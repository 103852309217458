<template>
  <div v-if="!loading" class="purchase-complete-module">

    <div class="pcm-actions">
      <text-button
        class="btn--neutral btn--pill"
        @click="goToUserPurchases"
      >
        View in library
      </text-button>

      <a :href="downloadUrl">
        <IconButton
          class="icon-btn--tint"
          icon="mdi-folder-download"
        />
      </a>
    </div>

    <div class="text-footnote" style="margin-bottom: 32px;">
      We've also emailed you a download link
    </div>

    <BundleReceipt />

    <!-- <div class="pcm__receipt-placeholder-wrap">
      <img :src="require('@/assets/png/bundle-receipt-inner.png')"/>
    </div> -->

    <RadialBarChart
      class="pcm__radial-bar-chart"
      category="heat"
      small
      :stats="stats"
    />
    <div class="pcm__chart-label">
      <span class="tag text-secondary">+</span>
      <span class="tag text-negative"> HEAT</span>
    </div>


  </div>
</template>

<script>
import BundleReceipt from '@/components/sales/BundleReceipt.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import RadialBarChart from '@/components/stats/RadialBarChart.vue';
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'purchase-complete-module',
  components: {
    BundleReceipt,
    IconButton,
    RadialBarChart,
    TextButton,
  },
  props: {
    downloadUrl: String,
    userId: Number,
  },
  data() {
    return {
      error: null,
      loading: true,
      stats: {
        indices: {},
        sums: {},
        avgTimeOnPage: 0,
      },
    };
  },
  created() {
    this.fetchStats();
  },
  methods: {
    fetchStats() {
      this.stats.sums = this.stats.indices = {};
      this.loading = true;

      if (this.$route.params.unit_id) {
        const url = `units/${this.$route.params.unit_id}/stats`;

        this.$http
          .get(url)
          .then((response) => {
            const uStats = response.data.unit.stats;
            const pStats = response.data.project.stats
              ? response.data.project.stats
              : this.noProject;

            this.stats.sums = {
              timeOnPage: 0.00001,
              ...uStats.sums,
              ...pStats.sums,
            };

            this.stats.indices = {
              timeOnPage: 0.000001,
              ...uStats.indices,
              ...pStats.indices,
              ...uStats.superindices,
            };

            this.stats.avgTimeOnPage = uStats.avgs.timeOnPage;

            this.loading = false;
          })
          .catch((error) => {
            this.error = error;
            // eslint-disable-next-line
            console.log(error);
          });
      }
    },
    goToUserPurchases() {
      this.$router.push({ name: 'purchases', params: { id: this.userId } });
    },
  },
};
</script>

<style lang="scss" scoped>
.purchase-complete-module {
  position: relative;
  display: flex;
  flex-direction: column;
}

.pcm-actions {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > :first-child {
    margin-right: 12px;
  }
}

.pcm__receipt-placeholder-wrap {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: calc(100vw * 3.23);
  background-color: transparent;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
  }
}

.pcm__radial-bar-chart {
  position: absolute;
  top: -150px;
  right: 26px;
}

.pcm__chart-label {
  position: absolute;
  top: -156px;
  right: 0px;
}
</style>
