<template>
  <div class="email-footer">
    <button>
      <MdiSettings
        class="svg-white-hint"
        style="margin-right: 6px;"
      />
      <span
        class="tag text-hint-white"
        style="text-align: left;"
      >
        YOUR SETTINGS
      </span>
    </button>
    <div class="text-footnote">
      Unsubscribe
    </div>
    <div class="app-logo-mobile">
      <img :src="require('@/assets/png/app-logo-mobile.png')">
    </div>
  </div>
</template>

<script>
import MdiSettings from '@/components/graphics/MdiSettings.vue';

export default {
  name: 'EmailFooter',
  components: {
    MdiSettings
  }
}
</script>

<style lang="scss" scoped>
.email-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 32px);
  height: 57px;
  margin-bottom: -10px;
  padding: 6px 10px;
  button {
    display: flex;
    align-items: center;
    width: 100px;
    cursor: pointer;
    background-color: transparent;
  }
}

.app-logo-mobile {
  width: 65px;
  height: 45px;
  padding: 0;
  img {
    width: 100%;
    height: auto;
  }
}
</style>


