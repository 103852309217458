<template>
  <div class="user-profile">
    <div class="profile-header">
      <span class="avatar-wrap" :title="user.name">
        <img draggable="false" :src= user.image />
      </span>
      <p class="title-1 name">{{ user.name }}</p>
      <div @click="editProfile" class="btn__wrap" v-if="user === this.$store.state.user ">
        <IconButton icon="mdi-pencil" />
      </div>
      <div v-else class="btn__wrap" @click="toggleFollow">
        <IconButton
          class="btn--52"
          :class="userFollowStatus ? '' : 'icon-btn--tint'"
          :icon="userFollowStatus ? 'mdi-account-check' : 'mdi-account-plus'"
        />
      </div>
    </div>
    <div class="profile-summary body-1">
      {{ this.user.bio }}
    </div>
  </div>
</template>

<script>
import IconButton from '../buttons/IconButton.vue';

export default {
  name: 'UserProfile',
  props: {
    user: Object,
  },
  components: {
    IconButton,
  },
  computed: {
    userFollowStatus() {
      return this.$store.getters.getUserFollowById(this.user.user_id);
    },
  },
  methods: {
    // toggleFollow() {
    //   let url = "users/" + this.user.id + "/following";
    //   this.$http.get(url).then(response => {
    //     this.following = response.data.following;
    //     this.$store.commit("setUserFollow", {
    //       id: this.user.user_id,
    //       liked: this.following,
    //     });
    //     // this.total_follows = response.data.total_follows;
    //   });
    // },
    toggleFollow() {
      this.userFollowStatus = !this.userFollowStatus;
    },
    editProfile() {
      this.$emit('edit');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-button{
  position:absolute;
  right:0;
}
.mdi-account-plus{

}
.profile-header {
  position:relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  & > :first-child {
    height: 56px;
    width: 56px;
    margin-right: 20px;
  }
  .name {
    margin-bottom: 0px;
  }
  & > :nth-child(3) {
    margin-left: 20px;
  }
  .btn__wrap {
    right:0;
    top:8px;
    display: inline-block;
    position: absolute;
  }
  .btn--52 {
    height: 52px;
    width: 52px;
    cursor: pointer;
    &.grey{
      background:var(--tint);
      *{
        color:rgba(0, 0, 0, 1)!important;
      }
    }
  }
}
</style>
