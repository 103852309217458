<template>
  <div class="event-block">
    <div
      class="subtitle text-sec-white"
      style="margin-bottom: 20px;"
    >
      {{ details.description }}
    </div>
    <div class="subtitle text-white">
      <MdiCalendarClock />
      {{ this.$moment(details.date).format("MMMM D, YYYY") }}
    </div>
    <div class="subtitle text-white">
      <MdiEarth />
      <b>{{ details.location }}</b>
    </div>
    <div
      class="subtitle text-white"
      style="margin-left: 22px;"
    >
      <b>{{ details.city }}</b>
    </div>
  </div>
</template>

<script>
import MdiCalendarClock from '@/components/graphics/MdiCalendarClock.vue';
import MdiEarth from '@/components/graphics/MdiEarth.vue';

export default {
  name: 'EventBlock',
  components: {
    MdiCalendarClock,
    MdiEarth,
  },
  props: {
    details: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}

svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  fill: white;
}
</style>

