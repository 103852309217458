<template>
  <div class="qa-prompt">
    <div class="tag text-sec-white">
      A QUESTION FOR YOU
    </div>
    <QaPromptThread
      :question="question"
    />
  </div>
</template>

<script>
import QaPromptThread from '@/components/qa/QaPromptThread.vue';

export default {
  name: 'QaPrompt',
  components: {
    QaPromptThread
  },
  props: {
    question: {
      default() {
        return {};
      },
      type: Object
    },
  }
}
</script>

<style lang="scss" scoped>
.qa-prompt {
  padding-top: 32px;
  > :first-child {
    margin-bottom: 10px;
  }
  // @include for-size(small-desktop-up) {
  //   padding-top: 0px;
  // }
}
</style>
