<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
		<rect x="11" y="7" width="6" height="2"/>
		<rect x="11" y="11" width="6" height="2"/>
		<rect x="11" y="15" width="6" height="2"/>
		<rect x="7" y="7" width="2" height="2"/>
		<rect x="7" y="11" width="2" height="2"/>
		<rect x="7" y="15" width="2" height="2"/>
		<path d="M20.1,3H3.9C3.4,3,3,3.4,3,3.9v16.2C3,20.5,3.4,21,3.9,21h16.2c0.4,0,0.9-0.5,0.9-0.9V3.9C21,3.4,20.5,3,20.1,3z M19,19H5
			V5h14V19z"/>
  </svg>
</template>

<script>
export default {
  name: 'mdi-list',
};
</script>

<style scoped>

</style>
