<template>
  <div
    v-if="unit"
    :key="unit.unit_id"
    class="unit-component"
  >
    <div
      id="unit-split-matrix"
      :class="{ 'with-app-nav': appNavIsActive }"
      @click.self="deactivateAppNav"
    >
      <img
        class="app-nav-activator"
        :src="require('@/assets/png/app-nav-activator.png')"
        @click="appNavIsActive = !appNavIsActive"
      >

      <UnitGrid
        :lower-state="contextState"
        @toggle-context-state="activateContextState"
      />

      <transition name="tools">
        <ProfileToolbelt
          v-if="unit"
          :class="[contextTrayHeight, { 'disable-during-app-nav': appNavIsActive }]"
          :lower-state="contextState"
          :type="toolbeltType"
          @toggle-profile-love="handleToggleProfileLove"
          @toggle-share-tool="activateContextState"
        />
      </transition>
      <div :class="[contextTrayHeight, 'tools-loading']" />

      <ContextTray
        ref="ct"
        :class="['context-tray', contextTrayHeight, { 'disable-during-app-nav': appNavIsActive }]"
        :context-state="contextState"
      />
    </div>
  </div>
</template>

<script>
import ContextTray from '@/components/unit/ContextTray.vue';
import ProfileToolbelt from '@/components/tools/ProfileToolbelt.vue';
import UnitGrid from '@/components/_unit/UnitGrid.vue';
import { mapState } from 'vuex';

export default {
  name: 'UnitComponent',
  components: {
    ContextTray,
    ProfileToolbelt,
    UnitGrid  
  },
  data() {
    return {
      appNavIsActive: false,
      contextState: 'preview',
      loading: false,
      windowHeight: null,
      toolbeltType: 'publicLove'
    };
  },
  computed: {
    ...mapState(['bundle', 'unit', 'user']),
    contextTrayHeight() {
      return `context-height--${this.contextState}`;
    },
    isNativeAudio() {
      return this.unit.unit_type === 'audio' && this.unit.audio.file.mp3;
    },
    hasPlayer() {
      const types = ['audio', 'playlist', 'video'];
      return types.includes(this.unit.unit_type) && this.isNativeAudio;
    },
  },
  watch: {
    $route: 'fetchData',
  },
  created() {
    if (this.$store.state.units.length == 0) {
      this.$store.dispatch('LOAD_UNITS');
    }
    this.$eventBus.$on('toggle-context-state', this.activateContextState);
    this.$eventBus.$on('toggle-tool', this.handleToolTransition);
    this.fetchData();

  },
  mounted() {
    this.calculateDocumentVariables();
    window.addEventListener('resize', this.$_.debounce(this.calculateDocumentVariables, 200, {
      leading: true,
      trailing: false,
    }));
  },
  beforeDestroy() {
    this.$eventBus.$off('toggle-context-state');
    this.$eventBus.$off('toggle-tool');
    window.removeEventListener('resize', this.calculateDocumentVariables);
  },
  methods: {
    pause(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    calculateDocumentVariables() {
      if (!this.windowHeight || this.windowHeight !== window.innerHeight) {
        this.windowHeight = window.innerHeight;
        // let vh = this.windowHeight * 0.01;
        let vw = window.innerWidth * 0.01;
        const ms = (this.windowHeight - 224) / this.windowHeight;
        const ts = (this.windowHeight - 336) / this.windowHeight;
        const ds = 336 / this.windowHeight;
        // document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}`);
        document.documentElement.style.setProperty('--ms', `${ms}`);
        document.documentElement.style.setProperty('--ts', `${ts}`);
        document.documentElement.style.setProperty('--ds', `${ds}`);
      }
    },
    deactivateAppNav() {
      if (this.appNavIsActive) {
        this.appNavIsActive = false;
      }
    },
    handleToggleProfileLove(payload) {
      this.toolbeltType = payload ? 'publicLoveSent' : 'publicLove';
    },
    async fetchData() {
      // this.loading = true;
      if (this.$route.params.unit_id) {
        const id = this.$route.params.unit_id;
        await this.$store.dispatch('LOAD_UNIT', {
          id,
          isBundle: false,
        });
      }
      this.toolbeltType = this.hasPlayer ? 'publicPlayable' : 'publicLove';
    },
    activateContextState(newState) {
      this.contextState = newState;
    },
    async handleToolTransition(toggledState) {
      const newState = this.contextState == toggledState
        ? 'preview'
        : toggledState;

      this.activateContextState(newState);
    },
  },
}
</script>

<style lang="scss" scoped>
/*
 * Component container & App navigator
 */

.unit-component {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 100%;
  max-height: 100%;
  resize: both;
  background-color: var(--background-dark);
}

.app-nav-activator {
  flex: 0 0 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  height: 40px;
  z-index: 10;
  img {
    width: 100%;
    height: 100%;
  }
}

.disable-during-app-nav {
  pointer-events: none;
}

/*
 * The split matrix container
 */

#unit-split-matrix {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms ease;
  transform-origin: center center;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  background-color: #000; /*DARKMODE*/
  z-index: 2;
}

#unit-split-matrix.with-app-nav {
  @include for-size(phone-only) {
    transform: translate(-50%, -50%) scale(var(--ms)) !important;
    transition: transform 300ms ease;
  }
  @include for-size(tablet-portrait-up) {
    transform: translate(-50%, -50%) scale(var(--ts)) !important;
    transition: transform 300ms ease;
  }
  @include for-size(small-desktop-up) {
    transform: translate(-50%, -50%) scale(var(--ds)) !important;
    transition: transform 300ms ease;
  }
}

/*
 * Tools
 */

.profile-toolbelt,
.tools-loading {
  position: absolute;
  margin: 0px 0 0 0;
  width: 100%;
  width: inherit;
  z-index: 3;
  bottom: auto;
  transition: bottom 300ms ease;
  &.context-height--active {
    bottom: calc(var(--vh, 1vh) * 100 - 100px);
    // transition: bottom 500ms ease;
  }
  &.context-height--summary,
  &.context-height--react {
    bottom: 255px;
  }
  &.context-height--preview {
    bottom: 60px;
  }
  &.context-height--purchase {
    bottom: 112px;
  }
  &.context-height--share {
    bottom: 168px;
  }
}

.tools-loading {
  height: 40px;
  background-color: black;
  z-index: 0;
}

/*
 * Context tray
 */

.context-tray {
  z-index: 2;
  display: flex;
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
  position: absolute;
  bottom: 0;
  width: inherit;
  height: inherit;
  background-color: black;
  transition: height 300ms ease;
  &.context-height--active {
    height: calc(var(--vh, 1vh) * 100 - 100px);
    // transition: bottom 500ms ease;
    > div {
      height: calc(var(--vh, 1vh) * 100 - 100px);
    }
  }
  &.context-height--summary,
  &.context-height--react {
    height: 255px;
    > div {
      height: 255px;
    }
  }
  &.context-height--preview {
    height: 60px;
    > div {
      height: 60px;
      min-height: 60px;
    }
  }
  &.context-height--purchase {
    height: 112px;
    > div {
      height: 112px;
      min-height: 112px;
    }
  }
  &.context-height--share {
    height: 168px;
    > div {
      height: 168px;
      min-height: 168px;
    }
  }
}

/*
 * Transitions
 */

.tools-enter-active {
  transition: opacity 250ms 150ms;
}

.tools-enter,
.tools-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
::-webkit-scrollbar {
  width: 17px;
  background-color: transparent;
  display: none;
}
</style>

