<template>
  <div class="unit-info-creators">
    <div class="text-footnote-hint">
      A collaboration with
    </div>
    <EntityChip
      v-for="entity in ['ensemble', 'series']"
      :key="`uic-ec-${entity}`"
      :name="credits[entity].name"
      :image="credits[entity].image_url"
      :size="size"
      theme="white"
    />
    <div class="uic__others">
      <AvatarGroup
        v-if="credits.creators.length"
        :entities="credits.creators"
        :length="credits.creators.length"
        size="s"
        style="margin-right: 20px;"
      />
      <ThemedButton
        v-if="!hideMoreInfo"
        text="More info"
        theme="neutral"
      />
    </div>
  </div>
</template>

<script>
import AvatarGroup from '@/components/entities/AvatarGroup.vue';
import EntityChip from '@/components/entities/EntityChip.vue';
import ThemedButton from '@/components/buttons/ThemedButton.vue';

export default {
  name: 'UnitInfoCreators',
  components: {
    AvatarGroup,
    EntityChip,
    ThemedButton,
  },
  props: {
    bundleCredits: {
      type: [Object, Boolean],
      default: false,
    },
    hideMoreInfo: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
    }
  },
  computed: {
    credits() {
      return this.bundleCredits || this.$store.state.unit.project;
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-info-creators {
  display: flex;
  flex-direction: column;
  height: 172px;
  width: inherit;
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
}

.uic__others {
  display: flex;
  align-items: center;
}
</style>

