<template>
  <div class="filter-summary">
    Showing
    <span
      v-for="(filter,i) in activeFilters"
      :key="'filter-summary-type-' + i"
    >
      <component
        :is="filterIcons[filter]"
        class="filter-icon"
      />
    </span>
    sorted by {{ sortText }}
  </div>
</template>

<script>
import {
  MdiCamera,
  MdiList,
  MdiMusic,
  MdiVideoCam,
} from '@/components/graphics';

export default {
  name: 'FilterSummary',
  props: {
    activeFilters: {
      required: true,
      type: Array,
    },
    activeSort: {
      required: true,
      type: String,
    },
  },
  components: {
    MdiCamera,
    MdiList,
    MdiMusic,
    MdiVideoCam,
  },
  data() {
    return {
      filterIcons: {
        music: 'mdi-music',
        image: 'mdi-camera',
        article: 'mdi-list',
        video: 'mdi-video-cam',
      },
    }
  },
  computed: {
    sortText() {
      return (this.activeSort === 'alpha') ? 'a-z' : 'date';
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-summary {
  width: 210px;
  text-align: center;
  color: var(--secondary-white);
  font-size: 12px;
  line-height: 12px;
  z-index: 10;
  .options {
    color: var(--primary-dark);
  }
  .filter-icon {
    fill: var(--primary-dark);
    width: 16px;
    height: 12px;
  }
}
</style>

