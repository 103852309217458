<template>
  <div class="email-editor" v-click-outside="clickOutside">
    <ul>
      <li class="tag error" v-if="showErrors" v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </ul>
    <input
      class="headline email"
      @input="checkErrors()"
      placeholder="New email address"
      v-model="$v.newEmail.$model"
    >
    <input
      class="headline"
      @input="checkErrors()"
      type="password"
      placeholder="Password"
      v-model="pwAttempt"
    >
    <text-button
      v-if="errors"
      class="btn--full btn--positive"
      @click="showErrors=true"
      :disabled="emptyField"
    >
      Save email
    </text-button>
    <text-button
      v-else
      class="btn--full btn--positive"
      @click="this.updateEmail"
    >
      Save email
    </text-button>
  </div>
</template>

<script>
import TextButton from '@/components/buttons/TextButton.vue';
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import clickOutside from '@/helpers/click-outside';

export default {
  name: 'EmailEditor',
  components: { TextButton },
  mixins: [clickOutside],
  data() {
    return {
      newEmail: '',
      pwAttempt: '',
      errors: true,
      emptyField: true,
      showErrors: false,
    };
  },
  props: {
    email: String,
  },
  validations: {
    newEmail: { required, email },
  },
  methods: {
    ...mapActions(['LOAD_USER']),
    checkErrors() {
      this.errors = [];
      this.showErrors = false;
      let valid = true;

      if (!this.$v.newEmail.required || !this.pwAttempt) {
        valid = false;
        this.emptyField = true;
      } else if (!this.$v.newEmail.email) {
        valid = false;
        this.errors.push('INVALID EMAIL ADDRESS');
      }

      if (valid) {
        this.errors = false;
        this.emptyField = false;
      }
    },
    checkPassword() {
      this.errors = [];
      this.showErrors = false;
      const url = `users/${this.$store.state.user.id}/check_password`;
      return this.$http
        .get(url, {
          params: {
            oldPassword: this.pwAttempt,
          },
        })
        .then((response) => {
          if (!response.data.validPassword) {
            this.errors.push('INVALID PASSWORD');
            this.showErrors = true;
          }
          return response.data.validPassword;
        });
    },
    updateEmail() {
      this.checkPassword().then((valid) => {
        if (valid) {
          const url = `users/${this.$store.state.user.id}`;
          this.$http
            .put(url, {
              user: {
                email: this.newEmail,
              },
            })
            .then((response) => {
              // eslint-disable-next-line
              console.log(response);
              this.LOAD_USER().then(() => {
                this.$emit('editCredentials');
              });
            });
        }
      });
    },
    clickOutside() {
      this.$emit('editCredentials');
    },
  },
  directives: {},
};
</script>

<style lang="scss" scoped>
.email-editor {
  width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  input {
    width: 335px;
    height: 58px;
    background: white;
    border: none;
    padding: 20px 12px;
  }
  .email {
    border-radius: 10px 10px 0px 0px;
  }
}

input:focus {
  outline: none;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

ul {
  width: 335px;
  display: flex;
  list-style: none;
  justify-content: space-around;
  padding: 10px 0px;
}

.error {
  color: #a44fff;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}
</style>
