<template>
  <div
    class="qa-head"
    @click="activateQa"
  >
    <div class="title-2 text-white">
      Commentary
    </div>
    <QaPreview class="qh__preview" />
    <IconButton
      :class="themeClass"
      :icon="icon"
      @click="toggleContextState"
    />
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';
import QaPreview from '@/components/qa/QaPreview.vue';

export default {
  name: "QaHead",
  components: {
    IconButton,
    QaPreview
  },
  props: {
    icon: {
      type: String,
      required: true,
    }
  },
  computed: {
    themeClass() {
      const theme = this.icon === 'mdi-chevron-down' ? 'icon-btn--tint-white' : '';
      return theme;
    },
  },
  methods: {
    toggleContextState() {
      const state = this.icon === 'mdi-chevron-down' ? 'preview' : 'active';
      this.$eventBus.$emit('toggle-context-state', state);
    },
    activateQa() {
      if (this.icon !== 'mdi-chevron-down') {
        this.$eventBus.$emit('toggle-context-state', 'active');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.qa-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--stroke-white);
}

.qh__preview {
  display: none;
  opacity: 0;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 320px;
  padding-right: 52px; 
  @include for-size(medium-screen-up) {
    display: flex;
    opacity: 1;
    transition: opacity 300ms ease;
  }
}

.title-2 {
  margin-bottom: 0px;
}
</style>
