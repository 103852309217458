<template>
  <div class="app-nav-footer">
    <div class="text-footnote text-hint-white">
      5 notifications want your attention
    </div>
    <div class="anf__inbox-preview"></div>
    <div class="anf__links">
      <div class="action-2 text-sec-white">
        Terms of Service
      </div>
      <div class="action-2 text-sec-white">
        Privacy Policy
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-nav-footer',
  methods: {
    goToPrivacyPolicy() {
      this.$router.push({ name: 'unit-list' });
    },
    goToTerms() {
      this.$router.push({ name: 'account' });
    },
    goToLibrary() {
      this.$router.push({ name: 'purchases' });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-nav-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0px;
  background-color: transparent;
  @include for-size(phone-only) {
    height: 66px;
    right: 10px;
    left: 10px;
  }
  @include for-size(tablet-portrait-up) {
    height: 116px;
    right: 32px;
    left: 32px;
    transition: height 300ms ease;
  }
  @include for-size(small-desktop-up) {
    height: 142px;
    transition: height 300ms ease;
  }
}

.anf__inbox-preview {
  width: 100%;
  background-color: #C4C4C4;
  @include for-size(phone-only) {
    height: 40px;
    width: 100%;
    transition: width 300ms ease height 300ms ease;
  }
  @include for-size(tablet-portrait-up) {
    width: 394px;
    height: 80px;
    transition: width 300ms ease height 300ms ease;
  }
  @include for-size(small-desktop-up) {
    width: 394px;
    height: 108px;
    transition: height 300ms ease;
  }
}

.anf__links {
  position: absolute;
  display: flex;
  justify-content: space-between;
  right: 0px;
  @include for-size(phone-only) {
    opacity: 0;
  }
  @include for-size(tablet-portrait-up) {
    flex-direction: column;
    top: 32px;
    height: 50px;
    text-align: right;
    opacity: 1;
    transition: opacity 300ms ease width 300ms ease height 300ms ease;
  }
  @include for-size(small-desktop-up) {
    flex-direction: row;
    top: 90px;
    width: 233px;
    height: 20px;
    transition: width 300ms ease height 300ms ease;
  }
}
</style>
