<template>
  <div class="project-info-module flex flex-col">
    <div class="body-1" style="margin-bottom: 10px">
      Stakeholders
    </div>
    <div class="pim__stakeholders-wrap">
      <template v-for="(stakeholder, i) in this.stakeholders">
        <div :key="stakeholder.name + '-' + i" class="at__user">
          <AvatarIcon
            :image="stakeholder.image"
            :title="stakeholder.name"
          />
          <div class="tag">{{ stakeholder.name }}</div>
          <div class="text-footnote">{{ stakeholder.role }}</div>
          <div
            v-if="i == 1"
            class="text-footnote text-disabled"
          >
            You're #72
          </div>
        </div>
      </template>

      <div class="pim__witnesses-wrap">
        <div
          class="pim__witness-wrap"
          style="width: 84px; height: 78px; left: -10px; top: -22px;"
        >
          <img :src="require('@/assets/png/recent.png')"/>
        </div>
        <div
          class="pim__witness-wrap"
          style="width: 76px; height: 56px; left: -6px; top: -18px;"
        >
          <img :src="require('@/assets/png/recenter.png')"/>
        </div>
        <div
          class="pim__witness-wrap"
          style="width: 68px; height: 60px; left: -2px; top: -14px;"
        >
          <img :src="require('@/assets/png/recentest.png')"/>
        </div>
      </div>

    </div>

    <div class="body-1" style="margin-bottom: 10px">
      Expenses & recovery
    </div>

    <div class="pim__funding-timeline">
      <img :src="require('@/assets/png/funding-timeline.png')"/>
    </div>

    <div style="margin-bottom: 10px">
      <EntityChip
        :image="artist.image"
        :name="artist.name"
        size="s"
      />
      <span class="body-1" style="vertical-align: middle;">'s terms</span>
    </div>

    <ContractCell
      :event="unit.asset_timeline[1]"
      noBackground
      style="box-sizing: content-box; margin-left: -10px; margin-bottom: 35px;"
    />
  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import ContractCell from '@/components/timeline/ContractCell.vue';
import EntityChip from '@/components/entities/EntityChip.vue';

import { mapState } from 'vuex';

const stripeKey = 'pk_test_gq2OXLu20ERr8lrnCuUVNV8J';

export default {
  name: 'project-info-module',
  components: {
    AvatarIcon,
    ContractCell,
    EntityChip,
  },
  props: {
    artist: Object,
    bundleTitle: String,
    creditCard: Object,
    price: String,
    profitShare: Number,
    series: Object,
  },
  data() {
    return {
      currencyFormat: {
        style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2,
      },
    };
  },
  computed: {
    ...mapState(['unit', 'user']),
    artistTake() {
      return (this.profitShare / 100 * this.price).toLocaleString('en-US', this.currencyFormat);
    },
    seriesTake() {
      const seriesTake = (100 - this.profitShare) / 100 * this.price;

      return seriesTake.toLocaleString('en-US', this.currencyFormat);
    },
    stakeholders() {
      const stakeholders = [
        {
          name: this.series.name.toUpperCase(),
          image: this.series.image,
          role: 'Series',
        },
        {
          name: 'YOU',
          image: this.user.image,
          role: 'Supporter',
        },
        {
          name: this.artist.name.toUpperCase(),
          image: this.artist.image,
          role: 'Ensemble',
        },
      ];

      return stakeholders;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.pim__stakeholders-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.at__user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  max-width: 70px;
  height: auto;
  max-height: 92px;
  overflow: visible;
  & > :nth-child(2) {
    padding-top: 4px;
    text-align: center;
    transition: color 300ms;
  }
}

.pim__funding-timeline {
  width: 80%;
  height: calc(64% * 80vw);
  margin: 0 auto 20px auto;
  // margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
  }
}

.pim__witnesses-wrap {
  position: absolute;
  width: 64px;
  left: calc(50% - 64px/2);
  top: 0px;
  bottom: 0px;
}

.pim__witness-wrap {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
  }
}

</style>
