<template>
  <div class="image-input">
    <AvatarIcon
      class="avatar--xl"
      :image="editingImage ? '' : image"
      :title="name"
    />

    <div  @click="cropper.chooseFile();">
      <IconButton icon="mdi-upload" class="upload" />
    </div>

    <croppa
      v-model="cropper"
      ref="cropper"
      class="avatar-wrap croppa-wrap"
      :height=80
      :width=80
      placeholder=""
      :disabled="false"
      disable-click-to-choose
      :prevent-white-space="false"
      :show-remove-button="true"
    >
    </croppa>

  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'ImageUploader',
  components: { AvatarIcon, IconButton },
  props: {
    image: String,
    name: String,
  },
  data() {
    return {
      cropper: null,
    };
  },
  computed: {
    editingImage() {
      if (this.cropper === null) {
        return false;
      }
      return !!this.cropper.chosenFile;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-input {
  display: flex;
  & > :first-child {
    align-self: flex-end;
    margin-right: -80px;
    z-index: 1;
  }
  & > :nth-child(2) {
    position: absolute;
    bottom: 5px;
    left: 0px;
    z-index: 3;
  }
  & > :nth-child(3) {
    height: 80px;
    width: 80px;
    margin-right: 20px;
    z-index: 2;
  }
}

.upload {
  background: #35a7ff;
  height: 28px;
  width: 28px;
}

.croppa-wrap {
  height: 80px;
  width: 80px;
  background-color: transparent;
}
</style>
