<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13.5,5A3.5,3.5 0 0,0 10,8.5A3.5,3.5 0 0,0 13.5,12C14.25,12 14.94,11.76 15.5,11.36C16.07,11.76 16.76,12 17.5,12A3.5,3.5 0 0,0 21,8.5A3.5,3.5 0 0,0 17.5,5C16.76,5 16.08,5.25 15.5,5.65C14.95,5.24 14.25,5 13.5,5M13.5,6.5A2,2 0 0,1 15.5,8.5A2,2 0 0,1 13.5,10.5A2,2 0 0,1 11.5,8.5A2,2 0 0,1 13.5,6.5M17.5,6.5A2,2 0 0,1 19.5,8.5A2,2 0 0,1 17.5,10.5C17.14,10.5 16.81,10.4 16.5,10.23C16.82,9.72 17,9.13 17,8.5C17,7.87 16.82,7.28 16.5,6.77C16.81,6.6 17.14,6.5 17.5,6.5M3,8V11H0V13H3V16H5V13H8V11H5V8H3M13.5,13C11.33,13 7,14.08 7,16.25V19H24V16.25C24,14.08 19.67,13 17.5,13C16.95,13 16.25,13.08 15.5,13.22C14.78,13.08 14.07,13 13.5,13M13.5,14.5C15.94,14.5 18.5,15.71 18.5,16.25V17.5H8.5V16.25C8.5,15.71 11.06,14.5 13.5,14.5M19.08,14.66C20.93,15 22.5,15.83 22.5,16.25V17.5H20V16.25C20,15.63 19.64,15.1 19.08,14.66Z" /></svg>
</template>

<script>
export default {
  name: 'mdi-account-multiple-plus',
};
</script>

<style scoped>
  svg{
    position:relative;
    left:-3px;
  }
</style>
