<template>
  <div class="bundle-receipt">
    <div class="br__header header-text">
      ***RECEIPT*** <br>
      POWERED BY <br>
      WHOLETONE
    </div>

    <div class="br__metadata">
      {{ formattedTransactionDate }} <br>
      {{ user.firstName.toUpperCase() }}  {{ user.lastName.toUpperCase() }}<br>
      VISA ######4242
    </div>

    ITEMS
    <hr style="border-top: dashed 1px; margin-top: 3px;" />

    <div
      class="header-text"
      style="margin-bottom: 20px;"
    >
      {{ bundle.title.toUpperCase() }}
    </div>

    TRACKS
    <ol>
      <li v-for="track in bundle.tracks" :key="'receipt-track-' + track.track_id">
        {{ track.name.toUpperCase() }}
      </li>
    </ol>

    <hr style="border-top: dashed 1px; margin-bottom: 32px;" />

    <div class="br__price">
      <div class="br__total header-text">
        <div>AMOUNT</div>
        <div>{{ formattedPrice }}</div>
      </div>

      <div>
        <div>SUB-TOTAL</div>
        <div>{{ formattedPrice }}</div>
      </div>

      <div>
        <div>TAX</div>
        <div>N/A (NY)</div>
      </div>

      <div>
        <div>BALANCE</div>
        <div>{{ formattedPrice }}</div>
      </div>
    </div>

    <div class="br__transaction-id header-text">
      TRANSACTION ID: <br>
      00193758012
    </div>

    <div class="br__barcode-wrap">
      <div class="br__barcode">
        <img :src="require('@/assets/png/barcode.png')"/>
      </div>
      <div style="font-size: 10px; line-height: 10px;">00193758012</div>
    </div>


  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'bundle-receipt',
  computed: {
    ...mapState(['bundle', 'user']),
    formattedPrice() {
      return `$${this.bundle.price}.00`;
    },
    formattedTransactionDate() {
      const today = this.$moment();
      return this.$moment(today).format('MM/DD/YY    hh:mmA');
    },
  },
};
</script>

<style lang="scss" scoped>
.header-text {
  font-size: 28px;
  line-height: 29px;
  letter-spacing: -0.02em;
}

.bundle-receipt {
  width: 100%;
  padding: 0px;
  font-family: 'VT323', monospace;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: -0.02em;
}

.br__header {
  text-align: center;
  margin-bottom: 32px;
}

.br__metadata {
  margin-bottom: 10px;
}

li {
  margin-bottom: -2px;
}

.br__price {
  display: flex;
  flex-direction: column;
  padding: 0px 36px;
  margin-bottom: 32px;

  > div {
    display: flex;
    justify-content: space-between;
  }
}

.br__total {
  margin-bottom: 16px;
}

.br__transaction-id {
  text-align: center;
  margin-bottom: 20px;
}

.br__barcode-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48vw;
  height: calc(24% * 48vw);
  margin: 0px auto;
}

.br__barcode {
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
  }
  margin-bottom: 4px;
}
</style>
