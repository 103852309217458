<template>
  <div class="rm__container" :class="expanded? 'is-expanded' : 'is-collapsed'">

    <div :class="expanded ? 'rm__expansion-container' : 'rm__expansion__closed'">
      <!-- <div v-show="expanded" class="expanded-container"> -->
        <div>
          <div v-for="(avatar, i) in avatars" :class="expanded ? 'rm__source' : 'rm__source--closed'" :key="'avatar' + i">
              <AvatarIcon
                class="avatar--s avatar__shadow"
                :image="avatar.image"
                :title="avatar.filter"
                @click="toggleFilter(avatar.filter)"
              />
            <span v-html="avatar.html" class="action-2 rm__source-btn" :class="avatar.active ? 'rm__source--active' : 'rm__source--inactive'" @click="toggleFilter(avatar.filter)" />
          </div>
          <div :class="expanded ? 'rm__source' : 'rm__source--closed'">
            <IconButton
              class="icon-btn--s"
              :class="classes.mention"
              :icon="icons[0].icon"
              :title="icons[0].filter"
              @click="toggleFilter(icons[0].filter)"
            />
            <span v-html="icons[0].html" class="action-2 rm__source-btn" :class="icons[0].active ? 'rm__source--active' : 'rm__source--inactive'" @click="toggleFilter(icons[0].filter)" />
          </div>
        </div>
        <MdiChevronUp v-show="expanded" class="rm__chevron" @click="cancel" />
      <!-- </div> -->
    </div>

    <transition name="opacity">
      <div v-if="expanded" class="rm__icon-bar">
        <IconButton
          v-for="(icon, i) in icons.slice(1)"
          :key="'icon-expanded-' + i"
          class="icon-btn--s"
          :class="icon.active ? classes.iconActive : classes.iconInactive"
          :icon="icon.icon"
          style="z-index: 1001"
          @click="toggleFilter(icon.filter)"
        />
        <button class="rm__btn action-2" @click="applyFilter">
          Apply
        </button>
      </div>
    </transition>

    <transition name="opacity-2">
      <div v-if="!expanded" class="rm__icon-bar">
        <AvatarIcon
          v-for="(avatar, i) in activeAvatars"
          :key="'avatar' + i"
          class="avatar--s avatar__shadow"
          :class="avatar.class"
          :image="avatar.image"
          :title="avatar.filter"
        />
        <icon-button
          v-if="icons[0].active"
          class="icon-btn--s"
          :class="classes.mention"
          :icon="icons[0].icon"
        />
        <icon-button
          v-if="all.active"
          class="icon-btn--s"
          :class="classes.iconActive"
          :icon="all.icon"
        />
        <div v-else class="rm__icon-bar-types">
          <icon-button
            v-for="(icon, i) in activeIcons"
            :key="'icon' + i"
            class="icon-btn--s"
            :class="icon.filter == 'mention' ? classes.mention : classes.iconActive"
            :icon="icon.icon"
          />
        </div>
        <MdiChevronDown class="rm__chevron" @click="expandMenu" />
      </div>
    </transition>
  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import MdiCheck from '@/components/graphics/MdiCheck.vue';
import MdiChevronDown from '@/components/graphics/MdiChevronDown.vue';
import MdiChevronUp from '@/components/graphics/MdiChevronUp.vue';
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'RelatedMenu',
  components: {
    AvatarIcon,
    IconButton,
    MdiCheck,
    MdiChevronDown,
    MdiChevronUp,
    TextButton,
  },
  props: {
    unit: Object,
  },
  data() {
    return {
      prevFilterState: {},
      expanded: false,
      avatarList: ['creator', 'ensemble', 'series'],
      avatars: [
        {
          active: true,
          filter: 'creator',
          html: `Posted by <b>${this.unit.owner.full_name}</b>`,
          image: this.unit.owner.image_url,
          name: this.unit.owner.full_name,
        },
        {
          active: true,
          filter: 'ensemble',
          html: `Featuring ${this.unit.related_units[0].name}`,
          image: this.unit.related_units[0].image,
          name: this.unit.related_units[0].name,
        },
        {
          active: true,
          filter: 'series',
          html: `Posted by ${this.unit.related_units[1].name}`,
          image: this.unit.related_units[1].image,
          name: this.unit.related_units[1].name,
        },

      ],
      iconList: ['mention', 'audio', 'image', 'article', 'video'],
      icons: [
        {
          active: true,
          filter: 'mention',
          html: 'Mentioned here',
          icon: 'mdi-comment-check',
        },
        {
          active: true,
          filter: 'audio',
          icon: 'mdi-music',
        },
        {
          active: true,
          filter: 'video',
          icon: 'mdi-movie',
        },
        {
          active: true,
          filter: 'image',
          icon: 'mdi-camera',
        },
        {
          active: true,
          filter: 'article',
          icon: 'mdi-list',
        },
      ],
      classes: {
        mention: 'icon-btn--stroke-related',
        iconActive: 'icon-btn--related',
        iconInactive: 'icon-btn--tint',
      },
    };
  },
  computed: {
    activeAvatars() {
      return this.avatars.filter(avatar => avatar.active);
    },
    activeIcons() {
      return this.icons.filter((icon) => {
        if (icon.filter != 'mention') {
          return icon.active;
        }
      });
    },
    all() {
      const all = { active: null, filter: 'All', icon: 'mdi-asterisk' };
      const activeTypes = this.icons.slice(1).filter(icon => icon.active);

      if (activeTypes.length == 4) {
        all.active = true;
      } else {
        all.active = false;
      }

      return all;
    },
  },
  methods: {
    cancel() {
      const prevState = this.prevFilterState;

      this.avatarList.forEach((e) => {
        const avatar = this.avatars.filter(avatar => avatar.filter == e)[0];

        avatar.active = prevState[e];
      });

      this.iconList.forEach((e) => {
        const icon = this.icons.filter(icon => icon.filter == e)[0];

        icon.active = prevState[e];
      });

      this.expanded = false;
      this.$emit('toggle-expand');
    },
    expandMenu() {
      this.prevFilterState = this.getFilterStatus();
      this.expanded = true;
      this.$emit('toggle-expand');
    },
    getFilterStatus() {
      const filters = {};

      this.avatarList.forEach((e) => {
        const avatar = this.avatars.filter(avatar => avatar.filter == e)[0];

        filters[avatar.filter] = avatar.active;
      });

      this.iconList.forEach((e) => {
        const icon = this.icons.filter(icon => icon.filter == e)[0];

        filters[icon.filter] = icon.active;
      });
      console.log(filters);
      return filters;
    },
    applyFilter() {
      const filters = this.getFilterStatus();

      this.$eventBus.$emit('apply-filter', filters);
      this.expanded = !this.expanded;
      this.$emit('toggle-expand');
    },
    toggleFilter(filter) {
      if (this.avatarList.includes(filter)) {
        const avatar = this.avatars.filter(avatar => avatar.filter == filter)[0];

        avatar.active = !avatar.active;
      } else if (this.iconList.includes(filter)) {
        const icon = this.icons.filter(icon => icon.filter == filter)[0];
        icon.active = !icon.active;
      } else {

      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rm__container {
  position: fixed;
  top: 0;
  // width: 100%;
  z-index: 1030;
  background-color: var(--background);
  // box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  transition: height 300ms ease-in-out;
  @include for-size(phone-only) {
    min-width: 100vw;
    width: 100vw;
  }
  @include for-size(tablet-portrait-up) {
    min-width: 375px;
    max-width: 375px;
  }
}

.is-expanded {
  height: 254px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  transition: height 250ms 50ms ease-in-out;
}

.is-collapsed {
  height: 60px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  transition: height 300ms ease-in-out;
}

.rm__expansion-container {
  display: flex;
  height: 194px;
  padding: 10px;
  transition: height 300ms 100ms ease-in-out;
}

.rm__expansion__closed {
  display: flex;
  padding: 0px;
  height: 0px;
  transition: height 300ms ease-in-out, padding 300ms ease-in-out;
}

.expanded-container {
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

.rm__source {
  display: flex;
  align-items: center;
  height: 36px;
  opacity: 1;
  transition: height 300ms 150ms ease-in-out, opacity 300ms 150ms ease-in-out;
  margin-bottom: 10px;
  > :first-child {
    margin-right: 12px;
  }
}

.rm__source--closed {
  display: flex;
  height: 0px;
  opacity: 0;
  transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.rm__source-btn {
  height: 25px;
  border-radius: 30px;
  padding: 4px 12px;
  cursor: pointer;
}

.rm__source--active {
  color: var(--primary);
  background-color: var(--primary-dark);
  cursor: pointer;
}

.rm__source--inactive {
  color: var(--object-fill-dark);
  background-color: #0f0f0f;
}

.rm__btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  height: 35px;
  width: 110px;
  border-radius: 50px;
  margin-left: auto;
  padding: 7px 12px;
  border: 1px solid transparent;
  border-radius: 22px;
  background-color: var(--positive);
  border-color: var(--positive);
  color: var(--background);
  cursor: pointer;
}

.rm__icon-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: 10px;
  > div {
    margin-right: 7px;
  }

  .rm__icon-bar-types {
    display: flex;
    justify-content: flex-start;
    > div {
      margin-right: 7px;
    }
  }
}

.rm__check {
  margin-left: 10px;
  fill: var(--positive);
}

.rm__chevron {
  margin-left: auto;
  fill: var(--secondary);
}

.opacity-enter-active {
  transition: opacity 300ms 300ms;
}
.opacity-leave-active {
  transition: opacity 300ms;
}

.opacity-2-enter-active {
  transition: opacity 400ms 400ms, height 200ms 400ms;
}


.opacity-2-leave-active {
  transition: opacity 0ms, height 0ms;
}

.opacity-enter,
.opacity-leave-to,
.opacity-2-enter,
.opacity-2-leave-to {
  opacity: 0;
}

.opacity-2-enter, .opacity-2-leave-to {
  height: 0;
}


</style>
