<template>
  <div class="masonry-column">
    <transition-group
      mode="out-in"
      name="list"
      tag="div"
    >
      <div
        v-for="(item, i) in items"
        :key="`col-item-` + item.component + (Math.random()).toFixed(4) + i"
        :style="{'margin-bottom': gap + 'px', 'width': '100%' }"
      >
        <component
          :is="item.component"
          v-bind="item.props"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import ChannelsModule from '@/components/profile/ChannelsModule.vue';
import EntityInfo from '@/components/profile/EntityInfo.vue';
import EntityMembers from '@/components/profile/EntityMembers.vue';
import EntityRelationships from '@/components/profile/EntityRelationships.vue';
import ImpactModule from '@/components/impact/ImpactModule.vue';
import TimelineModule from '@/components/_timeline/TimelineModule.vue';
import UnitInfoAnswer from '@/components/_unit/UnitInfoAnswer.vue';
import UnitPreview from '@/components/unit/UnitPreview.vue';

export default {
  name: 'MasonryColumn',
  components: {
    ChannelsModule,
    EntityInfo,
    EntityMembers,
    EntityRelationships,
    ImpactModule,
    TimelineModule,
    UnitInfoAnswer,
    UnitPreview
  },
  props: {
    gap: {
      type: Number,
      default: 10,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.masonry-column {
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.list-enter-active {
  transition: opacity 200ms 200ms;
}

.list-leave-active {
  transition: opacity 200ms;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-move {
  transition: transform 300ms;
}
</style>
