<template>
  <div class="unit-info-sources">
    <MadeWithWholetone class="uis__wholetone-position" />
    <DataSources class="uis__data-sources-position" />
  </div>
</template>

<script>
import DataSources from '@/components/logos/DataSources.vue';
import MadeWithWholetone from '@/components/logos/MadeWithWholetone.vue';

export default {
  name: 'UnitInfoSources',
  components: {
    DataSources,
    MadeWithWholetone,
  },
}
</script>

<style lang="scss" scoped>
.unit-info-sources {
  position: relative;
  width: inherit;
  @include for-size(small-screen-only) {
    height: 86px;
  }
  @include for-size(medium-screen-up) {
    height: 152px;
  }
  @include for-size(small-desktop-up) {
    height: 174px;
  }
}

.uis__wholetone-position {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-bottom: 4px;
  @include for-size(small-screen-only) {
    right: 0px;
  }
  @include for-size(small-desktop-up) {
    bottom: 0px;
    top: auto;
  }
}

.uis__data-sources-position {
  position: absolute;
  right: 0px;
  bottom: 0px;
  @include for-size(small-desktop-up) {
    top: 0px;
    bottom: auto;
    margin-bottom: 40px;
  }
}
</style>


