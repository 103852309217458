<template>
  <div class="masonry-layout">
    <MasonryColumn
      v-for="(items, i) in columns"
      :gap="gap"
      :key="'masonry-column-' + i"
      :items="items"
      :style="{'margin-left': `${i > 0 ? gutter : 0}px`, flex: 1 }"
    />
  </div>
</template>

<script>
import MasonryColumn from '@/components/shared/MasonryColumn.vue';

export default {
  name: 'MasonryLayout',
  components: {
    MasonryColumn,
  },
  props: {
    gap: {
      default: 10,
      type: Number,
    },
    gutter: {
      default: 20,
      type: Number,
    },
    columns: {
      required: true,
      type: [Array, Object],
    },
  },
};
</script>

<style lang="scss" scoped>
.masonry-layout {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  > div {
    max-width: 100%;
  }
}
</style>
