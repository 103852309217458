<template>
  <div class="email-container">
    <div class="slot-container">
      <slot />
    </div>
    <EmailFooter />
  </div>
</template>

<script>
import EmailFooter from '@/components/_email/EmailFooter.vue';
import documentVariables from '@/helpers/document-variables.js';

export default {
  name: 'EmailContainer',
  mixins: [documentVariables],
  components: {
    EmailFooter,
  }
}
</script>

<style lang="scss" scoped>
.email-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding: 10px 16px;
  background-color: black;
  max-height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
}

.slot-container {
  margin-bottom: 12px;
}

.app-logo-mobile {
  width: 65px;
  height: 45px;
  padding: 0;
  img {
    width: 100%;
    height: auto;
  }
}

.slot-container {
  max-width: 600px;
}
</style>

