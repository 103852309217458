<template>
  <div class="qa-entity-menu">
    <div class="qem__header">
      <text-button
        :class="[
          'btn__layout--small',
          'btn--pill',
          askedFilter ? 'btn--tip' : 'filter-btn--inactive'
        ]"
        small-text
        @click="handleAskedButtonClick"
      >
        Asked
      </text-button>
      <EntityStamp :entity="entityPlaceholder" />
      <text-button
        :class="[
          'btn__layout--small',
          'btn--pill',
          answeredFilter ? 'btn--warning' : 'filter-btn--inactive'
        ]"
        small-text
        @click="handleAnsweredButtonClick"
      >
        Answered
      </text-button>
    </div>
    <div class="qem__tabs btn-group">
      <text-button
        v-for="(tab,i) in tabs"
        :key="`qem-tab-${i}`"
        :class="[
          mode == tab ? 'btn--black-white' : 'btn--tint-white',
          'btn__layout--small', 
          'btn--pill'
        ]"
        small-text
        @click="handleTabClick(tab)"
      >
        {{ `${tab.slice(0,1).toUpperCase()}${tab.slice(1)}` }}
      </text-button>
    </div>
  </div>
</template>

<script>
import EntityStamp from '@/components/entities/EntityStamp.vue';
import TextButton from '@/components/buttons/TextButton.vue';

export default {
  name: 'QaEntityMenu',
  components: {
    EntityStamp,
    TextButton,
  },
  props: {
    askedFilter: {
      default: false,
      type: Boolean,
    },
    answeredFilter: {
      default: false,
      type: Boolean,
    },
    entity: {
      default() {
        return {};
      },
      type: Object,
    },
    mode: {
      default: 'public',
      type: String,
    }
  },
  data() {
    return {
      entityPlaceholder: {
        name: 'Aubrey Graham',
        image: `${require("@/assets/img/purchases/drake.png")}`
      },
      tabs: ['public', 'hidden', 'inbox'],
    };
  },
  methods: {
    handleTabClick(tab) {
      this.$emit('toggle-mode', tab);
    },
    handleAskedButtonClick() {
      this.$emit('filter-asked');
    },
    handleAnsweredButtonClick() {
      this.$emit('filter-answered');
    }
  }
};
</script>

<style lang="scss" scoped>
.qa-entity-menu {
  margin-bottom: 32px;
}

.qem__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  > :nth-child(odd) {
    height: 36px;
    margin-top: 6px;
  }
}

.qem__tabs {
  display: flex;
  justify-content: center;
}
</style>
