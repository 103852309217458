<template>
  <img
    class="app-nav-activator"
    :src="require('@/assets/png/app-nav-activator.png')"
    @click="activateAppNav"
  >
</template>

<script>
export default {
  name: 'AppNavActivator',
  methods: {
    activateAppNav() {
      this.$eventBus.$emit('activate-app-nav');
    }
  }
}
</script>

<style lang="scss" scoped>
.app-nav-activator {
  flex: 0 0 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

