<template>
  <div class="aib">
    <textarea
      class="qa-bubble-card"
      placeholder="Add your answer"
      :value="answerInput"
      @input="updateAnswerInput"
    />
    <AvatarIcon
      class="qbc__avatar avatar--xs avatar__border--black"
      :image="avatar"
    />
  </div>
</template>

<script>
import AvatarIcon from '@/components/entities/AvatarIcon.vue';

export default {
  name: 'QaBubbleCard',
  components: {
    AvatarIcon,
  },
  props: {
    answerInput: {
      default: '', 
      type: String
    },
    avatar: {
      default: '', 
      type: String
    },
  },
  methods: {
    updateAnswerInput(event) {
      this.$emit('update-answer-input', event.target.value);
    } 
  }
};
</script>

<style lang="scss" scoped>
.aib {
  position: relative;
  margin-bottom: 10px;
}

.qa-bubble-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 10px);
  height: 80px;
  margin-left: 10px;
  margin-right: 0px;
  padding: 10px 40px 10px 10px;
  border-radius: 0px 0px 10px 10px;
  border: none;
  resize: none;
  color: var(--hint-white);
  background-color: var(--background-dark);
  :first-child {
    padding-bottom: 5px;
  }
}

.qbc__avatar {
  position: absolute;
  bottom: 8px;
  right: -12px;
  z-index: 10;
}
</style>

