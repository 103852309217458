<template>
  <div class="type-filters">
    <IconButton
      v-for="(type, key) in types"
      :key="'filter-type-' + key"
      :class="{
        'filter-btn--active': !allTypesAreActive && type.active, 
        'filter-btn--inactive': allTypesAreActive || !type.active, 
      }"
      :icon="type.icon"
      @click="toggleFilter(key)"
    />
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'TypeFilters',
  components: { IconButton },
  data() {
    return {
      types: {
        music: { icon: 'mdi-music', active: true },
        video: { icon: 'mdi-movie', active: true },
        image: { icon: 'mdi-camera', active: true },
        article: { icon: 'mdi-document', active: true },
      }
    }
  },
  computed: {
    allTypesAreActive() {
      const mediaTypes = ['music', 'video', 'image', 'article'];
      const numActive = mediaTypes.reduce((total, mediaType) => {
        return total += this.types[mediaType].active;
      }, 0);
      return (numActive === 4);
    }
  },
  methods: {
    toggleFilter(name) {
      const mediaTypes = ['music', 'video', 'image', 'article'];
      if (this.allTypesAreActive) {
        mediaTypes.filter(type => type !== name).forEach(type => {
          this.types[type].active = false;
        });
      } else {
        this.types[name].active = !this.types[name].active;
      }
    

      if (!mediaTypes.filter(type => this.types[type].active).length) {
        mediaTypes.forEach(type => {
          this.types[type].active = true;
        });
      }

      const activeFilters = mediaTypes.filter(type => this.types[type].active);
      this.$eventBus.$emit('update-type-filters', activeFilters);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
