<template>
  <div class="profile-edit-nav">
    <div class="text-footnote">
      Editing your profile
    </div>
    <div class="wholetone">
      <div class="tag text-neutral">
        WHOLETONE
      </div>
      <IconButton
        class="icon-btn--32 icon-btn--neutral-tint"
        icon="mdi-settings"
      />
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  name: 'ProfileEditNav',
  components: {
    IconButton,
  },
}
</script>

<style lang="scss" scoped>
.profile-edit-nav {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  height: 60px;
  background-color: black;
  z-index: 1000;
}

.wholetone {
  display: flex;
  align-items: center;
  width: 116px;
  > :first-child {
    width: auto;
    margin-right: 6px;
  }
}
</style>

