<template>
  <div class="avatar" @click="$emit('click')">
    <router-link v-if="link" :to="linkProps">
      <img draggable="false" :src="image">
    </router-link>
    <img v-else draggable="false" :src="image">
    <div class="check"></div>
  </div>
</template>

<script>
export default {
  name: 'AvatarIcon',
  props: {
    image: String,
    link: {
      type: Boolean,
      default: false,
    },
    linkProps: Object,
    title: {
      type: String,
      default: 'Avatar icon',
    },
  },
};
</script>


<style lang="scss" scoped>
.avatar,
.avatar-wrap {
  position: relative;
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.avatar--positive {
  border: double 2px transparent;
  border-image-slice: 1;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #00ff19, #148c00);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.avatar--checked {
  .check {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.25);
    background-image: url("../../assets/svg/check-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color 200ms ease-in;
  }
}

.avatar__border--black {
  border: solid 2px black;
}

.avatar__shadow {
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.1);
}

.avatar--xs {
  height: 24px;
  width: 24px;
  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    object-position: center center;
  }
}

.avatar--32 {
  height: 32px;
  width: 32px;
  img {
    width: 100%;
    height: 100%;
  }
}

.avatar--s {
  height: 32px;
  width: 32px;
}

.avatar--36 {
  height: 36px;
  width: 36px;
}

.avatar--l {
  min-height: 56px;
  min-width: 56px;
}

.avatar--72 {
  height: 72px;
  width: 72px;
}

.avatar--xl {
  height: 80px;
  width: 80px;
}

.avatar--square {
  border-radius: 8px;
}
</style>
