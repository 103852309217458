import Vue from 'vue';
import Router from 'vue-router';
import AppIndex from '@/components/unit/AppIndex.vue';
import AskTool from '@/components/tools/AskTool.vue';
import AccountComponent from '@/components/account/AccountComponent.vue';
import EmailWelcome from '@/components/_email/EmailWelcome.vue';
import FollowersList from '@/components/account/FollowersList.vue';
import FollowingList from '@/components/account/FollowingList.vue';
import IndexPage from '@/components/_index/IndexPage.vue';
import Intro from '@/components/account/Intro.vue';
import LibraryContainer from '@/components/library/LibraryContainer.vue';
import ProfileComponent from '@/components/profile/ProfileComponent.vue';
import RegistrationContainer from '@/components/account/RegistrationContainer.vue';
import ResetPassword from '@/components/account/ResetPassword.vue';
import SalesFlowMatrix from '@/components/sales/SalesFlowMatrix.vue';
import SignInContainer from '@/components/account/SignInContainer.vue';
import UpdatePasswordForm from '@/components/account/UpdatePasswordForm.vue';
import UnitComponent from '@/components/_unit/UnitComponent.vue';
import { logOut } from "../store/localStorage";
import store from "../store/store";

Vue.use(Router);

let router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      // Keep scroll position when using browser buttons
      return savedPosition;
    }
    return { x: false, y: false };
  },
  routes: [
    {
      name: 'home',
      path: '/',
      redirect: '/index',
    },
    {
      path: '/email',
      name: 'email',
      component: EmailWelcome,
      meta: { requiresAuth: false },
    },
    {
      path: '/units/:unitId/ask',
      name: 'unit-ask',
      component: AskTool,
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: '/index',
      name: 'index',
      component: IndexPage,
      meta: { requiresAuth: true },
    },
    {
      path: '/units/',
      name: 'unit-list',
      redirect: '/index',
      // component: AppIndex,
      meta: { requiresAuth: true },
    },
    {
      path: '/units/:unit_id',
      id: ':unit_id',
      name: 'unit-view',
      component: UnitComponent,
      meta: { requiresAuth: true },
    },
    {
      path: '/units/checkout/:unit_id',
      name: 'unit-checkout',
      component: SalesFlowMatrix,
      meta: { requiresAuth: true },
    },
    {
      path: '/account',
      name: 'account',
      component: AccountComponent,
      meta: { requiresAuth: false },
    },
    {
      path: '/profile/:entityType/:entityId',
      name: 'profile',
      component: ProfileComponent,
      meta: { requiresAuth: true },
      // props: (route) => ({ userId: route.query['user-id'] })
    },
    {
      path: '/followers',
      name: 'followers',
      component: FollowersList,
      meta: { requiresAuth: true },
    },
    {
      path: '/following',
      name: 'following',
      component: FollowingList,
      meta: { requiresAuth: true },
    },
    {
      path: '/library',
      name: 'purchases',
      component: LibraryContainer,
      meta: { requiresAuth: true },
    },
    {
      path: '/intro',
      name: 'intro',
      component: Intro,
      meta: {},
    },
    {
      path: '/register',
      name: 'register',
      component: RegistrationContainer,
      meta: {},
      beforeEnter: (to, from, next) => {
        logOut();
        next();
      }
    },
    {
      path: '/signin',
      name: 'signin',
      component: SignInContainer,
      meta: {},
    },
    {
      path: '/password/reset',
      name: 'reset-password',
      component: ResetPassword,
      meta: {},
      beforeEnter: (to, from, next) => {
        logOut();
        next();
      }
    },
    {
      path: '/password/update',
      name: 'update-password',
      component: UpdatePasswordForm,
      meta: {},
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.loggedIn) {
      next();
    } else {
      next({
        path: '/signin'
      })
    }
  } else {
    next();
  }
})

export default router;
