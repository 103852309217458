<template>
  <a class="close-button" @click="$router.go(-1)">
    <MdiClose />
  </a>
</template>

<script>
import MdiClose from '../graphics/MdiClose.vue';

export default {
  name: 'CloseButton',
  components: { MdiClose },
};
</script>

<style lang="scss" scoped>
.close-btn {
  fill:white;
  height: 40px;
  width: 40px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
