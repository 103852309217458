<template>
  <div class="entity-info">
    <div :class="['ei__header', `ei__header--${entity.type}`]">
      <div
        :class="[`ei__header--${entity.type}`, 'hide-header-medium-up']"
        :style="headerBackground"
      />
      <div :class="[`ei__header--${entity.type}`, 'hide-header-mobile']">
        <img
          draggable="false"
          :src="entity.image_url"
        >
      </div>
      <div :class="['ei__header--gradient', `ei__header--${entity.type}`]" />
    </div>

    <div class="ei__info">
      <div class="name-box">
        <div
          v-if="!editingName"
          class="title-1 text-white"
        >
          {{ entityCache.name || entity.name }}
        </div>
        <input
          v-else
          v-model="$v.entityCache.name.$model"
          class="title-1"
          placeholder="Name"
          type="text"
          @input="emitName"
        >
        <IconButton
          v-if="editingProfile"
          class="icon-btn--32 icon-btn--black-white"
          :icon="editingName ? 'mdi-close' : 'mdi-pencil'"
          @click="toggleEditingName"
        />
      </div>
      <div class="info-genres tag">
        <span
          v-for="(genre, i) in entity.genres"
          :key="`em-info-genre-${i}`"
        >
          {{ genre.toUpperCase() }}
        </span>
      </div>
      <div v-if="editingProfile">
        <SettingsButton
          label="Genres"
          setting="genres"
          :showing="settings.genres"
        />
        <SettingsButton
          label="Genre colors"
          setting="genre_colors"
          :showing="settings.genre_colors"
        />
      </div>
      <div class="info-location-followers text-footnote">
        <IconButton
          v-if="editingProfile"
          class="icon-btn--32 icon-btn--black-white"
          :icon="editingLocation ? 'mdi-close' : 'mdi-pencil'"
          @click="toggleEditingLocation"
        />
        <div v-if="!editingLocation">
          {{ entity.location }}
        </div>
        <div
          v-else
          style="display: flex; flex-wrap: nowrap; align-items: center; max-width: 85%;"
        >
          <input
            v-model="entityCache.city"
            class="tag"
            placeholder="CITY"
            style="margin-right: 2px;"
            type="text"
            @input="emitLocation"
          >
          <input
            v-model="entityCache.state"
            class="tag"
            placeholder="STATE"
            style="margin-right: 2px;"
            type="text"
            @input="emitLocation"
          >
          <input
            v-model="entityCache.country"
            class="tag"
            placeholder="COUNTRY"
            style="margin-right: 2px;"
            type="text"
            @input="emitLocation"
          >
        </div>
        <div style="display: flex; flex-wrap: nowrap; align-items: center;">
          <div style="text-align: right;">
            {{ entity.relationships.total_followers }} subscribers&nbsp;&nbsp;<br>
            {{ entity.relationships.total_supporters }} supporters&nbsp;&nbsp;
          </div>
          <IconButton
            v-if="!editingProfile"
            :class="following ? '' : 'icon-btn--tip-fill'"
            :icon="following ? 'mdi-account-check' : 'mdi-account-plus'"
            @click="toggleFollow"
          />
        </div>
        <SettingsButton
          v-if="editingProfile"
          label="Supporter counts"
          setting="supporter_counts"
          :showing="settings.supporter_counts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';
import SettingsButton from '@/components/buttons/SettingsButton.vue';

import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'EntityInfo',
  validations: {
    entityCache: {
      name: { required },
    }
  },
  components: {
    IconButton,
    SettingsButton,
  },
  data() {
    return {
      following: true,
      entityCache: {
        name: '',
        city: '',
        state: '',
        country: '',
      },
      editingName: false,
      editingLocation: false,
    }
  },
  computed: {
    ...mapState(['editingProfile', 'entity']),
    headerBackground() {
      return {
        position: 'absolute',
        top: '0px',
        right: '0px',
        left: '0px',
        borderRadius: '12px',
        background: 'rgba(0, 0, 0, 0)',
        backgroundImage: `url(${this.entity.image_url})`,
      }
    },
    settings() {
      return this.entity.settings;
    }
  },
  methods: {
    toggleFollow() {
      this.following = !this.following;
    },
    emitName() {
      const { name } = this.$v.entityCache;
      if (name.required) {
        this.$eventBus.$emit('new-name', name.$model);
      }
    },
    emitLocation(event) {
      const { placeholder, value } = event.target;
      this.$eventBus.$emit('new-location', {
        key: placeholder.toLowerCase(),
        value
      });
    },
    toggleEditingName() {
      this.editingName = !this.editingName;
    },
    toggleEditingLocation() {
      this.editingLocation = !this.editingLocation;
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-info {
  width: inherit;
}
.ei__header {
  @include for-size(small-screen-only) {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
  }
  @include for-size(medium-screen-up) {
    position: relative;
    width: calc(50vw - 20px);
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0px 0px 100px rgba(255, 255, 255, 0.25);
  }
  @include for-size(small-desktop-up) {
    width: calc(33vw - 20px);
  }
  &--ensemble {
    height: 248px;
  }
  &--series {
    height: 375px;
  }
  &--gradient {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    border-radius: 12px;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #000000 100%);
    z-index: 10;
    @include for-size(medium-screen-up) {
      display: none;
    }
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    object-position: center center;
  }
}

.hide-header-mobile {
  display: block;
  @include for-size(small-screen-only) {
    display: none;
  }
}

.hide-header-medium-up {
  @include for-size(medium-screen-up) {
    display: none;
  }
}

.ei__info {
  position: relative;
  margin-bottom: 20px;
  z-index: 100;
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
  @include for-size(medium-screen-up) {
    padding-top: 10px;
  }
}

.info-genres {
  display: flex;
  flex-wrap: wrap;
  > span:not(:last-child) {
    margin-right: 20px;
  }
  > span:nth-child(3n+0) {
    color: rgba(255, 82, 155, 0.7);
  }
  > span:nth-child(3n+1) {
    color: rgba(95, 226, 255, 0.7);
  }
  > span:nth-child(3n+2) {
    color: rgba(224, 255, 31, 0.7);
  }
}

.info-location-followers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.name-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input {
  width: 100%;
  max-width: 280px;
  height: 50px;
  display: block;
  outline: none;
  border: none;
  background-color: black;
  color: white;
  cursor: text;
  &:invalid {
    opacity: 0.5;
  }
}
</style>

